import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-success',
  templateUrl: './upload-success.component.html',
  styleUrls: ['./upload-success.component.css']
})
export class UploadSuccessComponent implements OnInit {
  text: string;
  button: string;
  heading: string;

  constructor(public matDialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // //console.log(this.data);
    
    if(this.data.data==="loop"){
      this.heading = "Loop Updated"
      this.text = "Your loop edits have been updated"
      this.button = "View loop"

    }
    if(this.data.data==="pack"){
      this.heading = "Pack Updated"
      this.text = "Your pack edits have been updated"
      this.button = "View pack"
    }
  }
  cancel() {
    this.matDialog.closeAll();
  }
}
