import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/allServices/api-service.service';

@Component({
  selector: 'app-s3bucket',
  templateUrl: './s3bucket.component.html',
  styleUrls: ['./s3bucket.component.css']
})
export class S3bucketComponent implements OnInit {

  constructor(public api: ApiServiceService) { }

  ngOnInit(): void {

    let sendingdata = {

    }

    this.api.forclientgettokenpublic(`/user/delete_s3_bucket_drafts`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {

      }



    })

  }

  }
