




import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormGroupDirective, NgForm } from '@angular/forms'
// import { CustomvalidationService } from './customvalidation.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { ElementRef } from '@angular/core';
import { EMPTY } from 'rxjs';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import * as CryptoJS from 'crypto-js';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
@Component({
  selector: 'app-userform',
  templateUrl: './userform.component.html',
  styleUrls: ['./userform.component.css']
})
export class UserformComponent implements OnInit {
  tempemail: any;
  emailget: any;
  temp_emailget: any;
  showemailtemperrors = false;
  signupformforloophead: FormGroup;
  buttonShow = false;
  url = "assets/img/default.jpg";
  urla = "assets/img/artwork.jpg";

  tableDataofcountry: any[] = [];
  tableDataofstate: any[] = [];
  countryid: any;
  Passandconfirmvalid = false;
  atleastonesocialmedialink = false;
  forcity: any = false;
  disabvalue: any = false;
  displayname = "none";
  errorforusername = false;
  usernameerror: string;
  imagevalidation = true;
  imagevalidationprofile = true
  showartworkcancel = false;
  reader = new FormData();
  canDeactivate: any = false;
  imageloopartwork: any
  imagename: any;
  imagenameprofile: any;
  imageloopartworkprofile: any
  getloopidfrommedia = "";
  showartworkcancelbutton: boolean;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  shouldhitapi: any = false;
  encryptedagainremoveslashes: string;
  name: string;
  show: boolean = false;
  imagenullforleft: any;
  imagenull: any;
  firsttime = true;
  showcityerror: string;
  showerrorforstate: boolean;
  page_referer: any;
  showonlyonedit: boolean = false;

  // imagevalidation = true;
  ngAfterViewInit() {
    this.encrypted = this.route.snapshot.params['id'];
    this.encryptedagainremoveslashes = decodeURIComponent(this.encrypted);
    this.decryptUsingAES256();
    if (this.route.snapshot.params['id'] !== "new") {
      this.name = "Save";
      this.show = true
      this.getapicallforacountinfo();
      // //console.log("old page ha");
    } else {
      this.name = "Sign up";
      // //console.log("new page ha");

    }
    this.route.queryParams.subscribe(params => {
      // this.getpageno = params.page_no;
      if (params.referer) {
        this.page_referer = params.referer;
      }
      else {
        this.page_referer = '/admin/user';
      }
      // //console.log(params.referer)
      // this.encrypted = params.coupon_id;

    });

    // this.signupformforloophead.reset();/


  }
  cancelandredirect() {
    // //console.log(this.page_referer);
    this.router.navigateByUrl(this.page_referer);
  }

  decryptUsingAES256() {
    // //console.log(this.encryptedagainremoveslashes);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    this.decrypted = this.decrypted.replace(/[""]/gi, '');

  }



  constructor(private formbuilder: FormBuilder, public common: commonservice, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService, public matDialog: MatDialog) {
    this.getapicallforcountry();

    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const no = '^[0-9]{4,11}$';
    const password = '^(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$';
    const instagram = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im;
    const twitter = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
    const youtube = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const tiktok = /(?:(?:http|https):\/\/)?(?:www\.)?(?:tiktok\.com)\/@*([A-Za-z0-9-_\.]+)/;
    const twitch = /https:\/\/(?:clips|www)\.twitch\.tv\/(?:(?:[a-z])\/clip\/)?([a-zA-Z]+)/
    const beatstars = /http(?:s)?:\/\/(?:www\.)?beatstars\.com\/([a-zA-Z0-9_]+)/
    const username = /^\S+$/;

    if (this.route.snapshot.params['id'] !== "new") {
      this.showonlyonedit = true;
      this.signupformforloophead = formbuilder.group({

        // tickbox: ["", [Validators.requiredTrue]],
        // Firstname: ["", [Validators.required]],
        full_name: ["", [Validators.required]],
        // Lastname: ["", [Validators.required]],
        username: ["", [Validators.required, Validators.pattern(username)]],
        emailerr: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.minLength(6), Validators.pattern(password)]],
        confirmpassword: ["", [Validators.minLength(6), Validators.pattern(password)]],
        country: ["", []],
        state: ["", []],
        city: ["", []],
        // zip: ["", [Validators.required, Validators.pattern(no)]],
        instagram: ["", [Validators.pattern(instagram)]],
        twitter: ["", [Validators.pattern(twitter)]],
        youtube: ["", [Validators.pattern(youtube)]],
        beatstars: ["", [Validators.pattern(beatstars)]],
        tiktok: ["", [Validators.pattern(tiktok)]],
        twitch: ["", [Validators.pattern(twitch)]],
        bio_guidelines: ["", [Validators.maxLength(160)]],
        profilevisible: ["", []]
      });


    } else {
      this.showonlyonedit = false
      this.signupformforloophead = formbuilder.group({

        // tickbox: ["", [Validators.requiredTrue]],
        // Firstname: ["", [Validators.required]],
        full_name: ["", [Validators.required]],
        // Lastname: ["", [Validators.required]],
        username: ["", [Validators.required, Validators.pattern(username)]],
        emailerr: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.minLength(6), Validators.required, Validators.pattern(password)]],
        confirmpassword: ["", [Validators.minLength(6), Validators.required, Validators.pattern(password)]],
        // country: ["", [Validators.required]],
        // state: ["", [Validators.required]],
        // city: ["", Validators.required],
        // zip: ["", [Validators.required, Validators.pattern(no)]],
        instagram: ["", [Validators.pattern(instagram)]],
        twitter: ["", [Validators.pattern(twitter)]],
        youtube: ["", [Validators.pattern(youtube)]],
        beatstars: ["", [Validators.pattern(beatstars)]],
        tiktok: ["", [Validators.pattern(tiktok)]],
        twitch: ["", [Validators.pattern(twitch)]],
        bio_guidelines: ["", [Validators.maxLength(160)]],
      });

      this.imagenullforleft = true;
      this.imagenull = true;
    }

  }
  ngOnInit(): void {

    let left_sidebar: any = document.getElementsByClassName('sidebar-menu');
    if (left_sidebar.length > 0) {
      left_sidebar = left_sidebar[0];
      left_sidebar.querySelector('[href="/admin/user"]').parentElement.classList.add("active", 'menu-open', 'custom-active');
    }
    // this.signupformforloophead.reset();
    // let local = localStorage.getItem("clientintoken");
    // if (local) {

    //   this.router.navigate(['/plandetails']);


    // }

  }


  ngOnDestroy(): void {
    // this.signupformforloophead.reset();



  }
  cancelartwork() {
    let id: any = document.getElementById("imageerror");
    this.url = "assets/img/artwork.jpg";
    this.imagevalidation = true;
    id.innerHTML = "";
    this.showartworkcancelbutton = false;
    // this.callfordeleteartwork();
  }


  getapicallforacountinfo() {

    let sendingdata = {
      user_type_id: this.decrypted
    }
    this.common.showLoader();

    this.api.postApiHit(APIURL.admin_savedetailpage, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {


      this.common.hideLoader();
      this.buttonShow = false;
      if (res.response.status == 1) {

        // //console.log(res.response.data[0])

        this.displayname = "none";

        this.emailget = res.response.data.email;
        this.temp_emailget = res.response.data.temp_email;
        // //console.log(this.temp_emailget);

        if (this.emailget !== this.temp_emailget) {
          this.showemailtemperrors = true;
          this.tempemail = this.temp_emailget

        }
        this.signupformforloophead.patchValue({
          // profilevisible: res.response.data.profile_type,
          // Firstname: res.response.data.first_name,
          // Lastname: res.response.data.last_name,
          full_name: res.response.data[0].first_name + " " + res.response.data[0].last_name,
          username: res.response.data[0].username,
          emailerr: res.response.data[0].email,
          country: res.response.data[0].country,
          state: res.response.data[0].state,
          city: res.response.data[0].city,
          // zip: res.response.data[0].zip,
          instagram: res.response.data[0].instagram_link,
          twitter: res.response.data[0].twitter_link,
          youtube: res.response.data[0].youtube_link,
          beatstars: res.response.data[0].beatstars_link,
          tiktok: res.response.data[0].tiktok_link,
          twich: res.response.data[0].twitch_link,
          bio_guidelines: res.response.data[0].bio_guidelines,
          confirmpassword: '',
          password: '',
          // profile_type: this.signupformforloophead.value.profilevisible,
          // header_image_path : res.response.data[0].header_image_path
          // profile_image_path: res.response.data[0].profile_image_path
          // last_name: this.signupformforloophead.value.Lastname,
          // email: this.signupformforloophead.value.emailerr,
          // country: this.signupformforloophead.value.country,
          // city: this.signupformforloophead.value.city,
          // beatstars_link: this.signupformforloophead.value.beatstars,
          // instagram_link: this.signupformforloophead.value.instagram,
          // password: this.signupformforloophead.value.password,
          // confirm_new_password: this.signupformforloophead.value.confirmpassword,
          // state: this.signupformforloophead.value.state,

          // tiktok_link: this.signupformforloophead.value.tiktok,
          // twitch_link: this.signupformforloophead.value.twitch,
          // twitter_link: this.signupformforloophead.value.twitter,
          // username: this.signupformforloophead.value.username,
          // youtube_link: this.signupformforloophead.value.youtube,
          // zip: this.signupformforloophead.value.zip

        });

        if (this.shouldhitapi == false) {
          if (res.response.data[0].country == null || res.response.data[0].country == "") {

          } else {
            this.getapicallforcity(res.response.data[0].country);
          }

        } else {

        }
        // //console.log(res.response.data[0].profile_type)

        if (res.response.data[0].profile_type == 1) {
          // //console.log(1)
          // this.profiletype = "Public"
          this.signupformforloophead.patchValue({
            profilevisible: 0
          })
        } else {
          // //console.log(0)
          // this.profiletype = "Private"
          this.signupformforloophead.patchValue({
            profilevisible: 1
          })
        }
        if (res.response.data[0].email == null) {
          this.signupformforloophead.patchValue({
            emailerr: res.response.data[0].temp_email
          })
        }

        if (res.response.data[0].header_image_path !== null) {
          this.imagenullforleft = false;
          // //console.log("gaya")
          this.url = CONSTNAME.fILE_PATH + res.response.data[0].header_image_path
          // this.url = CONSTNAME.fILE_PATH + "/loopkit_artworks/1626421188636-4644bd195d081820a4f7.jpg"
        } else {
          this.imagenullforleft = true;
          this.url = "assets/img/default.jpg";

        }
        if (res.response.data[0].profile_image_path !== null) {
          this.urla = CONSTNAME.fILE_PATH + res.response.data[0].profile_image_path
          this.imagenull = false;
        } else {
          this.imagenull = true;
          this.urla = "assets/img/artwork.jpg";
        }

        if (res.response.data.last_name == null) {
          this.signupformforloophead.patchValue({
            full_name: res.response.data[0].first_name
          })

        }
      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          this.displayname = "none";
        }
      }
    }, async e => {
      this.common.hideLoader();


      this.displayname = "none";

      this.toastr.error("", "", { positionClass: 'toast-bottom-right' });



    });
  }








  passwordConfirm() {

    let id: any = document.getElementById("cpassword");
    id.addEventListener("change", () => {

      let againid: any = document.getElementById("confirmpasserror");
      if (this.signupformforloophead.value.password !== this.signupformforloophead.value.confirmpassword) {
        // againid.innerHTML = "Passsword and Confirm Password didnt match";
        this.Passandconfirmvalid = false;

      } else {
        againid.innerHTML = "";
        this.Passandconfirmvalid = true;

      }

    })

  }







  onSubmit() {

    // if (this.signupformforloophead.value.state == "") {
    //   this.showerrorforstate = true;
    //   this.showcityerror = "State is required"
    // } else {
    //   this.showerrorforstate = false;
    //   this.showcityerror = ""
    // }
    // //console.log("hello")


    // for (const key of Object.keys(this.signupformforloophead.controls)) {
    //   if (this.signupformforloophead.controls[key].invalid) {
    //     const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
    //     invalidControl.focus();
    //     break;
    //   }
    // }

    if (this.signupformforloophead.value.username.indexOf('@') == 0) {        //if @ is present in the beginning of word , remove it
      this.signupformforloophead.value.username = this.signupformforloophead.value.username.replace(/^@+/, '')
    }
    // //console.log(this.signupformforloophead.value.username)
    if (this.signupformforloophead.value.username == "") {
      this.usernameerror = "write '@' like this";
      this.errorforusername = true;

      // //console.log("khali");
    } else {
      this.usernameerror = "";
      this.errorforusername = false;
    }



    let againid: any = document.getElementById("confirmpasserror");
    let atleast1social: any = document.getElementById("atleastonesocialmedialink");
    // //console.log(this.Passandconfirmvalid, this.signupformforloophead.valid);
    let passwordverification: any = this.signupformforloophead.value.password === this.signupformforloophead.value.confirmpassword;
    // //console.log(passwordverification);
    // //console.log(this.signupformforloophead.value.tiktok.length)

    // if ((this.signupformforloophead.value.tiktok == "" || this.signupformforloophead.value.tiktok == null) && (this.signupformforloophead.value.twitch == "" || this.signupformforloophead.value.twitch == null) && (this.signupformforloophead.value.youtube == "" || this.signupformforloophead.value.youtube == null)&& (this.signupformforloophead.value.beatstars == "" || this.signupformforloophead.value.beatstars == null )&& (this.signupformforloophead.value.instagram == "" || this.signupformforloophead.value.instagram == null ) && (this.signupformforloophead.value.twitter == "" || this.signupformforloophead.value.twitter == null) == true) {
    //   atleast1social.innerHTML = "At Least one of the social media link is required"
    //   this.atleastonesocialmedialink = false;

    // } else {
    //   atleast1social.innerHTML = "";
    //   this.atleastonesocialmedialink = true;
    // }

    if (passwordverification) {
      againid.innerHTML = "";
      this.Passandconfirmvalid = true;
    } else {
      againid.innerHTML = "Passsword and Confirm Password didnt match";
      this.Passandconfirmvalid = false;
    }


    // //console.log(this.signupformforloophead.valid);
    // //console.log(this.Passandconfirmvalid);
    // //console.log(this.atleastonesocialmedialink);
    // && this.atleastonesocialmedialink == true
    // && this.showerrorforstate == false
    this.passwordConfirm();
    this.signupformforloophead.markAllAsTouched();
    if (this.signupformforloophead.valid == true && this.Passandconfirmvalid == true && this.errorforusername == false && this.imagevalidation == true && this.imagevalidationprofile == true) {


      this.apiforsubmitform();
      this.displayname = "";

    }
    else {
      // //console.log("not working");

    }

  }
  changevalue = false;


  valuepass() {
    this.countryid = this.signupformforloophead.value.country;

    this.forcity = true;
    this.getapicallforcity(this.countryid);
    if (this.signupformforloophead.value.country === "") {
      this.changevalue = false;
    } else {
      this.changevalue = true;
    }



  }

  testhtml() {
    if (this.changevalue == true) {
      return "Loading...Please wait"
    } else {
      return "Select";
    }

  }


  getapicallforcountry() {



    const headers = "";

    this.http.post(APIURL.BASE_URL + '/user/get_all_countries', { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        this.tableDataofcountry = res.response.data;



      } else {
        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.toastr.error("Failed to get country details ", "", { positionClass: 'toast-bottom-right' });
    });
  }





  getapicallforcity(value: any) {
    let sendingData: any;
    if (this.signupformforloophead.value.country === "") {
      this.tableDataofstate = [];
      this.changevalue = false;
      // //console.log("hey");
    } else {
      sendingData = {
        "country_id": value
      }


      this.http.post(APIURL.BASE_URL + '/user/get_states_for_country_id', sendingData).subscribe(async (res: any) => {
        if (res.response.status == 1) {
          this.changevalue = false;

          if (this.firsttime == true) {
            // //console.log("first time")
            this.firsttime = false;
          } else {
            this.signupformforloophead.value.state = "";
            // //console.log("second time")

          }

          this.tableDataofstate = res.response.data;




        } else {
          let errorthroughapi = res.response.errors;
          let error = [];
          for (var i = 0; i < errorthroughapi.length; i++) {
            let jk = error.push(errorthroughapi);
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }

        }
      }, async e => {
        this.changevalue = false;
        this.toastr.error("Failed to get state details ", "", { positionClass: 'toast-bottom-right' });
      });
    }




  }




  apiforsubmitform() {
    // //console.log(this.signupformforloophead.value)
    this.common.showLoader();
    this.buttonShow = true;

    // if (this.signupformforloophead.value.username.indexOf('@') == 0) {        //if @ is present in the beginning of word , remove it
    //   this.signupformforloophead.value.username = this.signupformforloophead.value.username.replace(/^@+/, '')
    // }

    let sendingData = {
      // first_name: this.signupformforloophead.value.Firstname,
      // last_name: this.signupformforloophead.value.Lastname,
      // full_name: this.signupformforloophead.value.full_name,
      // email: this.signupformforloophead.value.emailerr,
      // country: this.signupformforloophead.value.country,
      // confirm_new_password: this.signupformforloophead.value.confirmpassword,
      // city: this.signupformforloophead.value.city,
      // beatstars_link: this.signupformforloophead.value.beatstars,
      // instagram_link: this.signupformforloophead.value.instagram,
      // password: this.signupformforloophead.value.password,
      // state: this.signupformforloophead.value.state,
      // // terms_policy_checkbox: this.signupformforloophead.value.tickbox,
      // tiktok_link: this.signupformforloophead.value.tiktok,
      // twitch_link: this.signupformforloophead.value.twitch,
      // twitter_link: this.signupformforloophead.value.twitter,
      // username: this.signupformforloophead.value.username,
      // youtube_link: this.signupformforloophead.value.youtube,
      // zip: this.signupformforloophead.value.zip,
      // bio_guidelines: this.signupformforloophead.value.bio_guidelines

    }

    this.reader.append("full_name", this.signupformforloophead.value.full_name);
    this.reader.append("email", this.signupformforloophead.value.emailerr);

    this.reader.append("confirm_new_password", this.signupformforloophead.value.confirmpassword);
    if (this.showonlyonedit) {
      if (this.signupformforloophead.value.city == null) {
        this.reader.append("city", "");
      } else {
        this.reader.append("city", this.signupformforloophead.value.city);
      }

      if (this.signupformforloophead.value.state == null) {
        this.reader.append("state", "");
      } else {
        if (this.signupformforloophead.value.country == null || this.signupformforloophead.value.country == "") {
          this.reader.append("state", "");
        } else {
          this.reader.append("state", this.signupformforloophead.value.state);
        }
        
      }

      if (this.signupformforloophead.value.country == null || this.signupformforloophead.value.country == "" || this.signupformforloophead.value.country.length == 0) {
        this.reader.append("country", "");
        // this.reader.append("state", "");
      } else {
        this.reader.append("country", this.signupformforloophead.value.country);
      }




    }


    this.reader.append("password", this.signupformforloophead.value.password);




    this.reader.append("username", this.signupformforloophead.value.username);

    // this.reader.append("zip", this.signupformforloophead.value.zip);
    // this.reader.append("bio_guidelines", this.signupformforloophead.value.bio_guidelines);
    // this.reader.append("profile_image_path", this.imageloopartworkprofile, this.imagenameprofile);
    // this.reader.append("header_image_path", this.imageloopartwork, this.imagename);
    if (this.signupformforloophead.value.bio_guidelines !== "" && this.signupformforloophead.value.bio_guidelines !== null) {
      this.reader.append("bio_guidelines", this.signupformforloophead.value.bio_guidelines)
    }
    if (this.signupformforloophead.value.tiktok !== "" && this.signupformforloophead.value.tiktok !== null) {
      this.reader.append("tiktok_link", this.signupformforloophead.value.tiktok);
    }
    if (this.signupformforloophead.value.twitch !== "" && this.signupformforloophead.value.twitch !== null) {
      this.reader.append("twitch_link", this.signupformforloophead.value.twitch);
    }
    if (this.signupformforloophead.value.twitter !== "" && this.signupformforloophead.value.twitter !== null) {
      this.reader.append("twitter_link", this.signupformforloophead.value.twitter);
    }
    if (this.signupformforloophead.value.youtube !== "" && this.signupformforloophead.value.youtube !== null) {
      this.reader.append("youtube_link", this.signupformforloophead.value.youtube);
    }
    if (this.signupformforloophead.value.beatstars !== "" && this.signupformforloophead.value.beatstars !== null) {
      this.reader.append("beatstars_link", this.signupformforloophead.value.beatstars);
    }
    if (this.signupformforloophead.value.instagram !== "" && this.signupformforloophead.value.instagram !== null) {
      this.reader.append("instagram_link", this.signupformforloophead.value.instagram);

    }

    if (this.route.snapshot.params['id'] !== "new") {
      this.reader.append("user_type_id", this.decrypted);
      if (this.signupformforloophead.value.profilevisible == 1) {
        // //console.log("yes this will send")
        this.reader.append("profile_type", "0");

        // this.profiletype = "Public"
      } else {
        // //console.log("yes this will send",1)
        this.reader.append("profile_type", "1");
        // this.profiletype = "Private"
      }

      // this.sendingdata["coupon_code_id"] = ;

    } else {

    }
    this.api.postApiHit(APIURL.admin_registerpage, this.reader, VARS.ADMIN_TYPE).then((res: any) => {
      // this.http.post(APIURL.BASE_URL + '/admin/save_user', sendingData).subscribe(async (res: any) => {
      this.common.hideLoader();
      this.buttonShow = false;
      if (res.response.status == 1) {
        this.shouldhitapi = true;
        this.reader = new FormData();
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        this.router.navigate(['/admin/user'])




        // this.router.navigate(['plandetails']);
        this.displayname = "none";
      } else {
        this.reader = new FormData();
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          this.displayname = "none";
        }
      }
    }, async e => {
      this.common.hideLoader();


      this.displayname = "none";

      this.toastr.error("Error in api ", "", { positionClass: 'toast-bottom-right' });



    });


  }
  keyupfunc() {
    var text_max = 160;
    let get: any = document.getElementById("descriptiontext");
    // //console.log(get.value.length);
    let text_remainning = text_max - get.value.length
    // //console.log(text_remainning);
    let getid: any = document.getElementById("textarea_feedback");
    if (get.value.length > 0) {
      getid.innerHTML = (text_remainning + " characters remaining");
    } else {
      getid.innerHTML = "";
    }
  }

  selectFile(event: any) {
    let id: any = document.getElementById("imageerror");
    if (event.target.files[0] == undefined) {
      this.url = "assets/img/artwork.jpg";
      this.imagevalidation = true;
      id.innerHTML = "";
      // this.canDeactivate = true;
    } else {
      if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpg') {
        // this.canDeactivate = true;
        if (event.target.files[0].size > 10000000) {
          this.imagevalidation = false;

          id.innerHTML = "Image size can be max 10 mb";

          // alert("error size issue");

        } else {



          if (event.target.files) {

            this.imageloopartwork = event.target.files[0]

            this.imagevalidation = true;
            id.innerHTML = "";

            this.showartworkcancel = true;


            var read = new FileReader()
            read.readAsDataURL(event.target.files[0])
            read.onload = (event: any) => {
              this.url = event.target.result;
            }

            this.imagename = event.target.files[0].name;

            this.reader.append("header_image_path", this.imageloopartwork, this.imagename);
            // if (this.getloopidfrommedia !== "") {
            //   this.reader.append("loop_id", this.getloopidfrommedia);
            // }
            this.canDeactivate = true;




          }





        }
      } else {
        this.imagevalidation = false;
        id.innerHTML = "Image file should be in jpeg,png,jpg";

      }
    }


  }
  selectFileprofile(event: any) {
    let id: any = document.getElementById("imageerrorprofile");
    if (event.target.files[0] == undefined) {
      this.urla = "assets/img/artwork.jpg";
      this.imagevalidationprofile = true;
      id.innerHTML = "";
      // this.canDeactivate = true;
    } else {
      if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpg') {
        // this.canDeactivate = true;
        if (event.target.files[0].size > 10000000) {
          this.imagevalidationprofile = false;

          id.innerHTML = "Image size can be max 10 mb";

          // alert("error size issue");

        } else {



          if (event.target.files) {

            this.imageloopartworkprofile = event.target.files[0]

            this.imagevalidationprofile = true;
            id.innerHTML = "";

            // this.showartworkcancel = true;


            var read = new FileReader()
            read.readAsDataURL(event.target.files[0])
            read.onload = (event: any) => {
              this.urla = event.target.result;
            }

            this.imagenameprofile = event.target.files[0].name;

            this.reader.append("profile_image_path", this.imageloopartworkprofile, this.imagenameprofile);
            // if (this.getloopidfrommedia !== "") {
            //   this.reader.append("loop_id", this.getloopidfrommedia);
            // }
            // this.canDeactivate = true;




          }





        }
      } else {
        this.imagevalidationprofile = false;
        id.innerHTML = "Image file should be in jpeg,png,jpg";

      }
    }


  }

  verifyemail() {
    this.apihitforresendverification(this.temp_emailget);
    // //console.log("click hua");
  }

  apihitforresendverification(data: any) {

    let sendingData = {
      "email": data
    }
    this.common.showLoader();
    this.http.post(APIURL.BASE_URL + `/admin/resend_verification_mail`, sendingData).subscribe(async (res: any) => {
      if (res.response.status == 1) {


        this.common.hideLoader();
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      } else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
    });
  }

  // cancelandredirect() {
  //   this.router.navigate(['/admin/user'])
  // }

  editleft() {
    // //console.log("file-input");
    let a: any = document.getElementById('file-inputshiddenforleft');
    a.click()
  }
  edit() {
    // //console.log("file-input");
    let a: any = document.getElementById('file-inputshidden');
    a.click()
  }
  removeimage() {
    this.opendialogueforverification();
  }

  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "remove", pagename: "profile" } });
    dialogue.afterClosed().subscribe(result => {

      // return new Promise(resolve => {
      //   if (num) {

      //     resolve((Math.round(num * 100) / 100).toFixed(2))
      //   }
      // })
      // //console.log('The dialog was closed');
      // //console.log(result);

      if (result.data == true) {
        this.removeheaderima();
      } else {

      }

    });
  }

  removeheaderima() {
    this.common.showLoader();
    let sendingdata = {
      "remove_profile_image": 1
    }
    this.api.postApiHit('/user/remove_profile_header_image', sendingdata, VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        let keys = res.response.data.loop_id;
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        // this.getUserDetail();
        this.getapicallforacountinfo();
        this.common.hideLoader();

      } else {
        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
        this.common.hideLoader();
      }
    }, async e => {
      this.toastr.error("", "", { positionClass: 'toast-bottom-right' });
      this.common.hideLoader();
    });
  }

  remove() {
    this.removeheaderimage();
  }

  removeheaderimage() {
    this.opendialogueforremove();
  }

  opendialogueforremove(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "remove" } });
    dialogue.afterClosed().subscribe(result => {

      // return new Promise(resolve => {
      //   if (num) {

      //     resolve((Math.round(num * 100) / 100).toFixed(2))
      //   }
      // })
      // //console.log('The dialog was closed');
      // //console.log(result);

      if (result.data == true) {
        this.removeyes();
      } else {

      }

    });
  }

  removeyes() {
    this.common.showLoader();
    this.api.postApiHit('/user/remove_profile_header_image', {}, VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        let keys = res.response.data.loop_id;
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        // this.getUserDetail();
        this.getapicallforacountinfo();
        this.common.hideLoader();

      } else {
        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
        this.common.hideLoader();
      }
    }, async e => {
      this.toastr.error("", "", { positionClass: 'toast-bottom-right' });
      this.common.hideLoader();
    });
  }


}

