<app-header></app-header>
<div style="min-height: 100vh">
  <div class="page-header" style="background:white;background-size: cover; margin-bottom: 0px;">
    <div class="page-header-image"></div>
    <div class="divModal" style="position: relative">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mx-auto client-login">
          <div class="card z-index-0">
            <h1 class="color page-head" >Upload</h1>
            <div _ngcontent-xpf-c249="" class="row step-rw" >
              <div _ngcontent-xpf-c249="" class="rw col-xl-2 col-md-3 col-sm-5 col-6">
                <div _ngcontent-xpf-c249=""  [ngClass]="showsecondpage?'icons2':'icons'" *ngIf="!showsecondpage">1</div>
                <div _ngcontent-xpf-c249=""  [ngClass]="showsecondpage?'icons2':'icons'" *ngIf="showsecondpage"><i
                  class="fa fa-check "
                  style="
                    font-size: 14px;
                    color: #04C300;
                    /* z-index: 9999; */
                    position: relative;
                    top: -3px;
                  "
                ></i></div>
                <div _ngcontent-xpf-c249="" class="color step-head" [ngClass]="showsecondpage?'text1':'text'">Basic Info</div>
              </div>
              <div _ngcontent-xpf-c249="" class="rw">
                <div _ngcontent-xpf-c249="" class="color arrow">&gt; </div>
              </div>
              <div _ngcontent-xpf-c249="" class="rw col-xl-3 col-md-4 col-sm-5 col-6">
                <div _ngcontent-xpf-c249="" [ngClass]="!showsecondpage?'icons2':'icons'">2</div>
                <div _ngcontent-xpf-c249="" class="color step-head" [ngClass]="!showsecondpage?'text1':'text'">Optional Details</div>
              </div>
            </div>
            <form >
              <div
                class="row"
                style="justify-content: space-between; margin-top: 60px"
              >
                <div class="col-md-4 col">
                  <label class="color" style="font-size: 20px; font-weight: 600"
                    >Loop Name</label
                  >
                </div>
                <div
                  style="display: flex; flex-direction: column"
                  class="col-md-8 col-12"
                >
                  <input
                    style="
                      font-size: 16px;
                      width: 100%;
                      border-radius: 5px;
                      padding:10px 15px;
                      border: 1px solid #959595;
                      color: black;
                    "
                    [(ngModel)]="loop_name"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="Enter Loop Name"
                  />
                  <small *ngIf="loop_name_error" class="text-danger">Please enter loop name of less than 50 characters</small>
                </div>
              </div>
              <label></label>
              <div class="row mt-5">
                <div class="col-md-4 col">
                  <label class="color" style="font-size: 19px; font-weight: 600"
                    >Upload .mp3 or .wav
                  </label>
                </div>
              <div class="col-md-8 col-12">
                  <div class="browse-div">
                    <div style="padding: 20px;position:relative;zIndex:10;" *ngIf="disable_input_as_itsloading">
                      <label class="after-upload" *ngIf="!loopprogress && disable_input_as_itsloading"><p style="word-break: break-all;">{{ fileName }}</p>
                        <i (click)="deleteFile()" class="fa fa-trash" style=" font-size: 14px;color: #c70000; cursor: pointer;
                        /* z-index: 9999; */
                        position: relative;bottom:5px">
                        
                        </i>
                      </label>
                    
                      <div 
                      *ngIf="loopprogress"
                      class="spinner-box">
                        <circle-progress
  [percent]="percentage"
  [radius]="50"
  [outerStrokeWidth]="5"
  [innerStrokeWidth]="2"
  [outerStrokeColor]="'black'"
  [innerStrokeColor]="'#6c757d'"
  [animation]="false"
></circle-progress>
<button class="cancel" (click)="cancelUploadRequest()">Cancel</button>
                      </div>
                      <label
                        style="
                          color:#797979;
                          display: flex;
                          justify-content: flex-start;
                          padding-top: 10px;
                          align-items: center;
                          column-gap: 5px;
                          font-size: 16px;
                        "
                        *ngIf="!loopprogress && disable_input_as_itsloading"
                      >
                        <i
                          class="fa fa-check "
                          style="
                            font-size: 21px;
                                color: #04C300;
                                /* z-index: 9999; */
                                position: relative;
                                margin-right: 8px;
                          "
                        ></i>
                        file uploaded successfully
                     
                      </label>
                    </div>
                    <div class="before-text"  *ngIf="!disable_input_as_itsloading" >
                      <img src="../../../assets/img/file-download-03.svg" class="select-Label"  *ngIf="!disable_input_as_itsloading && !loopprogress"/>
                      <label  *ngIf="!disable_input_as_itsloading"   class="select-Label" [matTooltip]=" disable_input_as_itsloading
                          ? 'You cannot perform this action while uploading is in progress'
                          : ''  "for="uploadlooptit">Drag & Drop a File or
                        <div class="form-group" style=" margin-bottom:0px;" [ngClass]="dragClass">
                        <input
                          type="file"
                          id="fileinput"
                          [attr.disabled]="
                            disable_input_as_itsloading ? true : null
                          "
                          accept=".wav, .mp3"
                          class="form-control"
                          (change)="selectFilemandatory($event)"
                          placeholder="Title"
                          style="position: absolute;"
                        />
                        <span class="link"> Browse</span>
                        </div>
                      <span class="file-format">.wav or .mp3 files only</span>
                      </label>
                    </div>
                    <ngx-file-drop
                      dropZoneClassName="drop-zone"
                      class="dropBox"
                      (onFileDrop)="onFileDrop($event)"
                    ></ngx-file-drop>
                  </div>
                  <div
                    _ngcontent-xyg-c237=""
                    style="
                      display: flex;
                      margin-top: 20px; 
                      column-gap: 20px; 
                    "
                  >
                    <label _ngcontent-xyg-c237="" for="checkbox" class="switch">
                      <input
                        _ngcontent-xyg-c237=""
                        type="checkbox"
                        id="checkbox"
                        [(ngModel)]="visiblity"
                        [ngModelOptions]="{standalone: true}"
                      />
                      <div _ngcontent-xyg-c237="" class="slider round"></div>
                    </label>
                    <p _ngcontent-xyg-c237="" class="toggleText">
                      Make this loop private
                      <button
                      style="
                      padding: 0px 12px;
                      box-shadow: none;
                      min-width: auto;
                    "
                      mat-raised-button
                      matTooltip="Making a loop private means that it is only shareable by link"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <img src="../../../assets/img/Icon.svg"/>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </form>
            <form *ngIf="showsecondpage">
              <div style="margin-top: 50px" class="row">
                <div class="col-md-4 col-sm-7 col">
                  <div style="display: grid;">
                    <label class="color label upload-head"  style="text-align: start;font-size: 20px; font-weight: 600;    padding: 0px 0px 10px;"
                      >Upload Artwork
                    </label>
                    <label
                      class="color"
                      style="font-size: 15px; font-weight: 500; color: #797979;padding: 0px 10px;    padding: 0px 0px 10px;"
                      >Artwork will appear with <br>your loop in searches
                    </label>
                  </div>
                </div>
                <div class="col-md-8 col-12">
                  <div class="browse-div">
                    <div
                      style="
                        padding: 20px;
                        display: flex;"
                      *ngIf="disable_input_as_itsloading1"
                    >
                      <div class="mx-auto"  *ngIf="imageProgress">
                        <div class="spinner-box"
                        *ngIf="imageProgress">
                      
                          <circle-progress
                          [percent]="imgpercentage"
                          [radius]="50"
                          [outerStrokeWidth]="5"
                          [innerStrokeWidth]="2"
                          [outerStrokeColor]="'black'"
                          [innerStrokeColor]="'#6c757d'"
                          [animation]="false"
                        ></circle-progress>
                        <button class="cancel1" (click)="cancelImageUploadRequest()">Cancel</button>
                      </div>
                       

                        
                      </div>
                      <div>
                      <label
                      class="img-file-name" style="margin-right: 30px;position:relative;zIndex:10;"
                      *ngIf="!imageProgress && disable_input_as_itsloading1"
                      >{{ ImagefileName }}

                      <i
                        (click)="deleteImageFile()"
                        class="fa fa-trash"
                        style="
                          font-size: 14px;
                          color: #c70000;
                          /* z-index: 9999; */
                          position: relative;
                          cursor: pointer;
                        "
                      ></i>
                    </label>
                      <label
                          style="
                            color:#797979;
                            display: flex;
                            justify-content: flex-start;
                            padding-top: 10px; /* padding-left: 50px; */
                            align-items: center;
                            column-gap: 5px;
                            font-size: 16px;
                            margin-right: 30px;
                          "
                          *ngIf=" !imageProgress && disable_input_as_itsloading1 "
                        >
                          <i
                            class="fa fa-check "
                            style="
                              font-size: 21px;
                              color: #04C300;
                              /* z-index: 9999; */
                              position: relative;
                              margin-right: 8px;
                            "
                          ></i>
                          file uploaded successfully
                        </label>
                      </div>
                        <div>
                        <img
                          style="max-height:140px;object-fit: cover;width: 100%;"
                          *ngIf="selectedImage && !imageProgress"
                          [src]="selectedImage"
                          alt="Selected Image"
                        />
                      </div>
                    </div>
                    <div class="before-text"  *ngIf="!disable_input_as_itsloading1">
                    <img
                      src="../../../assets/img/file-download-03.svg"
                      class="select-Label"
                      *ngIf="!disable_input_as_itsloading1"
                    />
                    <label
                      *ngIf="!disable_input_as_itsloading1 && !imageProgress"
                      class="select-Label"
                      [matTooltip]="
                        disable_input_as_itsloading1
                          ? 'You cannot perform this action while uploading is in progress'
                          : ''
                      "
                      for="uploadlooptit"
                      >Drag & Drop a Files  or
                      <div
                        class="form-group"
                        style=" margin-bottom: 0px;"
                        [ngClass]="dragClass"
                      >
                        <input
                          type="file"
                          id="fileinput"
                          [attr.disabled]="
                            disable_input_as_itsloading1 ? true : null
                          "
                          accept=".jpeg, .jpg, .png"
                          class="form-control"
                          (change)="selectFileImage($event)"
                          placeholder="Title"
                          style="position: absolute;"
                        />
                        <span class="link"> Browse</span>
                      </div>
                      .jpeg & .png files only
                    </label>
                  </div>
                    <ngx-file-drop
                      *ngIf="showsecondpage"
                      dropZoneClassName="drop-zone"
                      class="dropBox"
                      (onFileDrop)="onFileDropArtwork($event)"
                    ></ngx-file-drop>
                  </div>
                </div>
              </div>

              <div class="row stem-rw">
                <div class="col-md-4 col">
                  <label style="font-size: 20px; font-weight: 600;    padding: 0px 0px 10px;" class="color label"
                    >Upload Stems
                  </label>
                </div>
                <div class="col-md-8 col-12">
                  <div class="browse-div">
                    <div
                     style="padding: 20px;position:relative;zIndex:10;"
                      *ngIf="disable_input_as_itsloading2"
                    >
                      <label
                        class="drop-file-name"
                        *ngIf="!zipProgress && disable_input_as_itsloading2"
                        >{{ zipfilename }}

                        <i
                          (click)="deleteZipFile()"
                          class="fa fa-trash"
                          style="
                            font-size: 14px;
                            color: #c70000;
                            /* z-index: 9999; */
                            position: relative;
                            cursor: pointer;
                          "
                        ></i>
                      </label>
                      <label
                        style="
                          color: #797979;
                          display: flex;
                          justify-content: flex-start;
                          padding-top: 10px; /* padding-left: 50px; */
                          align-items: center;
                          column-gap: 5px;
                          font-size: 16px;
                        "
                        *ngIf="disable_input_as_itsloading2 && !zipProgress"
                      >
                        <i
                          class="fa fa-check"
                          style="font-size: 21px;
                          color: #04C300;
                          /* z-index: 9999; */
                          position: relative;
                          margin-right: 8px;
                          "
                        ></i>
                        file uploaded successfully
                      </label>
                      <div *ngIf="zipProgress" class="spinner-box">
                        <circle-progress
                        [percent]="stempercentage"
                        [radius]="50"
                        [outerStrokeWidth]="5"
                        [innerStrokeWidth]="2"
                        [outerStrokeColor]="'black'"
                        [innerStrokeColor]="'#6c757d'"
                        [animation]="false"
                      ></circle-progress>
                      <button class="cancel" (click)="cancelStemUploadRequest()">Cancel</button>
                      </div>
                    </div>
                    <div class="before-text"  *ngIf="!disable_input_as_itsloading2">
                    <img
                      src="../../../assets/img/file-download-03.svg"
                      class="select-Label"
                      *ngIf="!disable_input_as_itsloading2 && !zipProgress"
                    />
                    <label
                      *ngIf="!disable_input_as_itsloading2"
                      class="select-Label"
                      [matTooltip]="
                        disable_input_as_itsloading2
                          ? 'You cannot perform this action while uploading is in progress'
                          : ''
                      "
                      for="uploadlooptit"
                      >Drag & Drop a File  or
                      <div
                        class="form-group"
                        style=" margin-bottom: 0px;"
                        [ngClass]="dragClass"
                      >
                        <input
                          type="file"
                          id="fileinput"
                          [attr.disabled]="
                            disable_input_as_itsloading2 ? true : null
                          "
                          accept=".zip"
                          class="form-control"
                          (change)="selectFileZip($event)"
                          placeholder="Title"
                          style="position: absolute;"
                        />
                        <span class="link"> Browse</span>
                      </div>
                      .zip files only
                    </label>
                    </div>
                    <ngx-file-drop
                      id="3"
                      *ngIf="showsecondpage"
                      dropZoneClassName="drop-zone"
                      class="dropBox"
                      (onFileDrop)="onFileDropZip($event)"
                    ></ngx-file-drop>
                  </div>
                </div>
              </div>

              <div class="row stem-rw">
                <div class="col-md-4">
                  <label style="font-size: 20px;    padding: 0px 0px 10px;" class="color label"
                    >Usage Rights</label
                  >
                </div>
                <div class="col-md-8 col-12">
                  <textarea
                    placeholder="i.e. 50/50 split and Production Credits"
                    class="custom-input"
                    [(ngModel)]="usage"
                    [ngModelOptions]="{ standalone: true }"
                  ></textarea>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      column-gap: 20px;
                      margin: 20px 0px 0px;
                    "
                  >
                    <label class="switch" for="checkbox1">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        [(ngModel)]="set_usage"
                        [ngModelOptions]="{standalone: true}"
                      />
                      <div class="slider round"></div>
                    </label>
                    <p class="toggleText">Set to default usage rights</p>
                  </div>
                </div>
              </div>
              <div class="row stem-rw">
                <div class="col-md-4 col">
                  <label style="font-size: 20px;    padding: 0px 0px 10px;"class="color label">Main Key</label>
                </div>
                <div class="col-md-8 col-12 errr">
                  <select
                    class="short-input"
                    [(ngModel)]="main_key"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="Select Main key"
                  >
                  <option *ngFor="let i of main_keys" value="{{i.key}}">{{i.name}}</option>
                 
                </select>
              <small class="text-danger error">{{mainkeyerror}}</small>
                </div>
              </div>
              <div class="row stem-rw">
                <div class="col-md-4 col ">
                  <label style="font-size: 20px;    padding: 0px 0px 10px;" class="color label">Key</label>
                </div>
                <div class="col-md-8 col-12 errr">
                  <select
                    class="short-input"
                    [(ngModel)]="key"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="Enter key"
                  >
                  <option *ngFor="let i of keys">{{i}}</option>
                 
                </select>
              <small class=" text-danger error">{{keyerror}}</small>
                </div>
              </div>

              <div class="row stem-rw">
                <div class="col-md-4 col">
                  <label style="font-size: 20px;    padding: 0px 0px 10px;" class="color label">BPM</label>
                </div>
                <div class="col-md-8 col-12 errr">
                  <input
                    class="short-input"
                    [(ngModel)]="bpm"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="Enter bpm"
                  />
                  <small class="text-danger error">{{bpmerror}}</small>
                </div>
                
              
            </div>
              <div class="row stem-rw">
                <div class="col-md-4 col">
                  <label class="color label" style="font-size: 19px; font-weight: 600;    padding: 0px 0px 10px;"
                    >Tags</label
                  >
                  <label
                      class="color"
                      style="font-size: 15px; font-weight: 500; color: #797979;padding: 0px 10px;max-width: 171px;   padding: 0px 0px 10px;
                      display: grid;"
                      >Choose up to three tags to describe loop
                    </label>
                </div>
                <div class="col-md-8 col-12 ">
                  <!-- <mat-form-field class="example-chip-list"> -->
                  <mat-label></mat-label>

                  <mat-chip-list #chipList aria-label="Fruit selection">
                    <input
                      placeholder="i.e drake, rnb, house"
                      #fruitInput
                      [formControl]="fruitCtrl"
                      (focusout)="checktag()"
                      [matChipInputFor]="chipList"
                      class="short-input"



                      
                      style="color: #444445;
                      width: 100%;
                      max-width: 670px;"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (matChipInputTokenEnd)="add($event)"
                    />
                  </mat-chip-list>
                  <div style="display: flex;flex-direction: column;">
                    <div>
                  <mat-chip
                    *ngFor="let fruit of fruits"
                    class="fi"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(fruit)"
                    matChipRemove
                  >
                    {{ fruit[0] == "#" ? "" + fruit : "#" + fruit }}
                  </mat-chip>
                </div>
                <small class="text-danger error">{{ tagserror }}</small>
              </div>
              </div>
             </div>
            <div class="col-md-12 d-flex btn-rw">
              <!-- <button class="previous-btn" (click)="previous()" >Previous</button> -->
              <button class="finish-btn"  (click)="submit()">Finish</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
