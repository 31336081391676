
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';

import { StaticService } from 'src/app/sevicesall/static.service';
// import { REPORTTRACKMODELComponent } from '../../MODAL/report-track-model/report-track-model.component';
import { NgWaveformModule } from 'ng-waveform';
import { ITimeUpdateEvent, NgWaveformComponent, IRegionPositions } from 'ng-waveform';

import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
import { APIURL, CONSTNAME, INPUT_TYPE_NAME, VARS } from 'src/app/allServices/constant.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { $ } from 'protractor';
import * as WaveSurfer from 'wavesurfer.js';

@Component({
  selector: 'app-waveform',
  templateUrl: './waveform.component.html',
  styleUrls: ['./waveform.component.css']
})
export class WaveformComponent implements OnInit {
  isplaying: any = "";
  currenttime: any = "0.00";
  totaltime: any;
  durationofaudio: any;
 time:any
 @ViewChild('waveform', { static: false }) waveformElement: ElementRef;
  @Input() arr: any;
  @Input() loop_id: any;
  @Input() items: any;
  @Input() color: any = "#444445";
  @Input() passedValue: any;
  @Input() sliderValue: any;
  @Output() triggerParentFunction: EventEmitter<void> = new EventEmitter<void>();  
  @Output() sliderValueChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() musicEnded: EventEmitter<void> = new EventEmitter<void>(); // Declare Output Event
  timer:any
    hideplaybutton: boolean;
  index = 0;
  // @Input() items: any;
  // @Input() audioSrc: string;
  private wavesurfer: WaveSurfer;
  hidepausebutton= true;
  // @ViewChild(`waveform`) waveform: NgWaveformComponent;
  completerender: boolean = false;
  constructor(public common: commonservice, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService, private matDialog: MatDialog, private _sanitizer: DomSanitizer) { }
  ngAfterViewInit(): void {
    // //console.log("i am running",this.color)
 
    this.wavesurfer = WaveSurfer.create({
      container: this.waveformElement.nativeElement,
      waveColor: this.color,
      barWidth: 2,
      progressColor: 'black',
      // interact: false 
    });
    this.completerender = true

    // const file = CONSTNAME.fILE_PATH + this.arr;
    this.wavesurfer.load(this.arr);
    
    this.waveformElement.nativeElement.querySelector('wave>wave').style.borderRight = "none"
    const wave=this.waveformElement.nativeElement.querySelector('wave')
    this.waveformElement.nativeElement.style.zIndex = 'unset';
    this.waveformElement.nativeElement.style.position = 'sticky';
    this.waveformElement.nativeElement.querySelector('wave>canvas').style.width  = '345px';
    this.waveformElement.nativeElement.querySelector('wave').style.overflow  = 'hidden';
    // this.ngOnInit()
    if (this.wavesurfer) {
      this.wavesurfer.on('interaction', () => {
        // //console.log('this', this.wavesurfer.timeline,this.passedValue.audio);
        this.time = this.wavesurfer.getCurrentTime().toFixed(2);
        // //console.log("passedValue1", this.time);
        // this.passedValue.audio.currentTime = this.time;
        // //console.log("passedValue1", this.passedValue.audio.currentTime);
        // this.triggerParentFunction.emit();
    // this.sliderValue = this.time
    // var seek_btn = document.querySelectorAll(
    //       "#player-row-" + this.items + " .play_button.seek"
    //     )[0];
    //     seek_btn.setAttribute("data-seek-time", String(this.time));
    //     (seek_btn as HTMLElement).click();
        // this.waveformElement.nativeElement.querySelectorAll(".play_button.seek")[0]?.setAttribute("data-seek-time",time);
      });
    } else {
      console.error('wavesurfer is not initialized');
    }

    this.wavesurfer.on('play', () => {
      // Update the sliderValue and passedValue.audio.currentTime
      // var fff = 
      var target = document.getElementsByClassName('vol');
      var vol :any,element:any
      for (let i = 0; i < target.length; i++) {
        element = target[i] as HTMLElement;
        // Do something with the element
    }
      vol = element.getAttribute("data-seek-vol");
      // //console.log(this.wavesurfer.getVolume());
      if(vol===0){
        this.wavesurfer.setMute(true);
      }else
      this.wavesurfer.setVolume(this.wavesurfer.getVolume());
      let currentTimeInSeconds = this.wavesurfer.getCurrentTime() * 1000;
      
      const audioDurationInSeconds = this.wavesurfer.getDuration() * 1000;
      // //console.log("plyyyyy",currentTimeInSeconds,audioDurationInSeconds);
      this.timer = setInterval(() => {
          currentTimeInSeconds += 10; // Update time smoothly by increasing in milliseconds
          this.sliderValueChange.emit(currentTimeInSeconds);
          
          // Stop the timer when audio duration is reached
          if (currentTimeInSeconds >= audioDurationInSeconds) {
              clearInterval(this.timer);
          }
      }, 10); // Update every 100 milliseconds
      
      // //console.log("ps-play");
      
      // const sliderValue = currtime;
      // this.sliderValueChange.emit(parseFloat(sliderValue)); 
      // //console.log(this.sliderValue,  this.passedValue.audio.currentTime );
      
    });
    this.wavesurfer.on('pause', () => {
      // //console.log("ps-play");
      clearInterval(this.timer);
      // //console.log("ps-play");
      // const sliderValue = currtime;
      // this.sliderValueChange.emit(parseFloat(sliderValue)); 
      // //console.log(this.sliderValue,  this.passedValue.audio.currentTime );
      
    });
    this.wavesurfer.on('finish', () => {
      this.musicEnded.emit(); // Emit music end event
    });
  }
  
  ngOnInit(): void {
    var id=this.items
    // //console.log('/',this.time)
   
    // this.sliderValue = this.time
    
    // ////console.log("i am running")
    // document.getElementById("player-row-" + this.player)?.addEventListener('customClick', (event: any) => {
    //   ////console.log('Custom click event received with value:', event.detail);
    // });
    // var seek_btn = document.querySelectorAll(
    //   "#player-row-" + this.items + " .play_button.seek"
    // )[0];
    // seek_btn.setAttribute("data-seek-time", String(this.time));
    // (seek_btn as HTMLElement).click();
//     this.tableData[this.player].audio.currentTime = seek_time;
//     //console.log("sli",this.tableData[this.player].audio.currentTime);
//     // this.playAudio(this.tableData[this.player],this.player,event)
    
// // this.sliderValue = seek_time
// //console.log("sli",this.sliderValue);

//     var seek_btn = document.querySelectorAll(
//       "#player-row-" + this.player + " .play_button.seek"
//     )[0];
//     seek_btn.setAttribute("data-seek-time", String(seek_time));
//     (seek_btn as HTMLElement).click();
  // }
  // //console.log("passedValue",this.passedValue.audio);
  
    
    
  }
  
  onPlayButtonClick(event: any) {
    // this.hidepausebutton=true
    // this.hideplaybutton = true;   
    //  //console.log('onPlayButtonClick');

    // this.hidepausebutton = false;
    // this.time = document.querySelectorAll(".play_button.seek")[0].getAttribute("data-seek-time");
    // // this.waveform.play(this.time);
    // var target = event.target || event.srcElement || event.currentTarget;
    // var currently_playing_btn:any = document.querySelectorAll(".is-playing");
    // if (currently_playing_btn.length > 0) {
    //   if (target != currently_playing_btn[0]) {
    //     currently_playing_btn[0].nextSibling.click();
    //   }
    // }
  
    this.wavesurfer.play()
    // //console.log(this.wavesurfer);
    
    // // this.wavesurfer.setMute(true)
    // //console.log('>>>', this.waveformElement.nativeElement.querySelector('wave'));

    this.waveformElement.nativeElement.querySelector('wave').classList.add("is-playing");
    
    // //console.log('>>>', this.waveformElement.nativeElement.querySelector('wave').classList);
    // this.currentwaveform=this;
    // this.currentwaveform.emit(this.waveform);
  }
  forward(event:any){
    var time :any;
    time = this.wavesurfer.getCurrentTime()+2;
    // //console.log(time);
    this.wavesurfer.setCurrentTime(time);
    
    // this.wavesurfer.play(time,this.wavesurfer.getDuration());

  }
  backward(event:any){
    var time :any;
    time = this.wavesurfer.getCurrentTime()-2;
    // //console.log(time);
    this.wavesurfer.setCurrentTime(time);
  }
  mute(){
    // //console.log("mute");
    
    this.wavesurfer.setMute(true)
  }
  unmute(){
    this.wavesurfer.setMute(false)

  }
  onChangeVolume(event:any){
    // //console.log(event);
    
    var target = event.target || event.srcElement || event.currentTarget;
    var vol :any;
    vol = parseFloat(target.getAttribute("data-seek-vol")).toFixed(2);
    this.wavesurfer.setVolume(vol);
  }
  onChange(event:any){
    ////console.log('onChange');
    // ////console.log(this.passedValue);
    // //console.log(event);
    
    var target = event.target || event.srcElement || event.currentTarget;
    var time :any;
    time = parseFloat(target.getAttribute("data-seek-time")).toFixed(2);
    // //console.log("time",time /( this.wavesurfer.getDuration()*1000));
    this.wavesurfer.setCurrentTime(time/1000);
    // const dura = this.passedValue.duration.toFixed(2)
    // //console.log(time,dura);
    // this.passedValue.audio.currentTime = time;
    // clearInterval(this.timer);

    // this.wavesurfer.playPause()
    ////console.log("p-time", time);
    // this.waveform.setRegionStart(time);
    // this.waveform.setRegionEnd(time);
    // this.waveform.play(time)

  }
  
  onPauseButtonClick(event: any) {
    ////console.log('onPauseButtonClick');
    // this.hidepausebutton=false
    // this.hidepausebutton = true;
    // this.hideplaybutton = false;
    // // this.waveform.pause();
    // //console.log('onPauseButtonClick',event);

    var currently_playing = document.querySelectorAll(".is-playing");
    if(currently_playing.length>0){
      currently_playing[0].classList.remove("is-playing");
    }
   
    this.wavesurfer.pause()
  }




  above15second = false;
  above5secondlessthan15 = false;
  above1secondlessthan5 = false;
  apihitforstream = true;
  audio_duration: any;
  play_duration: any;
  // totaltime: any;
  // durationofaudio: any;
  onDurationChange(value: any = null) {
    ////console.log('onDurationChange');
    const sec = parseInt(value, 10); 
    let hours:any   = Math.floor(sec / 3600); 
    let minutes:any = Math.floor((sec - (hours * 3600)) / 60); 
    let seconds:any = sec - (hours * 3600) - (minutes * 60);
   
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    this.totaltime= minutes+':'+seconds; 
    if (value == 0) {

    } else {
      // ////console.log(this.totaltime)
      this.durationofaudio = value;
      // this.totaltime = (Math.round(value * 100) / 100).toFixed(2);
      // if (this.totaltime > 60) {
      //   this.totaltime = (this.totaltime / 60).toFixed(2)
      // }
      // ////console.log(this.durationofaudio)
      // 
    }
    // ////console.log(this.audio_duration);

    if (this.durationofaudio >= 15) {
      this.above15second = true;
      // ////console.log("above 15 sec");
    }
    else if (this.durationofaudio < 15 && value >= 5) {
      // ////console.log("between 5 and 15");
      this.above5secondlessthan15 = true;
    } else if (this.durationofaudio > 1 && value <= 5) {
      // ////console.log("between 1 and 5");
      this.above1secondlessthan5 = true;
    }
  }

  onTimeUpdate(v: any = null, element: any) {
    // ////console.log(v, "time", this.above15second);
    // //console.log('onTimeUpdate');
    // ////console.log("pppp", element.el.nativeElement.querySelectorAll(".play_button.seek"));
    v.time = parseFloat(v.time).toFixed(2);
    if(this.passedValue.audio){
      if(Math.abs(v.time - this.passedValue.audio.currentTime)>0.1){
        this.passedValue.audio.currentTime = v.time;
      }
      // //console.log('onTimeUpdate', v.time, this.passedValue.audio.currentTime, Math.abs(v.time - this.passedValue.audio.currentTime));
    }
    // console.warn("ishika",v, element);
    // element.el.nativeElement.querySelectorAll(".play_button.seek")[0]?.setAttribute("data-seek-time", parseFloat(v.time).toFixed(2));
    
    var currently_playing_btn: any = document.querySelectorAll(".is-playing");
    // if (currently_playing_btn.length > 0) {
    // }
    // ////console.log('loop_id',currently_playing_btn[0].dataset.loop_id);
    

    if (this.hidepausebutton == false) {
      // ////console.log(this.currenttime)
      // ////console.log("time", v.time);
      // ////console.log("in")
      const sec = parseInt(v.time, 10);
      let hours:any   = Math.floor(sec / 3600); 
      let minutes:any = Math.floor((sec - (hours * 3600)) / 60); 
      let seconds:any = sec - (hours * 3600) - (minutes * 60); 
     
      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
      this.currenttime =  minutes+':'+seconds
      // this.currenttime = (Math.round(v.time * 100) / 100).toFixed(2);
      // if (this.currenttime > 60) {
      //   this.currenttime = (this.currenttime / 60).toFixed(2)
      // }
      if (v.progress >= 100) {
        // ////console.log("complete")
        // this.hidepausebutton = true;
        // this.onPauseButtonClick(event)
        // this.hideplaybutton = false;
        setTimeout(() => {
          this.apihitforstream = true;
          this.currenttime = "0.00";
        }, 1000);
        setTimeout(() => {

          this.currenttime = "0.00";
        }, 10);


      }
      if (this.above15second) {
        if (v.time >= 15) {

          // this.apihitforstream = true;
          // alert("15 second is passed");
          if (this.apihitforstream == true) {
            // ////console.log(v.time);
            this.play_duration = v.time
            // ////console.log("api will hit");
            this.apihitforstreaming(currently_playing_btn[0].dataset.loop_id);
            this.apihitforstream = false;
          }

        }
      } else if (this.above5secondlessthan15) {
        if (v.time >= 5) {
          if (this.apihitforstream == true) {
            // ////console.log(v.time);
            this.play_duration = v.time
            // ////console.log("api will hit");
            this.apihitforstreaming(currently_playing_btn[0].dataset.loop_id);
            this.apihitforstream = false;
          }
        }

      } else if (this.above1secondlessthan5) {
        if (v.time >= 1) {
          if (this.apihitforstream == true) {

            this.play_duration = v.time

            this.apihitforstreaming(currently_playing_btn[0].dataset.loop_id);
            this.apihitforstream = false;
          }
        }

      }




    }
  }


  async apihitforstreaming(loopid: any) {
    // ////console.log(loopid)
    // this.common.showLoader();
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }

    let sendingdata = {
      "audio_duration": this.durationofaudio,
      "loop_id": loopid,
      "play_duration": this.play_duration
    }
    this.http.post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      }

      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }


    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

}
