import { environment } from '../../sevicesall/alldata';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from '../../allServices/api-service.service';
import { commonservice } from '../../allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from '../../allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { ConfirmDeleteComponent } from '../../CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../MODAL/filter/filter.component';
import { StaticService } from 'src/app/sevicesall/static.service';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs/internal/Observable';
import { DownloadmodalComponent } from '../MODAL/downloadmodal/downloadmodal.component';
@Component({
  selector: 'app-searchbytag',
  templateUrl: './searchbytag.component.html',
  styleUrls: ['./searchbytag.component.css']
})
export class SearchbytagComponent implements OnInit {
  @ViewChild("fruitInput")
  fruitInput: ElementRef<HTMLInputElement>;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  advancesearch = StaticService.advancesearch();
  total: any;
  record: any;
  hidepagination = false;
  norecordfound: any;
  volumeValue: number = 1; 
  sorttype = "";
  sorton = "";
  imageofcomment: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  value: any;
  encryptedagainremoveslashes: string;
  tablefortagsdisplay1: any;
  tablefortagsdisplay2: any;
  tablefortagsdisplay3: any;
  time: string;
  addred: boolean;
  addgreen: boolean;
  durationofaudio: any;
  above15second = false;
  apihitforstream = true;
  above5secondlessthan15 = false;
  above1secondlessthan5 = false;
  id: any;
  nocomments: boolean =false;
  src: any;
  pagenocount: any;
  arrayforsearch: any = [];
  errorforsearch: boolean;
  searchvalue: string;
  totalRecords: any;
  searchQuery: any;
  filter: boolean;
  searchBy: any;
  active: boolean;
  usersearch: any;
  loopkitsearch: any;
  selectedKey1: string;
  selectedKey: string;
  check: string;
  filterForm = StaticService.advancesearch();
  dataSharingService: any;
  arrayforradio = ['A','A#', 'B', 'B#', 'C',  'C#', 'D','D#', 'E', 'E#', 'F','F#', 'G', 'G#'];
  arrayformainkey  = [{key:'0',name:"Major"},{key:'1',name:"Minor"}];
  errorforbpm: boolean;
  loopsearch: any;
  valueofmain_key: string;
  valueofsub_key: string;
  bpmrange: boolean;
  hideexactbpm: boolean;
  showAudioPlayer: boolean;
  player: any;
  completerender: boolean = false;
  sliderValue : any = 0.00
  mute: boolean;
  disable: boolean;
  steps: any;
  path: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruits: any[] = [];
  selectable = true;
  removable = true;

  fruitCtrl = new FormControl();
  fruit: any;

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService, private matDialog: MatDialog) { }
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.warn('hii',event,this.player);
    if(event.key== 'ArrowUp'){
      
    }
    if (event.key === 'ArrowLeft') {
      if(this.player>=0){
      this.previous();
      }
    } else if (event.key === 'ArrowRight') {
      // if(this.player<=this.tableData.length){
      this.next();
      // }else{
      //   this.player = 0
      //   this.next()
      // }
    }else if (event.key === ' ') {
      // if(!this.player){
      // this.playAudio(this.tableData[0],0);
      // }else{
        if(this.tableData[this.player].hidepausebutton){
          this.stopAudio(this.tableData[this.player],this.player)
        }else{
        this.playAudio(this.tableData[this.player],this.player);
      // }
      }
      }
           
  }
  ngOnInit(): void {
   
    this.value = this.route.snapshot.queryParamMap.get('search_by');
    this.fruit = this.value
    if (this.value == null || this.value == "") {
      // //console.log(this.value)
      this.fruits = []
    } else {
      var str = this.value;
      var str_array = str.split(',');

      for (var i = 0; i < str_array.length; i++) {
        // Trim the excess whitespace.
        str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
        // Add additional code here, such as:
        // alert(str_array[i]);
        this.fruits.push(str_array[i])


      }
    // this.encryptedagainremoveslashes = decodeURIComponent(this.value);
    // this.decryptUsingAES256();
    this.getapicallforcouponcode();
    // //console.log(this.value)
   
  }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }
  isTagSelected(tag: string): boolean {
    // //console.log(this.fruit.split(',').);
    
    // //console.log(tag,this.fruit && this.fruit.split(',').includes(tag));
    
    return this.fruit && this.fruit.split(',').includes(tag);
  }
  checktag(value: any = null) {


    var values = this.fruits;
    // //console.log(values)
  }
  remove(fruit: string): void {
    // //console.log("Ram")
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

 onButtonClick(item: string): void {
   // Update the selectedKey when a button is clicked
   this.selectedKey = item;
 }
 onButtonClick1(item: string): void {
   // Update the selectedKey when a button is clicked
   this.selectedKey1 = item;
 }
  open(){
    this.filter = true
  }
  close(){
    this.filter = false

  }
  apply(value: any) {


    // //console.log(this.minValue,this.maxValue)

    // 
    // //console.log(this.advancesearch.value.key);
    // if (this.errorforbpm == false) {
      // //console.log(this.advancesearch.value.sortof);
      // //console.log(this.advancesearch.value.keyword)
      this.router.navigate(['/search-by-tag'], { queryParams: { search_by: this.advancesearch.value.keyword } })
      this.active = false;
      let sendingdata: any =
      {
        "page_no": this.pageno,
        "sort_on":"",
        "row_size":"10",
        "sort_type": "",
        // "key_word": this.advancesearch.value.keyword,

      }
    this.validationforsearch = this.advancesearch.value.keyword 
    if (this.advancesearch.value.instrumentcategory !== "") {
        sendingdata["instrument_category"] = this.advancesearch.value.instrumentcategory
      }
      if (this.advancesearch.value.instruments !== "") {
        sendingdata["instrument"] = this.advancesearch.value.instruments
      }
      if (this.advancesearch.value.genre !== "") {
        sendingdata["genre"] = this.advancesearch.value.genre
      }
      if (this.advancesearch.value.subgenre !== "") {
        sendingdata["sub_genre"] = this.advancesearch.value.subgenre
      }
      if (this.valueofmain_key !== "") {
        sendingdata["main_key"] = this.valueofmain_key
      }
      if (this.valueofsub_key !== "") {
        sendingdata["sub_key"] = this.valueofsub_key
      }
      if (this.advancesearch.value.sellingpreference !== "") {
        sendingdata["selling_preference"] = this.advancesearch.value.sellingpreference
      }
      if (this.advancesearch.value.keyword !== "") {
        sendingdata["tags"] =[ this.advancesearch.value.keyword]
      }

      if (value == 'fromother') {
        // //console.log("inother")
        // //console.log(this.bpmrange)
        // //console.log(this.hideexactbpm)

        // if (this.bpmrange == true && this.hideexactbpm == false) {
        //   //console.log("in2")
        //   sendingdata["bpm_range"] = 1
        //   sendingdata["min_bpm"] = this.minValue;
        //   sendingdata["max_bpm"] = this.maxValue;
        // } else if (this.bpmrange !== true && this.hideexactbpm !== false) {
        //   //console.log("exact bpm")

        //   sendingdata["exact_bpm"] = this.advancesearch.value.exact_bpm
        // } else {

        // }
      } else {
        // //console.log("in1")
        // //console.log(this.hideexactbpm)
        if (this.bpmrange == true && this.hideexactbpm == false) {
          // //console.log("in2")
          // sendingdata["bpm_range"] = 1
          // sendingdata["min_bpm"] = this.minValue;
          // sendingdata["max_bpm"] = this.maxValue;
        } else if (this.bpmrange !== true && this.hideexactbpm !== false) {
          // //console.log("exact bpm")

          // sendingdata["exact_bpm"] = this.advancesearch.value.exact_bpm
        } else {

        }
      }


      // this.common.showLoader();




      this.api.forclientgettokenpublic(`/user/browse_tags`, sendingdata).then((res: any) => {



        if (res.response.status == 1) {
          this.common.hideLoader();
          // //console.log(res)
          this.check = ""
          this.totalRecords = res.response.data.total_records;
          this.tableData = res.response.data.pagging_list;
          this.check = res
          this.active = true;
          if(this.totalRecords==0){
            this.nocomments=false
          }else{
            this.nocomments=true
          }




        } else {
          this.common.hideLoader();


          let errorthroughapi = res.response.errors;
          let error = [];
          for (var i = 0; i < errorthroughapi.length; i++) {

            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }

        }
      }, async e => {
        this.common.hideLoader();

        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

      });

    // }
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.fruits.push(value);
    }

    // Clear the input value
    // event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
    this.getapicallforcouponcode();
    // this.checktag(value);
  }
  cancel(){
    this.matDialog.closeAll()
  }



  performSearch(): void {
    this.router.navigate(['advancesearch/'], { queryParams: { search: this.searchQuery } });
}
forward(){
  this.steps = this.steps + 0.01
  this.tableData[this.player].audio.playbackRate += 0.1; 
  
// //console.log(this.steps);

}
backward(){
  this.steps = this.steps - 0.01
  this.tableData[this.player].audio.playbackRate -= 0.1; 

}
downloadFile(filePath: any) {
  // Create a hidden anchor element
  // this.common.showLoader()
  const a = document.createElement('a');
  // //console.log('jid');
  
  this.api.postApiHit(`/user/save_free_loop`, {loop_id:filePath.loop_id}).then((res: any) => {
    if(res.response.errors){
      this.toastr.error(res.response.errors,"",{ positionClass: 'toast-bottom-right' })
      this.common.hideLoader()
    }else{
      document.body.appendChild(a);
      a.style.display = 'none';
      if(filePath.loop_path != null ){
        this.path = CONSTNAME.fILE_PATH + filePath.loop_path
      }else{
        this.path =CONSTNAME.fILE_PATH + filePath.tagged_loop_path
      }
      // //console.log(this.path);
      // Create a blob for the file data
      fetch( this.path)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a blob URL for the file
          const blobUrl = window.URL.createObjectURL(blob);
    
          // Set the anchor's href and download attributes
          a.href = blobUrl;
          
          a.download = filePath.title+'_'+filePath.bpm+'_'+filePath.sub_key+'_@'+filePath.username+'.mp3'; // Set the desired filename
          this.common.hideLoader()
    
          // Simulate a click on the anchor to trigger the download
          a.click();
    
          // Clean up by revoking the blob URL
          window.URL.revokeObjectURL(blobUrl);
        }).catch((response)=>{
          this.common.hideLoader()
          this.toastr.error(response.response.errors, "", { positionClass: 'toast-bottom-right' })
        })
    
      // Remove the anchor element from the DOM
      document.body.removeChild(a);
    }
  })
  
}
async getapicallforcouponcode() {

  // this.common.showLoader();
  // let string: any = this.value
  // if (this.value == "") {
  //   this.arrayforsearch = [];
  //   // var string = "text to split";
  //   // //console.log(this.validationforsearch)
  //   var stringArray = this.arrayforsearch;
  // } else {
  //   this.arrayforsearch = [];
  //   // var string = "text to split";
  //   string = string.split(" ");
  //   var stringArray = this.arrayforsearch;
  //   for (var i = 0; i < string.length; i++) {
  //     stringArray.push(string[i]);
  //     if (i != string.length - 1) {
  //       // stringArray.push(" ");
  //     }
  //   }
  // }

  let values = this.fruits
  this.router.navigate(['search-by-tag/'], { queryParams: { search_by: [values] } });
  
  values.map((i: string)=>{
      this.isTagSelected(i)
  })
  // this.router.navigate(['my-feed'], { queryParams: {  pageno: this.fruits } });
  // //console.log(this.decrypted);

  let sendingdata =
  {
    "page_no": this.pageno,
    "sort_on": this.sorton,
    "row_size": this.rowsize,
    "sort_type": this.sorttype,
    "tags": this.fruits
  }

  let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

  let headers: any;
  if (tokkenget) {
    headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

  }



  this.http.post(APIURL.BASE_URL + `/user/browse_tags`, sendingdata, { headers }).subscribe(async (res: any) => {



    if (res.response.status == 1) {




      if (res.response.data.total_records == 0) {
        this.norecordfound = false;
        this.common.hideLoader();
      } else {
        this.norecordfound = true;
        
          this.common.hideLoader();
      
      }

      if (res.response.data.total_pages == 1) {
        this.hidepagination = true;
      } else {
        this.hidepagination = false;
      }
      if (this.paginationempty == true) {
        this.pagercount = [];
      } else {
        // this.pagercount = [];
      }
      res.response.data.pagging_list.map((item:any,index: any) =>{
        res.response.data.pagging_list[index].tags_array= item.tags.split('\n');
    });
      // this.tableData = res.response.data.pagging_list;
      for (let i = 0; i < res.response.data.pagging_list.length; i++) {

        let temp_item = res.response.data.pagging_list[i];
        let a = temp_item.tags
        let strtag: any = a.split('\n ');
        temp_item['tag1'] = strtag[0];
        temp_item['tag2'] = strtag[1];
        temp_item['tag3'] = strtag[2];

        this.common.Encryptedfunction(temp_item.user_id).then(data => {
          if (data) {
            temp_item['enc_link'] = data;
       
          }
        })
        this.common.Encryptedfunction(temp_item.loop_id).then(data2 => {
          if (data2) {
            temp_item['loop_idencrypt'] = data2;
            // this.arrayforllopkit.push(temp_item);
          }
        })
        this.tableData.push(temp_item);
      }

      this.record = this.tableData.length;
      if (this.record == 0) {
        this.nocomments = false;
      } else {
        this.nocomments = true;
      }

      this.pagenolist = res.response.data.total_pages + 1;
      this.record = this.pageno - 1 + "1";
      // //console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
      this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length

      this.tableData = res.response.data.pagging_list;
      // this.record = this.tableData.length;
      // this.pagenolist = res.response.data.total_pages + 1;
      if (this.record == "01") {
        this.record = 1;
      }
      for (let i = 1; i < this.pagenolist; i++) {
        // //console.log("in")
        this.pagercount.push(i);

      }

      if (res.response.data.total_records == 0) {
        this.norecordfound = false;
      } else {
        this.norecordfound = true;






        var date_future: any = new Date();
        var date_now: any = new Date(res.response.data.pagging_list[0].modified_on);


        if (res.response.data.pagging_list[0].is_liked == 1) {

          this.addred = true;

        } else {
          this.addred = false;

        }
        if (res.response.data.pagging_list[0].is_reposted == 1) {
          this.addgreen = true;

        } else {
          this.addgreen = false;

        }

        var time = date_future - date_now;

        var seconds = moment.duration(time).seconds();
        var minutes = moment.duration(time).minutes();
        var hours = moment.duration(time).hours();
        var days = moment.duration(time).days();




        if (seconds < 60 && minutes === 0) {
          this.time = seconds + " sec"
        }
        else if (minutes < 60 && hours === 0) {
          this.time = minutes + " min"
        } else if (hours <= 24 && days === 0) {
          this.time = hours + " hrs"
        } else if (days >= 1) {
          this.time = days + " days"
        }





        this.total = res.response.data.total_records;

        if (res.response.data.pagging_list[0].loop_type == 1) {
          if (res.response.data.pagging_list[0].tagged_loop_path !== null) {
            this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].tagged_loop_path;
            this.durationofaudio = res.response.data.pagging_list[0].tagged_loop_duration;
            // //console.log("checking");
          } else {
            this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].loop_path;
            this.durationofaudio = res.response.data.pagging_list[0].loop_duration;
          }

          // //console.log(this.src);
        } else {
          this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].tagged_loop_path;
          // //console.log(this.src);
          this.durationofaudio = res.response.data.pagging_list[0].tagged_loop_duration;
        }

      }


    } else {
      this.common.hideLoader();


      let errorthroughapi = res.response.errors;
      let error = [];
      for (var i = 0; i < errorthroughapi.length; i++) {

        this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
      }

    }
  }, async e => {
    this.common.hideLoader();

    this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

  });
}
// toggleAudioPlayPause(event: KeyboardEvent, items: any,i:any) {
//   //console.log();
  
//   if (event.code === 'Space') {
//     event.preventDefault(); // Prevent scrolling when pressing spacebar
//     if (items.hidepausebutton) {
//       this.stopAudio(items);
//     } else {
//       this.playAudio(items,i);
//     }
//   }
// }

  srcforsound(items: any) {
    if (items.loop_type == 1) {
      if (items.tagged_loop_path !== null) {
        this.durationofaudio = items.tagged_loop_duration;
        return CONSTNAME.fILE_PATH + items.tagged_loop_path;
        // //console.log("checking");
      } else {
        this.durationofaudio = items.loop_duration;
        return CONSTNAME.fILE_PATH + items.loop_path;
      }

      // //console.log(this.src);
    } else {
      this.durationofaudio = items.tagged_loop_duration;
      return CONSTNAME.fILE_PATH + items.tagged_loop_path;
      // //console.log(this.src);
    }
  }

  addredcolor(item: any) {
    // //console.log("in");
    if (item.is_liked == 1) {
      return "red";
    }
  }
  addgreencolor(item: any) {
    if (item.is_reposted == 1) {
      return "green";
    }
  }

  redirecttoloopdetailpage(items: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(items.loop_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
  }














  detailpageofloop(value: any) {

    this.encryptUsingAES256(value);

  }

  paginationgetmore() {
    this.paginationempty = true;

    // this.pageno = pageinfo;
    this.rowsize += this.rowsize
    // //console.log(this.rowsize);


    this.getapicallforcouponcode();



  }
  paginationget(pageinfo: any) {
    this.paginationempty = true;
    this.pageno = pageinfo;

    if (this.route.snapshot.queryParamMap.get('search')) {
      // this.searchApi()
    } else {
      this.getapicallforcouponcode();
    }


  }

  // routerLink=""
  toreportedloops() {

    this.router.navigate(['/admin/loops/reported-loops']);
  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
  }

  // [routerLink]="['/admin/loops/detail-uploads/'+item.loop_id]"

  addcolor(item: any) {
    if (item == this.pageno) {
      return 'activated';
    } else {
      return ''
    }
  }



  setimage(data: any) {
    // if (data.artwork_path == null) {

    //   return this.profile_image_pathofcomments;
    // } else {

    //   return CONSTNAME.fILE_PATH + data.artwork_path;
    // }
    if (data.artwork_path == null) {
      if (data.owner_profile_image_path == null) {
        return this.profile_image_pathofcomments;

      } else {
        return CONSTNAME.fILE_PATH + data.owner_profile_image_path;
      }

    } else {

      return CONSTNAME.fILE_PATH + data.artwork_path;
    }

  }

  likesnumber(item: any) {
    return item.number_of_likes
  }


  async heartcolorred(value: any, detail: any, item: any) {

    let sendingData = {
      "loop_id": value,
    }

    // if (item.is_liked == 1) {
    //   item.is_liked = 0;
    // } else if (item.is_liked == 0) {
    //   item.is_liked = 1;
    // }




    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      // this.common.showLoader();
      this.api.postApiHit(`/user/like_dislike_upload`, sendingData).then((res: any) => {
        if (res.response.status == 1) {
          // let v: any = document.getElementById("red");
          // v.classList.add("red");
          // this.getapicallforcouponcode();



          this.common.hideLoader();
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          if (item.is_liked == 1) {
            item.is_liked = 0;

            return ((item.number_of_likes) = item.number_of_likes - 1);
          } else if (item.is_liked == 0) {
            item.is_liked = 1;

            return ((item.number_of_likes) = item.number_of_likes + 1);
          }
          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];

          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }


        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];

          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async (e: any) => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.opendialogueforverification();
      // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      // this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
    }

  }


  decryptUsingAES256() {

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    // //console.log(this.decrypted);
    // this.decrypted = this.decrypted.replace(/[""]/gi, '');
    // //console.log(this.decrypted);


  }
  openDialog(): void {
    let dialogue = this.matDialog.open(FilterComponent, { panelClass: 'filter-modal',data: { arrayforradio: this.arrayforradio , arrayformainkey:this.arrayformainkey,name:"search-by-tag"} });
    dialogue.afterClosed().subscribe(result => {
      // Handle the data received from the dialog
      // //console.log('Data received from dialog:', result);
      this.active= true
     this.apply('main')
    });
  }

  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "detailpage" } });
    dialogue.afterClosed().subscribe(result => {



      if (result.data == true) {
        this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
        this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
        // this.router.navigate(['/users/uploadloopstatus']);
      } else {

      }

    });
  }

  repostnumber(item: any) {
    return item.number_of_reposts
  }

  async repost(value: any, item: any) {

    let sendingData = {
      "loop_id": value,
    }



    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {


      // this.common.showLoader();
      this.api.postApiHit(`/user/repost_upload`, sendingData).then((res: any) => {
        if (res.response.status == 1) {
          // this.getapicallforcouponcode();

          this.common.hideLoader();

          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          if (item.is_reposted == 1) {
            item.is_reposted = 0;

            return ((item.number_of_reposts) = item.number_of_reposts - 1);
          } else if (item.is_reposted == 0) {
            item.is_reposted = 1;

            return ((item.number_of_reposts) = item.number_of_reposts + 1);
          }
          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();

          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
          this.router.navigate(['/users/uploadloopstatus']);

        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async (e: any) => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.opendialogueforverification();


    }
  }

  async redirecttolooppagecomment(value: any) {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    if (tokkenget) {
      this.redirecttolooppage(value)
    } else {
      this.opendialogueforverification();
    }

  }

  redirecttolooppage(value: any) {


    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);


    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });



  }


  async apihitforstreaming(item: any, r: any, dura: any) {

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }

    let sendingdata = {
      "audio_duration": dura,
      "loop_id": item.loop_id,
      "play_duration": r
    }
    this.http.post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      }

      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }


    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }
  next(){
    
    if(this.tableData.length-1==this.player){
     this.tableData[this.player].hidepausebutton =false
    }else{
      this.disable =false
      this.tableData[this.player].audio.currentTime = 0
     this.sliderValue =0
     this.player = this.player + 1
     this.playAudio(this.tableData[this.player],this.player)
    }
    }
    previous(){
      if(this.tableData.length-1==this.player){
        this.disable =true
       }else{
         this.disable =false
     this.sliderValue =0
     this.tableData[this.player].audio.currentTime = 0
      this.player = this.player - 1
      this.playAudio(this.tableData[this.player],this.player)
       }
    }
    onInput(event: any): void {
      // //console.log(event.target.value);
      this.tableData[this.player].audio.currentTime = parseFloat(event.target.value);
    }
    onVolumeInput(event: any): void {
      // //console.log(event.target.value);
      this.tableData[this.player].audio.volume = parseFloat(event.target.value);
    }
    Mute(){
      this.mute = true
      this.tableData[this.player].audio.muted = true
    }
    Unmute(){
      this.mute = false
      this.tableData[this.player].audio.muted = false
    
    }


    playAudio(item: any,index: any) {
      // this.common.showLoader()
      this.showAudioPlayer = true;
      // this.waveform = item
      this.player = index
      var play_time :any;
      // document.querySelectorAll("#player-row-"+index+ " .play_button.play")[0].click();
      const element = document.querySelectorAll("#player-row-"+index+ " .play_button.play")[0];
      play_time = document.querySelectorAll("#player-row-"+index+ " .play_button.seek")[0].getAttribute("data-seek-time");
      play_time = parseFloat(play_time).toFixed(2);

      if (element && 'click' in element) {
        (element as HTMLElement).click();
      }
      this.tableData.map((r: any) => {
        r.hidepausebutton = false;
      })
      this.tableData.map((r: any) => {
        if (r.audio) r.audio.pause()
      })
      
  
      // this.tableData[index].audio.addEventListener('timeupdate', () => {
      //   this.sliderValue = this.tableData[index].audio.currentTime;
      // });
  
      if (!item.audio) {
        if (item.loop_type == 1) {
          if (item.tagged_loop_path !== null) {
            item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
            this.durationofaudio = item.tagged_loop_duration;
          } else {
            item.file = CONSTNAME.fILE_PATH + item.loop_path;
            this.durationofaudio = item.loop_duration;
          }
        } else {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        }
        // if (!item.duration) {
        //   let sound: any;
        //   sound = await new Promise((resolve, reject) => {
        //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
        //   });
        // }
        item.duration = this.durationofaudio;
        item.audio = new Audio(item.file);
        // this.childComponent.onPlayButtonClick(event)
        // //console.log(this.waveform);
        // item.waveform = new NgWaveformComponent(item.file);
        // this.waveform = item.waveform
        // //console.log(this.waveform);
        // this.waveform.play();
        // var target = event.target || event.srcElement || event.currentTarget;
        // var currently_playing_btn:any = document.querySelectorAll(".is-playing");
        // if (currently_playing_btn.length > 0) {
        //   if (target != currently_playing_btn[0]) {
        //     currently_playing_btn[0].nextSibling.click();
        //   }
        // }
      item.audio.currentTime = play_time;
      item.audio.play();
      // //console.log(item.audio.currentTime);
      
      this.common.hideLoader()
      item.audio.addEventListener('timeupdate', () => {
          this.sliderValue = item.audio.currentTime;
          var seek_btn = document.querySelectorAll("#player-row-"+this.player+ " .play_button.seek")[0];
          seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
          (seek_btn as HTMLElement).click();
          
        });
        item.audio.addEventListener('ended', () => {
          this.next();
        });
        if (item.duration >= 15) {
          this.above15second = true;
          this.apihitforstream = true
  
        }
        else if (item.duration < 15 && item.duration >= 5) {
  
          this.apihitforstream = true
          this.above5secondlessthan15 = true;
        } else if (item.duration > 1 && item.duration <= 5) {
  
          this.apihitforstream = true
          this.above1secondlessthan5 = true;
        }
      } else {
        item.audio.currentTime = play_time;
        // //console.log(item.audio.currentTime);
        item.audio.addEventListener('timeupdate', () => {
          this.sliderValue = item.audio.currentTime;
        });
        item.audio.addEventListener('ended', () => {
          this.next();
        });
        item.audio.play();
      this.common.hideLoader()
    }
      
  
  
  
  
      item.hidepausebutton = true;
      let r: any;
      this.id = setInterval(() => {
        r = item.audio.currentTime;
        if (r === item.duration || r > item.duration) {
  
          setTimeout(() => {
  
          }, 1000);
        }
  
        let dura: any = item.duration;
        if (item.duration >= 15) {
          if (r > 15) {
            if (this.apihitforstream == true) {
              this.apihitforstreaming(item, r, dura);
              clearInterval(this.id);
              this.apihitforstream = false;
  
            }
  
          }
        } else if (item.duration < 15 && item.duration >= 5) {
  
          if (r > 5) {
  
            if (this.apihitforstream == true) {
              this.apihitforstreaming(item, r, dura);
              clearInterval(this.id);
              this.apihitforstream = false;
  
            }
          }
  
  
        } else if (item.duration > 1 && item.duration <= 5) {
          if (r >= 1) {
            if (this.apihitforstream == true) {
              this.apihitforstreaming(item, r, dura);
              clearInterval(this.id);
              this.apihitforstream = false;
  
            }
  
          }
        }
      }, 200);
  
    }
    stopAudio(item: any,index:any) {
      // //console.log(item);
      if (item) {
        if (item.audio) {
          item.audio.pause()
          // this.showAudioPlayer = false;s
        }
        clearInterval(this.id);
  
        this.tableData.map((r: any) => {
          r.hidepausebutton = false;
        })
  
      } else {
        this.tableData.map((r: any) => {
          if (r.audio) {
            r.audio.pause()
          }
          r.hidepausebutton = false;
        })
  
      }
      // item.waveform.pause();
      
      // document.querySelectorAll("#player-row-"+index+ " .play_button.pause")[0].click();
      const element = document.querySelectorAll("#player-row-"+index+ " .play_button.pause")[0];

      if (element && 'click' in element) {
        (element as HTMLElement).click();
      }
    }
    forcolor(items:any){
      this.fruits.map((i)=>{
      // //console.log(i,"//",items);
      // //console.log(i===items);
        
        if(items===i){
          return 'equal'
        }

      })
      
    } 

  priceget(item: any) {
    if (item.loop_price == 0) {

    } else {
      return (Math.round(item.loop_price * 100) / 100).toFixed(2);

    }
  }

  onRegionChange(value: any = null) {
    // //console.log(value);
  }
  onTrackLoaded(v: any = null) {
    // //console.log("track");
    // //console.log(v);

  }
  onTrackRendered(v: any = null) {
    // //console.log("trackren");
    // //console.log(v);
  }

  audio_duration: any;
  play_duration: any;
  onDurationChange(value: any = null) {
    if (value == 0) {

    } else {
      this.audio_duration = value;
      // this.totaltime = (Math.round( value* 100) / 100).toFixed(2);

    }
    // //console.log(this.audio_duration);

    if (this.durationofaudio >= 15) {
      this.above15second = true;
      // //console.log("above 15 sec");
    }
    else if (this.durationofaudio < 15 && value >= 5) {
      // //console.log("between 5 and 15");
      this.above5secondlessthan15 = true;
    } else if (this.durationofaudio > 1 && value <= 5) {
      // //console.log("between 1 and 5");
      this.above1secondlessthan5 = true;
    }
    // //console.log(this.durationofaudio);
    // if (value >= 15) {
    //   this.above15second = true;
    //   // //console.log("above 15 sec");
    // }
    // else if (value < 15 && value >= 5) {
    //   // //console.log("between 5 and 15");
    //   this.above5secondlessthan15 = true;
    // } else if (value > 1 && value <= 5) {
    //   // //console.log("between 1 and 5");
    //   this.above1secondlessthan5 = true;
    // }
  }



  onTimeUpdate(v: any = null) {

    if (v.progress) {

      // //console.log("time", v.time);
      // this.currenttime = (Math.round( v.time* 100) / 100).toFixed(2); 
      if (v.progress >= 100) {
        // //console.log("complete")
        // this.hidepausebutton = true;
        // this.hideplaybutton = false;
        setTimeout(() => {
          this.apihitforstream = true;
          // this.currenttime = "0.00";
        }, 1000);
        setTimeout(() => {

          // this.currenttime = "0.00";
        }, 10);


      }
      if (this.above15second) {
        if (v.time >= 15) {

          // this.apihitforstream = true;
          // alert("15 second is passed");
          if (this.apihitforstream == true) {
            // //console.log(v.time);
            this.play_duration = v.time
            // //console.log("api will hit");
            // this.apihitforstreaming();
            this.apihitforstream = false;
          }

        }
      } else if (this.above5secondlessthan15) {
        if (v.time >= 5) {
          if (this.apihitforstream == true) {
            // //console.log(v.time);
            this.play_duration = v.time
            // //console.log("api will hit");
            // this.apihitforstreaming();
            this.apihitforstream = false;
          }
        }

      } else if (this.above1secondlessthan5) {
        if (v.time >= 1) {
          if (this.apihitforstream == true) {

            this.play_duration = v.time

            // this.apihitforstreaming();
            this.apihitforstream = false;
          }
        }

      }




    }



  }

  timeget(items: any) {
    // //console.log("great ", items)
    let date_future: any = new Date();
    let date_now: any = new Date(items.modified_on);




    let time = date_future - date_now;

    let seconds = moment.duration(time).seconds();
    let minutes = moment.duration(time).minutes();
    let hours = moment.duration(time).hours();
    let days = moment.duration(time).days();


    // //console.log(time,seconds, minutes , hours , days , date_future , date_now)




    if (seconds < 60 && minutes === 0) {
      // //console.log(seconds)
      return "Posted " + seconds + " sec" + " ago"
    }
    else if (minutes < 60 && hours === 0) {

      return "Posted " + minutes + " min" + " ago"

    }
    else if (hours == 1 && days === 0) {
      return "Posted " + hours + " hr" + "ago"
    }
    else if (hours <= 24 && days === 0) {
      return "Posted " + hours + " hrs" + " ago"
    } else if (days = 1) {
      return "Posted " + days + " day" + " ago"

    }

    else if (days > 1) {
      // //console.log(days)
      return "Posted " + days + " days" + " ago"
    }
  }
  onPaused(v: any = null) {
    // //console.log("pause");
  }

  tagsget(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    let strtag1: any = strtag[0];
    let strtag2: any = strtag[1];
    let strtag3: any = strtag[2];
    // //console.log(strtag1);
    // //console.log(strtag2);
    // //console.log(strtag3);
    return "#" + strtag1;

  }
  tagsget2(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');

    let strtag2: any = strtag[1];
    return "#" + strtag2
  }
  tagsget3(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    //  //console.log(strtag[2])
    let strtag3: any = strtag[2];
    // //console.log(strtag3);
    return "#" + strtag3
  }

  redirecttoprofilepage(item: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.user_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);

    this.router.navigate(['user/'], { queryParams: { user_id: this.encrypted } });

  }

  searchClick() {

    this.pageno = 1;
    this.searchApi();
  }


  async searchApi() {

    let string: any = this.validationforsearch;
    // this.validationforsearch = string








    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: string } });

    if (this.validationforsearch == "") {
      this.arrayforsearch = [];

      var stringArray = this.arrayforsearch;
    } else {
      this.arrayforsearch = [];
      // var string = "text to split";
      string = string.split(" ");
      var stringArray = this.arrayforsearch;
      for (var i = 0; i < string.length; i++) {
        stringArray.push(string[i]);
        // if (i != string.length - 1) {
        //   // stringArray.push(" ");
        // }
      }
    }
    if (stringArray.length > 5) {
      // //console.log("not more than 5");
      this.errorforsearch = true;
      this.searchvalue = "Not more than 5 tag are allowed"
    } else {
      this.errorforsearch = false;
      this.searchvalue = " "


      let arr: any = stringArray;
      // empty object

      let result = false;
      // create a Set with array elements
      const s = new Set(arr);
      // compare the size of array and Set
      if (arr.length !== s.size) {
        result = true;
      }
      if (result) {
        this.errorforsearch = true;
        this.searchvalue = "Tags can not be repeated"
      } else {
        this.errorforsearch = false;
        this.searchvalue = ""




        // let tokkenget = localStorage.getItem("loggedintoken");
        // const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };


        let sendingdata = {
          "page_no": this.pageno,
          "sort_on": this.sorton,
          "row_size": this.rowsize,
          "sort_type": this.sorttype,
          "tags": stringArray
        }

        let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

        let headers: any;
        if (tokkenget) {
          headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

        }
        // this.common.showLoader();
        this.api.forclientgettokenpublic(`/user/browse_tags`, sendingdata).then((res: any) => {

          // this.api.postApiHit(`/admin/get_all_uploads?page_no=${this.pageno}&row_size=${this.rowsize}&search=${search}`, {}, VARS.ADMIN_TYPE).then((res: any) => {
          // this.validationforsearch = "";
          if (res.response.status == 1) {
            this.common.hideLoader();
            if (res.response.data.total_pages == 1) {
              this.hidepagination = true;
            } else {
              this.hidepagination = false;
            }
            if (res.response.data.total_records == 0) {
              this.norecordfound = false;
            } else {
              this.norecordfound = true;
            }



            if (res.response.data.total_records == 0) {
              this.pagercount = [];


              this.tableData = res.response.data.pagging_list;
              this.record = this.tableData.length;
              this.noresult = true;
              // //console.log("aa");
              // this.toastr.success("No record found", res.response.errors, { positionClass: 'toast-bottom-right' });
              this.total = res.response.data.total_records;


            } else {
              this.common.hideLoader();

              this.noresult = false;
              this.pagercount = [];
              this.tableData = res.response.data.pagging_list;
              this.record = this.tableData.length;
              this.record = this.tableData.length;
              this.record = this.pageno - 1 + "1";
              if (this.record == "01") {
                this.record = 1;
              }
              this.pagenolist = res.response.data.total_pages + 1;
              for (let i = 1; i < this.pagenolist; i++) {
                this.pagercount.push(i);
              }
              this.total = res.response.data.total_records;
              this.pagenolist = res.response.data.total_pages + 1;
              this.record = this.pageno - 1 + "1";
              // //console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
              this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length

              this.tableData = res.response.data.pagging_list;
              // this.record = this.tableData.length;
              this.pagenolist = res.response.data.total_pages + 1;
              if (this.record == "01") {
                this.record = 1;
              }
              for (let i = 1; i < this.pagenolist; i++) {
                // //console.log("in")
                this.pagercount.push(i);

              }

            }

          } else {
            this.common.hideLoader();

            let errorthroughapi = res.response.errors;
            let error = [];

            for (var i = 0; i < errorthroughapi.length; i++) {
              let jk = error.push(errorthroughapi);
              this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
            }

          }
        }, async (e: any) => {
          this.common.hideLoader();
          this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
        });
      }
    }
  }

  sendingstrtag1: any
  sendingstrtag2: any
  sendingstrtag3: any

  redirectto1(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    this.sendingstrtag1 = strtag[0];
    // //console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag1 } });
    // //console.log(this.sendingstrtag1)

  }
  redirectto2(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    this.sendingstrtag2 = strtag[1];
    // //console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag2 } });
    // //console.log(item)
    // //console.log(this.sendingstrtag2)

  }
  redirectto3(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    this.sendingstrtag3 = strtag[2];
    // //console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag3 } });
    // //console.log(item)
    // //console.log(this.sendingstrtag3)

  }

  redirectto(item: any) {
    // //console.log(item);
    const param = `${this.value},${item}`
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by:param } })
      .then(() => {
        // This code will be executed after the navigation is complete
        // //console.log('Navigation complete');
        location.reload();
      });
  }
}
