import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table'
import { adminLteConf } from './admin-lte.conf';

import { AppRoutingModule } from './app-routing.module';


import { LayoutModule } from 'angular-admin-lte';

import { AppComponent } from './app.component';


import { LoadingPageModule, MaterialBarModule } from 'angular-loading-page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';



import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import { ToastrModule } from 'ngx-toastr';
// import { SubscriptionplanComponent } from './subscriptionplan/subscriptionplan.component'
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

// import { LoopsComponent } from './loops/loops.component';

// import { SubscriptiontiermainComponent } from './subscriptiontiermain/subscriptiontiermain.component';
import { CommonModule, DatePipe } from '@angular/common';

// import { PlansignupComponent } from './plansignup/plansignup.component';

import {MatDialogModule} from '@angular/material/dialog';

import {MatButtonToggleModule} from '@angular/material/button-toggle';

import { NgxSpinnerModule } from "ngx-spinner";

// import { RegisterComponent } from './user/register/register.component';

import {MatDividerModule} from '@angular/material/divider';
import { CookieService } from 'ngx-cookie-service';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';

import { MatNativeDateModule } from '@angular/material/core';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
// import { NgSelect2Module } from 'ng-select2';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgSelectModule } from '@ng-select/ng-select';

import {MatProgressBarModule} from '@angular/material/progress-bar';

import { NgxFileDropModule } from 'ngx-file-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import { GlobalsettingComponent } from './ADMIN_PAGE/globalsetting/globalsetting.component';
// import { CouponcodesComponent } from './ADMIN_PAGE/couponcodes/couponcodes.component';
import { AdminprofileComponent } from './ADMIN_PAGE/adminprofile/adminprofile.component';
import { AdminLoginComponent } from './ADMIN_PAGE/admin-login/admin-login.component';
import { SubscriptionplanComponent } from './ADMIN_PAGE/SUBSCRIPTION_PAGE/subscriptionplan/subscriptionplan.component';
import { CouponcodesComponent } from './ADMIN_PAGE/COUPON_PAGE/couponcodes/couponcodes.component';
import { SubscriptiontiermainComponent } from './ADMIN_PAGE/SUBSCRIPTION_PAGE/subscriptiontiermain/subscriptiontiermain.component';
import { CouponcodeformComponent } from './ADMIN_PAGE/COUPON_PAGE/couponcodeform/couponcodeform.component';
// import { LoopsComponent } from './ADMIN_PAGE/loops/loops.component';
import { CoreModule } from './ADMIN_PAGE/core/core.module';
import { Loopheadsignup2Component } from './CLIENT_PAGES/CLIENT_PAYMENT_PAGE_AFTER_LOGIN/loopheadsignup2/loopheadsignup2.component';
import { Plan3signupComponent } from './CLIENT_PAGES/CLIENT_PAYMENT_PAGE_AFTER_LOGIN/plan3signup/plan3signup.component';
import { Plan2signupComponent } from './CLIENT_PAGES/CLIENT_PAYMENT_PAGE_AFTER_LOGIN/plan2signup/plan2signup.component';
import { TermandconditionComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/termandcondition/termandcondition.component';
import { ContactusComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/contactus/contactus.component';
import { PaymentreceiptComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/paymentreceipt/paymentreceipt.component';
import { VerifyEmailComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/verify-email/verify-email.component';
import { HomepageComponent } from './CLIENT_PAGES/CLIENT_PAGE_HEADER_FOOTER/homepage/homepage.component';
import { NotFoundComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/not-found/not-found.component';
import { LoginLoopheadComponent } from './CLIENT_PAGES/client_LOGIN_PAGE/login-loophead/login-loophead.component';
import { ForgotpasswordmodelComponent } from './CLIENT_PAGES/forgotpasswordmodel/forgotpasswordmodel.component';
import { ResetPasswordComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/reset-password/reset-password.component';
import { RegistersComponent } from './CLIENT_PAGES/user/registers/registers.component';
import { EmailVerificationComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/email-verification/email-verification.component';
// import { DashboardComponent } from './CLIENT_PAGES/user/dashboard/dashboard.component';
import { DeleteaccountComponent } from './CLIENT_PAGES/MODAL/deleteaccount/deleteaccount.component';
import { MyuploadsmyaccountComponent } from './CLIENT_PAGES/user/component/myuploadsmyaccount/myuploadsmyaccount.component';
import { EmailchangeverificationComponent } from './CLIENT_PAGES/MODAL/emailchangeverification/emailchangeverification.component';
import { SubscriptionstatusComponent } from './CLIENT_PAGES/user/subscriptionstatus/subscriptionstatus.component';
import { DetailuploadloopComponent } from './CLIENT_PAGES/user/detailuploadloop/detailuploadloop.component';
// import { LoopheadcontainerComponent } from './CLIENT_PAGES/user/component/loopheadcontainer/loopheadcontainer.component';
import { UploadloopComponent } from './CLIENT_PAGES/uploadloop/uploadloop.component';
import { FooterComponent } from './CLIENT_PAGES/CLIENT_PAGE_HEADER_FOOTER/footer/footer.component';
import { HeaderComponent } from './CLIENT_PAGES/CLIENT_PAGE_HEADER_FOOTER/header/header.component';
// import { CouponcodeformComponent } from './ADMIN_PAGE/couponcodeform/couponcodeform.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { MYACCOUNTComponent } from './CLIENT_PAGES/my-account/my-account.component';
import { REPORTTRACKMODELComponent } from './CLIENT_PAGES/MODAL/report-track-model/report-track-model.component';
import { WebhookEventsComponent } from './CLIENT_PAGES/ROOT/webhook-events/webhook-events.component';
import { UploadloopkitComponent } from './CLIENT_PAGES/user/uploadloopkit/uploadloopkit.component';
import { UploadClickComponent } from './CLIENT_PAGES/MODAL/upload-click/upload-click.component';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { ConfirmCancelComponent } from './CLIENT_PAGES/MODAL/confirm-cancel/confirm-cancel.component';
import { NgWaveformModule } from 'ng-waveform';
import { RedirectfrompaypalComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/redirectfrompaypal/redirectfrompaypal.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { BannedComponent } from './ADMIN_PAGE/banned/banned.component';
import { AddBannedComponent } from './ADMIN_PAGE/add-banned/add-banned.component';
import { LoopsComponent } from './ADMIN_PAGE/loops/loops-list-uploads/loops.component';
import { LoopsReportedloopsComponent } from './ADMIN_PAGE/loops/loops-reportedloops/loops-reportedloops.component';
import { LoopsDetailUploadsComponent } from './ADMIN_PAGE/loops/loops-detail-uploads/loops-detail-uploads.component';
import { RoughComponent } from './rough/rough.component';
import { ConfirmLeaveComponent } from './CLIENT_PAGES/MODAL/confirm-leave/confirm-leave.component';
import { BannedlistingComponent } from './ADMIN_PAGE/containerforbanpage/bannedlisting/bannedlisting.component';
import { AddbannedComponent } from './ADMIN_PAGE/containerforbanpage/addbanned/addbanned.component';
import { HomepagemainComponent } from './CLIENT_PAGES/homepagemain/homepagemain.component';
import { ConfirmDeleteComponent } from './CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
import { AudioPlayerComponent } from './commonComponent/audio-player/audio-player.component';
import { LoopkittrialComponent } from './loopkittrial/loopkittrial.component';
import { ImageoptionsComponent } from './CLIENT_PAGES/MODAL/imageoptions/imageoptions.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { IvyCarouselModule } from 'angular-responsive-carousel';

import { CarouselModule } from 'ngx-owl-carousel-o';

// import { AngularFontAwesomeModule } from 'angular-font-awesome';
// import { CarouselModule } from '@ciri/ngx-carousel';
import { SwiperModule } from "swiper/angular";

import 'hammerjs';
import { ProfileComponent } from './CLIENT_PAGES/profile/profile.component';
import { SoundsComponent } from './CLIENT_PAGES/componentprofile/sounds/sounds.component';
// import { LikesComponent } from './CLIENT_PAGES/componentprofile/likes/likes.component';
// import { LoopsuserlikeComponent } from './CLIENT_PAGES/componentprofile/likes/loopsuserlike/loopsuserlike.component';
import { LoopkitsuserlikesComponent } from './CLIENT_PAGES/componentprofile/likes/loopkitsuserlikes/loopkitsuserlikes.component';
import { ForprivateuserComponent } from './CLIENT_PAGES/MODAL/forprivateuser/forprivateuser.component';
import { MyFeedComponent } from './CLIENT_PAGES/my-feed/my-feed.component';
import { ContactComponent } from './CLIENT_PAGES/contact/contact.component';
import { RoughwaveComponent } from './CLIENT_PAGES/roughwave/roughwave.component';
import { DashboarComponent } from './ADMIN_PAGE/dashboar/dashboar.component';
import { DashboardComponent } from './ADMIN_PAGE/dasboard/dashboard/dashboard.component';
import { WaveformComponent } from './CLIENT_PAGES/waveform/waveform.component';
import { ParamsirroughComponent } from './paramsirrough/paramsirrough.component';
import { RoughtrialforcheckComponent } from './roughtrialforcheck/roughtrialforcheck.component';
import { SearchbytagComponent } from './CLIENT_PAGES/searchbytag/searchbytag.component';
import { AboutComponent } from './CLIENT_PAGES/Footercontent/about/about.component';
import { CustomerSupportComponent } from './CLIENT_PAGES/Footercontent/customer-support/customer-support.component';
import { PrivacyPolicyComponent } from './CLIENT_PAGES/Footercontent/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './CLIENT_PAGES/Footercontent/cookies-policy/cookies-policy.component';
import { MaintagsearchforadvanceComponent } from './CLIENT_PAGES/Advancesearchfortags/maintagsearchforadvance/maintagsearchforadvance.component';
import { UsersearchComponent } from './CLIENT_PAGES/Advancesearchfortags/usersearch/usersearch.component';
import { LoopsearchComponent } from './CLIENT_PAGES/Advancesearchfortags/loopsearch/loopsearch.component';
import { LoopkitsearchComponent } from './CLIENT_PAGES/Advancesearchfortags/loopkitsearch/loopkitsearch.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Ng5SliderModule } from 'ng5-slider';
import { DownloadmodalComponent } from './CLIENT_PAGES/MODAL/downloadmodal/downloadmodal.component';
import { RoughworkComponent } from './CLIENT_PAGES/roughwork/roughwork.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FollowListingComponent } from './CLIENT_PAGES/follow-listing/follow-listing.component';
import { TestFileComponent } from './test-file/test-file.component';
import { InvoiceComponent } from './CLIENT_PAGES/MODAL/invoice/invoice.component';
import { AllinvoicesComponent } from './ADMIN_PAGE/loops/allinvoices/allinvoices.component';
import { LikesComponent } from './ADMIN_PAGE/loops/loopdetail/likes/likes.component';
import { RepostsComponent } from './ADMIN_PAGE/loops/loopdetail/reposts/reposts.component';
import { CommentsComponent } from './ADMIN_PAGE/loops/loopdetail/comments/comments.component';
import { ReportedlogComponent } from './ADMIN_PAGE/loops/loopdetail/reportedlog/reportedlog.component';
import { UserformComponent } from './ADMIN_PAGE/userform/userform.component';
import { ListinguserComponent } from './ADMIN_PAGE/Alluser/listinguser/listinguser.component';
import { InvoiceredirectComponent } from './CLIENT_PAGES/invoiceredirect/invoiceredirect.component';
import { AdmininvoiceComponent } from './CLIENT_PAGES/MODAL/admininvoice/admininvoice.component';
import { S3bucketComponent } from './CLIENT_PAGES/s3bucket/s3bucket.component';
import { JwPaginationModule } from 'jw-angular-pagination';
import { DownloadsComponent } from './ADMIN_PAGE/Alluser/Listforuser/downloads/downloads.component';
import { UploadsComponent } from './ADMIN_PAGE/Alluser/Listforuser/uploads/uploads.component';
import { DetailComponent } from './ADMIN_PAGE/Alluser/Listforuser/detail/detail.component';
// import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { DeletedloopLoopkitsComponent } from './ADMIN_PAGE/loops/deletedloop-loopkits/deletedloop-loopkits.component';
import { RefreshroughComponent } from './ADMIN_PAGE/rough/refreshrough/refreshrough.component';
import { TagCloudModule } from 'angular-tag-cloud-module';

import { ExploreComponent } from './CLIENT_PAGES/explore/explore.component';
import { FilterComponent } from './CLIENT_PAGES/MODAL/filter/filter.component';
import { EmailsentComponent } from './CLIENT_PAGES/MODAL/emailsent/emailsent.component';
import { MusicPlayerComponent } from './CLIENT_PAGES/MODAL/music-player/music-player.component';
import { ProfileSettingsComponent } from './CLIENT_PAGES/profile-settings/profile-settings.component';
import { FollwersComponent } from './CLIENT_PAGES/MODAL/follwers/follwers.component';
import { ShareComponent } from './CLIENT_PAGES/MODAL/share/share.component';
import { AuthComponent } from './CLIENT_PAGES/MODAL/auth/auth.component';
import { FollowingComponent } from './CLIENT_PAGES/MODAL/following/following.component';
import { VerifyotpComponent } from './CLIENT_PAGES/verifyotp/verifyotp.component';
import { MydownloadComponent } from './CLIENT_PAGES/mydownload/mydownload.component';
import { NotificationsComponent } from './CLIENT_PAGES/notifications/notifications.component';
import { CreatePackComponent } from './CLIENT_PAGES/create-pack/create-pack.component';
import { PackDetialsComponent } from './CLIENT_PAGES/pack-detials/pack-detials.component';
import { PendingComponent } from './CLIENT_PAGES/MODAL/pending/pending.component';
import { UpdatePackComponent } from './CLIENT_PAGES/update-pack/update-pack.component';
import { VerifyLoginTokenComponent } from './CLIENT_PAGES/verify-login-token/verify-login-token.component';
import { TestComponent } from './CLIENT_PAGES/test/test.component';
import { EditLoopComponent } from './CLIENT_PAGES/MODAL/edit-loop/edit-loop.component';
import { EditPackComponent } from './CLIENT_PAGES/MODAL/edit-pack/edit-pack.component';
import { DeleteComponent } from './CLIENT_PAGES/MODAL/delete/delete.component';
import { UploadSuccessComponent } from './CLIENT_PAGES/MODAL/upload-success/upload-success.component';
import { DeletePackComponent } from './CLIENT_PAGES/MODAL/delete-pack/delete-pack.component';
import { DownloadFilterComponent } from './CLIENT_PAGES/MODAL/download-filter/download-filter.component';
import { DownloadComponent } from './CLIENT_PAGES/MODAL/downloads/downloads.component';
import { ImagePreviewComponent } from './CLIENT_PAGES/MODAL/image-preview/image-preview.component';
import { UrlComponent } from './CLIENT_PAGES/url/url.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
// import { FileSaverModule } from 'ngx-filesaver';
// import { FileSaverModule } from 'ngx-filesaver';
// import { FileSaverModule } from 'ngx-filesaver';
// import { FileSaverService } from 'ngx-filesaver';
// import { FileSaverModule } from 'ngx-filesaver';
// import { FileService } from './file.service';
// import { LoopsuserlikeComponent } from './CLIENT_PAGES/componentprofile/Likes/loopsuserlike/loopsuserlike.component';
// import { LoopkitsuserlikesComponent } from './CLIENT_PAGES/componentprofile/Likes/loopkitsuserlikes/loopkitsuserlikes.component';
@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    ReactiveFormsModule,
    MatTableModule,
    HttpClientModule,
    FormsModule,
    LayoutModule.forRoot(adminLteConf),
    LoadingPageModule, MaterialBarModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    MatButtonModule,
    MatButtonToggleModule,
    NgxSpinnerModule,
    MatDividerModule,
    NgxSkeletonLoaderModule,
    MatSlideToggleModule,
    // MatChipsModule,
    // MatCardModule,
    // MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    NgSelectModule,
    MatProgressBarModule,
    NgxFileDropModule,
    NgxPaginationModule,
    NgImageFullscreenViewModule,
    NgWaveformModule,
    MatTooltipModule,
    NgImageSliderModule,
    IvyCarouselModule,
    CarouselModule ,
    // CarouselModule,
    MatProgressSpinnerModule,
    SwiperModule,
    CommonModule,
    Ng5SliderModule,
    InfiniteScrollModule,
    JwPaginationModule,
    MatBadgeModule,
    TagCloudModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    })
    
    // CommonModule
    
    // FileSaverModule
    // FileSaverModule
    // FileSaverModule
    // FileService
    
    // AngularFontAwesomeModule
    
    
  ],
  declarations: [
    AppComponent,
    
    PackDetialsComponent,
    GlobalsettingComponent,
    SubscriptionplanComponent,
   
    LoopsComponent,
    CouponcodesComponent,
    AdminprofileComponent,
    SubscriptiontiermainComponent,
    Loopheadsignup2Component,
    // PlansignupComponent,
    Plan3signupComponent,
    Plan2signupComponent,
    TermandconditionComponent,
    ContactusComponent,
    PaymentreceiptComponent,
    VerifyEmailComponent,
    HeaderComponent,
    FooterComponent,
    HomepageComponent,
    NotFoundComponent,
    LoginLoopheadComponent,
    ForgotpasswordmodelComponent,
    ResetPasswordComponent,
    AdminLoginComponent,
    AdminLoginComponent,

    // RegisterComponent,
    RegistersComponent,
    EmailVerificationComponent,
    
    CouponcodeformComponent,
    UploadloopComponent,
  
    DetailuploadloopComponent,
    SubscriptionstatusComponent,
    EmailchangeverificationComponent,
    MyuploadsmyaccountComponent,
    DeleteaccountComponent,
    MYACCOUNTComponent,
    REPORTTRACKMODELComponent,
    WebhookEventsComponent,
    UploadloopkitComponent,
    UploadClickComponent,
    ConfirmCancelComponent,
    RedirectfrompaypalComponent,
    BannedComponent,
    AddBannedComponent,
    LoopsReportedloopsComponent,
    LoopsDetailUploadsComponent,
    RoughComponent,
    ConfirmLeaveComponent,
    BannedlistingComponent,
    AddbannedComponent,
    HomepagemainComponent,
    ConfirmDeleteComponent,
    AudioPlayerComponent,
    LoopkittrialComponent,
    ImageoptionsComponent,
    ProfileComponent,
    SoundsComponent,
    // LikesComponent,
    // LoopsuserlikeComponent,
    LoopkitsuserlikesComponent,
    ForprivateuserComponent,
    MyFeedComponent,
    ContactComponent,
    RoughwaveComponent,
    DashboarComponent,
    DashboardComponent,
    WaveformComponent,
    ParamsirroughComponent,
    RoughtrialforcheckComponent,
    SearchbytagComponent,
    AboutComponent,
    CustomerSupportComponent,
    PrivacyPolicyComponent,
    CookiesPolicyComponent,
    MaintagsearchforadvanceComponent,
    UsersearchComponent,
    LoopsearchComponent,
    LoopkitsearchComponent,
    DownloadmodalComponent,
    RoughworkComponent,
    FollowListingComponent,
    TestFileComponent,
    InvoiceComponent,
    AllinvoicesComponent,
    LikesComponent,
    RepostsComponent,
    CommentsComponent,
    ReportedlogComponent,
    UserformComponent,
    ListinguserComponent,
    InvoiceredirectComponent,
    AdmininvoiceComponent,
    S3bucketComponent,
    DownloadsComponent,
    UploadsComponent,
    DetailComponent,
    DeletedloopLoopkitsComponent,
    RefreshroughComponent,
    ExploreComponent,
    FilterComponent,
    EmailsentComponent,
    MusicPlayerComponent,
    ProfileSettingsComponent,
    FollwersComponent,
    ShareComponent,
    AuthComponent,
    FollowingComponent,
    VerifyotpComponent,
    MydownloadComponent,
    NotificationsComponent,
    CreatePackComponent,
    PendingComponent,
    UpdatePackComponent,
    VerifyLoginTokenComponent,
    TestComponent,
    EditLoopComponent,
    EditPackComponent,
    DeleteComponent,
    UploadSuccessComponent,
    DeletePackComponent,
    DownloadFilterComponent,
    DownloadComponent,
    ImagePreviewComponent,
    UrlComponent
 
    
    
    
   
  ],
  entryComponents: [ForgotpasswordmodelComponent],
  providers: [DatePipe, CookieService,Meta],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
