






import { environment } from 'src/app/sevicesall/alldata';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
// import { ConfirmDeleteComponent } from 'src/app/CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.
import { NgWaveformModule } from 'ng-waveform';
import { ITimeUpdateEvent, NgWaveformComponent, IRegionPositions } from 'ng-waveform';
import { MatDialog } from '@angular/material/dialog';
import { WaveformComponent } from 'src/app/commonComponent/waveform/waveform.component';
// import { ConfirmDeleteComponent } from '../CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
// import { WaveformComponent } from '../commonComponent/waveform/waveform.component';

@Component({
  selector: 'app-roughwave',
  templateUrl: './roughwave.component.html',
  styleUrls: ['./roughwave.component.css']
})
export class RoughwaveComponent implements OnInit {
  // @ViewChild(ChildComponent, {static : true}) child : WaveformComponent;

  @ViewChild('waveform', { static: false }) waveform: NgWaveformComponent;
    @ViewChild('waveform2', { static: false }) waveform2: NgWaveformComponent;

  


  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  hidepagination = false;
  norecordfound = false;
  sorttype = "";
  sorton = "";
  imageofcomment: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  value: any;
  encryptedagainremoveslashes: string;
  tablefortagsdisplay1: any;
  tablefortagsdisplay2: any;
  tablefortagsdisplay3: any;
  time: string;
  addred: boolean;
  addgreen: boolean;
  durationofaudio: any;
  above15second = false;
  apihitforstream = true;
  above5secondlessthan15 = false;
  above1secondlessthan5 = false;
  id: any;
  nocomments: boolean;
  src: any;
  pagenocount: any;
  hideplaybutton: boolean;
  hidepausebutton: boolean;

  audioLinks= [
    {file:'https://d1ptp6rux3p5im.cloudfront.net/demo_loopkits/1624344925911-589286d5cb3c1fb8865d.mp3'},
    {file:'https://d1ptp6rux3p5im.cloudfront.net/main_loops/1624002108706-file_example_WAV_1MG.wav'},
  ]

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService, private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.value = this.route.snapshot.queryParamMap.get('user_id');
    this.encryptedagainremoveslashes = decodeURIComponent(this.value);

  }



  // onPlayButtonClick() {

  //   // //console.log(this.waveform)
  //   this.waveform2.play();
    
  //   // this.tableData.map((r: any) => {
  //   //   r.waveform.play();
  //   //   //console.log(r);
  //   // })

  //   this.hideplaybutton = true;
  //   this.hidepausebutton = false;
  // }
  // onPauseButtonClick() {
  //   this.waveform2.pause();
  //   this.hidepausebutton = true;
  //   this.hideplaybutton = false;
  // }

  // onRegionChange(value: any = null) {
  //   // //console.log(value);
  // }
  // onTrackLoaded(v: any = null) {
  //   // //console.log("track");
  //   // //console.log(v);

  // }
  // onTrackRendered(v: any = null) {
  //   // //console.log("trackren");
  //   // //console.log(v);
  // }
  // onPaused(v: any = null) {
  //   // //console.log("pause");
  // }
  // callChild(index: any = null){
  //   //console.log('index',index);
  //   this.child.onPlayButtonClick(index)
  // }

}


// function ChildComponent(ChildComponent: any, arg1: { static: true; }) {
//   throw new Error('Function not implemented.');
// }



