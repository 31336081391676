import { Component, ElementRef, HostListener, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { ZipService } from 'src/app/allServices/zip.service';
import * as CryptoJS from 'crypto-js';
import { DownloadFilterComponent } from '../MODAL/download-filter/download-filter.component';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mydownload',
  templateUrl: './mydownload.component.html',
  styleUrls: ['./mydownload.component.css']
})
export class MydownloadComponent implements OnInit {
  data:any=[]
  search :any
  durationofaudio: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  id: any;
  player: number =0;
  disable: boolean=false;
  volumeValue: number = 1; 
  sliderValue: any;
  apihitforstream: boolean;
  mute: boolean;
  steps: any;
  showAudioPlayer: boolean;
  above15second: boolean;
  above5secondlessthan15: boolean;
  above1secondlessthan5: boolean;
  http: any;
  hide: any=false;
  isempty: boolean = false;
  active: boolean;
  paginationempty: boolean;
  rowsize: any =10;
  title: any;
  days: any;
  username: any;
  pages: any;
  type: string | null;
  index: any;
  private navigationSubscription: Subscription;

  constructor(private router: Router,public api: ApiServiceService, private route: ActivatedRoute,public common: commonservice, private zipService: ZipService,private matDialog: MatDialog, private toastr: ToastrService,private titleService: Title,private zone: NgZone,    private elementRef: ElementRef
  ) {
    // this.titleService.setTitle("My Downloads");
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Navigation is about to start, handle cleanup logic
        this.handleCleanup();
      } else if (event instanceof NavigationEnd) {
        // Navigation has ended, perform any necessary actions
        // (e.g., trigger the click event)
        this.handleNavigationEnd();
      }
    });
   }
   @HostListener('body:click', ['$event'])
  onDocumentClick(event: Event): void {
    const target = event.target as HTMLElement;
    // Check if the target or its parent is the button
    const isButtonOrChild = target.matches(`.action-dropdown${this.index}, .action-dropdown${this.index} img`) ||
                            target.closest(`.action-dropdown${this.index}`) !== null ;
  
let flag = 1
    if (isButtonOrChild) {
      // Run the code inside the Angular zone
      const dropdownMenus = document.querySelectorAll(`.action-dropdown-menu${this.index}.show`);
      dropdownMenus.forEach((menu: Element) => {
        // ////////console.log(menu);
        flag = 0;   
        menu.classList.remove('show');
        const body = document.body
    body?.classList?.remove('dropdown-show');
      })
      if(flag==1){
      this.zone.run(() => {
        const thisId = target.closest(`.action-dropdown${this.index}`)?.id;
        const dropdownMenu = document.querySelector(`.action-dropdown-menu${this.index}[aria-labelledby="${thisId}"]`);
        dropdownMenu?.classList.add('show');
        const body = document.body
    body?.classList?.add('dropdown-show');
      });
    }
    } else {

      // Handle body click to close dropdown
      const dropdownMenus = document.querySelectorAll(`.action-dropdown-menu${this.index}.show`);
    
      
      dropdownMenus.forEach((menu: Element) => {
        // Check if the element is an HTMLElement before accessing classList
        if (menu instanceof HTMLElement) {
          // Check if the click is on a button with class '.play_button.seek'
          const isPlayButtonSeek = target.matches('.play_button.seek') || target.closest('.play_button.seek') !== null;

          if (!isPlayButtonSeek) {
            // Run the code inside the Angular zone
            this.zone.run(() => {
              menu.classList.remove('show');
              const body = document.body
    body?.classList?.remove('dropdown-show');
            });
          }
        }
      });
    }
  }
   @HostListener("window:keydown", ["$event"])
   handleKeyboardEvent(event: KeyboardEvent) {
     console.warn("hii", event, this.player);
 
     if (event.key === "ArrowUp") {
      event.preventDefault();
      if (this.player >= 0) {
         this.previous();
       }
     } else if (event.key === "ArrowDown") {
       // if(this.player<=this.data.length){
       this.next(this.player);
       // }else{
       //   this.player = 0
       //   this.next()
       // }
     } 
     else if (event.key === " ") {
      const searchInput: HTMLInputElement = this.elementRef.nativeElement.querySelector('.form-control');
      const isFocused = (searchInput === document.activeElement);
            if(!isFocused){
              event.preventDefault();
              if (this.data[this.player]?.hidepausebutton) {
                this.stopAudio(this.data[this.player], this.player);
              } else {
                this.playAudio(this.data[this.player], this.player, event);
                // }
              }
          }
     }
   }
   private handleCleanup() {
    // Perform cleanup logic before navigation
    // (e.g., pausing audio or video playback)
    const element = this.elementRef.nativeElement.querySelector("#player-row-" +  this.player + " .play_button.pause");
    if (element) {
      element.click();
    }
    // //console.log('Performing cleanup before navigation');
  }

  private handleNavigationEnd() {
    // Navigation has ended, perform necessary actions
    // (e.g., trigger the click event)
    // //console.log('Navigation has ended, triggering click event');

    const element = this.elementRef.nativeElement.querySelector("#player-row-" +  this.player + " .play_button.pause");
    if (element) {
      element.click();
    }
  }
   row(i:any){
    this.index = i
    const body = document.body
    body?.classList?.add('show');


   }
  ngOnInit(): void {
  this.title = this.route?.snapshot?.queryParamMap.get('title');
  this.days = this.route?.snapshot?.queryParamMap.get('days');
  this.username = this.route?.snapshot?.queryParamMap.get('username');
  this.type = this.route?.snapshot?.queryParamMap.get('type');
this.submit()

  }
  
 
  submit(){
this.stopAudio(this.data[this.player],this.player)
   
    // ////////console.log(this.title);
    
    // this.common.showLoader()
    const sending = {
       page_no: 1,
       row_size: this.rowsize,
       sort_on: "created_on",
       sort_type: "DESC",
       search :this.search,
       search_title:this.title,
       search_username:this.username,
       days:this.days,
       type:this.type

     }
       this.api.postApiHit("/user/get_all_downloads",sending, VARS.ClIENT_TYPE).then((res: any) => {
         this.common.hideLoader()
        //  ////////console.log(res.response.data.pagging_list);
      if(res.response.data.total_records==0){
           this.isempty = true
         }else{
           this.isempty = false
           this.data = res.response.data.pagging_list
           res.response.data.pagging_list.map((item:any,index: any) =>{
        this.pages =  res.response.data.total_pages
        res.response.data.pagging_list[index].tags_array= item.tags?.split('\n');
      this.data[index].hidepausebutton = false;
    });
         }
         
     })


  }
  timeupdate(){
    const du =  this.data[this.player].duration || this.data[this.player].loop_duration || this.data[this.player].tagged_loop_duration
    return du * 1000
   }
   onSliderValueChanged(value: number) {
    this.sliderValue = value; // Update sliderValue in the parent component
   
  }
  ngOnDestroy() {
    const element = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.pause"
    )[0];
    // //console.log(element);
    
    if (element && "click" in element) {
      (element as HTMLElement).click();
    }
  }
  redirectto(item: any) {
    // ////////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: item } });
    // ////////console.log(item)
    
  }
  setimage(data: any) {
    if (data.artwork == null) {
      if (data.profile_image_path == null) {
        return this.profile_image_pathofcomments;

      } else {
        if (data.profile_image_path.includes("/profile_photos/")) {
          return  CONSTNAME.fILE_PATH + data.profile_image_path;
  
          }else
          return data.profile_image_path;
      }

    } else {

      return CONSTNAME.fILE_PATH + data.artwork;
    }
  }
  downloadFile(filePath: any) {
    // Create a hidden anchor element
    // this.common.showLoader()
    this.disable = true
    const a = document.createElement('a');
    // ////////console.log('jid',filePath);
    
    document.body.appendChild(a);
    a.style.display = 'none';
    var path
    if( filePath.loop_path != null){
      path =  CONSTNAME.fILE_PATH + filePath.loop_path
    }else{
      path=CONSTNAME.fILE_PATH + filePath.tagged_loop_path
    }
    // Create a blob for the file data
    fetch(path)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a blob URL for the file
        const blobUrl = window.URL.createObjectURL(blob);

        // Set the anchor's href and download attributes
        a.href = blobUrl;
        
        a.download = filePath.title+'_'+filePath.bpm+'_'+filePath.sub_key+'_@'+filePath.username+'.mp3'; // Set the desired filename
        this.common.hideLoader()

        // Simulate a click on the anchor to trigger the download
        a.click();

        // Clean up by revoking the blob URL
        window.URL.revokeObjectURL(blobUrl);
        this.disable = false
      }).catch((response)=>{
        this.toastr.error(response.response.errors, "", { positionClass: 'toast-bottom-right' })
        this.disable = false
      })

    // Remove the anchor element from the DOM
    document.body.removeChild(a);
  }



  redirecttodetailpage(items:any) {
  if(items.loop_type==1){    
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(items.loop_id), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    this.encrypted =encodeURIComponent(this.encrypted);
    // ////////console.log(value);
    // ////////console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
  }else{
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(items.pack_id), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    this.encrypted =encodeURIComponent(this.encrypted);
    // ////////console.log(value);
    // ////////console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-pack'], { queryParams: { pack_id: this.encrypted } });
  }
  }

  srcforsound(items: any) {
    if (items.loop_type == 1) {
      if (items.tagged_loop_path !== null) {
        this.durationofaudio = items.tagged_loop_duration;
        return CONSTNAME.fILE_PATH + items.tagged_loop_path;
      } else {
        this.durationofaudio = items.loop_duration;
      return CONSTNAME.fILE_PATH + items.loop_path;
    }

    } else {
      this.durationofaudio = items.tagged_loop_duration;
      return CONSTNAME.fILE_PATH + items.tagged_loop_path;
    }
  }
  
  /* Start of Player Functions */
  next(i:any) {
    if (this.data.length - 1 == i) {
      this.disable = false;
      this.data[i].hidepausebutton = false;
      this.data[i].audio.currentTime = 0;
      this.sliderValue = 0;
      //  this.player = this.player + 1
      // this.playAudio(this.data[0], 0, event);
    } else {
      this.disable = false;
      this.data[i].audio.currentTime = 0;
      this.sliderValue = 0;
      this.stopAudio(this.data[i], i);
      i = i + 1;
      var seek_btn = document.querySelectorAll(
        "#player-row-" + i + " .play_button.vol"
      )[0];
      seek_btn.setAttribute("data-seek-vol", String(this.volumeValue));
      (seek_btn as HTMLElement).click();
      setTimeout(() => {
      this.playAudio(this.data[i], i, event);
        
      }, 1000);
      if(this.mute===true){
        var seek_btn = document.querySelectorAll(
          "#player-row-" + i + " .play_button.mute"
        )[0];
        // seek_btn.setAttribute("data-seek-time", String());
        (seek_btn as HTMLElement).click();
      }
    }
  }
  previous() {
    //////console.log(this.data.length - 1, this.player);

    if (this.player == 0) {
      this.disable = true;
    } else {
      this.disable = false;
      this.sliderValue = 0;
      this.data[this.player].audio.currentTime = 0;
      this.stopAudio(this.data[this.player], this.player);
      this.player = this.player - 1;
      this.playAudio(this.data[this.player], this.player, event);
    }
  }
  onInput(event: any): void {
    // //console.log('event',event.target.value);
//     // this.stopAudio(this.data[this.player],this.player)
    var seek_time = parseFloat(event.target.value).toFixed(2);
//     this.data[this.player].audio.currentTime = seek_time;
//     //console.log("sli",this.data[this.player].audio.currentTime);
//     // this.playAudio(this.data[this.player],this.player,event)
    
this.sliderValue = seek_time
// //console.log("sli",this.sliderValue);

    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.seek"
    )[0];
    seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }


  onVolumeInput(event: any): void {
    // ////console.log(event.target.value);
    var seek_time = parseFloat(event.target.value).toFixed(2);
    this.data[this.player].audio.volume = parseFloat(event.target.value);
    this.volumeValue =  this.data[this.player].audio.volume
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.vol"
    )[0];
    seek_btn.setAttribute("data-seek-vol", String(seek_time));
    (seek_btn as HTMLElement).click();
    if(this.volumeValue>0){
      this.Unmute()
    }else if(this.volumeValue==0){
      this.Mute()
    }
  }
  Mute() {
    this.volumeValue = 0
    this.mute = true;
    this.data[this.player].audio.muted = true;
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.mute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }
  Unmute() {
    this.volumeValue = this.data[this.player].audio.volume
    this.mute = false;
    this.data[this.player].audio.muted = false;
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.unmute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }


  forward() {
    // this.steps = this.steps + 0.10;
    this.data[this.player].audio.currentTime += 2;
    this.sliderValue =  this.data[this.player].audio.currentTime
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.forward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
    //////console.log(this.steps);
  }
  backward() {
    // this.steps = this.steps - 0.01;
    this.data[this.player].audio.currentTime -= 2;
    this.sliderValue =  this.data[this.player].audio.currentTime
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.backward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }

  playAudio(item: any, index: any, event: any) {
    // //console.log(item,index);
    const divsWithClass = document.querySelectorAll('div.list');

    divsWithClass.forEach(div=>{
      div.classList.add('ply');
    })
    // this.common.showLoader();
   
    this.showAudioPlayer = true;
  
    // list.forEach((menu: Element) => {
    //   // ////////console.log(menu);
    //   menu.classList.remove('ply');
    // })

    // this.waveform = item
    this.player = index;
    var play_time: any =0.00;
    // document.querySelectorAll("#player-row-"+index+ " .play_button.play")[0].click();
   

    this.data.map((r: any) => {
      r.hidepausebutton = false;
    });
    this.data.map((r: any,i:any) => {
      if (r.audio) {
        //////console.log('hii');
        
        r.audio.pause();
        const element = document.querySelectorAll(
          "#player-row-" + i + " .play_button.pause"
        )[0];
    
        if (element && "click" in element) {
          (element as HTMLElement).click();
        }
      }
    });
    const element = document.querySelectorAll(
      "#player-row-" + index + " .play_button.play"
    )[0];

    // //console.log(element);
    
    play_time = document
      .querySelectorAll("#player-row-" + index + " .play_button.seek")[0]
      ?.getAttribute("data-seek-time");
    //////console.log(play_time);

    play_time = parseFloat(play_time).toFixed(2);
    // this.data[index].audio.addEventListener('timeupdate', () => {
    //   this.sliderValue = this.data[index].audio.currentTime;
    // });

    if (!item.audio) {
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      // if (!item.duration) {
      //   let sound: any;
      //   sound = await new Promise((resolve, reject) => {
      //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
      //   });
      // }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      // this.childComponent.onPlayButtonClick(event)
      // //////console.log(this.waveform);
      // item.waveform = new NgWaveformComponent(item.file);
      // this.waveform = item.waveform
      // //////console.log(this.waveform);
      // this.waveform.play();
      // var target = event.target || event.srcElement || event.currentTarget;
      // var currently_playing_btn:any = document.querySelectorAll(".is-playing");
      // if (currently_playing_btn.length > 0) {
      //   if (target != currently_playing_btn[0]) {
      //     currently_playing_btn[0].nextSibling.click();
      //   }
      // }
      // item.audio.currentTime = play_time;
    
      // item.audio.addEventListener('loadedmetadata', () => {
        if(!this.mute){
          item.audio.muted = false
            }
      item.audio.volume = this.volumeValue
      
    //  const i =  item.audio.play();
    //  //console.log(i);
     
      // if(!item.audio.paused){
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
    // }
      // });
      // item.audio.play();

      this.common.hideLoader();
      // item.audio.playbackRate = 1.3
      // //console.log(item.audio.currentTime);
    //   item.audio.addEventListener("timeupdate", (test:any, test2:any) => {
    //     // ////console.log("fff",test, test2);
    // //console.log("timeupdate",item.audio.currentTime);
        
    //   this.sliderValue = item.audio.currentTime;
    //     // this?.currentwaveform?.setRegionStart(this.sliderValue);
    //   ////////console.log(item.audio.currentTime);
    //   ////////console.log(this.sliderValue);
    //     var seek_btn = document.querySelectorAll(
    //       "#player-row-" + index + " .play_button.seek"
    //     )[0];
    //     // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
    //     // (seek_btn as HTMLElement).click();
    //     // this.currentwaveform.setRegionStart(this.sliderValue);
    //   });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        this.next(index);
      });
      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true;
      } else if (item.duration < 15 && item.duration >= 5) {
        this.apihitforstream = true;
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {
        this.apihitforstream = true;
        this.above1secondlessthan5 = true;
      }
    } else {
      if(!this.mute){
        item.audio.muted = false
          }
      item.audio.volume = this.volumeValue
      // item.audio.play();
      // item.audio.currentTime = play_time;
      // //console.log("currentTime",item.audio.currentTime);
      // item.audio.addEventListener("timeupdate", () => {
      //   this.sliderValue = item.audio.currentTime;
      //   // var seek_btn = document.querySelectorAll(
      //   //   "#player-row-" + index + " .play_button.seek"
      //   // )[0];
      //   // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
      //   // (seek_btn as HTMLElement).click();
      //   //////console.log( this.currentwaveform);
      //   // this?.currentwaveform?.setRegionStart(item.audio.currentTime);
        
      //   // this.currentwaveform.setRegionStart(this.sliderValue);
      // });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        // this?.currentwaveform?.setRegionStart(item.duration);
        this.next(index);
      });
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
      this.common.hideLoader();
    }

    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      if (r === item.duration || r > item.duration) {
        setTimeout(() => {}, 1000);
      }

      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration < 15 && item.duration >= 5) {
        if (r > 5) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      }
    }, 200);
  }
  stopAudio(item: any, index: any) {
    // //console.log(item);
    if (item) {
      if (item.audio) {
        item.audio.pause();
        // this.showAudioPlayer = false;s
      }
      clearInterval(this.id);

      this.data.map((r: any) => {
        r.hidepausebutton = false;
      });
    } else {
      this.data.map((r: any) => {
        if (r.audio) {
          r.audio.pause();
        }
        r.hidepausebutton = false;
      });
    }
    // item.waveform.pause();

    // document.querySelectorAll("#player-row-"+index+ " .play_button.pause")[0].click();
    const element = document.querySelectorAll(
      "#player-row-" + index + " .play_button.pause"
    )[0];

    if (element && "click" in element) {
      (element as HTMLElement).click();
    }
  }
  /* End of Player Functions */

    tokenFromUI: string = "0123456789123456";
    encrypted: any = "";
    redirecttouser(id: any){
      let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(id), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      this.encrypted = encrypted.toString();
      this.encrypted = encodeURIComponent(this.encrypted);
  
      this.router.navigate([`user/sounds/@${id}`])
    }
    async apihitforstreaming(item: any, r: any, dura: any) {

      let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
  
      let headers: any;
      if (tokkenget) {
        headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };
  
      }
  
      let sendingdata = {
        "audio_duration": dura,
        "loop_id": item.loop_id,
        "play_duration": r
      }
      this.http.post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers }).subscribe(async (res: any) => {
        if (res.response.status == 1) {
          // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        }
  
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
  
  
      }, async (e: any) => {
  
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    }
    show(item:any){
      this.data.map((i:any)=>{
        if(i!==item)
        i.hide = false
      })
      item.hide = !item.hide
    }
    openDialog(){
      let dialogue = this.matDialog.open(DownloadFilterComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "detailpage" },autoFocus: false });
    dialogue.afterClosed().subscribe(result => {
      // ////////console.log(location.pathname);
      this.ngOnInit()
      if(location.pathname){}
      // location.reload()
    })


    }

    paginationget() {
      this.active = false;
      // ////////console.log("great");
      this.paginationempty = true;
      // this.pageno = pageinfo;
      this.rowsize += this.rowsize
     
      // if (this.route.snapshot.queryParamMap.get('search')) {
        // this.searchApi()
        this.submit();
      // } else {
      //   this.submit();
      // }
  
  
    }

    downloadstem(item:any){
      // ////////console.log(item);
      this.disable = true
      if(item.stems===null){
        this.toastr.error("No stems found in loop", "", { positionClass: 'toast-bottom-right' })
      }
      this.zipService.downloadFile(CONSTNAME.fILE_PATH  + item.stems).subscribe((data) => {
        const blob = new Blob([data], { type: 'application/zip' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${item.title}.zip`;
        link.click();
      this.disable = false
      this.common.hideLoader()
    })
    }
}
