<div class="modal-header">
  <button type="button" (click)="cancel()" class="close"><span aria-hidden="true">×</span></button>
  <h4 class="mt-0 mb-0">Download Review</h4>
</div>
<mat-divider></mat-divider>
<div class="modal-body">
  <!-- <p class="h4 mt-3 mb-3" (click)="paypal()">Paypal</p> -->

  <!-- <button class="btn btn-secondary mb-3" (click)="cancel()" >Cancel</button><button class="btn btn-theme mb-3 ml-1" (click)="deleteaccount()">Yes, delete</button>-->
  <div class="gray-box">
    <table class="table-n3">
      <tbody>
        <tr>
          <td align="left">
            <span class="loop_artwork">
              <img [src]="srcofimage">
            </span>
            <span class="loop_info">
              <span (click)="redirecttolooppage()" class="hover"> <b>"{{title}}"</b> by </span> <a class="link hover"
                (click)="redirecttoprofilepage()">@{{username}}</a><br>
              <span class="badge loop-badge">{{looptype}}</span>
            </span>

          </td>

          <td class="text-right">
            <h4><span *ngIf="!free" class="boldprice">${{priceforloop}}</span></h4>
            <p class="dn-type"><small class="text-muted">Download Type:</small> <span>{{pre}}
                {{termsofpreference}}</span></p>
          </td>
        </tr>
      </tbody>
    </table>


  </div>
</div>
<small class="ml-3" *ngIf="royaltytrue">The {{exact}} is royalty free</small>
<form [formGroup]="signupformforloophead">
  <div class="form-row m-0">
    <div class="form-group check-group col-md-6 " class="col-md-12" *ngIf="splittrue">
      <input type="checkbox" class="form-check-input" id="tickbox" formControlName="tickbox">
      <label class="tickbox boxcss" class="form-check-label" for="tickbox"><small>You agree to use this loop and split
          publishing 50/50 with @{{username}}</small></label>


    </div>
    <div class="form-group check-group col-md-12" *ngIf="exclusive">
      <input type="checkbox" class="form-check-input" id="tickbox" formControlName="tickbox">
      <label class="tickbox boxcss" class="form-check-label" for="tickbox"><small>You acknowlege that you are being
          transferred over 100% of recording and composition rights of this {{looptype}} </small></label>


    </div>
    <div class="col-xs-12">
      <small class="form-text text-danger checkboxtick"
        *ngIf=" signupformforloophead.get('tickbox')?.touched && signupformforloophead.get('tickbox')?.hasError('required')">
        Please check this box to proceed further
      </small>
    </div>


  </div>
  <div class="">
    <div class="col-xs-12 displayChange  btm-margin">
      <div *ngIf="!free && !royaltytrue && stripeButtonShow" (click)="paypalcheck('paid')" class="text-center margin">
        <button [ngStyle]="{'display':btndis == true ? 'none' : 'inline-block' }" class="btn btn-theme btn-rounded ">
          Stripe Check Out</button>
      </div>

      <div *ngIf="!free && !royaltytrue && paypalButtonShow" (click)="paypal()" class="text-center"
        id="paypal-button-container-{{merchantIdInPayPal}}"> <button
          [ngStyle]="{'display':btndis == true ? 'none' : 'inline-block' }" class="btn btn-theme btn-rounded">Paypal
          Check
          out</button>
      </div>
    </div>

  </div>


</form>

<div class="modal-footer">
  <div class="">
    <div class="col-xs-12 displayChange btm-margin">
      <div *ngIf="royaltytrue && !free && stripeButtonShow" (click)="paypalcheck('paidroyal')"
        class="text-center margin">
        <button [ngStyle]="{'display':btndis == true ? 'none' : 'inline-block' }" class="btn btn-theme btn-rounded">
          Stripe Check Out</button>
      </div>
      <div *ngIf="royaltytrue && !free && paypalButtonShow " (click)="paypal()" class="text-center"
        id="paypal-button-container-{{merchantIdInPayPal}}"> <button
          [ngStyle]="{'display':btndis == true ? 'none' : 'inline-block' }" class="btn btn-theme btn-rounded">Paypal
          Check
          out</button>
      </div>
    </div>


  </div>
  <div *ngIf="free" (click)="paypalcheck('free')" class="text-center"><button class="btn btn-theme btn-rounded"> <i
        class="fa fa-download mr-3" aria-hidden="true"></i>FREE DOWNLOAD</button></div>








</div>