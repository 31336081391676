<!-- <h1 class="text-center">Welcome to Coupon Code Page. Coming soon !!!</h1> -->
<div class="page-actions" *ngFor="let item of detailofloop">
	<div class="row">
		<div class="col-md-3 col-xs-12">
      <h3 class="mt-0"> <strong>{{item.loop_type==1?'LOOP':'LOOP KIT'}}</strong> </h3>
		</div>
		<div class="col-md-9 col-xs-12">
			<div class="btn-group-right">
        <button (click)="suredeleteloop()" *ngIf="item.delete_flag == 0" class="btn btn-theme text-right ">Delete
          {{item.loop_type==1?'Loop':'Loop Kit'}} </button>
     <button *ngIf="item.loop_owner_delete_flag == 0" (click)="suretodeleteowner()" class="btn btn-theme text-right ">Delete Owner </button>
      <button (click)="redirectto(item.loop_id)" *ngIf="item.delete_flag == 0" class="btn btn-theme text-right">Preview </button>
  
      <button (click)="
        redirecttoinvoices()" class="btn btn-theme text-right ">View All Invoices </button>
      <button (click)="
      cancelandredirect()" class="btn btn-theme text-right ">Go Back </button>
			</div>
		</div>
	</div>
</div>



<div class="detail-view">
  <div class="card card-gray mt-3 mb-3">
    <div class="card-body">
      <div class="loop--details" *ngFor="let item of detailofloop">
        <!-- <fa name="cog" animation="spin"></fa> -->
        <div class="loop-artwork"><img alt="" [src]="artworkpathimage"><span class="play-btn hover"><i
              *ngIf="!hideplaybutton" class="fa fa-play" aria-hidden="true"></i>
            <i *ngIf="!hidepausebutton" class="fa fa-pause" aria-hidden="true"></i></span></div>
        <div class="loop-info-header">
          <h4><b class="hover"  (click)="redirectto(item.loop_id)">"{{titlename}}"</b> by<a
              class="link" (click)="redirecttoprofilepage()"> @{{username}}</a></h4>
          <span class="posted">Posted {{time}}
            ago</span>
          <p class="info-row"><small class="total-plays">{{stream}}</small><small *ngIf="showbpm" class="bpm"><i
                class="badge-style mr-2">BPM </i> {{bpm}}</small><small class="badge-style loopkey"
              *ngIf="showbpm">{{sub_key}} {{key}}</small><small class="badge-style loop-inst-cat"
              *ngIf="!showdescription">{{parent_genre_name}} {{genre_name}}</small><small
              *ngIf="showbpm && !showdescription"
              class="badge-style loop-inst">{{parent_instrument_name}}{{instrument_name}}</small>
            <small class="badge-style loopkey" *ngIf="showdescription">{{showloopno}} Loops</small>
            <span class="badge btn-danger
                    del-loop" *ngIf="item.delete_flag == 1">Deleted
                    {{item.loop_type==1?'Loop':'Loop Kit'}}</span>
            <span class="badge btn-danger
                    del-loop" *ngIf="item.loop_owner_delete_flag == 1">Deleted
                    Owner</span>
          </p>
          <div class="row mt-2">
            <div class="col-md-6">
              <ul class="loop-stats">
                <li id="red" mat-raised-button #red="matTooltip" matTooltip="Likes" matTooltipHideDelay="100" class=""
                  [ngClass]=" addred ? 'red' : ''"><i class="fa fa-heart "></i>{{number_of_likes}}</li>
                <li mat-raised-button #red="matTooltip" matTooltip="Reposts" matTooltipHideDelay="100" id="green"
                  class="" [ngClass]=" addgreen ? 'green' : ''"><i class="fa fa-retweet"></i> {{number_of_reposts}}
                </li>
                <li mat-raised-button #red="matTooltip" matTooltip="Comments" matTooltipHideDelay="100" class=""><i
                    class="fa  fa-comment"></i> {{commentno}}</li>
                <li *ngIf="!hidepricedetail" mat-raised-button #red="matTooltip" matTooltip="Price"
                  matTooltipHideDelay="100" class="">
                  <i class="fa fa-dollar boldprice"></i>{{loop_price}}
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <div class="loop-col-cat">
                <h5 class="mt-0
           ">{{termsofpreference}}</h5><span class="badge loop-badge">{{loop_type}}</span><span
                  class="badge">#{{tablefortagsdisplay1}}</span><span
                  class="badge">#{{tablefortagsdisplay2}}</span><span class="badge">#{{tablefortagsdisplay3}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <div class="tabs tabs-alt ng-star-inserted"><button [ngClass]=" showcommentpage ? 'active' : ''"
          (click)="commentpageon()">Comments</button><button [ngClass]=" showlikepage ? 'active' : ''"
          (click)="likepageon()">Likes </button>
        <button [ngClass]="showrepostpage  ? 'active' : ''" (click)="repostpageon()">Reposts</button>
        <button [ngClass]=" showreportlog ? 'active' : ''" (click)="reportlogpageon()">Report-Log</button>
      </div>



      <div *ngIf="active">
        <app-comments *ngIf="showcommentpage" [items]="check" (messageEvent)="paginationget($event)" (sendingsearchEvent)="searchClick($event)" ></app-comments>
        <app-likes *ngIf="showlikepage" [items]="check" (messageEvent)="paginationget($event)" (sendingsearchEvent)="searchClick($event)"></app-likes>
        <app-reportedlog *ngIf="showreportlog" [items]="check" (messageEvent)="paginationget($event)" (sendingsearchEvent)="searchClick($event)" (sortValueEvent)="sortOn($event)"></app-reportedlog>
        <app-reposts *ngIf="showrepostpage" [items]="check" (messageEvent)="paginationget($event)" (sendingsearchEvent)="searchClick($event)"></app-reposts>
        <!-- <app-usersearch *ngIf="usersearch" ></app-usersearch>
        <app-loopsearch (callparentFun)="parentFun()" *ngIf="loopsearch" [items]="check"></app-loopsearch>
        <app-loopkitsearch *ngIf="loopkitsearch" [items]="check"></app-loopkitsearch> -->
      </div>