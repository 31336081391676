

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { HeaderComponent } from '../../CLIENT_PAGE_HEADER_FOOTER/header/header.component';

@Component({
  selector: 'app-imageoptions',
  templateUrl: './imageoptions.component.html',
  styleUrls: ['./imageoptions.component.css']
})
export class ImageoptionsComponent implements OnInit {
  expirydateget: any;
  show: boolean;
  constructor(public matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public api: ApiServiceService, private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, public common: commonservice, private dialogRef: MatDialogRef<HeaderComponent>) { }
  
  ngOnInit(): void {
    // //console.log(this.expirydateget = this.data.pageValue)
  }

  update() {
    // this.matDialog.closeAll();
    // //console.log("click");
    this.show = true;
    // this.dialogRef.close({ data: true });
    var element = document.createElement('div');
    element.innerHTML = '<input type="file">';
    var fileInput:any = element.firstChild;

    fileInput.addEventListener('change', function () {
      var file = fileInput.files[0];

      if ( file == undefined) {
        // this.urlforheader = "assets/img/artwork.jpg";
        // this.imagevalidation = true;
        // id.innerHTML = "";
      } else {
        if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
  
          if (file.size > 10000000) {
            // this.imagevalidation = false;
  
            // id.innerHTML = "Image size cannot be max 10 mb";
            // this.toastr.error("Image size cannot be more than 10 mb", "", { positionClass: 'toast-bottom-right' })
  
  
            var read = new FileReader()
            read.readAsDataURL(file)
            read.onload = (event: any) => {
              // this.urlforheader = event.target.result;
            }
          } else {
            if (file) {
              // //console.log(file);
             let  reader = new FormData();
  
              // this.imagevalidation = true;
              // id.innerHTML = "";
              reader.append('header_image_path', file);
              // this.imageshow = event.target.files[0];
              var read = new FileReader()
              read.readAsDataURL(file)
              // read.onload = (event: any) => {
              //   this.urlforheader = event.target.result;
              // }
              // this.apihitforimage();
            }
          }
        } else {
          // this.imagevalidation = false;
          // id.innerHTML = "Image file should be in jpeg,png,jpg";
  
        }
      }
    });

    fileInput.click();
  }


  leave() {
    // //console.log("leave");
    this.matDialog.closeAll();
    // this.dialogRef.close({ data: true })
  }
  remove() {
    this.dialogRef.close({ data: false });
  }


}

