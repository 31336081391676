<div class="title-header">
   <div class="container">
      <h3>My Account</h3>
   </div>
</div>
<div class="account-wrapper">
   <div class="container">
      <div class="account-cols">
         <div class="account-sidebar">
            <div class="account-profile"><img src="assets/img/artwork.jpg" alt=""><span class="edit-pImg"><i
                     class="fa fa-edit"></i></span></div>
            <ul class="fLevel">
               <li>
                  <a class="active"><i class="fa fa-user"></i> Profile</a>
                  <ul class="sLevel">
                     <li><a [routerLink]="['/user/my-account/profile']">My Account</a></li>
                     <li><a href="#">Notifications</a></li>
                     <li><a  [routerLink]="['/subscription-status']">Subscription Status</a></li>
                     <li><a href="#">Payment Accounts</a></li>
                     <li><a href="#">Legal</a></li>
                  </ul>
               </li>
               <li><a [routerLink]="['/user/my-account/my-uploads']" class=""><i class="fa fa-upload"></i> My Uploads</a></li>
               <li><a class=""><i class="fa fa-history"></i> My Download History</a></li>
               <li><a class=""><i class="fa fa-power-off"></i> Logout</a></li>
            </ul>
         </div>
         <!-- account-sidebar Ends -->
         <div class="account-rCol">
            <h4 class="mb-2">My Uploads</h4>
            <div class="uploads-wrapper">
               <div *ngFor="let items of tableData" class="uploaded-item">
                  <span class="loop-badge">{{items.loop_type ===1?loopinfo(items.loop_type):loopinfo(items.loop_type)}} {{looptype}}</span><span
                     class="item-name">{{items.title}}, </span><span class="item-terms" > {{items.selling_preference ===1?callfree(items.terms):callpremium(items.terms) }} {{termsofpreference}}, </span><span class="upload-date">{{items.created_on| date: 'dd/MM/yyyy h:mm a 
                     ' }}</span> , <span class="fa fa-download upload-date mx-1">: {{items.number_of_downloads}}</span> ,<span *ngIf="items.delete_flag == 0" class="fa fa-window-close upload-date"> {{items.delete_flag?'deleted':'notdeleted'}}</span> <span *ngIf="items.delete_flag == 1" class="fa fa-arrow-circle-down upload-date"> {{items.delete_flag?'deleted':'notdeleted'}}</span><span class="download-archive"><i class="fa fa-history"></i></span>

                     <!-- <span *ngIf="items.selling_preference === 1?call(items.terms):call()"></span> -->
               </div>
               <ul class="pagination pagination-end">
                  <!-- <li><</li> -->
                  <li class="paginationliyellowcolor bluecolor" *ngFor="let item of pagercount" id="liststyle{{item}}"
                     (click)="paginationget(item)">
                     {{item}}
                  </li>
                  <!-- <li>></li> -->
               </ul>
               <!-- <ul class="pagination pagination-end">
                   <li class="paginationliyellowcolor bluecolor" *ngFor="let item of pagercount | paginate: { itemsPerPage: 1, currentPage: pageno, totalItems: totallength }" id="liststyle{{item}}"
                   (click)="paginationget(item)">
                   {{item}}
                   </li>
                </ul>
                <pagination-controls (pageChange)="pageno = $event"></pagination-controls> -->
            </div>
            <!-- uploads-wrapper Ends -->
            <div *ngIf="noresultshow">
               <div class="" style="
               color: #721c24;
               background-color: #f8d7da;
               border-color: #f5c6cb;
               padding: 10px;
           ">No Loop Found. <a href="">Please Upload</a> one.</div>
            </div>
         </div>
         <!-- account-rCol Ends -->
      </div>
      <!-- account-cols Ends -->
   </div>
   <!-- Container Wrapper Ends -->
</div>
<!-- Accounts Wrapper Ends -->