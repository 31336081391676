<app-header></app-header>
<div style="margin-top: 100px;">

	<div class="container tandc">
		<h1>Introduction</h1>
	
	<p>Welcome to Loophead! These Terms and Conditions are a binding contract between Loophead ("we", "us", or "our") and you ("User", "you", or "your"). By using Loophead.com, you agree to these terms. If you do not agree, do not use Loophead.<p>
	
	<h3>1. Ownership and License</h3>
	
	<p class="semi-li"><strong>1.1.1</strong> The website and its original content, features, and functionality are owned by Loophead and are protected by copyright, trademark, and other intellectual property laws.</p>
	<p class="semi-li">
	<strong>1.1.2 </strong>Users retain all rights to their uploaded music loops but grant Loophead a non-exclusive, worldwide, royalty-free license to use, reproduce, adapt, publish, and display these loops for operating and promoting the site.</p>
	
	<p class="main-li"><strong>1.2 User Content</strong></p>
	<p class="semi-li">
	<strong>1.2.1</strong> Users are responsible for ensuring they have all necessary rights to their content.</p>
	<p class="semi-li">
	<strong>1.2.2</strong> Loophead is not responsible for the content uploaded by its users and disclaims any liability in relation to user content.</p>
	
	<h3>2. User Responsibilities</h3>
	
	<p class="main-li"><strong>2.1 Account Creation and Security </strong></p>
	<p class="semi-li">
	<strong>2.1.1 </strong>Users must provide accurate, current, and complete registration information.</p>
	<p class="semi-li">
	<strong>2.1.2 </strong>Users are responsible for safeguarding their account details and should notify Loophead immediately of any unauthorized use of their account.</p>
	
	<p class="main-li"><strong>2.2 Acceptable Use Policy</strong></p>
	<p class="semi-li">
	<strong>2.2.1 </strong>Users must not use Loophead for any unlawful or prohibited activity.</p>
	<p class="semi-li">
	<strong>2.2.2</strong> Uploading harmful content, such as malware or content infringing on intellectual property, is strictly forbidden.</p>
	
	<h3>3. User Generated Content</h3>
	
	<p class="main-li"><strong>3.1 Content Ownership</strong></p>
	<p class="semi-li">
	<strong>3.1.1</strong> Users retain ownership of their uploaded content but must ensure they possess all necessary rights to such content.</p>
	
	<p class="main-li"><strong>3.2 Licensing Agreements </strong></p>
	<p class="semi-li">
	<strong >3.2.1 </strong> Users can specify usage rights for their loops, such as revenue sharing agreements. Loophead is not a party to these agreements and bears no responsibility for their enforcement.</p>
	
	<h3>4. Privacy and Data Usage</h3>
	
	<p class="main-li"><strong>4.1 Data Collection and Use</strong></p>
	<p class="semi-li">
	<strong>4.1.1</strong> Our Privacy Policy details how we collect, use, and protect user data.</p>
	<p class="semi-li">
	<strong>4.1.2</strong> Users consent to the processing of their data as described in the Privacy Policy.</p>
	
	<h3>5. Modifications and Termination</h3>
	
	<p class="main-li"><strong>5.1 Changes to Terms</strong></p>
	<p class="semi-li">
	<strong>5.1.1</strong> Loophead reserves the right to modify these terms at any time. Continued use after changes constitutes acceptance of the new terms.</p>
	
	<p class="main-li"><strong>5.2 Account Termination</strong></p>
	<p class="semi-li">
	<strong >5.2.1 </strong>We reserve the right to terminate or suspend access to our service immediately, without prior notice, for any breach of these terms.</p>
	
	<h3>6. Disclaimers and Limitations of Liability</h3>
	
	<p class="main-li"><strong>6.1 No Warranties</strong></p>
	<p class="semi-li">
	<strong>6.1.1</strong> Loophead is provided on an "AS IS" and "AS AVAILABLE" basis without any warranties, expressed or implied.</p>
	
	<p class="main-li"><strong>6.2 Liability Limitation</strong></p>
	<p class="semi-li">
	<strong>6.2.1 </strong>Loophead will not be liable for any indirect, incidental, special, consequential, or punitive damages arising from the use of the service.</p>
	
	<h3>7. Dispute Resolution</h3>
	
	<p class="main-li"><strong>7.1 Governing Law</strong></p>
	<p class="semi-li">
	<strong>7.1.1 </strong>These terms are governed by the laws of New Jersey, US.</p>
	
	<p class="main-li"><strong>7.2 Dispute Resolution</strong></p>
	<p class="semi-li">
	<strong>7.2.1 </strong>Any disputes arising from these terms will be resolved through arbitration or in the courts of New Jersey, US. </p>
	
	<h3>8. Contact Information</h3>
	
	<p>For any queries or concerns about these Terms, please contact us at theloophead@gmail.com</p>
	</div>
</div>

<app-footer></app-footer>