<!-- 
<span class="loader d-none" id="divLoading" [ngStyle]="{'display': displayname}">
    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    <span class="sr-only">Loading...</span>
  </span> -->
  



<main>
    <div class="firstcontainer container">
        <div class="secondcontainer">
            <ul class="subscriptionul">
                <li class="page1 active" ><span>1</span> Subscription Plan</li>
                <li class="page1"><span>2</span>  Subscription Interval</li>
                <li class="page1"> <span>3</span>  Payment</li>
            </ul>
        </div>
        <div class="subscription-wrapper">
            <div class="boxs" >
                
                <div class="packages" >
                    <div class="plan-title free-plan">
                        <h3 class="free">{{freename}}</h3>
                    </div>
                    <div class="dullwhite">
                        
                        <h1 class="money" *ngIf="!freeloop" ><ngx-skeleton-loader [theme]="{ 
                            'border-radius': '5px',
                            border: '1px solid white'
                          }" count="5"></ngx-skeleton-loader></h1>
                          <ng-container *ngIf="freeloop" >
                            <h1 class="money">${{freeprice}}</h1>
                            <h6>Limited Access</h6>
                              <ul class="list-check">
                            <li><p>Upload Up To {{freeloop}} Loops</p></li>
                            <li> <p>Upload Up To {{freeloopkit}} Loop Kits</p> </li>
                            <li class="notallowed"><p>Can't Sell Single Loops</p></li>
                                  </ul>
                               <button  class="btn btn-theme btn-rounded" (click)="choosefirstplan()" >Choose</button>   
                          </ng-container>
                        
                    </div>
                </div>
                <div class="packages">
                              <div class="plan-title premium-plan">
                            <h3 class="premimum">Premium</h3>
                        </div>
                        <div class="dullwhite">
                           
                            <h1 class="money"  *ngIf="!premimiumprice"><ngx-skeleton-loader count="1" [theme]="{ 
                                'border-radius': '5px',
                                border: '1px solid white'
                              }" count="5"></ngx-skeleton-loader></h1>
                            <ng-container  *ngIf="premimiumprice">
                                <h1 class="money">${{premimiumprice}} /month</h1>
                            <h6>Unlimited Access</h6>
                            <ul class="list-check">
                            <li><p>Upload Unlimited Loops</p></li>
                            <li><p>Upload Unlimited Loop Kits </p></li>
                            <li><p>Sell Unlimited Loops and Loop Kits</p></li>
                            <li><p>Accept PayPal Payments</p></li>
                            </ul>
                            <button class="btn btn-theme btn-rounded" (click)="choosesecondplan()">Choose</button> 
                            </ng-container>
                            
                        </div>
                 
                </div>
                <!-- <div class="boxin2">
                    <div class="boxin2" *ngFor="let item of planData">
                        <div class="orangebackground">
                            <h3 class="premimum">Premimum</h3>
                        </div>
                        <div class="dullwhite" >
                            <h1 style="display: inline;" class="money"> ${{item.plan_price}} </h1><span class="money"> /month</span>
                            <h6>Unlimited Access</h6>
                            <h5> <i class="fa fa-check" aria-hidden="true"></i> Upload unlimited  loops</h5>
                            <h5> <i class="fa fa-check" aria-hidden="true"></i> Upload unlimited Loop Kits (for sale)</h5>
                            <h5> <i class="fa fa-check" aria-hidden="true"></i> sell unlimited loops and loopkits</h5>
                            <h5> <i class="fa fa-check" aria-hidden="true"></i> Accept paypal payment</h5>
                            <h6 class="btn btn-theme btn-rounded" (click)="chooseplan(item.plan_id)" >Choose</h6> 
                        </div>
                    </div>
                </div> -->
            </div>


            
            <div class="informationthirdcontainer">
                <h4 class="firsth5">Have any questions about Premium Memberships?</h4>
                <h5 class="secondh5">Our support team will be happy to assist you.</h5>
                <h5 class="thirdh5"><a [routerLink]="['/contactus']" target="_blank" autoscroll="true" >Contact us</a> </h5>

            </div>
        </div>
    </div>
</main>
