<div class="money" *ngIf="!completerender">
    <ngx-skeleton-loader [theme]="{ 
  'border-radius': '30px',
  border: '5px solid white'
  }" count="2"></ngx-skeleton-loader>
  </div>
    <div #waveform class="wave" id="{{items}}" [ngClass]=" completerender ? '' : 'hidewave' " ></div>

  <div class="loop-btns">
    <button class=" zin play-btn hover play_button  play" attr.data-loop_id="{{loop_id}}"
      (click)="onPlayButtonClick($event)"></button>
    <button class=" play-btn hover play_button pause" (click)="onPauseButtonClick($event)"></button>
    <button class=" play-btn hover play_button seek" data-seek-time="0" (click)="onChange($event)"></button>    
    <button class=" play-btn hover play_button forward"(click)="forward($event)"></button>    
    <button class=" play-btn hover play_button backward" (click)="backward($event)"></button>  
    <button class=" play-btn hover play_button mute"(click)="mute()"></button>    
    <button class=" play-btn hover play_button unmute" (click)="unmute()"></button>     
    <button class=" play-btn hover play_button vol" data-seek-vol="1" (click)="onChangeVolume($event)"></button>     
  </div>