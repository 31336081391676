


<div *ngfor="let items of detail">
    <p>Total users </p>
    <!-- <p>Total Loops {{items.total_loops_count}}</p>
    <p>Total Loopkits {{items.total_loopkits_count}}</p> -->
    
</div>

<div class="table-responsive">
    <table class="table table-striped table-bordered table-loops v-middle"  >
      <thead>
        <tr>
  
          <th class="text-center marginfa" >
           username
          </th>
          <th class="text-center marginfa" >
           Action
          </th>
  
        </tr>
  
      </thead>
      <tbody>
  
    
        <!-- <tr *ngFor="let items of users">
         
        
  
          <td class="text-center">{{items.username}} </td>
          <td class="text-center hover"><a class="text-center" ><i
            class=" text-center fa fa-pencil-square-o text-center" aria-hidden="true"></i></a></td>
        </tr> -->
        <!-- (click)="encryptUsingAES256(user_type_id) -->
   
  
      </tbody>
  
    </table>
    
  
  
  </div>
<div class="table-responsive">
    <table class="table table-striped table-bordered table-loops v-middle"  >
      <thead>
        <tr>
  
          <th class="text-center marginfa" >
           Title
          </th>
          <th class="text-center marginfa" >
           Action
          </th>
  
        </tr>
  
      </thead>
      <tbody>
  
    
        <!-- <tr *ngFor="let items of users">
         
        
  
          <td class="text-center">{{items.title}} </td>
          <td class="text-center hover"><a class="text-center"  (click)="encryptUsingAES256(items.loop_id)"><i
            class=" text-center fa fa-pencil-square-o text-center" aria-hidden="true"></i></a></td>
        </tr> -->
       
   
  
      </tbody>
  
    </table>
    
  
  
  </div>
