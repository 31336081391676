import { Component, OnInit } from "@angular/core";
import { ApiServiceService } from "src/app/allServices/api-service.service";
import { commonservice } from "src/app/allServices/commonservice";
import { CONSTNAME } from "src/app/allServices/constant.service";
import * as CryptoJS from "crypto-js";
import { Router } from "@angular/router";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent implements OnInit {
  rowsize: any;
  pageno: any;
  totalnotification: any;
  arrayfortable: any;
  lengthofnotificationdiv: any;
  total_records: any;
  morethatzero: boolean;
  toastr: any;
  profile_image_pathofcomments: any = "assets/img/artwork.jpg";
  tokenFromUI: string = "0123456789123456";
  encrypted: string;

  constructor(public common: commonservice, public api: ApiServiceService, public router: Router) {}

  ngOnInit(): void {
    this.callfornotification();
  }
  async callfornotification() {
    // this.common.showLoader();
    // console.warn("Notification");
    let sendingdata = {
      sort_on: "created_on",
      sort_type: "DESC",
      page_no: this.pageno,
      row_size: this.rowsize,
    };

    this.api.forclientgettokenpublic(`/user/get_notifications`, sendingdata).then(
      (res: any) => {
        if (res.response.status == 1) {
          // this.common.hideLoader();
          // //console.log(res.response.data.total_records)
          this.totalnotification = res.response.data.number_of_unread_notifications;
          if (res.response.data.number_of_unread_notifications > 9) {
            this.totalnotification = "9 +";
          }
          if (res.response.data.number_of_unread_notifications == 0) {
            this.totalnotification = "";
          }
          // this.arrayfortable.push(res.response.data.pagging_list)
          // //console.log(this.arrayfortable)
          this.arrayfortable = res.response.data.pagging_list;
          // //console.log( res.response.data.pagging_list)
          this.lengthofnotificationdiv = this.arrayfortable.length;
          this.total_records = res.response.data.total_records;

          // //console.log(this.arrayfortable)
          if (res.response.data.total_records == 0) {
            this.morethatzero = false;
          } else {
            this.morethatzero = true;
          }

          // if (res.response.data.number_of_unread_notifications > 0) {
          //   setTimeout(() => {
          //   this.callforasreadnoti();
          // }, 3000);
          // } else {
          //   // //console.log("no")
          // }
        } else {
          // this.common.hideLoader();
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            let jk = error.push(errorthroughapi);
            // this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      },
      async (e) => {
        this.common.hideLoader();
        this.common.hideLoader();
        this.toastr.error("Error in api", "", { positionClass: "toast-bottom-right" });
      }
    );
  }
  setimage(data: any) {
    // if (data.artwork_path == null) {
    if (data.notifier_profile_image_path == null) {
      return this.profile_image_pathofcomments;
    } else {
      if (data.notifier_profile_image_path.includes("/profile_photos/")||data.notifier_profile_image_path.includes("/loop_artworks/")) {
        return CONSTNAME.fILE_PATH + data.notifier_profile_image_path;

        }else
      return data.notifier_profile_image_path;
    }

    // } else {

    //   return CONSTNAME.fILE_PATH + data.artwork_path;
    // }
  }
  remove(item: any) {
    const sendingdata = {
      id: item.notification_id,
    };
    this.api.forclientgettokenpublic(`/user/mark_notification_as_read`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        this.callfornotification();
        this.ngOnInit()
      }
    });
  }
  redirecttouser(id: any) {
    // //console.log(id);

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);

    this.router.navigate([`user/sounds/${id}`])
  }
  redirecttoLoop(id: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    this.router.navigate(["/detail-loop"], { queryParams: { loop_id: this.encrypted } });
  }
  redirecttopack(items:any) {
    
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(items.id), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    this.encrypted =encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-pack'], { queryParams: {pack_id: this.encrypted } });
  }
}
