<!-- <p>redirectfrompaypal works!</p>

<h1>Your Paypal account linked successfully</h1>
<h1>Your Paypal account linked successfully</h1> -->

<main>
    <div class="container">
        <div class="verifyemai">
            <div class="card-eVerificati">
                <div class="card-succes" *ngIf="paypalvalid && apiResponse">
                    <h1 class="text-center"></h1>

                        <div class="text-center" style="
    color: #4F8A10;
    background-color: #DFF2BF;
    border-color: #f5c6cb;
    padding: 10px;
">Account linked successfully. Redirecting to the Dashboard</div>
                        <!-- <img class="inline" src="assets/img/emal-success.png" alt="">
                    <h3 class="inline headingleftmargin">{{statusmessage}}</h3> -->
                </div>
                <div class="text-center" class="card-fail" *ngIf="!paypalvalid && apiResponse">
                    <h3 class="text-center">{{statusmessage}}</h3>
                    <!-- <img class="inline" src="assets/img/incorrect.png" alt="">
                    <h3 class="inline headingleftmargin">{{statusmessage}}</h3> -->
                </div>
            </div>
        </div>
    </div>
</main>