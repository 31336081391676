import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-redirectfrompaypal',
  templateUrl: './redirectfrompaypal.component.html',
  styleUrls: ['./redirectfrompaypal.component.css']
})
export class RedirectfrompaypalComponent implements OnInit {
  paypalvalid: boolean = false;
  apiResponse: boolean = false;
  statusmessage: any;
  user_type_id_From_Storage: any;
  Payee_id: any;
  constructor(private router: Router, public common: commonservice, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService) { }

  ngOnInit(): void {

    if (this.route.snapshot.queryParamMap.get('merchantId')) {
      this.getapi();
      this.common.showLoader()

    } else {
      this.getapi();
      this.common.showLoader()
    }

    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.getUserDetail();

      }

      let resa = JSON.parse(res)
      if (resa) {
        this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed);
        this.call(resa);
      }

    })

  }





  getapi() {
    this.apiResponse = true;
    // //console.log("in get api");
    let merchantid: any = this.route.snapshot.queryParamMap.get('merchantId');
    let merchantIdInPayPal: any = this.route.snapshot.queryParamMap.get('merchantIdInPayPal');
    let permissionsGranted: any = this.route.snapshot.queryParamMap.get('permissionsGranted');
    let consentStatus: any = this.route.snapshot.queryParamMap.get('consentStatus');
    let productIntentId: any = this.route.snapshot.queryParamMap.get('productIntentId');
    let productIntentID: any = this.route.snapshot.queryParamMap.get('productIntentID');
    let isEmailConfirmed: any = this.route.snapshot.queryParamMap.get('isEmailConfirmed');
    let accountStatus: any = this.route.snapshot.queryParamMap.get('accountStatus');
    let isredirectfrompaypal: any = this.route.snapshot.queryParamMap.get('pay')

    this.Payee_id = merchantid;
    let sendingdata1 = {

    }


    let sendingdata2 = {
      "merchantid": merchantid,
      "merchantIdInPayPal": merchantIdInPayPal,
      "permissionsGranted": permissionsGranted,
      "consentStatus": consentStatus,
      "productIntentId": productIntentId,
      "productIntent_ID": productIntentID,
      "isEmailConfirmed": isEmailConfirmed,
      "accountStatus": accountStatus
    }

    if (isredirectfrompaypal != 1) {
      // //console.log(isredirectfrompaypal, "yes redirect")

      this.api.postApiHit(`/user/get_stripe_seller_detail`, sendingdata1).then((res: any) => {
        if (res.response.status == 1) {
          // //console.log(res)
          this.paypalvalid = true;

          this.getUserDetail();
          this.common.hideLoader();
          setTimeout(() => {
            this.router.navigate(['/profile/payment-accounts'], { queryParams: { linkstripe: "yes" } });
          }, 3000);

          this.statusmessage = res.response.msg;
          // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });

        } else if (res.response.status == 3) {
          let errorthroughapi = res.response.errors;
          let error = [];

          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
          this.common.clearAllLocalAndSession();
          // localStorage.removeItem("clientintoken");
          this.router.navigate([CONSTNAME.REDIRECT_CLIENT_loginCLIENT]);
        }
        else {
          this.paypalvalid = false;




          let errorthroughapi = res.response.errors;
          this.statusmessage = res.response.errors;
          let error = [];
          setTimeout(() => {
            // this.router.navigate(['/profile/payment-accounts'], { queryParams: { linkpaypal: "yes" } });
            this.router.navigate(['/profile/payment-accounts'], { queryParams: { linkstripe: "no" } });
          }, 3000);

          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            // this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {





      // //console.log(isredirectfrompaypal,"no redirect")

      this.api.postApiHit(`/user/save_paypal_seller`, sendingdata2).then((res: any) => {
        if (res.response.status == 1) {
          this.paypalvalid = true;
          






          this.getUserDetail();
          this.common.hideLoader();
          this.callApiFOrVerifyAccount()
          setTimeout(() => {
            this.router.navigate(['/profile/payment-accounts'], { queryParams: { linkpaypal: "yes" } });
          }, 3000);

          this.statusmessage = res.response.msg;
          // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });

        } else if (res.response.status == 3) {
          let errorthroughapi = res.response.errors;
          let error = [];

          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
          this.common.clearAllLocalAndSession();
          // localStorage.removeItem("clientintoken");
          this.router.navigate([CONSTNAME.REDIRECT_CLIENT_loginCLIENT]);
        } else {
          let errorthroughapi = res.response.errors;
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    }




  }

  // call(value: any) {
  //   // //console.log(value);
  //   if (value.paypal_seller_account_linked == 1) {

  //     // this.callallinfoofcards();
  //     // //console.log("value is 1")
  //   } else {
  //     // this.hidepaypalbutton = false;
  //     // //console.log("value is zero");
  //   }
  // }



  getUserDetail() {
    this.common.showLoader();
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          let resa = JSON.parse(res);
          this.call(resa);
          this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }

  call(value: any) {
    // //console.log(value)
    this.user_type_id_From_Storage = value.user_type_id
  }

  callApiFOrVerifyAccount() {
    let sendingData = {
      user_type: 2,
      user_type_id: this.user_type_id_From_Storage,
      payee_email: this.Payee_id
    }
    this.api.postApiHit(`/user/get_paypal_seller_account_details`, sendingData).then((res: any) => {
      // //console.log(res)
      if (res.response.status == 1) {

        this.common.hideLoader();


      } else if (res.response.status == 2) {

        let Error = "Your Paypal account is restricted and cannot be used to receive payments online. Please login to your paypal account and fix issues if any. "
        this.common.hideLoader();
        this.toastr.error(Error, "", { positionClass: 'toast-bottom-right' })
      } else {

        let errorthroughapi = res.response.errors;
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }


    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    })

  }

}
