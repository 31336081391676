<h3 class="dynamictext">{{name}} Coupon Code</h3>

<form (ngSubmit)="couponcodepage()" [formGroup]="couponcodeform">

    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="couponcode">Coupon Code</label><span class="ml-1 text-danger">*</span>
            <input type="text" id="couponcode" class="form-control" (keyup.enter)="couponcodepage()" for="couponcode"
                formControlName="couponcode" placeholder="Coupon Code">
            <small class="text-danger" [innerHTML]="vCheck('couponcode', '_COUPON')"></small>

        </div>

        <div class="form-group col-md-6">
            <label for="coupondiscountpercentage">Coupon Discount Percentage</label><span
                class="mx-4 text-danger">*</span>
            <input type="number" id="coupondiscountpercentage" min="1" class="form-control"
                (keyup.enter)="couponcodepage()" formControlName="couponenddiscountamount"
                for="coupondiscountpercentage" placeholder="Coupon Discount Percentage">
            <small class="text-danger" [innerHTML]="vCheck('couponenddiscountamount', '_COUPONPATTERN')"></small>



        </div>


    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="couponstartdate">Coupon Start Date</label><span class="ml-1 text-danger">*</span>
            <input type="date" id="couponstartdate" class="form-control" (keyup.enter)="couponcodepage()"
                formControlName="couponstartdate">
                <!-- <div>
                    <mat-form-field appearance="fill">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="couponstartdate" (keyup.enter)="couponcodepage()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field> 
                </div> -->
               
                  
            <small class="text-danger" [innerHTML]="vCheck('couponstartdate', '_STARTDATECOUPON')"></small>

        </div>
        <div class="form-group col-md-6">
            <label for="couponenddate">Coupon End Date</label><span class="ml-1 text-danger">*</span>
            <input type="date" id="couponenddate" class="form-control" formControlName="couponendenddate"
                (keyup.enter)="couponcodepage()">
                <!-- <div>
                    <mat-form-field appearance="fill">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="picke" formControlName="couponendenddate" (keyup.enter)="couponcodepage()">
                    <mat-datepicker-toggle matSuffix [for]="picke"></mat-datepicker-toggle>
                    <mat-datepicker #picke></mat-datepicker>
                  </mat-form-field> 
                </div> -->
            <small class="text-danger" [innerHTML]="vCheck('couponendenddate', '_ENDDATECOUPON')"></small>

        </div>


    </div>

    <div class="form-row">
        <div class="form-group col-md-12 ">
            <label for="couponenradio">Status </label><span class="ml-1 text-danger">*</span>
            <div>

                <!-- <input type="radio"  class="paddingradio" id="enabled" formControlName="couponenradio" name="couponenradio" value=1
                   >
                <label for="enabled">Enabled</label>
                <input class="mx-5 paddingradio" formControlName="couponenradio"  type="radio" id="disabled"
                    name="couponenradio" value=0>
                <label for="disabled">Disabled</label> -->

                <mat-slide-toggle  formControlName="couponenradio"></mat-slide-toggle>

            </div>



        </div>

    </div>


    <div class="form-row" *ngIf ="!noteditableform">
        <div class="form-group col-md-6">
            <label for="couponcreatedon">Created Date</label>
            <input type="datetime" name="subscriptioncreatedon"  formControlName="createddate"
                id="subscriptioncreatedon" class="form-control" >
        </div>
        <div class="form-group col-md-6">
            <label for="subscriptionModifiedOn">Modified Date</label>
            <input type="datetime" name="subscriptionModifiedOn"  formControlName="modifieddate"
                id="subscriptionModifiedOn" class="form-control" >
        </div>
    </div>


    <div class="form-group  text-right">
        <button type="button" class="btn btn-theme " (click)="cancelandredirect()">Cancel</button>
        <button type="button" class="btn btn-theme marginleft" (click)="couponcodepage()">Save </button>
    </div>





</form>