
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BannedComponent } from 'src/app/ADMIN_PAGE/banned/banned.component';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-invoiceredirect',
  templateUrl: './invoiceredirect.component.html',
  styleUrls: ['./invoiceredirect.component.css']
})
export class InvoiceredirectComponent implements OnInit {
 


  loopid: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  arrayfortable: any = []
  loopidpayment: any;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  payment__id: any;
  showerror: boolean;
  type: string | null;
  constructor( public api: ApiServiceService, private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, public common: commonservice) { }

  ngOnInit(): void {
    this.loopid = this.route.snapshot.queryParamMap.get('loop_id');
    this.payment__id = this.route.snapshot.queryParamMap.get('payment_id');
    // //console.log(this.loopid);
    // this.type = this.route.snapshot.queryParamMap.get('2');
    // //console.log(this.payment__id,this.loopid)
    
    this.loopid = atob(this.loopid)
    // //console.log(this.loopid);
    this.payment__id = atob(this.payment__id)
    this.callforinvoice(this.loopid,this.payment__id);
  }


  callforinvoice(item:any,items:any) {
    this.common.showLoader();
    let sendingData = {
      "loop_id": Number(item),
      "payment_id": Number(items)
    }
    this.api.postApiHit('/user/get_invoice',sendingData , VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        // //console.log(res.response.data);
       
        this.arrayfortable = res.response.data
        // this.getallinfo();
        // this.getUserDetail();
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        this.common.hideLoader();


      } else {
        this.showerror = true
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
        this.common.hideLoader();
       

      }
    }, async e => {
      
      this.toastr.error("Error in Api ", "", { positionClass: 'toast-bottom-right' });
      this.common.hideLoader();
      
    });
  }

  setimage(data:any) {
    if (data.artwork_path == null) {
      // //console.log("i am in null");
    return this.profile_image_pathofcomments;
    } else {
      // //console.log("i am not in null");
      return CONSTNAME.fILE_PATH + data.artwork_path;
    }
  }

  redirecttoprofilepage(item:any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(item.user_id);

    this.router.navigate(['user/sounds'], { queryParams: { user_id: this.encrypted } });
   
  }
  redirecttolooppage(item:any) {
    // //console.log(value)


    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);


    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
  

  }

}
