import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BannedComponent } from 'src/app/ADMIN_PAGE/banned/banned.component';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { CONSTNAME } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.css']
})
export class ConfirmDeleteComponent implements OnInit {
  whichpage: any;
  showmodalforpageredirect = false;
  showmodeletebannedpage = false;
  showmounfollow = false;
  showwithdraw= false;
  removeimage = false;
  followerror = false;
  username: any;
  pagename: string;
  clearhistory: boolean;

  constructor(public matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public api: ApiServiceService, private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, public common: commonservice, private dialogRef: MatDialogRef<BannedComponent>) { }

  ngOnInit(): void {
    this.whichpage = this.data.pageValue;
    // //console.log(this.data.pageValue);
    if (this.data.pageValue == "detailpage") {
      this.showmodalforpageredirect = true;
    } else if (this.data.pageValue == "bannedpage") {
      this.showmodeletebannedpage = true;
    } else if (this.data.pageValue == "deleteloop") {
      this.showmodeletebannedpage = true;
    } else if (this.data.pageValue == "unfollow") {
      this.username = this.data.name
      // //console.log(this.data.name);
      this.showmounfollow = true;
    
    } else if (this.data.pageValue == "withdraw") {
      this.showwithdraw = true;
    
    } else if (this.data.pageValue == "remove") {
      if (this.data.pagename == "profile") {
        this.pagename = " profile image"
      } else {
        this.pagename = " header image"
      }
      this.removeimage = true;
    
    } else if (this.data.pageValue == "follow") {
      this.followerror = true;
    } else if (this.data.pageValue == "clearhistory") {
      this.clearhistory = true;
    }
  }

  leave() {
    this.matDialog.closeAll();
    // //console.log("cancel");
    this.dialogRef.close({ data: false });
  }


  delete() {
    // //console.log("delete");
    this.matDialog.closeAll();
    this.dialogRef.close({ data: true });
  }

  loginpage() {
    this.matDialog.closeAll();
    this.dialogRef.close({ data: false });
    this.router.navigate([CONSTNAME.REDIRECT_CLIENT_loginCLIENT]);
  }


}
