import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
// import { APIURL } from '../allServices/constant.service';
@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
  tokenget: any;
  apivalid: any= false;
  statusmessage: any;
  displayname = "";
  apiResponse = false;
  firsttime = true;
  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute,public common: commonservice,public api: ApiServiceService) {
    
      
    }
     
  goestosecond = false;
  ngOnInit(): void {
    this.getapiforemailverification();
  }

  getapiforemailverification() {
    this.common.showLoader();
    this.apiResponse = false;
    this.tokenget = this.route.snapshot.queryParamMap.get('token');
    // //console.log(this.tokenget);


    this.http.get(APIURL.BASE_URL+`/user/verify_user_email?token=${this.tokenget}`).subscribe(async (res: any) => {
      this.apiResponse = true;
      if (res.response.status == 1) {
        this.common.hideLoader();
        this.firsttime = false;
        this.displayname = "none";
        // //console.log(res);
        this.apivalid = true;
        this.statusmessage = res.response.msg;
        
        this.getUserDetail()
        
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        this.router.navigate(['/explore'])
     
      } else {
        this.common.hideLoader();
        // //console.log(res);
        this.displayname = "none";
        let errorthroughapi = res.response.errors;
        let error = [];
        this.apivalid = false;
        this.statusmessage = res.response.errors;
        for (var i = 0; i < errorthroughapi.length; i++) {
          // this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.displayname = "none";
      this.common.hideLoader();
    
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
    
  }

  getUserDetail(){
    this.api.postApiHit(APIURL.user_get_logged_in_user_details,{},VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if(res.response.status){
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res:any)=>{
          let resa = JSON.parse(res)
          
          this.api.geturltoredirectexceptemailverify(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }
  

}
