import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { APIURL } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-webhook-events',
  templateUrl: './webhook-events.component.html',
  styleUrls: ['./webhook-events.component.css']
})
export class WebhookEventsComponent implements OnInit {

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getapiforwebhook();
  }
  getapiforwebhook() {
   let sendingData = {
      
    }
    this.http.post(APIURL.BASE_URL + `/webhook_events/webhook_events
    `,sendingData).subscribe(async (res: any) => {
      if (res.response.status == 1) {
       
        

        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })





      } else {
       
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
     
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });





    })
  }
}
