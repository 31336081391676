<div class="container-1">
    <div class="row">
        <div class="loop-main-div">
            <div class="cross-icon" (click)="cancel()"><img src="../../../../assets/img/Edit loop.svg"></div>
            <div class="right-content-div">
            <div class="heading-div">
                <h3>Edit Loop<img src="../../../../assets/img/logo.png" class="logo-img"><img src="../../../../assets/img/logo-secondary.png"
                        class="logo-img1"></h3>
                <p>Change the details from a loop you’ve published</p>
            </div>
            <div class="content-div ">
                <div class="col-sm-12 name-artwork-div m-b">
                    <div class="input-box col-md-6 ">
                        <label for="name">Loop Name</label>
                        <input type="text" class="form-control" name="surname" [(ngModel)]="loop_name" [ngModelOptions]="{ standalone: true }"
                            placeholder="Rainbow">
                    </div>
                    <div class="img-change-div col-md-4">
                        <img [src]="setimage(tableData)" class="choose-img">
                        <label class="form-control file-input-div">Change Artwork
                            <input type="file" class="d-none" accept=".jpeg, .jpg, .png" (change)="selectFileImage($event)" />
                        </label>
                    </div>
                </div>
                <div class="col-sm-12 usage-rights-div m-b">
                    <label for="rights">Usage Rights</label>
                    <textarea class="form-control" rows="3"  [(ngModel)]="usage" [ngModelOptions]="{ standalone: true }"></textarea>
                </div>
                <div class="col-md-12 key-bpm-div">
                    <div class="input-box">
                        <div class="col-lg-2 col key-space">
                            <label for="name">Key</label>
                            <select
                    class="form-control short-input edit-key"
                    [(ngModel)]="key"
                    id="surname" name="surname"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="Enter key"
                  >
                  <option *ngFor="let i of keys">{{i}}</option>
                 
                </select>
                            <!-- <input type="text" class="form-control" id="surname" name="surname" [(ngModel)]="key" [ngModelOptions]="{ standalone: true }"> -->
                        </div>
                        <div class="col-md-3 bpm-inp">
                            <label for="name">BPM</label>
                            <input type="number" class="form-control editloop-bpm" name="surname" [(ngModel)]="bpm" [ngModelOptions]="{ standalone: true }" >
                  <small class="text-danger error">{{bpmerror}}</small>
                </div>

                    </div>
                </div>
                    <div class="col-sm-12 toggle-div">
                        <label _ngcontent-xyg-c237="" for="checkbox" class="switch">
                            <input _ngcontent-xyg-c237="" type="checkbox" id="checkbox" [(ngModel)]="visiblity" 
                            [ngModelOptions]="{ standalone: true }"
                            />
                            <div _ngcontent-xyg-c237="" class="slider round">
        
                            </div>
                          </label>
                          <p _ngcontent-xyg-c237="" class="toggleText">Make this loop private</p>
                          <button
                      style="
                      box-shadow: none;
                      min-width: auto;
                    "
                      mat-raised-button
                      matTooltip="Making a loop private means that it is only shareable by link"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <img src="../../../assets/img/Icon.svg"/>
                      </button>
                      </div>
                      <div class="col-sm-12">
                        <button class="btn delete-loop-btn" (click)="delete()"><img src="../../../../assets/img/trash-01.png"/>Delete This Loop</button>
                      </div>
                      <div class="col-sm-12 ">
                        <button class="btn update-btn" (click)="submit()" >Update</button>
                      </div>
            </div>
        </div>
        </div>
    </div>
</div>