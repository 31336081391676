import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APIURL, CONSTNAME } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute } from '@angular/router';
import { emit } from 'node:process';
@Component({
  selector: 'app-likes',
  templateUrl: './likes.component.html',
  styleUrls: ['./likes.component.css']
})
export class LikesComponent implements OnInit {
  @Input() items: any;
  @Output() messageEvent = new EventEmitter<string>();
  @Output() sendingsearchEvent = new EventEmitter<string>();
  total: any;
  record: any;
  hidepagination = false;
  norecordfound: any;
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  pagenocount: any;
  nocomments: boolean;
  pagenolist: any;
  tableData: any[] = [];
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  looptype: string;
  titlename: any;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string
  loopid: any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.pageno = this.route.snapshot.queryParamMap.get('pageno')
    this.run(this.items);
    // //console.log(this.items)
  }
  paginationget(item:any) {
    // //console.log("red")
    this.messageEvent.emit(item)
    // //console.log(item)
  }

  searchClick() {
    // //console.log(this.validationforsearch)
    this.sendingsearchEvent.emit(this.validationforsearch)
  }

  run(res:any) {
    if (res.response.data.total_records == 0) {
      this.norecordfound = true;
    } else {
      this.norecordfound = false;
      // this.callforallinfo(res);
    }

    if (res.response.data.total_pages == 1) {
      this.hidepagination = true;
      // //console.log("hide");
    } else {
      this.hidepagination = false;
      // //console.log("not hide");
    }
    if (this.paginationempty == true) {
      this.pagercount = [];
    }
    this.total = res.response.data.total_records;
    
    this.tableData = res.response.data.pagging_list;

    // //console.log( res.response.data.loop_details.loop_type)
    // //console.log(this.tableData)

    this.record = this.tableData.length;
    if (this.record == 0) {
      this.nocomments = false;
    } else {
      this.nocomments = true;
      if (res.response.data.loop_details[0].loop_type == 1) {
        this.looptype = "Loop"
      } else {
        this.looptype = "Loop Kit"
      }

      this.titlename = res.response.data.loop_details[0].title;
      this.loopid = res.response.data.loop_details[0].loop_id;
      // //console.log(this.titlename)
    }
    this.pagenolist = res.response.data.total_pages + 1;

    this.record = this.pageno - 1 + "1";
    // //console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
    this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length

    this.tableData = res.response.data.pagging_list;
    // this.record = this.tableData.length;
    this.pagenolist = res.response.data.total_pages + 1;
    if (this.record == "01") {
      this.record = 1;
    }
    for (let i = 1; i < this.pagenolist; i++) {
      this.pagercount.push(i);
    }
    this.total = res.response.data.total_records;
  }

  setimage(data: any) {
    if (data.profile_image_path == null) {
      // //console.log("i am in null");
      return this.profile_image_pathofcomments;
    } else {
      // //console.log("i am not in null");
      return CONSTNAME.fILE_PATH + data.profile_image_path;
    }
  }

  redirecttoprofilepagefromcomment(value:any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    // window.open(`http://loophead-web.blinkcoders.com/detail-loop?loop_id=${this.encrypted}`, `_blank`)
    window.open(`${APIURL.BASE_URLforredirect}/user?user_id=${this.encrypted}`, `_blank`)
    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);
  }

  redirecttoloop() {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(this.loopid), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    window.open(`${APIURL.BASE_URLforredirect}/detail-loop?loop_id=${this.encrypted}`, `_blank`)
  }

}
