<!-- <h1 class="text-center">Welcome to Coupon Code Page. Coming soon !!!</h1> -->
<h3 class="dynamictext">Banned Words</h3>
<div class="page-actions">
	<div class="row">
		<div class="col-md-4 col-sm-5">
      <form class="search-group">
      <input type="search" [(ngModel)]="validationforsearch" class="form-control rounded form-control-sm" placeholder="Search"
        aria-label="Search" name="searchsubscription" id="searchsubscription" aria-describedby="search-addon" />
      <button class="btn btn-theme " style="margin: 0px;" type="submit" (click)=" searchClick()"><i class="fa fa-search "></i></button>
    </form>
    </div>
    <div class="col-md-8 col-sm-7">
      <div class="btn-group-right">
      <button *ngIf="!norecordfound" class="btn btn-danger" [disabled]="disabledeletebutton" (click)="sure()"> <i class="fa fa-trash text-right"
              aria-hidden="true"></i> Delete Selected</button>
      <button routerLink="/admin/addbanned/new" class="btn btn-theme"> <i class="fa fa-plus" aria-hidden="true"></i>
          Add Banned words</button>
        </div>
      </div>
    </div>
  </div>
<div class="table-responsive">
  <table class="table table-striped table-bordered table-loops v-middle" *ngIf="!norecordfound && !noresult" >
    <thead>
      <tr>

        <th class="text-center marginfa" *ngFor="let items of tablevaluearray">
          <input type="checkbox" (change)="clickforallcheckbox(items)" name="" id="delete" class="delete"
            *ngIf="items.tablehead == 'banned_word'" [(ngModel)]="items.isSelected"> {{items.name}} <i
            (click)="sortOn(items)" *ngIf="items.showicon" id="{{items.tablehead}}" class={{items.class}}
            style="cursor: pointer;" aria-hidden="true"></i>
        </th>

      </tr>

    </thead>
    <tbody>

      <!-- (change)="onChange(item.banned_word_id, $event.target.checked)" -->
      <tr *ngFor="let item of tableData">
        <!-- <td></td> -->
        <!-- (change)="yourfunc($event,item.banned_word_id)" -->
        <!-- [checked]="deletebanned(item.banned_word_id)" -->
        <td class="text-center"><input type="checkbox" (ngModelChange)="singleclick(item)" [(ngModel)]="item.isSelected"
            name="" id="" class="checkbox mt-0 mr-2">{{item.banned_word}}</td>

        <td class="text-center">{{item.created_on| date: 'dd-MM-yyyy h:mm a'}} </td>

        <td class="text-center hover"><a class="text-center" (click)="encryptUsingAES256(item.banned_word_id)"><i
              class=" text-center fa fa-pencil-square-o text-center" aria-hidden="true"></i></a></td>
      </tr>
 <!-- [routerLink]="['/admin/addbanned/'+]" -->

    </tbody>

  </table>
  <div *ngIf="noresult" class="noresult">No records to display</div>


</div>
<div *ngIf="norecordfound" class="noresult ">No records to display </div>

<!-- <div *ngIf="norecordfound" class="text-center ">No records to display </div> -->

<!-- <div *ngIf="norecordfound" class="text-center" style="
     color: #108a84;
    background-color: #DFF2BF;
    border-color: #f5c6cb;
    padding: 10px;
">No Record Found</div> -->

<div class="text-center">
  <ul class="paginationlist" *ngIf="!noresult">
    <!-- <li class="text-info" *ngIf="!norecordfound">Showing {{record}} records of {{total}}</li> -->
    <li class="text-info" *ngIf="!noresult">Showing {{record}} to {{pagenocount}} records of {{total}}</li>
    <!-- <li class="text-info"> {{total}}</li> -->
    <ul class="nextulpagination" *ngIf="!hidepagination">

      <!-- <li class="paginationliyellowcolor bluecolor hover"  [ngClass]="{'activated':item === pageno}" *ngFor="let item of pagercount" id="liststyle{{item}}"
        (click)="paginationget(item)">
        {{item}}

      </li> -->
      <li><jw-pagination class="hover" [items]="items" (changePage)="onChangePage($event)"
    
        [pageSize]=1
        [maxPages]="7"
        [initialPage]=pageno
        
        ></jw-pagination></li>
    </ul>

  </ul>


</div>