import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, VARS } from 'src/app/allServices/constant.service';
// import { ApiServiceService } from '../allServices/api-service.service';
// import { commonservice } from '../allServices/commonservice';
// import { APIURL, VARS } from '../allServices/constant.service';

@Component({
  selector: 'app-loopheadsignup2',
  templateUrl: './loopheadsignup2.component.html',
  styleUrls: ['./loopheadsignup2.component.css']
})
export class Loopheadsignup2Component implements OnInit {
  planData: any[] = [];
  freename: any ="Free";
  freeloopkit: any;
  freeloop: any;
  freeprice: any;
  premimiumprice: any;
  objectforfree: any;
  displayname = "";
  freeid = "";

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute,public api: ApiServiceService,public common: commonservice) {
    this.getapicallforplans();
  }

  ngOnInit(): void {

    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.getUserDetail();
      }
      let resa = JSON.parse(res)
      if(resa) this.api.getUrlToRedirect(resa.is_on_boarding_complete, resa.steps_completed);
    })
    // this.getapiforallinfo()
    
  }


  getapicallforplans() {


    
    
    this.common.showLoader();
    // this.http.get(APIURL.BASE_URL+'/user/get_signup_subscription_plans').subscribe(async (res: any) => {
    
    this.api.getApiForClient('/user/get_signup_subscription_plans', VARS.ClIENT_TYPE).then((res: any) => {
      // //console.log(res);
      if (res.response.status == 1) {
        this.common.hideLoader();
        // //console.log(res.response.data);
        // //console.log(res.response.data);
        // //console.log(res);
        this.planData = res.response.data[0];
        // alert("done");
         this.freename = res.response.data[0].plan_name ;
         this.freeid = res.response.data[0].plan_id ;
         this.freeloopkit = res.response.data[0].loopkits_to_upload ;
        this.freeprice = res.response.data[0].plan_price;
        this.freeloop = res.response.data[0].loops_to_upload;

        this.premimiumprice = res.response.data[1].plan_price;


        this.objectforfree = {
          can_sell_loopkits: res.response.data[0]. can_sell_loopkits,
          can_sell_loops: res.response.data[0]. can_sell_loops,
          created_on: res.response.data[0]. created_on,
          delete_flag: res.response.data[0]. delete_flag,
          is_enabled: res.response.data[0].  is_enabled,
          loopkits_to_upload: res.response.data[0]. loopkits_to_upload,
          loops_to_upload: res.response.data[0]. loops_to_upload,
          modified_on: res.response.data[0].  modified_on,
          plan_id: res.response.data[0]. plan_id,
          plan_name: res.response.data[0].  plan_name,
          plan_price: res.response.data[0]. plan_price,
          recurrence_interval: res.response.data[0]. recurrence_interval,
          recurrence_interval_unit: res.response.data[0].recurrence_interval_unit,
          live_plan_id: res.response.data[0].live_plan_id,
          sandbox_plan_id:  res.response.data[0]. sandbox_plan_id

        }
        this.displayname = "none";
        




      } else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          
          this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        }
        this.displayname = "none";
      }
    }, async e => {
      this.common.hideLoader();
      this.displayname = "none";
      this.toastr.error("Error in Api. Please come after some time", "Error", { positionClass: 'toast-bottom-right' });

    });
  }


  choosefirstplan() {
    
    // alert("will shift to ....page");
    
      // localStorage.setItem("Premimumplandetails", JSON.stringify(this.objectforfree));
    
    this.datapostapi();

    // this.router.navigate(['/paymentreceipt'], { queryParams: { plantype: "free" } })
    
  }



  choosesecondplan() {
    // alert("yo");
    this.router.navigate(['premimum']);
  }



  datapostapi() {
    // //console.log("api hit");
    let tokkenget = localStorage.getItem(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // //console.log(tokkenget)
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    let sendingData = {
      "plan_id": this.freeid,
      "visible_plan_name": this.freename,
      "visible_plan_price": this.freeprice,
      


    }
    this.common.showLoader();
    this.http.post(APIURL.BASE_URL+'/user/save_user_subscription',sendingData,{headers}).subscribe(async (res: any) => {
      
      if (res.response.status == 1) {
        // //console.log(res);
        // //console.log("sucess")
        // this.displayname = "none";
        // this.common.hideLoader();
        this.getUserDetail()
        let successmess = res.response.msg;

        // this.toastr.success(res.response.msg, "Success", { positionClass: 'toast-bottom-right' });
        // this.router.navigate(['/paymentreceipt'], { queryParams: { plantype: "free" } })
        // this.router.navigate(['/emailverify']);


      } else if (res.response.status == 3) {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        // //console.log("in going")
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          // this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

        this.common.clearAllLocalAndSession();
        // localStorage.removeItem("clientintoken");
        this.router.navigate(['/login']);
      } else {
        this.common.hideLoader();
        // this.displayname = "none";
        // //console.log("fail");
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

        // this.displayname = "none";
        // this.submitbutton.submitresponse = "Save changes";
        // this.submitbutton.iswaitingoption = false;
      }
    }, async e => {
      this.common.hideLoader();
      // this.displayname = "none";
      // //console.log('Error', e);
      this.toastr.error("Error please try again later (API error)", "", { positionClass: 'toast-bottom-right' });
    });
  }

  getUserDetail(){
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {},VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if(res.response.status){
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res:any)=>{
          let resa = JSON.parse(res)
          this.api.geturltoredirectexceptplandetail(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }

  

  getapiforallinfo() {
    let tokkenget = localStorage.getItem("clientintoken");
    let sendingData = {

    }

    

    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    // //console.log(headers);
    this.http.post(APIURL.BASE_URL + `/user/get_logged_in_user_details`, sendingData, { headers }).subscribe(async (res: any) => {
      
      if (res.response.status == 1) {
        // //console.log(res.response.data);
        // //console.log(res.response.data.user_details.is_on_boarding_complete);
        // //console.log(res.response.data.user_details.steps_completed);
        this.api.getUrlToRedirect(res.response.data.is_on_boarding_complete, res.response.data.steps_completed);


      } else if (res.response.status == 3) {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        // //console.log("in going")
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          // this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

        this.common.clearAllLocalAndSession();
        // localStorage.removeItem("clientintoken");
        this.router.navigate(['/login']);
      }else {
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) { 
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      // //console.log('Error', e);
    });

  }

}
