<app-header></app-header>
<div class="main-box card z-index-0">
<div class="infodiv" *ngIf="emailget && fullpageload">
    <div>
    <h1 class="headingemail">Verify Email Address</h1>
    <p class="h4 loophead-email">An email with the subject <b>"Verify your Loophead Email"</b> has been sent to : {{email}}</p>
    <p class="h5 text-muted">Don't Forget to check the spam folder</p>
    <p class="h4"><a class="link" (click)="verifyemail()"><b><u>Resend verification email</u></b></a></p>
    <!-- <button class="btn" (click)="checkemail()">Next</button> -->
</div>
</div>
<div class="infodiv" *ngIf="!emailget && fullpageload">

    <p class="h4">First login and then come to this page </p>

</div>

</div>