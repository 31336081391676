import { Component, NgZone, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { APIURL, CONSTNAME, VARS } from "src/app/allServices/constant.service";
import { ApiServiceService } from "src/app/allServices/api-service.service";
import { HttpClient } from "@angular/common/http";
import * as CryptoJS from 'crypto-js';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from "ngx-file-drop";
import { commonservice } from "src/app/allServices/commonservice";
import { ToastrService } from "ngx-toastr";

interface DroppedFile {
  file: File;
  fileEntry: FileSystemFileEntry;
}
@Component({
  selector: "app-create-pack",
  templateUrl: "./create-pack.component.html",
  styleUrls: ["./create-pack.component.css"],
})
export class CreatePackComponent implements OnInit {
  tokenFromUI: string = "0123456789123456";
  disable_input_as_itsloading1: boolean;
  ImagefileName: any;
  droppedFiles: never[];
  decrypted: string;
  pic: any;  
  encryptedagainremoveslashes: string;
  pack_id: any;
  value: any;
  created:any = false;
  formData = new FormData();
  disable_input_as_itsloading = false;
  fileName: any;
  user_id: any;
  pack_name: string ;
  dragClass: any;
  canDeactivate: any;
  showsecondpage: boolean = false;
  imageProgress: boolean = false;
  zipProgress: boolean = false;
  wavfilevalidation: boolean = true;
  encrypted: any = "";
  loop_id: any;
  public files: NgxFileDropEntry[] = [];
  visiblity:any = 0
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  edit: boolean = false;
  nameerror: any;
  picerror: string;
  selectedImage: string | ArrayBuffer | null | undefined;

  constructor(
    public api: ApiServiceService,
    private http: HttpClient,
    public location: Location,
    public router: Router,
    public matDialog: MatDialog,private route: ActivatedRoute,
    private zone: NgZone,
    public common: commonservice, private toastr: ToastrService
  ) {}
  ngOnInit(): void {
    this.value = this.route.snapshot.queryParamMap.get('pack_id');
   
    
    this.encryptedagainremoveslashes = decodeURIComponent(this.value);
    this.decryptUsingAES256();
     var sendingdata = {
      pack_id : this.decrypted
    }
    if(this.value){
    this.api.forclientgettokenpublic(`/user/pack_detail`, sendingdata).then(async (res: any) => {
      this.pack_name = res.response.data.pack_name
      this.zone.run(() => {
      this.visiblity = res.response.data.pack_visibility==1?true:false
    });
    // if(res.response.data.pack_cover_img){
      // //console.log(res.response.data.pack_cover_img);
      
        if(res.response.data.pack_cover_img!=="undefined"){
          this.ImagefileName = res.response.data.pack_cover_img
        this.selectedImage = CONSTNAME.fILE_PATH+res.response.data.pack_cover_img
        this.disable_input_as_itsloading1 = true
      }
      // }else{
      // this.ImagefileName = this.profile_image_pathofcomments;
    // }
    this.edit=true 
    // //console.log(this.visiblity);
         
    })
  }
  }
 ngOnDestroy():void{

 }

  setimage(data: any) {
    // if (data.artwork_path == null) {
      // //console.log(data);
      
      if (data == null) {
        return this.profile_image_pathofcomments;

      } else {
        return CONSTNAME.fILE_PATH + data;
      }

    // } else {

      // return CONSTNAME.fILE_PATH + data.artwork_path;
    // }
  }
  redirecttoProfile() {
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      let resa = JSON.parse(res);
      let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(resa.user_type_id), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      this.encrypted = encrypted.toString();
      this.encrypted = encodeURIComponent(this.encrypted);
    this.router.navigate([`/user/sounds/@${resa.username}`])
       
    })
      // //console.log("goback");

    // this.matDialog.closeAll();
    // this.location.back();
  }
  // selectFilemandatory(event: any) {

  //   const fileToUpload = event.target.files[0];

  //   if (fileToUpload) {
  //     this.fileName = fileToUpload.name;
  //     this.disable_input_as_itsloading = true;

  //     this.user_id = localStorage.getItem("client_data")
  //     const user=JSON.parse(this.user_id).user_type_id
  //     //console.log("hey_ram",user);

  //     let tokkenget: any = localStorage.getItem("clientintoken");
  //     let headers: any = {
  //       Authorization: `Bearer ${tokkenget}`,
  //       "My-Custom-Header": "foobar",
  //     };
  //     // this.user_id.user_type_id
  //     //console.log("hey_ram",user,this.pack_name);

  //     const formData: FormData = new FormData();
  //     formData.append("image", fileToUpload);
  //     formData.append("user_id", user);
  //     formData.append("pack_name", this.pack_name);

  //     this.http
  //       .post(`${APIURL.BASE_URL}/user/create_pack`, formData, {
  //         headers: headers,
  //       })
  //       .subscribe(
  //         (response: any) => {
  //           //console.log("response:", response);
  //         },
  //         (error: any) => {
  //           console.error("error:", error);
  //         }
  //       );
  //   }
  // }

  deleteImageFile() {
    this.pic = ""
    this.disable_input_as_itsloading1 = false
    this.ImagefileName = ""
    this.selectedImage =""

  }
  decryptUsingAES256() {

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    this.decrypted = this.decrypted.replace(/[""]/gi, '');

    // //console.log(this.decrypted);
    // this.getapicallforcouponcode();


  }
  loadImage(file: File): void {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.selectedImage = e.target?.result;
    };
    reader.readAsDataURL(file);
  }
  onFileDropArtwork(files: NgxFileDropEntry[]) {
    // //console.log("files", files);
    let id: any = document.getElementById("fileerrorformandatory");
    this.files = files;
    files[0].fileEntry.isFile;
    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    fileEntry.file(async (file: File) => {
      let extensiontype = file.name.split(".").pop();
      // //console.log(extensiontype, file.name);
      this.uploadArtworkFileViaDrop(file)
    });
  }
  uploadArtworkFileViaDrop(file:any){
    this.imageProgress = true;
    this.fileName = file.name;
    // //console.log("file", file);
    this.picerror = ""

    this.disable_input_as_itsloading1 = true;
    // let tokkenget: any = localStorage.getItem("clientintoken");
    // this.user_id = localStorage.getItem("client_data");
    // const user = JSON.parse(this.user_id).user_type_id;
    // this.formData.append("loop_artwork", file);
    // this.formData.append("loop_id",this.loop_id)
    // let headers: any = { Authorization: `Bearer ${tokkenget}`, "My-Custom-Header": "foobar" };
    // this.http
    //   .post(`${APIURL.BASE_URL}/user/upload_loop_draft`, this.formData, {
    //     headers: headers,
    //   })
    //   .pipe()
    //   .subscribe((event: any) => {
    //     this.imageProgress = false;
    //     this.disable_input_as_itsloading1 = false;

    //     //console.log("event:", event);
    //     this.loop_id = event.response.data.loop_id;
    //   });
  }
  selectFileImage(event: any) {
    this.imageProgress = true;
    this.disable_input_as_itsloading1 = true;
    const file = event.target.files[0];
    this.ImagefileName=file.name
    this.user_id = localStorage.getItem("client_data");
    const user = JSON.parse(this.user_id).user_type_id;
    // //console.log("hey_ram", user);

    let tokkenget: any = localStorage.getItem("clientintoken");
    const formData: FormData = new FormData();
    formData.append("loop_artwork", file);
    this.pic = file
    this.loadImage(file);
    // formData.append("loop_id", this.loop_id);

    // let headers: any = {
    //   Authorization: `Bearer ${tokkenget}`,
    //   "My-Custom-Header": "foobar",
    // };

    // this.http
    //   .post(`${APIURL.BASE_URL}/user/upload_loop_draft`, formData, {
    //     headers: headers,
    //   })
    //   .pipe()
    //   .subscribe((event: any) => {
    //     this.imageProgress = true;
    //     this.disable_input_as_itsloading1 = false;
    //     //console.log("event:", event);
    //     this.loop_id = event.response.data.loop_id;
    //   });
  }
  // onFileDrop(event: any) {
  //   //console.log(event);

  //   this.droppedFiles = [];

  //   for (const droppedFile of event) {
  //     if (droppedFile.fileEntry.isFile) {
  //       const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
  //       fileEntry.file((file: File) => {
  //         this.droppedFiles.push({ file, fileEntry });
  //       });
  //     } else if (droppedFile.fileEntry.isDirectory) {
  //       // Handle dropped directories if needed
  //     }
  //   }
  //   const formData = new FormData();
  //   this.droppedFiles.forEach(({ file }, index) => {
  //     formData.append(`file${index + 1}`, file);
  //   });
  //   // formData.append('main_loop',this.droppedFiles)

  //   //console.log("FormData:", formData);

  //   // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
  //   this.api
  //     .postApiHit("/user/upload_loop_draft", formData, VARS.ClIENT_TYPE)
  //     .then((res: any) => {
  //       //console.log("API Response:", res);
  //     });
  // }
  next() {
    // this.common.showLoader()
    this.created = true
    if(this.value){
      this.formData.append("pack_id",this.decrypted)
      if(this.pic)
      this.formData.append("loopkit_artwork",this.pic)
      this.formData.append('name',this.pack_name)
      if(this.visiblity){
      this.formData.append('visiblity','1')
    }else{
      this.formData.append('visiblity','0')

    }
      let tokkenget: any = localStorage.getItem("clientintoken");
      let headers: any = { Authorization: `Bearer ${tokkenget}`, "My-Custom-Header": "foobar" };
      this.http
        .post(`${APIURL.BASE_URL}/user/edit_pack`, this.formData, {
          headers: headers,
        })
        .pipe()
        .subscribe((event: any) => {
          this.router.navigate(['add-loops/'], { queryParams: { pack_id: this.value }});
          this.common.hideLoader()

        })
  
    
    }
    
    else{
      if(this.pack_name===undefined){
        this.common.hideLoader()

      this.nameerror = "Enter Pack name"
    }
    if(this.pack_name.length >50){
      this.common.hideLoader()

    this.nameerror = "Please enter pack name of less than 50 characters"
  }
    // //console.log(this.pic);
    if(this.pack_name &&this.pack_name.length <50 ){
    // //console.log("heyyyyyyy",this.pic,this.pack_name,this.visiblity);
    this.user_id = localStorage.getItem("client_data");
    const user = JSON.parse(this.user_id);
    if(this.pic===undefined){
    this.formData.append("loopkit_artwork","")
    }else{
    this.formData.append("loopkit_artwork",this.pic)
   } 
   this.formData.append('name',this.pack_name)
    if(this.visiblity){
      this.formData.append('visiblity','1')
    }else{
      this.formData.append('visiblity','0')

    }  
  
      this.formData.append('user_id',user.user_type_id)
    let tokkenget: any = localStorage.getItem("clientintoken");
    let headers: any = { Authorization: `Bearer ${tokkenget}`, "My-Custom-Header": "foobar" };
    this.http
      .post(`${APIURL.BASE_URL}/user/create_pack`, this.formData, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => {

        // //console.log("event:", event.response.data.pack_id);
        this.pack_id = event.response.data.pack_id;
    this.common.hideLoader()
        
        let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(this.pack_id), _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
        this.encrypted = encrypted.toString();
        this.encrypted = encodeURIComponent(this.encrypted);
        // //console.log('pack_id:', this.encrypted );
        
          this.router.navigate(['add-loops/'], { queryParams: { pack_id: this.encrypted }});
      });
    }
    }

  }
  deleteFile() {
    // //console.log("closeeee");
    this.disable_input_as_itsloading = false;
    
  }
  toggle() {
    // //console.log('Toggle value:', this.visiblity);
    this.visiblity = !this.visiblity
  }
}
