<div>
    <ul class="name">

        <!-- <li>
            <form class="displayinline">
                <input type="search" [(ngModel)]="validationforsearch" class="form-control rounded form-control-sm"
                    placeholder="Search" aria-label="Search" name="searchsubscription" id="searchsubscription"
                    aria-describedby="search-addon" />
                <button class="btn btn-theme" type="submit" (click)="searchClick()"><i
                        class="fa fa-search"></i></button>
            </form>
        </li> -->

    </ul>
</div>


<div class="comments-section">
    <div class="comment" *ngFor="let item of tableData">
        <img [src]="setimage(item)" class="comment-user-img" />

        <p>{{item.comment}}</p>
        <small class="text-muted"><strong class="hover"
                (click)="redirecttoprofilepagefromcomment(item.user_id)">@{{item.username}} </strong> , {{item.created_on
            |
            date: 'dd MMM,yyyy, h:mm a'}}</small>
    </div>
</div>


<div *ngIf="noresult" class="noresul text-center">No records to display </div>

<div *ngIf="norecordfound" class="text-center">No records to display </div>

<div class="text-center" *ngIf="!noresult">
    <ul class="paginationlist">
        <!-- <li class="text-info" *ngIf="!norecordfound">Showing {{record}} records of {{total}}</li> -->
        <li class="text-info" *ngIf="!norecordfound">Showing {{record}} to {{pagenocount}} records of {{total}}</li>
        <!-- <li class="text-info"> {{total}}</li> -->
        <ul class="nextulpagination" *ngIf="!hidepagination">

            <li class="paginationliyellowcolor bluecolor hover" [ngClass]="{'activated':item == pageno}"
                *ngFor="let item of pagercount" id="liststyle{{item}}" (click)="paginationget(item)">
                {{item}}

            </li>
        </ul>

    </ul>


</div>