<span class="play-btn hover play_button" id="play_button">
    <i aria-hidden="true" *ngIf="!hideplaybutton[1]" (click)="onPlayButtonClick(1)" class="fa fa-play"></i>
    <i (click)="onPauseButtonClick(1)" *ngIf="!hidepausebutton[1]" class="fa fa-pause" aria-hidden="true"></i></span>
  
  
  
  
  
  
  
  
  
  <ng-waveform #waveform1 class="waveform"
    src="https://d1ptp6rux3p5im.cloudfront.net/main_loops/1624002108706-file_example_WAV_1MG.wav" [height]="100"
    [useRegion]="false" backgroundColor="#d3d3d3" regionBackgroundColor="rgba(200, 255, 255, 0.7)"
    regionStartStickColor="#21f032" regionEndStickColor="grey" regionTextColor="#09417e" [withRegionLabels]="true"
    waveColor="#909090" (paused)="onPaused()" (regionChange)="onRegionChange($event)"
    (trackLoaded)="onTrackLoaded($event)" (rendered)="onTrackRendered($event)" (paused)="onPaused()">
  
  </ng-waveform>
  
  <span class="play-btn hover play_button" id="play_button2">
    <i aria-hidden="true" *ngIf="!hideplaybutton[2]" (click)="onPlayButtonClick(2)" class="fa fa-play"></i>
    <i (click)="onPauseButtonClick(2)" *ngIf="!hidepausebutton[2]" class="fa fa-pause" aria-hidden="true"></i></span>
  
  
  
  
  
  
  
  <ng-waveform #waveform2 class="waveform"
    src="https://d1ptp6rux3p5im.cloudfront.net/demo_loopkits/1624344925911-589286d5cb3c1fb8865d.mp3" [height]="100"
    [useRegion]="false" backgroundColor="#d3d3d3" regionBackgroundColor="rgba(200, 255, 255, 0.7)"
    regionStartStickColor="#21f032" regionEndStickColor="grey" regionTextColor="#09417e" [withRegionLabels]="true"
    waveColor="#909090" (paused)="onPaused()" (regionChange)="onRegionChange($event)"
    (trackLoaded)="onTrackLoaded($event)" (rendered)="onTrackRendered($event)" (paused)="onPaused()">
  
  </ng-waveform>
  
  
  <!-- <div *ngFor="let item of audioLinks;index as index">
            <app-waveform [arr]='item' ></app-waveform>
          
          </div>
          
          
          <button (click)="onPlayButtonClick()">play</button>
          <button (click)="onPauseButtonClick()">pause</button> -->
          
