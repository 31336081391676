
<div >
    <div class="text-start" style="width: 100%; height: 100%; justify-content:center; align-items: flex-start; gap: 4px; display: inline-flex">
        <img style="width: 30px" src="../../../../assets/img/loophead transparent logo_ 1.png" />
        <img style="width: 30px" src="../../../../assets/img/Loophead_DarkBlue 1 (1).png" />
      </div>
     
    <div class="email-sent">
        <h4 style="color:#4d4949">Email Sent</h4>
        <p style="color:#797979;font-weight:500">Check your inbox for a link to reset your password</p>
        <button class="btn" (click)="redirecttologin()">Back to Login</button>
    </div>
</div>