import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BannedComponent } from 'src/app/ADMIN_PAGE/banned/banned.component';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-confirm-cancel',
  templateUrl: './confirm-cancel.component.html',
  styleUrls: ['./confirm-cancel.component.css']
})
export class ConfirmCancelComponent implements OnInit {
  expirydateget: any;
  hidedate: boolean = false;
  constructor( public matDialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any,public api: ApiServiceService,private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute,  public common: commonservice,private dialogRef: MatDialogRef<BannedComponent>) { }

  ngOnInit(): void {
    this.expirydateget = this.data.pageValue
    if (this.data.pageValue !== null) {
      this.hidedate = true
    } else {
      this.hidedate = false
    }
  }


  cancel(){
    this.matDialog.closeAll()
  }

  deletefunction() {
     this.common.showLoader();

    this.api.postApiHit('/user/cancel_subscription', {}, VARS.ClIENT_TYPE).then((res: any) => {

      this.common.hideLoader();
      if (res.response.status == 1) {
        // this.getapiforallinfo();
        this.matDialog.closeAll();
        
        this.router.navigate([CONSTNAME.REDIRECT_CLIENT_subscriptiondetail]);
        this.dialogRef.close({ data: "done" });
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
      } else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api.", "", { positionClass: 'toast-bottom-right' });
    })
  }

  getUserDetail() {
    this.common.showLoader();
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {},VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if(res.response.status){
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          // this.datafromlocal = JSON.parse(res);
          // //console.log(this.datafromlocal);
          let resa = JSON.parse(res)
          this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }

  

}
