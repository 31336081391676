<main>
    <div class="firstcontainer container">
        <div class="secondcontainer">
            <ul class="subscriptionul">
                <li class="page1 active"><span class="span1">1</span> Subscription Plan</li>
                <li class="page1 active"><span class="span2">2</span> Subscription Interval</li>
                <li class="page1 active"> <span class="span3">3</span> Payment</li>
            </ul>
        </div>
        <div class="subscription-wrapper">
            <div class="cols-double">
                <div class="left-col">
                    <div class="insidebx1h2">
                        <h4>Paypal</h4>

                    </div>
                    <div class="muted-badge">After clicking the button below, you will be redirected to paypal.com to
                        approve the subscription.</div>
                    <hr>
                    <div class="insidebox1radio">
                        <div class="Plandetailsflex border-0">
                            <div>
                                <h4>{{Premimum}} Plan: <small>{{name}} Subscription</small></h4>
                            </div>
                            <div>
                                <h4>$ {{planpriceforanother}}</h4>
                            </div>

                        </div>
                        <div class="Plandetailsflex">
                            <div>
                                <h4>Coupon Discount: </h4>
                            </div>
                            <div>
                                <h4>$ {{coupondiscountprice}}</h4>
                            </div>

                        </div>
                        <div class="checkoutplandetailflex row">

                            <div class="col-md-7 col-sm-7 col-xs-12">
                                <form class="input-group coupon-group">
                                    <input type="search" [(ngModel)]="validationforsearch"
                                        class="form-control rounded form-control-sm" placeholder="Coupon Code"
                                        aria-label="Search" name="searchsubscription" id="searchsubscription"
                                        aria-describedby="search-addon" />
                                    <span class="input-group-btn">
                                        <button *ngIf="!showremoveoption" class="btn btn-theme " type="submit"
                                            (click)=" searchClick()">Apply</button>
                                        <button *ngIf="showremoveoption" class="btn btn-theme " type="submit"
                                            (click)="removecouponcode()">Remove</button>
                                    </span>
                                </form>
                            </div>
                            <div class="col-md-5 col-sm-5 col-xs-12">
                                <div class="totalflexdetail">
                                    <h3 class="space">Total:</h3>
                                    <h3> $ {{planprice}}</h3>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div *ngIf="showerror">
                                <small class="text-danger">Coupon code cannot be empty</small>
                            </div>
                        </div>
                    </div>
                    <!-- Checkout via Stripe -->
                    <div *ngIf="!couponcodeapplied && !freeforlifetime" (click)="click1()" class="text-right"
                        id="paypal-button-container-{{paypalid}}"> <button
                            [ngStyle]="{'display':btndis == true ? 'none' : 'inline-block' }"
                            class="btn btn-paypal ">Checkout via Paypal
                            <!-- <img src="assets/img/stripe.jpg" alt="">  -->
                         </button>
                    </div>
                    <div *ngIf="couponcodeapplied && !freeforlifetime" (click)="click2()" class="text-right"
                        id="paypal-button-container-{{paypalid}}"> <button
                            [ngStyle]="{'display':btndis == true ? 'none' : 'inline-block' }"
                            class="btn btn-paypal ">Checkout via Paypal
                            <!-- <img src="assets/img/stripe.jpg" alt=""> -->
                        </button>
                    </div>
                    <div *ngIf="freeforlifetime " (click)="datapostapi()" class="text-right"> <button
                            [ngStyle]="{'display':btndis == true ? 'none' : 'inline-block' }"
                            class="btn btn-paypal ">Checkout </button> </div>




                    <div class="text-right">

                    </div>
<!-- assets/img/stripe.jpg -->
                </div>
                <div class="right-col">
                    <div class="box2in1">
                        <h4>Accepted Payment Methods</h4>
                        <img src="assets/img/paypal.png" alt="">
                    </div>
                    <div class="box2in2">Premium plan: {{name}} subscription
                        <h6>Unlimited Access</h6>
                        <ul class="list-check">
                            <li>
                                <p>Upload Unlimited Loops</p>
                            </li>
                            <li>
                                <p>Upload Unlimited Loop Kits </p>
                            </li>
                            <li>
                                <p>Sell Unlimited Loops and Loopkits</p>
                            </li>
                            <li>
                                <p>Accept PayPal Payments</p>
                            </li>
                        </ul>
                    </div>
                    <div class="box2in3">
                        <div class="ssl">
                            <div>
                                <img src="assets/img/secure.png" alt="">
                            </div>
                            <div>
                                <h5>SSL SECURE PAYMENT</h5>
                                <p>Your information is protected by 256-bit SSL encryption</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>


    </div>
    <!-- <form (ngSubmit)="createStripeToken()" class="checkout">
        <label for="card-info">Card Info</label>
        <div id="form-field">
            <div id="card-info" #cardInfo></div>
            <button id="submit-button" type="submit">
                Pay ${{_totalAmount}}
            </button>
            <mat-error id="card-errors" role="alert" *ngIf="cardError">
                <mat-icon style="color: #f44336">cancel</mat-icon>
                &nbsp;{{ cardError }}
            </mat-error>
        </div>
    </form> -->

</main>