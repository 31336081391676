<app-header></app-header>
<div class="main" style="min-height:100vh;">
    <div class="container">
      <div class="row">
        <div class="col-10">
          <div class="main-head">My Downloads</div>
          <div class="main-sub-head" style="margin-top: 20px;">View and manage your Loophead downloads</div>
        </div>
        <div class="col-2 hidden-mobile" style="gap: 12px; display: inline-flex; float: right; padding-top: 20px;">
          <div class="hidden-mobile" style="width: 24px; height: 24px;">
            
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-12 ">
          <div class="search-bar">
            <label class="fas fa-search"></label>
            <input type="search" [(ngModel)]="search" (input)="submit()" placeholder="Search by title">
          </div>
        </div>
        <div class="col-md-8 col-12 my-auto">
          <!-- <div class="filter-btn">
            <a class="filter" (click)="openDialog()"> <img src="../../../../assets/img/icons/filter-lines.svg"/>View Filter</a>
          </div> -->
        </div>
        <div class="table-responsive" style="width:100%; margin-top: 10px; padding-bottom:60px;margin: 15px;" >
          <table class="table table-borderless"  *ngIf="!isempty">
          <thead>
            <tr>
              <th scope="col" >Loop</th>
              <th scope="col" class="loop-details" ></th>
              <th class="artist">User</th>
              <th scope="col" class="col-md-8 wave"></th>
              <th scope="col" class="mobi bpk" >BPM</th>
              <th scope="col" class="mobi bpk" >Key</th>
              <th scope="col" class="mobi tag">Tags</th>
              <th scope="col" class="bpk action">Action</th>
            </tr>
          </thead>
          <tbody >
            <tr *ngFor="let item of data;let i = index" id="player-row-{{i}}" >
                <!-- <td>{{}}</td> -->

                <td class="loop-artwork">
                  <img [src]="setimage(item)"/>
                  <!-- <span ngcontent-yrg-c82="" *ngIf="item.loop_type==1"
                  class="play-btn hover">
                  <span class="play-btn hover play_button" id="play_button">
                      <i aria-hidden="true" *ngIf="!item.hidepausebutton" (click)="playAudio(item,i,$event)" class="fa fa-play"></i>
                      <i (click)="stopAudio(item,i)" *ngIf="item.hidepausebutton" class="fa fa-pause"
                          aria-hidden="true"></i></span>
      
              </span> -->
              <span class="play-btn hover play_button" id="play_button"  *ngIf="!item?.hidepausebutton"  (click)="playAudio(item,i,$event)"  >

                <i aria-hidden="true" class="fa fa-play"></i></span>
            <span class="play-btn hover play_button" id="play_button"  *ngIf="item?.hidepausebutton" (click)="stopAudio(item,i)" >
                <i class="fa fa-pause"
                    aria-hidden="true"></i></span>
              </td>
                <td  class="loop-details">
                  <div class="detail-name">
                    <div (click)="redirecttodetailpage(item)" class="name-title-div">{{item.title}}</div>
                  <span> <a style="cursor: pointer; color: black" (click)="redirecttouser(item.username)" >
                    @{{item.username}}</a></span></div>
                 
                </td>
                <td class="artist-d" (click)="redirecttouser(item.username)"> @{{item.username}}</td>
                <td class="col-md-8 wave" >
                  <div _ngcontent-yrg-c82="" class="" *ngIf="item.loop_type==1">
                  <div _ngcontent-yrg-c82="" class="media-equlizer">
                    <app-waveform #waveformRef [arr]="srcforsound(item)" [loop_id]="item.loop_id" [items]="i"[passedValue]="item" 
                    [slide]="sliderValue"  (sliderValueChange)="onSliderValueChanged($event)"  (musicEnded)="next(i)"></app-waveform>                      </div>
                  </div>
              </td>
              <td class="mobi bpk">
                {{item.bpm}}
              </td>
              <td class="mobi bpk">
                {{item.sub_key}}  {{item.main_key===0?"Major":item.main_key===1?"Minor":""}}
              </td>
              <td class="mobi">
                <div *ngIf="item.loop_type==1">
                <div class="tags" *ngFor="let i of item.tags_array" >
                    {{i}}

                </div>
              </div>
            </td>
                <!-- <td style="vertical-align: middle;">
                  <div >
                    <img src="../../../assets/img/dots-horizontal.svg" (click)="show(item)"/>
                  </div>
                  <div *ngIf="item.hide" class="list-dropdwn"style="display: flex;position: absolute;flex-direction: column;     border-radius: 5px;
                  padding: 7px;">
                    <td (click)="downloadFile(item)">Re-download Loop</td>
                    <td (click)="downloadstem(item)">Download Stems</td>
                  </div>
                </td> -->
                <td class="nav-item ntify ntify-li bpk action act">
                  <button class="btn redwn-btn dropdwn-btn btn-default action-dropdown{{i}}" type="button" (click)="row(i)" id="dropdownMenu"   *ngIf="item.loop_type==1" aria-expanded="false">
                    <img src="../../../assets/img/dots-horizontal.svg" />
                  </button>
                  <ul class="dropdown-content menu redwn-list action-dropdown-menu{{i}}" id='dropdown-row-{{i}}' aria-labelledby="dropdownMenu" type="none"  >
                    <div >
                      <li class="content-text" *ngIf="item.loop_type==1&&!disable" (click)="downloadFile(item)">Re-download Loop</li>
                      <li class="content-text1" *ngIf="item.loop_type==1&&disable">Re-download Loop</li>
                      <!-- <li class="content-text" *ngIf="item.loop_type==2" (click)="downloadZipFile(item)">Re-download Pack</li> -->
                      <li class="content-text" *ngIf="item.loop_type==1&&item.stems!==null&&!disable" (click)="downloadstem(item)">Download Stems</li>
                      <li class="content-text1" *ngIf="item.loop_type==1&&item.stems!==null&&disable">Download Stems</li>
                  </div>
                  </ul>
                </td>
            </tr>
          </tbody>
          </table>
          <button class="load-btn btn btn-theme center"  *ngIf="pages>1" (click)="paginationget()">Load More Results</button>
         
        </div>
        <div  style="width:100%; margin-top: 40px;text-align: center; height: 100vh;" *ngIf="isempty" style="height: 250px;">
         <h1 style="text-align: center;">No Downloads Match Your Criteria</h1> 
        </div>
      </div>
    </div>
  </div>
  <div class="music-player"  *ngIf="showAudioPlayer"  >
        
        
    <div class="aWrap" data-src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3">
        <div>
          <input #slider class="range" style="width: 100%;" id="pi_input" type="range" [min]="0" [max]="timeupdate()" [step]="steps" [(ngModel)]="sliderValue" (input)="onInput($event)"/>
        </div>
        <div class="col-md-4 col-sm-6 player-img">
            <div class="img-info-div">
            <img class="img" [src]="setimage(data[player])"/>
            <div class="name">
            <h5>{{data[player].title}} </h5>
            <span class="username-box" style="cursor: pointer;">@{{data[player].username}}</span>
            </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-6 media-player">
            <div class="btns d-flex media-player-btns">
                <div>
                    <div (click)="backward()"><img src="../../../assets/img/fast-backward.svg"/></div>
                </div>
                <div class="buttons" *ngIf="!data[player].hidepausebutton"  (click)="playAudio(data[player],player,$event)">
                    <button class="aPlay" ><span class="aPlayIco"><i class="fa fa-play" style="color: white;"></i></span></button>
                </div>
                
                <div class="buttons" *ngIf="data[player].hidepausebutton" (click)="stopAudio(data[player],player)" >
                    <button class="aPlay"><span class="aPlayIco"><i class="fa fa-pause" style="color: white;"></i></span></button>
                </div>
                <div>
                    <div (click)="forward()" ><img src="../../../assets/img/fast-forward.svg"/></div>
                </div>
            </div>
        </div>
        <div class="col-md-4 volume-btns">
            <div class="volume-div d-flex">
                <div *ngIf="!mute" class="volume-container" (click) ="Mute()">
                <span class="aVolIco"><i class="fa fa-volume-up icon"></i></span>
                </div>
                <div *ngIf="mute" class="volume-container"  (click) ="Unmute()">
                    <span class="aVolIco" >
                      <span class="aVolIco" >
                        <i class="fa fa-volume-off" style="color: black"></i>
                    </span>                     </span> 
                    <!-- <span class="aVolIco" > <img src="../../../../assets/img/volume-xmark-solid.svg"/> </span> -->
                </div>
                <div  class="range-volume">
                    <input class="aVolume" id="volume" type="range" min="0" max="1" step="0.01" [(ngModel)]="volumeValue" (input)="onVolumeInput($event)" style="height: 5px;">
                </div>
            </div>
        </div>         
    </div>
</div>
<div class="overlay-div"></div>
<app-footer></app-footer>
