import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BannedComponent } from 'src/app/ADMIN_PAGE/banned/banned.component';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { PaypalService } from 'src/app/allServices/paypal.service';
import * as CryptoJS from 'crypto-js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

var forthis: any;

declare let paypal: any;
@Component({
  selector: 'app-downloadmodal',
  templateUrl: './downloadmodal.component.html',
  styleUrls: ['./downloadmodal.component.css']
})
export class DownloadmodalComponent implements OnInit {
  noofpercentage: any;
  @ViewChild("paypal", { static: true }) paypalElement: ElementRef;
  merchantIdInPayPal: any;
  price: any;
  aftercuttingdown: any;
  ispremiumorpaid: any;
  free: boolean;
  allitem: any;
  title: any;
  username: any;
  looptype: string;
  priceforloop: any;
  loopidget: any;
  merchantid: any;
  btndis: any = false;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  useridloop: any;
  arraytableforcomments: any = [];
  image = CONSTNAME.fILE_PATH;
  imageprofile: any = "assets/img/artwork.jpg";
  srcofimage: any;
  termsofpreference: string;
  pre: string;
  exact: string;
  royaltytrue = false;
  splittrue = false
  exclusive = false;
  signupformforloophead: FormGroup;
  handler: any = null;
  urlfromback: any;
  encryptedforsuccess: string;
  cancelurl: any;
  paypalButtonShow: boolean = false;
  stripeButtonShow: boolean = false;
  constructor(private formbuilder: FormBuilder, public matDialog: MatDialog, public router: Router, @Inject(MAT_DIALOG_DATA) public data: any, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService, public common: commonservice, private toastr: ToastrService, private dialogRef: MatDialogRef<BannedComponent>, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) {
    this.signupformforloophead = formbuilder.group({

      tickbox: ["", [Validators.requiredTrue]]
    })
  }

  ngOnInit(): void {
      // //console.log(this.data)


    // this.merchantIdInPayPal = "PB7XBV79FELNY";
    // this.paypal();
    this.encryptedforsuccess = btoa("success")
    this.cancelurl = this.data.url;
    this.urlfromback = this.data.url + "&msg=" + this.encryptedforsuccess
    // //console.log(this.urlfromback)
    this.ispremiumorpaid = this.data.pagevalue;
    this.allitem = this.data.allitems
    // //console.log(this.allitem)
    this.arraytableforcomments = this.allitem
    this.title = this.allitem.title

    if (this.allitem.owner_username) {
      this.username = this.allitem.owner_username
    } else {
      this.username = this.allitem.username
    }
    if (this.allitem.loop_type == 1) {
      this.looptype = "Loop"
      this.exact = "loop "
    } else {
      this.looptype = "LoopKit"
      this.exact = "loop (kit) "
    }
    if (this.ispremiumorpaid == "free") {
      this.free = true
      this.pre = "Free - "

    } else if (this.ispremiumorpaid == "premium") {
      this.free = false
      this.pre = "Paid - "
      this.priceforloop = this.allitem.loop_price
    }
    this.loopidget = this.allitem.loop_id
    this.merchantIdInPayPal = this.allitem.merchantIdInPayPal
    this.merchantid = this.allitem.merchantid

    // //console.log(this.ispremiumorpaid)
    this.useridloop = this.allitem.user_id;

    if (this.allitem.artwork_path !== null) {
      // //console.log("gaya")
      this.srcofimage = CONSTNAME.fILE_PATH + this.allitem.artwork_path
    } else if (this.allitem.profile_image_path !== null && this.allitem.profile_image_path !== undefined) {
      this.srcofimage = CONSTNAME.fILE_PATH + this.allitem.profile_image_path
      // //console.log("gaya",this.allitem.profile_image_path,this.allitem)
    } else if (this.allitem.owner_profile_image_path !== null && this.allitem.owner_profile_image_path !== undefined) {
      this.srcofimage = CONSTNAME.fILE_PATH + this.allitem.owner_profile_image_path
      // //console.log("in this",this.allitem.profile_image_path,this.allitem)
    }
    else {
      this.srcofimage = this.imageprofile
    }

    if (this.allitem.selling_preference == 1) {
      // //console.log("gaya")
      if (this.allitem.terms == 1) {
        this.termsofpreference = "Royalty Free";
        this.royaltytrue = true
      } else if (this.allitem.terms == 2) {
        this.termsofpreference = "50/50  Split";
        this.splittrue = true
      }
    } else if (this.allitem.selling_preference == 2) {
      if (this.allitem.terms == 1) {
        this.termsofpreference = "Royalty Free";
        this.royaltytrue = true
      } else if (this.allitem.terms == 2) {
        this.termsofpreference = "50/50  Split";
        this.splittrue = true
      } else {
        this.termsofpreference = "Exclusive";
        this.exclusive = true
      }
    }
    if (this.allitem.paypal_merchant_account_linked == 1) {
      this.paypalButtonShow = true
    } else {
      this.paypalButtonShow = false
    }
    if (this.allitem.stripe_merchant_account_linked == 1) {
      this.stripeButtonShow = true
    } else {
      this.stripeButtonShow = false
    }

    let script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.text = '';
    // script.src = `https://www.paypal.com/sdk/js?client-id=${APIURL.httpaddresforscript}&disable-funding=credit,card&merchant-id=X4FEYXZT2PKVA`;
    script.src = `https://www.paypal.com/sdk/js?client-id=${APIURL.httpaddresforscript}&merchant-id=${this.merchantIdInPayPal}`;
    // script.src = `https://www.paypal.com/sdk/js?client-id=${APIURL.httpaddresforscript}&disable-funding=credit,card&merchant-id=${this.merchantIdInPayPal}`;

    this._renderer2.appendChild(this._document.body, script);


  }

  cancel() {
    this.matDialog.closeAll()
    // //console.log("cancekl")
    this.dialogRef.close({ data: "" });
  }

  async redirecttolooppagecomment() {

    this.redirecttolooppage()


  }

  redirecttolooppage() {
    // //console.log(value)

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(this.loopidget), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
    this.matDialog.closeAll()
    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);

  }

  redirecttoprofilepage() {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(this.useridloop), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(item.user_id);

    this.router.navigate(['user/sounds'], { queryParams: { user_id: this.encrypted } });
    setTimeout(() => {
      location.reload()
    }, 5);
  }








  paypalcheck(value: any) {
    // //console.log("in ppp")
    if (value == 'free') {
      if (this.exclusive || this.splittrue) {
        this.signupformforloophead.markAllAsTouched();
        if (this.signupformforloophead.valid == true) {
          this.paypal()
        }
      } else {
        this.paypal()
      }

      // //console.log("free")
    } else if (value == "paidroyal") {
      // this.paypal();
      this.newapihitforpayment();
    } else {
      this.signupformforloophead.markAllAsTouched();
      // //console.log(this.signupformforloophead.value)
      if (this.signupformforloophead.valid == true) {
        // this.paypal();
        this.newapihitforpayment();
      } else {
        // //console.log("nahi")
      }
      // //console.log()
    }
  }

  async checkoutstripe() {
    // //console.log("yee");

    // const stripe = require('stripe')('sk_test_51JUlbtSCoQV54vG1XgZCeTxeCftdiLBLBiNoF8USxJenOSyN7G8HPMfWmWwDa51mGvKVsTyNgZAE98BVSP2zuTeO00KC34Qods');

    // const session = await stripe.checkout.sessions.create({
    //   payment_method_types: ['card'],
    //   line_items: [{
    //     name: 'Stainless Steel Water Bottle',
    //     amount: 1000,
    //     currency: 'inr',
    //     quantity: 1,
    //   }],
    //   payment_intent_data: {
    //     application_fee_amount: 123,
    //   },
    //   mode: 'payment',
    //   success_url: 'https://example.com/success',
    //   cancel_url: 'https://example.com/cancel',
    // }, {
    //   stripeAccount: '{{CONNECTED_STRIPE_ACCOUNT_ID}}',
    // });


    // //console.log("open stripe")
    // //console.log(amount)
    var handler = (<any>window).StripeCheckout.sessions.create({
      key: 'pk_test_51JUlbtSCoQV54vG1MLBfEowqktuwa8KdL58ZudupxVzAp7E3ZPcB3Nqa27FHTa5pACkCrgsQb13cu8ZhivbpgX4c00LSzoVKo2',
      payment_method_types: ['card'],
      line_items: [{
        name: 'Stainless Steel Water Bottle',
        amount: 1000,
        currency: 'inr',
        quantity: 1,
      }],
      payment_intent_data: {
        application_fee_amount: 123,
      },
      mode: 'payment',
      success_url: 'https://example.com/success',
      cancel_url: 'https://example.com/cancel',
    }, {
      stripeAccount: 'acct_1JUs1nSArAMueoy0',
    });

    handler.open({
      name: 'Demo Site',
      description: '2 widgets',
      // amount: amount * 100
    });



  }



  paypal() {
    // //console.log("here")
    forthis = this;
    // //console.log(forthis.priceforloop,forthis.merchantid,forthis.priceforloop)

    //  == true

    if (this.free) {
      this.apihitfree();
    } else {
      this.btndis = true;


      // //console.log(this.aftercuttingdown);



      // //console.log(this._renderer2, paypal);
      paypal.Buttons({


        createOrder: function (data: any, actions: any) {
          return actions.order.create({
            "intent": "CAPTURE",
            "purchase_units": [{
              "amount": {
                "currency_code": "USD",

                "value": forthis.priceforloop
              },
              "payee": {

                "email_address": forthis.merchantid
              }

            }],
          });
        },
        onApprove: function (data: any, actions: any) {
          return actions.order.capture().then(function (details: any) {

            // //console.log(details)
            // //console.log("image will show")

            forthis.call(details)

          });
        }
      }).render('#paypal-button-container-' + forthis.merchantIdInPayPal);


    }


  }

  call(details: any) {
    this.common.showLoader();
    this.apihitforpremium(details)

    // //console.log(details.purchase_units[0].disbursement_mode)
  }

  apihitforpremium(details: any) {
    //  //console.log(details.status)
    //   //console.log(details.purchase_units[0].payments.captures[0].status,details.purchase_units[0].payments.captures[0].disbursement_mode)
    let statuspayment: any
    let capturestatus: any

    if (details.status == "COMPLETED") {
      statuspayment = 1
    } else {
      statuspayment = 0
    }


    if (details.purchase_units[0].payments.captures[0].status == "PENDING") {
      capturestatus = 0
    } else {
      capturestatus = 1
    }

    let sendingdata = {
      "txn_id": details.id,
      "loop_id": this.loopidget,
      "payment_amount": details.purchase_units[0].amount.value,
      "payment_status": statuspayment,
      "payer_email": details.payer.email_address,
      "payee_email": details.purchase_units[0].payee.email_address,
      "payee_merchant_id": details.purchase_units[0].payee.merchant_id,
      "payer_id": details.payer.payer_id,
      "shipping_name": details.purchase_units[0].shipping.name.full_name,
      "shipping_address_line_1": details.purchase_units[0].shipping.address.address_line_1,
      "shipping_admin_area_2": details.purchase_units[0].shipping.address.admin_area_2,
      "shipping_admin_area_1": details.purchase_units[0].shipping.address.admin_area_1,
      "shipping_postal_code": details.purchase_units[0].shipping.address.postal_code,
      "shipping_country_code": details.purchase_units[0].shipping.address.country_code,
      "payment_disbursement_mode": details.purchase_units[0].payments.captures[0].disbursement_mode,
      "paypal_payment_capture_id": details.purchase_units[0].payments.captures[0].id,
      "payer_payment_capture_status": capturestatus
    }
    // //console.log(details)
    this.api.postApiHit(`/user/save_loop_payment`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {

        // //console.log(res)
        // //console.log(res.response.msg)
        this.matDialog.closeAll()
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        this.dialogRef.close({ data: "downloadpre", data2: res.response.data });


        this.common.hideLoader();

      } else if (res.response.status == 2) {
        let errorthroughapi = res.response.errors;
        let error = [];

        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        }


      }
      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }
  apihitfree() {
    this.common.showLoader();
    let sendingdata = {
      "loop_id": this.loopidget
    }
    this.api.postApiHit(`/user/save_free_loop`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {

        // //console.log("in")
        this.matDialog.closeAll()
        this.dialogRef.close({ data: "downloadfree" });



        this.common.hideLoader();
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        // this.getallinfoofcomments();
      } else if (res.response.status == 2) {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();

        // //console.log("gaya");
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }


      }
      else {
        // //console.log("here in 471")
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        // this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      // //console.log("here in 481")

      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  srcartwork(items: any) {
    if (items.artwork_path !== null) {
      return this.image + items.artwork_path
    } else if (items.owner_profile_image_path !== null) {
      return this.image + items.owner_profile_image_path
    } else {
      return this.imageprofile

    }
  }

  newapihitforpayment() {
    this.common.showLoader();
    // this.btndis = true;
    let sendingdata = {
      "loop_id": this.loopidget,
      "success_referrer": this.urlfromback,
      "cancel_referrer": this.cancelurl
    }


    this.api.postApiHit(`/user/make_stripe_payment`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();

        // //console.log(res.response.data)
        let url = res.response.data;
        document.location.href = url;

        // //console.log(res.response.msg)
        // this.matDialog.closeAll()
        this.toastr.success(res.response.msg, "Redirecting to Stripe", { positionClass: 'toast-bottom-right' })
        // this.dialogRef.close({ data: "downloadpre", data2: res.response.data });


        this.common.hideLoader();

      }
      else if (res.response.status == 2) {
        let errorthroughapi = res.response.errors;
        let error = [];

        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        }


      }
      else if (res.response.status == 3) {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        // //console.log("in going")
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          // this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

        this.common.clearAllLocalAndSession();
        // localStorage.removeItem("clientintoken");
        this.router.navigate(['/login']);


      }
      else if (res.response.status == 4) {
        let errorthroughapi = res.response.errors;
        let error = [];

        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        }


      }
      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });

  }











}
