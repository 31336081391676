<h3 class="dynamictext">Dashboard</h3>

<div class="row" *ngFor="let items of arraytableforcommentsforloopkit">
  <div class="col-md-4">
    <div class="dashboard-card color1"><i class="fa fa-user"></i>
      <span>Total users</span>
      <h2>{{items.total_users_count}}</h2>
    </div>
  </div>
  <div class="col-md-4">

    <div class="dashboard-card color2"><i class="fa fa-refresh"></i><span>Total Loops</span>
      <h2>{{items.total_loops_count}}</h2>
    </div>
  </div>
  <div class="col-md-4">
    <div class="dashboard-card color3"><i class="fa fa-folder"></i><span>Total Loopkits</span>
      <h2>{{items.total_loopkits_count}}</h2>
    </div>
  </div>
</div>

<div class="row">

  <div class="col-md-6">
    <h3 class="panel-heading">Users</h3>
    <div *ngFor="let items of this.arraytableforrecommendeduser">


      <div>
       
        <div class="user-li"><span class="user-img"><img class="imageparam" [src]="setimage(items)"></span>
          <div class="follower-name  addorange" ><span class="hover" (click)="redirectto(items.user_type_id)">@{{items.username}}</span>  <span
              class="text-muted small d-block"> {{items.first_name}} <span
                *ngIf="items.last_name">{{items.last_name}}</span> <span> {{items.created_on| date: 'dd-MM-yyyy h:mm
                a'}}</span> </span></div><a class="view-user"><i aria-hidden="true" (click)="sendtouserdetail(items.user_type_id)" 
              class="text-center fa fa-eye text-center hover"></i></a>
        </div>
<!-- (click)="encryptUsing(items.user_type_id)" -->
        


      </div>
    </div>
    <p  (click)="redirecttouserpage()"  class="hover textend" >View All</p>
  </div>
  <div class="col-md-6">
    <h3 class="panel-heading">Uploads</h3>
    <div>
    
      <div *ngFor="let items of arraytableforrecommendedloopkitsagain" class="">
        <div class="user-li"><span class="user-img"><img class="imageparam" [src]="setimageforupload(items)"></span>
          <div class="follower-name " ><b><span class="hover" (click)="redirecttodetail(items.loop_id)">{{items.title}}</span></b>  by <span
              class="addorange hover" (click)="redirectto(items.user_id)">@{{items.username}} </span><span
              class="text-muted small d-block "> {{items.loop_type ===1?"Loop":"Loop Kit"}} <span> {{items.created_on|
                date: 'dd-MM-yyyy h:mm a'}}</span></span></div><a class="view-user"><i aria-hidden="true"
              (click)="encryptUsingAES256(items.loop_id)" class="text-center hover fa fa-eye text-center"></i></a>
        </div>

        


      </div>
      
    </div>
    <p (click)="redirecttolooppage()" class="hover textend" >View All</p>
  </div>
</div>