import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import Cropper from 'cropperjs';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { BannedComponent } from 'src/app/ADMIN_PAGE/banned/banned.component';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { VARS } from 'src/app/allServices/constant.service';
// Import Cropper from 'cropperjs' if you have installed it via npm or yarn
// import Cropper from 'cropperjs';


@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.css']
})
export class ImagePreviewComponent implements OnInit {

  @ViewChild("image", { static: false })
  public imageElement: ElementRef;

  @Input("src")
  public imageSource: any;

  public imageDestination: string;
  private cropper: Cropper;

  public constructor(public matDialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any, public api: ApiServiceService, public common: commonservice,private toastr: ToastrService, private dialogRef: MatDialogRef<BannedComponent>) {
      this.imageDestination = "";
  }

  public ngAfterViewInit() {
      
  }

  public ngOnInit() { 
    // this.imageSource = 
    this.loadImage(this.data.image)
  }
  loadImage(file: File): void {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.imageSource = e.target?.result;
   
    
    };
    reader.readAsDataURL(file);
    setTimeout(() => {
      this.cropper = new Cropper(this.imageElement.nativeElement, {
        zoomable: false,
        scalable: false,
        aspectRatio: 1,
        crop: () => {
          const canvas = this.cropper.getCroppedCanvas({
            width: 300, // set desired output width
            height: 300, // set desired output height
            fillColor: '#ffffff', // optional: set background color
          });
          if (canvas !== null) {
            const roundedCanvas = this.getRoundedCanvas(canvas); // function to create rounded canvas
            this.imageDestination = roundedCanvas.toDataURL("image/png");
          }
        }
      });
    }, 200);
  }
  context :any
  getRoundedCanvas(sourceCanvas: HTMLCanvasElement): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');// Check if the sourceCanvas has a valid width and height
    if (sourceCanvas.width === 0 || sourceCanvas.height === 0) {
        // throw new Error('Source canvas has invalid dimensions');
    return canvas;
  }else{
    


    if (!context) {
        throw new Error('Unable to create 2D context for canvas');
    }

    const width = sourceCanvas.width;
    const height = sourceCanvas.height;

    const size = Math.min(width, height);

    canvas.width = size;
    canvas.height = size;
    const cornerRadius = size * 0.01; // Adjust the corner radius as needed

    // Draw a rounded rectangle onto the canvas
    context.beginPath();
    context.moveTo(cornerRadius, 0);
    context.arcTo(size, 0, size, size, cornerRadius);
    context.arcTo(size, size, 0, size, cornerRadius);
    context.arcTo(0, size, 0, 0, cornerRadius);
    context.arcTo(0, 0, size, 0, cornerRadius);    context.strokeStyle = 'rgba(0,0,0,0)'; // Transparent stroke to hide the outline
    context.stroke(); // Apply the stroke
    context.clip(); // Clip to the drawn path
    context.drawImage(sourceCanvas, (width - size) / 2, (height - size) / 2, size, size, 0, 0, size, size); // Draw the cropped image onto the canvas

    return canvas;
  }

}

  // apihitforimage(item:File) {
  //   //console.log(item);
  //   this.reader = new FormData();
  //   this.reader.append('profile_image_path',item);
    
  //   this.api.postApiHit('/user/upload_profile_photo',this.reader, VARS.ClIENT_TYPE).then((res: any) => {
  //     if (res.response.status == 1) {
  //       let keys = res.response.data.loop_id;
  //       this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
  //       // this.getUserDetail();
  //       this.common.hideLoader();

  //     } else {
  //       let errorthroughapi = res.response.errors;
  //       let error = [];

  //       for (var i = 0; i < errorthroughapi.length; i++) {
  //         let jk = error.push(errorthroughapi);
  //         this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
  //       }
  //       this.common.hideLoader();
  //     }
  //   }, async e => {
  //     this.toastr.error("", "", { positionClass: 'toast-bottom-right' });
  //     this.common.hideLoader();
  //   });

  // }
  cancel(){
    // this.matDialog.closeAll()
    
    this.dialogRef.close({ data: true });

  }
  async submit() {

    this.api.postApiHit('/user/upload_profile_photo',{ profile_image_path:this.imageDestination,cropped:true}, VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        let keys = res.response.data.loop_id;
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        // this.getUserDetail();
        this.common.hideLoader();
        setTimeout(() => {
        this.dialogRef.close({ data: true });
          
        }, 2000);

      } else {
        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
        this.common.hideLoader();
        setTimeout(() => {
          this.dialogRef.close({ data: true });
            
          }, 2000);
  
      }
    }, async e => {
      this.toastr.error("", "", { positionClass: 'toast-bottom-right' });
      this.common.hideLoader();
      this.dialogRef.close({ data: true });
    });
  

  }
}
