

<div class="navbar-custom-menu">
  <ul class="nav navbar-nav">
    
    
     <!-- <li mk-dropdown type="list" [isWrapper]="false" class="messages-menu">
      <mk-dropdown-toggle>
        <a #toggleElement>
          <i class="fa fa-envelope-o"></i>
          <span class="label label-success">4</span>
        </a>
      </mk-dropdown-toggle>
      <mk-dropdown-menu>
        <li class="header">You have 4 messages</li>
        <li>
          <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 200px;"><ul class="menu" style="overflow: hidden; width: 100%; height: 200px;">
            <li>
              <a href="#">
                <div class="pull-left">
                  <img src="assets/img/user2-160x160.jpg" class="img-circle" alt="User Image">
                </div>
                <h4>
                  Support Team
                  <small><i class="fa fa-clock-o"></i> 5 mins</small>
                </h4>
                <p>Why not buy a new awesome theme?</p>
              </a>
            </li>
            <li>
              <a href="#">
                <div class="pull-left">
                  <img src="assets/img/user3-128x128.jpg" class="img-circle" alt="User Image">
                </div>
                <h4>
                  AdminLTE Design Team
                  <small><i class="fa fa-clock-o"></i> 2 hours</small>
                </h4>
                <p>Why not buy a new awesome theme?</p>
              </a>
            </li>
            <li>
              <a href="#">
                <div class="pull-left">
                  <img src="assets/img/user4-128x128.jpg" class="img-circle" alt="User Image">
                </div>
                <h4>
                  Developers
                  <small><i class="fa fa-clock-o"></i> Today</small>
                </h4>
                <p>Why not buy a new awesome theme?</p>
              </a>
            </li>
            <li>
              <a href="#">
                <div class="pull-left">
                  <img src="assets/img/user3-128x128.jpg" class="img-circle" alt="User Image">
                </div>
                <h4>
                  Sales Department
                  <small><i class="fa fa-clock-o"></i> Yesterday</small>
                </h4>
                <p>Why not buy a new awesome theme?</p>
              </a>
            </li>
            <li>
              <a href="#">
                <div class="pull-left">
                  <img src="assets/img/user4-128x128.jpg" class="img-circle" alt="User Image">
                </div>
                <h4>
                  Reviewers
                  <small><i class="fa fa-clock-o"></i> 2 days</small>
                </h4>
                <p>Why not buy a new awesome theme?</p>
              </a>
            </li>
          </ul><div class="slimScrollBar" style="width: 3px; position: absolute; top: 0; opacity: 0.4; display: block; border-radius: 7px; z-index: 99; right: 1px; background: rgb(0, 0, 0);"></div><div class="slimScrollRail" style="width: 3px; height: 100%; position: absolute; top: 0; display: none; border-radius: 7px; opacity: 0.2; z-index: 90; right: 1px; background: rgb(51, 51, 51);"></div></div>
        </li>
        <li class="footer"><a href="#">See All Messages</a></li>
      </mk-dropdown-menu>
    </li>

    <li mk-dropdown type="list" [isWrapper]="false" class="notifications-menu">
      <mk-dropdown-toggle>
        <a #toggleElement>
          <i class="fa fa-bell-o"></i>
          <span class="label label-warning">10</span>
        </a>
      </mk-dropdown-toggle>
      <mk-dropdown-menu>
        <li class="header">You have 10 notifications</li>
        <li>
          <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 200px;"><ul class="menu" style="overflow: hidden; width: 100%; height: 200px;">
            <li>
              <a href="#">
                <i class="fa fa-users text-aqua"></i> 5 new members joined today
              </a>
            </li>
            <li>
              <a href="#">
                <i class="fa fa-warning text-yellow"></i> Very long description here that may not fit into the
                page and may cause design problems
              </a>
            </li>
            <li>
              <a href="#">
                <i class="fa fa-users text-red"></i> 5 new members joined
              </a>
            </li>
            <li>
              <a href="#">
                <i class="fa fa-shopping-cart text-green"></i> 25 sales made
              </a>
            </li>
            <li>
              <a href="#">
                <i class="fa fa-user text-light-blue"></i> You changed your username
              </a>
            </li>
          </ul><div class="slimScrollBar" style="width: 3px; position: absolute; top: 0; opacity: 0.4; display: block; border-radius: 7px; z-index: 99; right: 1px; background: rgb(0, 0, 0);"></div><div class="slimScrollRail" style="width: 3px; height: 100%; position: absolute; top: 0; display: none; border-radius: 7px; opacity: 0.2; z-index: 90; right: 1px; background: rgb(51, 51, 51);"></div></div>
        </li>
        <li class="footer"><a href="#">View all</a></li>
      </mk-dropdown-menu>
    </li>

    <li mk-dropdown type="list" [isWrapper]="false" class="tasks-menu">
      <mk-dropdown-toggle>
        <a #toggleElement>
          <i class="fa fa-flag-o"></i>
          <span class="label label-danger">9</span>
        </a>
      </mk-dropdown-toggle>
      <mk-dropdown-menu>
        <li class="header">You have 9 tasks</li>
        <li>
          <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 200px;"><ul class="menu" style="overflow: hidden; width: 100%; height: 200px;">
            <li>
              <a href="#">
                <h3>
                  Design some buttons
                  <small class="pull-right">20%</small>
                </h3>
                <div class="progress xs">
                  <div class="progress-bar progress-bar-aqua" style="width: 20%" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                    <span class="sr-only">20% Complete</span>
                  </div>
                </div>
              </a>
            </li>

            <li>
              <a href="#">
                <h3>
                  Create a nice theme
                  <small class="pull-right">40%</small>
                </h3>
                <div class="progress xs">
                  <div class="progress-bar progress-bar-green" style="width: 40%" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                    <span class="sr-only">40% Complete</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="#">
                <h3>
                  Some task I need to do
                  <small class="pull-right">60%</small>
                </h3>
                <div class="progress xs">
                  <div class="progress-bar progress-bar-red" style="width: 60%" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                    <span class="sr-only">60% Complete</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="#">
                <h3>
                  Make beautiful transitions
                  <small class="pull-right">80%</small>
                </h3>
                <div class="progress xs">
                  <div class="progress-bar progress-bar-yellow" style="width: 80%" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                    <span class="sr-only">80% Complete</span>
                  </div>
                </div>
              </a>
            </li>
          </ul><div class="slimScrollBar" style="width: 3px; position: absolute; top: 0; opacity: 0.4; display: block; border-radius: 7px; z-index: 99; right: 1px; background: rgb(0, 0, 0);"></div><div class="slimScrollRail" style="width: 3px; height: 100%; position: absolute; top: 0; display: none; border-radius: 7px; opacity: 0.2; z-index: 90; right: 1px; background: rgb(51, 51, 51);"></div></div>
        </li>
        <li class="footer">
          <a href="#">View all tasks</a>
        </li>
      </mk-dropdown-menu> 
    </li> -->
    <li mk-dropdown type="list" [isWrapper]="false" class="user user-menu">
      <mk-dropdown-toggle>
        <a #toggleElement class="hover">
          <img src="assets/img/logo.png" class="user-image hover" style="cursor: pointer;" alt="User Image">
          <span class="hidden-xs hover" style="cursor: pointer;">{{ownerName}}</span>
        </a>
      </mk-dropdown-toggle>
      <mk-dropdown-menu>
        <li class="user-header">
          <img src="assets/img/logo.png" class="img-circle" alt="User Image">

          <p>
            {{ownerName}}
            <!-- <small>Loophead since Nov. 2018</small> -->
          </p>
        </li>
        <li class="user-body">
          <div class="row">
            <div class="col-xs-4 text-center">
              <!-- <a href="#">Followers</a> -->
            </div>
            <div class="col-xs-4 text-center">
              <!-- <a href="#">Sales</a> -->
            </div>
            <div class="col-xs-4 text-center">
              <!-- <a routerLink="/globalsetting">Global </a> -->
            </div>
          </div>
        </li>
        <li class="user-footer">
          <div class="pull-left">
            <!-- <a routerLink="/profileupdate" class="btn btn-default btn-flat">Profile</a> -->
          </div>
          <div class="pull-right">
            <a (click)="onlogout()" class="btn btn-default btn-flat">Log out</a>
          </div>
        </li>
      </mk-dropdown-menu>
    </li>
  </ul>
</div>