<div>
<div (click)="cancel()"class="modal-head">
    <div class="col-md-6 heding">
        <img src="../../../../assets/img/x-close.svg"/>
        
        <div _ngcontent-twy-c176="" class="text-start" style="width: 100%;height: 100%;justify-content: flex-start;align-items: flex-start;gap: 4px;">
            <div _ngcontent-twy-c176="" class="text d-flex">
                <p _ngcontent-twy-c176="">Followers</p>
                <img _ngcontent-twy-c176="" src="../../../../assets/img/logo.png" style="width: 35px;">
                <img class="img" src="../../../../assets/img/logo-secondary.png" style="width: 35px;">
            </div>
            <span _ngcontent-twy-c176="">People that follow you</span>
        </div>
    </div>
    <div class="col-md-6 srch">
        <input type="text" [(ngModel)]="search" value="search" (input)="performsearch()"/>
        <!-- <button (click)="performsearch()">Search</button> -->
    </div>
</div>


<div class="list mt-5" *ngIf="total!=0">
    <ul type="none" *ngFor="let item of data" > 
        <li style="cursor: pointer;" (click)="redirecttouser(item.username)">
            <div>
                <img class="img" [src]="setimage(item)" width="50px" height="50px"/>
            </div>
            <div class="user">
                <p class="username">@{{item.username}}</p>
            </div>
        </li>
    </ul>
</div>
<div *ngIf="total==0">
   <h5 class="empty">No Followers</h5> 
</div>
</div>