<!-- <h1 class="text-center">Welcome to Coupon Code Page. Coming soon !!!</h1> -->
<!-- <div>
  <ul class="name" *ngFor="let item of detailofloop">



    <li><button (click)="redirectto()" class="btn btn-theme text-right mx-2">View Profile </button></li>
    <li><button (click)="encryptUsingAES256again()" class="btn btn-theme text-right mx-2">Edit Profile </button></li>


    <li><button (click)="
        toredirect()" class="btn btn-theme text-right mx-2">Go Back </button></li>
  </ul>
</div> -->
<div class="page-actions">
  <div class="row">
    <!-- <div class="col-md-4 col-sm-5">
      <form class="search-group">
        <input type="search" placeholder="Search" [(ngModel)]="validationforsearch" aria-label="Search"
          name="searchsubscription" id="searchsubscription" aria-describedby="search-addon"
          class="form-control rounded form-control-sm">
        <button type="submit" (click)=" searchClick()" class="btn btn-theme">
          <i class="fa fa-search"></i>
        </button>
      </form>
    </div> -->
    <div class="col-xs-12">
      <div class="btn-group-right">
        <button (click)="redirectto()" class="btn btn-theme ">View Profile </button>
        <button (click)="encryptUsingAES256again()" class="btn btn-theme ">Edit Profile </button>
        <button (click)="
        toredirect()" class="btn btn-theme  ">Go Back </button>
      </div>
    </div>
  </div>
</div>


<div class="detail-view">
  <div class="card card-gray mt-3 mb-3">
    <div class="card-body">
      <div class="loop--details artist--details" *ngFor="let item of detailofloop">
        <!-- <fa name="cog" animation="spin"></fa> -->
        <div class="loop-artwork"><img alt="" [src]="artworkpathimages(item)"></div>
        <div class="loop-info-header">
          <div class="adm-user-profile">
            <h2>{{item.first_name}} <span *ngIf="item.last_name">{{item.last_name}}</span><span
                class="followers">{{item.number_of_followers}} Followers, {{item.number_of_following}} Following</span>
            </h2>

            <h4 class="mt-0"><a class="link" (click)="redirecttoprofilepage()">@{{item.username}}</a> <small
                class="ml-5">{{item.email}}</small></h4>
            <hr class="mt-2 mb-3">
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-6">
                <p><small class="d-block text-muted">No of Downloads</small>
                  <strong>{{item.number_of_downloads}}</strong></p>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-6">
                <p><small class="d-block text-muted">No of Uploads</small> <strong>{{item.number_of_uploads}}</strong>
                </p>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-6">
                <small class="d-block text-muted">Subscription type</small>
                <p *ngIf="item.is_premium_member == 0"><span class="badge">Free member</span></p>
                <p *ngIf="item.is_premium_member == 1"><span class="badge badge-golden"><i class="fa fa-star"></i>
                    Premium member</span></p>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-6">
                <small class="d-block text-muted">Account type</small>
                <p *ngIf="item.profile_type == 1"><span class="badge">Public</span></p>
                <p *ngIf="item.profile_type == 0"><span class="badge"><i class="fa fa-lock"></i> Private</span></p>
              </div>
            </div>
          </div>

          <!-- <div *ngIf="item.is_premium_member !== 1 && item.is_premium_member !== 1">Pending</div> -->





        </div>
      </div>
      <!-- <hr> -->

      <div class="tabs tabs-alt ng-star-inserted"><button [ngClass]="showuploadpage  ? 'active' : ''"
          (click)="uploadpage()">Uploads</button><button [ngClass]=" showdownloadpage ? 'active' : ''"
          (click)="downloadpage()">Downloads </button>

      </div>



      <div *ngIf="active">

        <app-downloads *ngIf="showdownloadpage" [items]="check" (messageEvent)="paginationget($event)"></app-downloads>
        <app-uploads *ngIf="showuploadpage" [items]="check" (messageEvent)="paginationget($event)"></app-uploads>
        <!-- <app-comments *ngIf="showcommentpage" [items]="check" (messageEvent)="paginationget($event)" (sendingsearchEvent)="searchClick($event)" ></app-comments>
          <app-likes *ngIf="showlikepage" [items]="check" (messageEvent)="paginationget($event)" (sendingsearchEvent)="searchClick($event)"></app-likes>
          <app-reportedlog *ngIf="showreportlog" [items]="check" (messageEvent)="paginationget($event)" (sendingsearchEvent)="searchClick($event)" (sortValueEvent)="sortOn($event)"></app-reportedlog>
          <app-reposts *ngIf="showrepostpage" [items]="check" (messageEvent)="paginationget($event)" (sendingsearchEvent)="searchClick($event)"></app-reposts> -->
        <!-- <app-usersearch *ngIf="usersearch" ></app-usersearch>
          <app-loopsearch (callparentFun)="parentFun()" *ngIf="loopsearch" [items]="check"></app-loopsearch>
          <app-loopkitsearch *ngIf="loopkitsearch" [items]="check"></app-loopkitsearch> -->
      </div>