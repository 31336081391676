import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgWaveformComponent } from 'ng-waveform';
import { $ } from 'protractor';

@Component({
  selector: 'app-waveform',
  templateUrl: './waveform.component.html',
  styleUrls: ['./waveform.component.css']
})
export class WaveformComponent implements OnInit {
  @Input()  arr:any;
  // @Input() audioSrc: string;
  @ViewChild('waveform', { static: false }) waveform: NgWaveformComponent;
  constructor() { }

  ngOnInit(): void {
  }

  onPlayButtonClick(i:any) {
    // //console.log(i);
    //this.waveform.pause();
    this.waveform.play();
    // pause other sounds
    // $("button:nth-child(2)")
    // let a: any = document.getElementsByClassName("ng-star-inserted ");
    //a.triggerHandler('click',);
    // //console.log(a);
    // a.onPauseButtonClick();
  }
  onPauseButtonClick(i:any) {
    // //console.log('pasue',i)
    this.waveform.pause();
  }

}
