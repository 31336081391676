<div class="title-header ">
  <div class="container ">
    <h3>About</h3>
  </div>
</div>
<div class=" content-section ">
  <div class="container">

    <div class="about-section">
      <div class="flex-row">
        <div class="col-md-5">
          <img src="assets/img/logo.png" class="aboutimage" >
        </div>
        <div class="col-md-7">
          <h4><span>Loophead</span> is a digital marketplace for producers to buy and sell loops. Our goal is to connect producers from all around the world and make the collaboration process simple and easy. Users can browse by instrument, genre, and tag to find exactly what they’re looking for. With Loophead, you will always have access to new content so you can consistently create and never have to worry about beat block.</h4>
        </div>
      </div>
    </div>
  </div>
</div>



