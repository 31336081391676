<div class="title-header">
    <div class="container">
        <h3>My Account</h3>
    </div>
</div>

<div class="account-wrapper">
    <div class="container">
        <div class="account-cols">
            <div class="account-sidebar">
                <div class="account-profile">
                    <img [src]="url" alt="" (click)="showLightbox(0)">
                    <ng-image-fullscreen-view [images]="imageObject" [imageIndex]="selectedImageIndex" [show]="showFlag"
                        (close)="closeEventHandler()"></ng-image-fullscreen-view>
                    <span class="edit-pImg image-upload"><label for="file-input"><i class="fa fa-edit"><input
                                    id="file-input" type="file" (change)="selectFile($event)"
                                    accept=".jpg,.jpeg,.png" /></i></label></span>
                    <div>
                        <small id="imageerror" class="text-danger confirmperror"></small>
                    </div>
                </div>
                <ul class="fLevel">

                    <li><a class="active"><i class="fa fa-user"></i> Profile</a>
                        <ul class="sLevel">
                            <li><a [routerLink]="['/user/my-account/profile']">My Account</a></li>
                            <li><a>Notifications</a></li>
                            <li><a [routerLink]="['/subscription-status']">Subscription Status</a></li>
                            <li><a>Payment Accounts</a></li>
                            <li><a>Legal</a></li>
                        </ul>
                    </li>

                    <li><a [routerLink]="['/user/my-account/my-uploads']" class=""><i class="fa fa-upload"></i> My
                            Uploads</a></li>
                    <li><a class=""><i class="fa fa-history"></i> My Download History</a></li>
                    <li><a class=""><i class="fa fa-power-off"></i> Logout</a></li>
                </ul>
            </div><!-- account-sidebar Ends -->
            <div class="account-rCol">
                <h3>Subscription Status</h3>
                <div class="card card-gray">
                    <div class="card-body">
                        <div class="current-plan">
                            <h6 class="active-plan">Your Plan</h6>
                            <h4>Loophead {{subscription_type}}</h4>
                            <h4 *ngIf="premiumshow">${{premiumpriceshow}}/Month</h4>
                            <h4 *ngIf="free">$ 0.00/Month</h4>
                            <p *ngIf="premiumshow">Your plan will automatically renew on <b>{{subscription_expiry |  date: 'dd/MM/yyyy '}} </b> and you will
                                be charged <b>${{premiumpriceshow}}</b> </p>
                            <p *ngIf="free"> you are currently using Loophead's Free plan </p>
                        </div>
                    </div><!-- Card Body Ends -->
                </div> <!-- Card Ends -->
                <h4 class="text-center mt-3">Change Plan</h4>

                <div class="card card-gray" *ngIf="premiumshow">
                    <div class="card-body">
                        <div class="alt-plan">
                            <h4>Loophead Free</h4>
                            <p class="text-muted small mt-2">Limited Access</p>
                            <ul>
                                <li>
                                    <p>Upload Up To {{loops_to_upload_allowed}} Loops</p>
                                </li>
                                <li>
                                    <p>Upload Up To {{already_uploaded_loops}} Loop Kits</p>
                                </li>
                                <li>
                                    <p>Can't Sell Single Loops</p>
                                </li>
                            </ul>
                            <div class="action-right">
                                <button  *ngIf="!hidecancelbutton" class="btn btn-theme" (click)="cancelapirun()">Cancel Premium</button>
                            </div>
                        </div>
                    </div><!-- Card Body Ends -->
                </div><!-- Card Ends -->
                <div>

                    <div class="card card-gray" *ngIf="free">
                        <div class="card-body">
                            <div class="alt-plan">
                                <h4>Loophead Premium</h4>
                                <p class="text-muted small">
                                    Unlimited Access*
                                </p>
                                <ul>
                                    <li>
                                        <p>Upload Unlimited Loops</p>
                                    </li>
                                    <li>
                                        <p>Upload Unlimited Loop Kits </p>
                                    </li>
                                    <li>
                                        <p>Sell Unlimited Loops and Loop Kits</p>
                                    </li>
                                    <li>
                                        <p>Accept PayPal Payments</p>
                                    </li>
                                </ul>
                                <div class="action-right">
                                    <h4>${{premiumpriceshow}}/Month</h4>
                                    <p><b>${{premiumpriceshowyearly}}</b> if paid anually</p>
                                    <button class="btn btn-theme" (click)="routing()">Subscribe Premium</button>
                                </div>
                            </div>
                        </div><!-- Card Body Ends -->
                    </div> <!-- Card Ends -->
                </div>
            </div><!-- account-rCol Ends -->
        </div><!-- account-cols Ends -->
    </div><!-- Container Wrapper Ends -->
</div><!-- Accounts Wrapper Ends -->