import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { APIURL } from 'src/app/allServices/constant.service';
import { Adminvariables } from '../globaladminvariable';
import { commonservice } from 'src/app/allServices/commonservice';

@Component({
  selector: 'app-adminprofile',
  templateUrl: './adminprofile.component.html',
  styleUrls: ['./adminprofile.component.css']
})


export class AdminprofileComponent implements OnInit {

  emailerror = "";
  phonenoerr = "";
  firstnameerror = "";
  secondnameerror = "";
  Passworderror = "";
  cpassworderror = "";
  isemailvalidate = false;
  isphonevalid = false;
  isfirstnamevalidate = false;
  issecondnamevalidate = false;
  ispasswordvalidate = false;
  isconfirmpasswordvalidate = false;
  // isimagevalidate = true;
  // imageinfo = "Only jpeg,png,jpg files are allowed";
  // text = "info";




  validation = {
    emailcheck: '',
    isvalid: true
  }

  validationforphoneno = {
    phonecheck: '',
    isphone: true
  }

  validationfirstname = {
    firstnamecheck: '',
    isfirstname: true
  }

  validationsecondname = {
    secondnamecheck: '',
    issecondname: true
  }


  validationpassword = {
    passwordecheck: '',
    ispasswordcheck: true
  }

  validationconfirmpassword = {
    confirmpasswordcheck: '',
    isconfirmpasswordcheck: true
  }

  validationforcreateddate = {
    datecheck: ''
  }

  validationformodifieddate = {
    datecheckd: ''
  }

  createdate: any = "";
  modifidate: any = "";

  url = "assets/img/images.png";
  ownerName:any = Adminvariables.ownername

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private datePipe: DatePipe,public common: commonservice) {
    
  }
  ngOnInit(): void {
    this.getapicallforupdate();
  }



  getapicallforupdate() {
    this.common.showLoader();
    let tokkenget = localStorage.getItem("loggedintoken");

    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    this.http.get(APIURL.BASE_URL+APIURL.GET_ADMINPROFILE_DETAILS, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();
        // //console.log(this.common.eventPublish('_ADMINUPDATE_profile'))
        localStorage.setItem("adminprofiledetail", JSON.stringify(res.response.data))
        this.common.eventPublish('_ADMINUPDATE_profile');
        Adminvariables.ownername = res.response.data.first_name +" "+ res.response.data.last_name;
        // //console.log(Adminvariables.ownername,"owner name");
        this.validationfirstname.firstnamecheck = res.response.data.first_name;
        this.validationsecondname.secondnamecheck = res.response.data.last_name;
        this.validation.emailcheck = res.response.data.email;
        this.validationforphoneno.phonecheck = res.response.data.phone;
        // const date = new Date(res.response.data.created_on).toISOString().slice(0, 10);
        // const time = new Date(res.response.data.created_on);
        let createddate = moment(res.response.data.created_on).format('DD-MM-YYYY h:mm A');
        // //console.log(createddate);
        // let create = moment("2021-04-29T04:30:59.000Z").format('DD-MM-YYYY h:mm A');
        // //console.log(create);
        // //console.log(time.toLocaleTimeString());
        // //console.log(date);
        // const date = new Date(res.response.data.created_on);
        // this.createdate = this.datePipe.transform(date, 'YY-MM-yyyy hh:mm a');
        // this.createdate = this.datePipe.transform(date, 'YY-MM-yyyy hh:mm a');
        this.validationforcreateddate.datecheck = createddate;

        // //console.log(this.createdate);
        // //console.log(res.response.data.created_on);

        if (res.response.data.modified_on == null) {
          this.validationformodifieddate.datecheckd = "";
        } else {
          // const moddate = new Date(res.response.data.modified_on);
          let modifieddate = moment(res.response.data.modified_on).format('DD-MM-YYYY h:mm A');
          // const datemo =new Date(res.response.data.modified_on).toISOString().slice(0,10); 
          // this.modifidate = this.datePipe.transform(moddate, 'YY-MM-yyyy hh:mm a');
          this.validationformodifieddate.datecheckd = modifieddate;
        }
        // //console.log(this.modifidate);
        // //console.log(res.response.data.modified_on);


        // let resdata = res.response.data;
        // //console.log(resdata.length);
        // //console.log(res);
        // let arr = [];
        // for (var j = 1; j < resdata.length; j++) {

        //   // let jk = error.push(errorthroughapi);
        //   // this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        //   arr.push(res.response.data[j].meta_value);
        //   //console.log(res.response.data[j].meta_value);
        // }


        // //console.log(arr);

        // this.validateemail.emailcheck = res.response.data[1].meta_value,
        //   this.validatetopchart.topchartcheck = res.response.data[2].meta_value,
        //   this.validationinstagram.instagramcheck = res.response.data[3].meta_value,
        //   this.validationtwitter.twittercheck = res.response.data[4].meta_value,
        //   this.validationdiscord.discordcheck = res.response.data[5].meta_value,
        //   this.validaterecommendloop.loopcheck = res.response.data[6].meta_value

      } else {
        this.common.hideLoader();
        // //console.log(res.response.errors);
        // this.toastr.error(res.response.msg, "Error", { positionClass: 'toast-bottom-right' });
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          // let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();
      // //console.log('Error', e);
      this.toastr.error("Error in Api", "Error", { positionClass: 'toast-bottom-right' });

    });
  }


  apicallforupdate() {

    let tokkenget = localStorage.getItem("loggedintoken");

    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    let sendingData = {
      "first_name": this.validationfirstname.firstnamecheck,
      "last_name": this.validationsecondname.secondnamecheck,
      "email": this.validation.emailcheck,
      "phone": this.validationforphoneno.phonecheck,
      // "password": this.validationpassword.passwordecheck,
      // "confirm_new_password": this.validationconfirmpassword.confirmpasswordcheck


    }




    this.http.post(APIURL.BASE_URL+APIURL.ADMIN_PROFILE, sendingData, { headers }).subscribe(async (res: any) => {
      // this.disabledvalue = false;
      if (res.response.status == 1) {
        // //console.log(res);
        // this.displayname = "none";
        let successmess = res.response.msg;
        
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        this.getapicallforupdate();
        // //console.log(res.response.msg);
        // let successmessage = [];
        // for (var i = 0; i < successmess.length; i++) {
        //   let pushintoarray = successmessage.push(successmess);

        // }
        // this.displayname = "none";
        // this.submitbutton.submitresponse = "Save changes";
        // this.submitbutton.iswaitingoption = false;


      } else {
        // this.displayname = "none";
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

        // this.displayname = "none";
        // this.submitbutton.submitresponse = "Save changes";
        // this.submitbutton.iswaitingoption = false;
      }
    }, async e => {
      // this.displayname = "none";
      // //console.log('Error', e);
      this.toastr.error("(API error)", "", { positionClass: 'toast-bottom-right' });
    });
  }






  // for image validation 
  // selectFile(event: any) {
  //   if (event.target.files[0] == undefined) {
  //     this.url = "assets/img/images.png";
  //   } else {
  //     if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpg') {

  //       if (event.target.files[0].size > 10000000) {
  //         this.imageinfo = "image size should not be more than 10mb";
  //         this.text = "danger";
  //         this.isimagevalidate = false;

  //       } else {
  //         if (event.target.files) {
  //           var reader = new FileReader()
  //           reader.readAsDataURL(event.target.files[0])
  //           reader.onload = (event: any) => {
  //             this.url = event.target.result;
  //             this.isimagevalidate = true;
  //           }
  //         }
  //       }
  //     } else {
  //       this.imageinfo = "image file should be in jpeg,png,jpg";
  //       this.text = "danger"
  //       this.isimagevalidate = false;
  //     }
  //   }


  // }


  onupdate() {


    // Email validation 

    if (this.validation.emailcheck.length !== 0) {
      const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (this.validation.emailcheck.match(mailformat)) {
        this.validation.isvalid = true
        this.emailerror = "";
        this.isemailvalidate = true;
      } else {
        this.validation.isvalid = false;
        this.emailerror = "Please Enter Valid Email";
        this.isemailvalidate = false;
      }
    } else {
      this.validation.isvalid = false;
      this.emailerror = "Email Is Required";
      this.isemailvalidate = false;
    }


    // Phone no validation 

    if (this.validationforphoneno.phonecheck.length !== 0) {
      const phoneformat = /^[6-9]\d{9}$/;
      if (phoneformat.test(this.validationforphoneno.phonecheck)) {
        this.validationforphoneno.isphone = true
        this.phonenoerr = "";
        this.isphonevalid = true;
      } else {
        this.validationforphoneno.isphone = false;
        this.phonenoerr = "Please Enter Valid Phone No";
        // //console.log("no");
        this.isphonevalid = false;
      }
    } else {
      this.validationforphoneno.isphone = false;
      this.phonenoerr = "Phone No Is Required";
      this.isphonevalid = false;
    }

    //  Firstname validation 

    if (this.validationfirstname.firstnamecheck.length !== 0) {

      this.validationfirstname.isfirstname = true;
      this.firstnameerror = "";

      this.isfirstnamevalidate = true;

    } else {
      this.validationfirstname.isfirstname = false;
      this.firstnameerror = "First Name Is Required";
      this.isfirstnamevalidate = false;
    }

    //  Last name validation 
    if (this.validationsecondname.secondnamecheck.length !== 0) {

      this.validationsecondname.issecondname = true;
      this.secondnameerror = "";
      this.issecondnamevalidate = true;

    } else {
      this.validationsecondname.issecondname = false;
      this.secondnameerror = "Last Name Is Required";
      this.issecondnamevalidate = false;
    }

    // password validation 

    if ((this.validationpassword.passwordecheck.length == 0) && (this.validationconfirmpassword.confirmpasswordcheck.length == 0)) {
      this.ispasswordvalidate = true;
      this.isconfirmpasswordvalidate = true;
      this.Passworderror = "";
      this.cpassworderror = "";
      // //console.log("have no length  pass");
    } else {
      // //console.log("in");
      if ((this.validationpassword.passwordecheck.length === 0) && (this.validationconfirmpassword.confirmpasswordcheck.length !== 0)) {
        this.ispasswordvalidate = false;
        this.validationpassword.ispasswordcheck = false;
        this.Passworderror = "Password Is Required";
        this.cpassworderror = "";
        // //console.log("no length in password");
      }
      else if ((this.validationpassword.passwordecheck.length !== 0) && (this.validationconfirmpassword.confirmpasswordcheck.length === 0)) {
        this.isconfirmpasswordvalidate = false;
        this.Passworderror = "";
        this.cpassworderror = "Confirm Password Is Required";
        this.validationconfirmpassword.isconfirmpasswordcheck = false;
      } else if (this.validationpassword.passwordecheck.length < 6) {
        this.ispasswordvalidate = false;
        this.validationpassword.ispasswordcheck = false;
        this.Passworderror = "Password must be at least 6 digit";
        this.cpassworderror = "";

      }
      else if (this.validationconfirmpassword.confirmpasswordcheck.length < 6) {
        this.isconfirmpasswordvalidate = false;
        this.Passworderror = "";
        this.cpassworderror = "Confirm Password must be at least 6 digit";
        this.validationconfirmpassword.isconfirmpasswordcheck = false;

      }
      else if ((this.validationpassword.passwordecheck) === (this.validationconfirmpassword.confirmpasswordcheck)) {
        this.ispasswordvalidate = true;
        this.isconfirmpasswordvalidate = true;
        this.Passworderror = "";
        this.cpassworderror = "";

      }
      else {
        this.ispasswordvalidate = false;
        this.isconfirmpasswordvalidate = false;
        this.validationpassword.ispasswordcheck = false;
        this.validationconfirmpassword.isconfirmpasswordcheck = false;
        this.Passworderror = "Password And Confirm Password Must Be Same";
        this.cpassworderror = "Password And Confirm Password Must Be Same";
      }
    }






    if ((this.isemailvalidate == true) && (this.isphonevalid == true) && (this.issecondnamevalidate) && (this.isfirstnamevalidate) && (this.ispasswordvalidate == true) && (this.isconfirmpasswordvalidate == true)) {
      // this.toastr.success("api will hit", "Success", { positionClass: 'toast-bottom-right' });
      this.apicallforupdate();

    } else {
      // this.toastr.error("api will not hit", "Error", { positionClass: 'toast-bottom-right' });
    }


  }
}
