



import { Component, Input, OnInit } from '@angular/core';

import { environment } from './../../../sevicesall/alldata';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from './../../../allServices/api-service.service';
import { commonservice } from './../../../allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from './../../../allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { ConfirmDeleteComponent } from './../../../CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-loopkitsearch',
  templateUrl: './loopkitsearch.component.html',
  styleUrls: ['./loopkitsearch.component.css']
})
export class  LoopkitsearchComponent implements OnInit {
  @Input() items: any;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  hidepagination = false;
  norecordfound: any;
  sorttype = "";
  sorton = "";
  imageofcomment: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  value: any;
  encryptedagainremoveslashes: string;
  tablefortagsdisplay1: any;
  tablefortagsdisplay2: any;
  tablefortagsdisplay3: any;
  time: string;
  addred: boolean;
  addgreen: boolean;
  durationofaudio: any;
  above15second = false;
  apihitforstream = true;
  above5secondlessthan15 = false;
  above1secondlessthan5 = false;
  id: any;
  nocomments: boolean;
  src: any;
  pagenocount: any;
  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService, private matDialog: MatDialog) { }

  
  ngOnInit(): void {
    this.value = this.route.snapshot.queryParamMap.get('user_id');
    this.encryptedagainremoveslashes = decodeURIComponent(this.value);
    this.decryptUsingAES256();
    this.run(this.items);
    
  }

  ngOnDestroy() {
    this.stopAudio();
  }
  run(res:any) {
    if (res.response.data.total_records == 0) {
      this.norecordfound = true;
    } else {
      this.norecordfound = false;
      // this.callforallinfo(res);
    }

    if (res.response.data.total_pages == 1) {
      this.hidepagination = true;
      // //console.log("hide");
    } else {
      this.hidepagination = false;
      // //console.log("not hide");
    }
    if (this.paginationempty == true) {
      this.pagercount = [];
    }
    this.total = res.response.data.total_records;

    this.tableData = res.response.data.pagging_list;

    this.record = this.tableData.length;
    if (this.record == 0) {
      this.nocomments = false;
    } else {
      this.nocomments = true;
    }
    this.pagenolist = res.response.data.total_pages + 1;

    this.record = this.pageno - 1 + "1";
    // //console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
    this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length

    this.tableData = res.response.data.pagging_list;
    // this.record = this.tableData.length;
    this.pagenolist = res.response.data.total_pages + 1;
    if (this.record == "01") {
      this.record = 1;
    }
    for (let i = 1; i < this.pagenolist; i++) {
      this.pagercount.push(i);
    }
    this.total = res.response.data.total_records;
  }

  



       
          

     




  srcforsound(items: any) {
    if (items.loop_type == 1) {
      if (items.tagged_loop_path !== null) {
        this.durationofaudio = items.tagged_loop_duration;
        return CONSTNAME.fILE_PATH + items.tagged_loop_path;
        // //console.log("checking");
      } else {
        this.durationofaudio = items.loop_duration;
        return CONSTNAME.fILE_PATH + items.loop_path;
      }

      // //console.log(this.src);
    } else {
      this.durationofaudio = items.tagged_loop_duration;
      return CONSTNAME.fILE_PATH + items.tagged_loop_path;
      // //console.log(this.src);
    }
  }



  redirecttoloopdetailpage(items: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(items.loop_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
  }














  detailpageofloop(value: any) {

    this.encryptUsingAES256(value);

  }


  paginationget(pageinfo: any) {
    this.paginationempty = true;
    this.pageno = pageinfo;

    if (this.route.snapshot.queryParamMap.get('search')) {
      // this.searchApi()
    } else {
     
    }


  }

  // routerLink=""
  toreportedloops() {

    this.router.navigate(['/admin/loops/reported-loops']);
  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
  }

  // [routerLink]="['/admin/loops/detail-uploads/'+item.loop_id]"

  addcolor(item: any) {
    if (item == this.pageno) {
      return 'activated';
    } else {
      return ''
    }
  }


  setimage(data: any) {
    if (data.artwork_path == null) {
      if (data.profile_image_path == null) {
        return this.profile_image_pathofcomments;

      } else {
        return CONSTNAME.fILE_PATH + data.profile_image_path;
      }

    } else {

      return CONSTNAME.fILE_PATH + data.artwork_path;
    }
  }




  async heartcolorred(value: any, detail: any, item: any) {

    let sendingData = {
      "loop_id": value,
    }
    // if (item.is_liked == 1) {
    //   item.is_liked = 0;
    // } else if (item.is_liked == 0) {
    //   item.is_liked = 1;
    // }



    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      this.common.showLoader();
      this.api.postApiHit(`/user/like_dislike_upload`, sendingData).then((res: any) => {
        if (res.response.status == 1) {
          // let v: any = document.getElementById("red");
          // v.classList.add("red");
          // this.getapicallforcouponcode();
          this.common.hideLoader();

          // if (item.user_id !== t) {
            
          // }
          if (item.is_liked == 1) {
            item.is_liked = 0;
            
            return ((item.number_of_likes)=item.number_of_likes - 1);
          } else if (item.is_liked == 0) {
            item.is_liked = 1;
     
            return ((item.number_of_likes)=item.number_of_likes + 1);
          }


         
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];

          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }


        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];

          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async (e: any) => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.opendialogueforverification();
      // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      // this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
    }

  }


  decryptUsingAES256() {

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    // //console.log(this.decrypted);
    // this.decrypted = this.decrypted.replace(/[""]/gi, '');
    // //console.log(this.decrypted);


  }


  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "detailpage" } });
    dialogue.afterClosed().subscribe(result => {



      if (result.data == true) {
        this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
        this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
        // this.router.navigate(['/users/uploadloopstatus']);
      } else {

      }

    });
  }

  addredcolor(item: any) {
    // //console.log("in");
    if (item.is_liked == 1) {
      return "red";
    }
  }
  addgreencolor(item: any) {
    if (item.is_reposted == 1) {
      return "green";
    }
  }

  repostnumber(item: any) {
    return item.number_of_reposts
  }
  likesnumber(item: any) {
    return item.number_of_likes
  }

  async repost(value: any, item: any) {

    
    let sendingData = {
      "loop_id": value,
    }



    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {


      this.common.showLoader();
      this.api.postApiHit(`/user/repost_upload`, sendingData).then((res: any) => {
        if (res.response.status == 1) {
          // this.getapicallforcouponcode();

          this.common.hideLoader();

          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          // this.getallinfoofcomments();
          if (item.is_reposted == 1) {
            item.is_reposted = 0;
            
            return ((item.number_of_reposts)=item.number_of_reposts - 1);
          } else if (item.is_reposted == 0) {
            item.is_reposted = 1;
     
            return ((item.number_of_reposts)=item.number_of_reposts + 1);
          }
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();

          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
          this.router.navigate(['/users/uploadloopstatus']);

        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async (e: any) => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.opendialogueforverification();


    }
  }

  async redirecttolooppagecomment(value: any) {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    if (tokkenget) {
      this.redirecttolooppage(value)
    } else {
      this.opendialogueforverification();
    }

  }

  redirecttolooppage(value: any) {


    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);


    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });



  }


  async apihitforstreaming(item: any, r: any, dura: any) {

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }

    let sendingdata = {
      "audio_duration": dura,
      "loop_id": item.loop_id,
      "play_duration": r
    }
    this.http.post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      }

      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }


    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  playAudio(item: any) {

    this.tableData.map((r: any) => {
      r.hidepausebutton = false;
    })
    this.tableData.map((r: any) => {
      if (r.audio) r.audio.pause()
    })




    if (!item.audio) {
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      // if (!item.duration) {
      //   let sound: any;
      //   sound = await new Promise((resolve, reject) => {
      //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
      //   });
      // }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      item.audio.play();

      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true

      }
      else if (item.duration < 15 && item.duration >= 5) {

        this.apihitforstream = true
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {

        this.apihitforstream = true
        this.above1secondlessthan5 = true;
      }
    } else {
      item.audio.play();
    }





    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      if (r === item.duration || r > item.duration) {

        setTimeout(() => {

        }, 1000);
      }

      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;

          }

        }
      } else if (item.duration < 15 && item.duration >= 5) {

        if (r > 5) {

          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;

          }
        }


      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;

          }

        }
      }
    }, 200);

  }

  stopAudio(item: any = null) {
    if (item) {
      if (item.audio) {
        item.audio.pause()
      }
      clearInterval(this.id);

      this.tableData.map((r: any) => {
        r.hidepausebutton = false;
      })

    } else {
      this.tableData.map((r: any) => {
        if (r.audio) {
          r.audio.pause()
        }
        r.hidepausebutton = false;
      })

    }


  }

  onRegionChange(value: any = null) {
    // //console.log(value);
  }
  onTrackLoaded(v: any = null) {
    // //console.log("track");
    // //console.log(v);

  }
  onTrackRendered(v: any = null) {
    // //console.log("trackren");
    // //console.log(v);
  }

  audio_duration: any;
  play_duration: any;
  onDurationChange(value: any = null) {
    if (value == 0) {

    } else {
      this.audio_duration = value;
      // this.totaltime = (Math.round( value* 100) / 100).toFixed(2);

    }
    // //console.log(this.audio_duration);

    if (this.durationofaudio >= 15) {
      this.above15second = true;
      // //console.log("above 15 sec");
    }
    else if (this.durationofaudio < 15 && value >= 5) {
      // //console.log("between 5 and 15");
      this.above5secondlessthan15 = true;
    } else if (this.durationofaudio > 1 && value <= 5) {
      // //console.log("between 1 and 5");
      this.above1secondlessthan5 = true;
    }
    // //console.log(this.durationofaudio);
    // if (value >= 15) {
    //   this.above15second = true;
    //   // //console.log("above 15 sec");
    // }
    // else if (value < 15 && value >= 5) {
    //   // //console.log("between 5 and 15");
    //   this.above5secondlessthan15 = true;
    // } else if (value > 1 && value <= 5) {
    //   // //console.log("between 1 and 5");
    //   this.above1secondlessthan5 = true;
    // }
  }



  onTimeUpdate(v: any = null) {

    if (v.progress) {

      // //console.log("time", v.time);
      // this.currenttime = (Math.round( v.time* 100) / 100).toFixed(2); 
      if (v.progress >= 100) {
        // //console.log("complete")
        // this.hidepausebutton = true;
        // this.hideplaybutton = false;
        setTimeout(() => {
          this.apihitforstream = true;
          // this.currenttime = "0.00";
        }, 1000);
        setTimeout(() => {

          // this.currenttime = "0.00";
        }, 10);


      }
      if (this.above15second) {
        if (v.time >= 15) {

          // this.apihitforstream = true;
          // alert("15 second is passed");
          if (this.apihitforstream == true) {
            // //console.log(v.time);
            this.play_duration = v.time
            // //console.log("api will hit");
            // this.apihitforstreaming();
            this.apihitforstream = false;
          }

        }
      } else if (this.above5secondlessthan15) {
        if (v.time >= 5) {
          if (this.apihitforstream == true) {
            // //console.log(v.time);
            this.play_duration = v.time
            // //console.log("api will hit");
            // this.apihitforstreaming();
            this.apihitforstream = false;
          }
        }

      } else if (this.above1secondlessthan5) {
        if (v.time >= 1) {
          if (this.apihitforstream == true) {

            this.play_duration = v.time

            // this.apihitforstreaming();
            this.apihitforstream = false;
          }
        }

      }




    }



  }

  timeget(items: any) {
    // //console.log("great ", items)
    let date_future: any = new Date();
    let date_now: any = new Date(items.modified_on);




    let time = date_future - date_now;

    let seconds = moment.duration(time).seconds();
    let minutes = moment.duration(time).minutes();
    let hours = moment.duration(time).hours();
    let days = moment.duration(time).days();


    // //console.log(time,seconds, minutes , hours , days , date_future , date_now)




    if (seconds < 60 && minutes === 0) {
      // //console.log(seconds)
      return "Posted " + seconds + " sec" + " ago"
    }
    else if (minutes < 60 && hours === 0) {

      return "Posted " + minutes + " min" + " ago"

    }
    else if (hours == 1 && days === 0) {
      return "Posted " + hours + " hr" + "ago"
    }
    else if (hours <= 24 && days === 0) {
      return "Posted " + hours + " hrs" + " ago"
    } else if (days === 1) {
      return "Posted " + days + " day" + " ago"

    }
    else if (days > 1) {
      // //console.log(days)
      return "Posted " + days + " days" + " ago"
    }
  }



  priceget(item: any) {
    if (item.loop_price == 0) {

    } else {
      return (Math.round(item.loop_price * 100) / 100).toFixed(2);

    }
  }
  onPaused(v: any = null) {
    // //console.log("pause");
  }
  sendingstrtag1:any
  sendingstrtag2:any
  sendingstrtag3:any

  tagsget(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    let strtag1: any = strtag[0];
    let strtag2: any = strtag[1];
    let strtag3: any = strtag[2];
    // this.sendingstrtag1 = strtag[0];
    // this.sendingstrtag2 = strtag[1];
    // this.sendingstrtag3 = strtag[2];
    // //console.log(strtag1);
    // //console.log(strtag2);
    // //console.log(strtag3);
    return "#" + strtag1;

  }
  tagsget2(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');

    let strtag2: any = strtag[1];
    return "#" + strtag2
  }
  tagsget3(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    //  //console.log(strtag[2])
    let strtag3: any = strtag[2];
    // //console.log(strtag3);
    return "#" + strtag3
  }

  redirecttoprofilepage(item: any) {
    this.router.navigate(['user/'], { queryParams: { user_id: this.value } });
  }

  redirectto1(items:any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    this.sendingstrtag1 = strtag[0];
    // //console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag1} });
    //console.log(this.sendingstrtag1)
    
  }
  redirectto2(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    this.sendingstrtag2 = strtag[1];
    // //console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag2 } });
    // //console.log(item)
    //console.log(this.sendingstrtag2)
    
  }
  redirectto3(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    this.sendingstrtag3 = strtag[2];
    // //console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag3 } });
    // //console.log(item)
    //console.log(this.sendingstrtag3)
    
  }
  forall(items:any) {
    //console.log("Aa",items)
  }


  
  

}

