import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, INPUT_TYPE_NAME, VARS } from 'src/app/allServices/constant.service';
import { StaticService } from 'src/app/sevicesall/static.service';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-add-banned',
  templateUrl: './add-banned.component.html',
  styleUrls: ['./add-banned.component.css']
})
export class AddBannedComponent implements OnInit {
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  name: any;
  bannedwordform = StaticService.bannnedwordsadmin();
  sendingdata: any;
  noteditableform: any = true;
  value: any;
  encryptedagainremoveslashes: string;
  
  
  constructor(private formbuilder: FormBuilder, public common: commonservice, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService,) {
  
    this.encrypted = this.route.snapshot.params['id'];
    this.encryptedagainremoveslashes = decodeURIComponent(this.encrypted);
    this.decryptUsingAES256();
    if (this.route.snapshot.params['id'] !== "new") {
      this.name = "Edit";
      this.getapicallforcouponcode();
      // //console.log("old page ha");
    } else {
      this.name = "Add";
      // //console.log("new page ha");
     
    }
  

  }
  ngOnInit(): void {
    let left_sidebar:any = document.getElementsByClassName('sidebar-menu');
    if (left_sidebar.length > 0) {
      left_sidebar = left_sidebar[0];
      left_sidebar.querySelector('[href="/admin/bannedwords"]').parentElement.classList.add("active", 'menu-open', 'custom-active');
    }
  }
  decryptUsingAES256() {
    // //console.log(this.encryptedagainremoveslashes);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

  }



  vCheck(fieldName: any, type: INPUT_TYPE_NAME, options: any = {}) {
    options.isRequired = true;
    const cFrm = this.bannedwordform.controls;
    if (cFrm) {
      return StaticService.formError(cFrm, fieldName, type, options).msg;
    }
  }
  apihit = false;
  async bannedwordsubmit() {
    
    // const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    // StaticService.markFormGroupTouched(this.couponcodeform.controls, invalidElements);
    this.bannedwordform.markAllAsTouched();
    // //console.log(this);
    let validfrom = moment(this.bannedwordform.value.couponstartdate).format('DD-MM-YYYY');
    let validto = moment(this.bannedwordform.value.couponendenddate).format('DD-MM-YYYY');


    this.sendingdata = {

      "banned_word": this.bannedwordform.value.bannedwords,

    }
    if (this.route.snapshot.params['id'] !== "new") {
      this.sendingdata["banned_word_id"] = this.decrypted;

    } else {

    }


    if (this.bannedwordform.valid && !this.apihit) {
      this.apihit = true;
      this.common.showLoader();


      this.api.postApiHit(APIURL.ADMIN_GET_ALL_Addbannedwords, this.sendingdata, VARS.ADMIN_TYPE).then((res: any) => {

        this.common.hideLoader();
        // this.common.showToast(res.response.msg?res.response.msg:res.response.errors[0], res.response.status);
        if (res.response.status) {
          this.router.navigate(['/admin/bannedwords']);
          // //console.log(res);
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        } else {
          this.common.hideLoader();
          let errorthroughapi = res.response.errors;
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }

        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });


    }



  }


  cancelandredirect() {
    this.router.navigate(['/admin/bannedwords']);
  }



  getapicallforcouponcode() {
    this.common.showLoader();
    // //console.log(this.decrypted);
    this.noteditableform = false;
    // let Idfromurl = this.route.snapshot.params['id'];
    let sendingdata = {
      "banned_word_id": this.decrypted
    }
    this.api.postApiHit(`/admin/get_banned_word_detail`, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {

      if (res.response.status == 1) {
        this.common.hideLoader();
        // //console.log(res);

        let createddate = moment(res.response.data[0].created_on).format('DD-MM-YYYY h:mm A');
        let modifieddate = moment(res.response.data[0].modified_on).format('DD-MM-YYYY h:mm A');
        // //console.log(modifieddate);
        if (res.response.data[0].modified_on == null) {
          modifieddate = "";
        }


        // //console.log(couponenddate);
        this.bannedwordform.patchValue({
          bannedwords: res.response.data[0].banned_word,

          createddate: createddate,
          modifieddate: modifieddate

        });

      } else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }

    }, async e => {
      // this.common.hideLoader();
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });

  }

  


}
