<div class="main-share"> 
        <div (click)="cancel()" style="background-color: transparent; color: gray; display: flex;cursor: pointer;">
        <img style="height: 30px;" src="../../../../assets/img/x-close.svg"/>
         </div>
         <div class="body">
            <div>
            <img style="width: 35px" src="../../../../assets/img/logo.png" />
            <img style="width: 35px; filter: invert(96%) sepia(53%) saturate(2%) hue-rotate(209deg) brightness(0%) contrast(100%);
            fill:black;" src="../../../../assets/img/logo-secondary.png" />
        </div>
         <h6 class="share-modal-heading">Share</h6>
         <p>Click ‘copy link’ to copy this {{data.data}} to your clipboard</p>
         <a>{{link}}</a>
         <button class="btn" (click)="copyToClipboard(link)">Copy Link</button>
        </div>
</div>