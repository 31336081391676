import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { APIURL, VARS } from '../allServices/constant.service';
import { ApiServiceService } from '../allServices/api-service.service';
import { commonservice } from '../allServices/commonservice';

@Injectable({
  providedIn: 'root'
})
export class UserauthGuard implements CanActivate {
  constructor(public common: commonservice,public api: ApiServiceService,public router: Router) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let b = localStorage.getItem("clientintoken");
      //console.log('hello');
      
      this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
        if (res.response.status) {
          this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
          this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
            // let resa = JSON.parse(res)
            
            if(res){
              return true;

            } else {
        
        this.common.clearAllLocalAndSession();
        this.router.navigate(['/login']);
              return false;
            }
            
          })
        
        }else{
        // this.common.clearAllLocalAndSession();
        // this.router.navigate(['/login']);
          return false; 
        }
      })
      if (!b) {
      //  //console.log('jihi');
       
        this.router.navigate(['/login']);
        
        return false;
      } else {
        
              return true;
      }
  }
  
}
