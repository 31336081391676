<!-- <div class="hold-transition login-page">





    <span class="loader d-none" id="divLoading" [ngStyle]="{'display': displayname}">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>

    </span>




    <div class="login-box">

        <div class="login-logo">
            <a class="testing"><b>Log in</b></a>
        </div>


        <!-- <form [formGroup]="loginformforloophead">
            <div class="form-group col-lg-12 ">
                <label for="email">Username or Email </label><span class="mx-4 text-danger">*</span>
                <input type="email" formControlName="emailLoginpage" class="form-control " aria-describedby="emailHelp"
                    placeholder="Username or Email">

                <small class="form-text text-danger"
                    *ngIf="loginformforloophead.get('emailLoginpage')?.touched && loginformforloophead.get('emailLoginpage')?.hasError('required')">Email
                    is required</small>
                <small class="form-text text-danger"
                    *ngIf="loginformforloophead.get('emailLoginpage')?.touched && loginformforloophead.get('emailLoginpage')?.hasError('email')">Enter
                    valid email address</small>
            </div>



            <div class="form-group col-lg-12 ">
                <label for="password">Password</label><span class="mx-4 text-danger">*</span>
                <input type="password" class="form-control " formControlName="passwordLoginpage" placeholder="Password"
                    name="password" name="password">
                <span style="display: flex; justify-content: space-between; flex-direction: column;">
                    <small class="form-text text-danger"
                        *ngIf="loginformforloophead.get('passwordLoginpage')?.touched && loginformforloophead.get('passwordLoginpage')?.hasError('required')">Password
                        is required</small>
                    <small class="form-text text-danger"
                        *ngIf="loginformforloophead.get('passwordLoginpage')?.touched && loginformforloophead.get('passwordLoginpage')?.hasError('minlength')">Minimum
                        6 characters required</small>
                    <small class="form-text text-danger"
                        *ngIf=" loginformforloophead.get('passwordLoginpage')?.hasError('pattern')">Password should
                        include at
                        least
                        one uppercase letter and one special character</small>
                </span>

            </div>

            <div class="form-group col-lg-12 flex">

                <div>
                    <input type="checkbox"  name="remember" id="remember">
                    <label for="remember"> Remember me</label>

                </div>
                <div>
                    <a mat-button (click)="openDialog()" >forgot password?</a>
                </div>

            </div>
            <div class="form-group col-lg-12 col-md-6">
                <button type="button" class="btn btn-theme" (click)="onSubmit()">login</button>
            </div>
            

        </form> -->

        <!-- <form (ngSubmit)="login()" [formGroup]="loginForm">
            <input type="email" formControlName="email" class="form-control " aria-describedby="emailHelp"
                    placeholder="Username or Email">
          
            <small [innerHTML]="vCheck('email', '_EMAIL')"></small>
           
            <input type="email" formControlName="password" class="form-control " aria-describedby="emailHelp"
                    placeholder="Username or Email">
            <small [innerHTML]="vCheck('password', '_PASSWORD')"></small>
            <br>
            <div class="form-group col-lg-12 col-md-6">
                <button type="button" class="btn btn-theme" (click)="onSubmit()">login</button>
            </div>
          </form> 
        <form (ngSubmit)="login()" [formGroup]="loginForm">
            <div class="form-group col-lg-12 ">
                <label for="email">Email or Username </label><span class="ml-1 text-danger">*</span>
                <input type="text" formControlName="username_email" (keyup.enter)="login()" class="form-control " aria-describedby="emailHelp"
                    placeholder="Email or Username">
                <small class="text-danger" [innerHTML]="vCheck('username_email', '_INPUT')"></small>
            </div>
            <div class="form-group col-lg-12 ">
                <label for="password">Password</label><span class="ml-1 text-danger">*</span>
                <input type="password" formControlName="password" (keyup.enter)="login()"  autocomplete="new-password" class="form-control " aria-describedby="emailHelp"
                    placeholder="Password">
                <span style="display: flex; justify-content: space-between; flex-direction: column;">
                    <small class="text-danger" [innerHTML]="vCheck('password', '_PASSWORD',{minLength: 6})"></small>
                </span>
            </div>
            <div class="form-group col-lg-12 flex">
                <!-- <div>
                    <input type="checkbox" name="remember" id="remember" formControlName="remember">
                    <label for="remember"> Remember me</label>
                </div> 
                <div>
                    <a mat-button class="paddingright" (click)="openDialog()">Forgot password?</a>
                </div>
            </div>
            <div class="form-group col-lg-12 col-md-6">
                <button type="button" class="btn btn-theme" (click)="login()">Log in</button>
            </div>
        </form>
    </div>
</div> -->
<app-header *ngIf="!isMobile"></app-header>
<div class="header"  *ngIf="isMobile">
    <div class="container">
      <div class="row">
        <div class="navbar-translate logged-out col-sm-9 col-7">
          <div class="header-logo">
            <img src="./assets/img/logo1.png" href="index.html" height="60" class="header-logo-img">
            <a class="navbar-brand" href="index.html" rel="tooltip" data-placement="bottom">
              Loophead
            </a>
          </div>
        </div>
        <div class="col-sm-3 col-5 btn-div">
          <button class="header-btn" (click)="redirecttologin()">Login</button>
        </div>
      </div>
    </div>
  </div>
<div class="page-header" style="background-image:url(../../../../assets/img/background1.png);background-size: cover;">
    <div class="page-header-image" ></div>
    <div class="container my-auto text-left">
      <div class="row">
        <div class="col-lg-5 col-md-8 col-12 mx-auto client-login">
          <div class="card z-index-0" style="border-radius: 0; padding: 25px;padding-top: 30px;">
            <div class="card-body">
              <form role="form" class="text-start" (ngSubmit)="login()" [formGroup]="loginForm">
                <div class="text-start" style="width: 100%; height: 100%; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                  <img style="width: 35px" src="../../../../assets/img/loophead transparent logo_ 1.png" />
                  <img style="width: 35px; filter: invert(96%) sepia(53%) saturate(2%) hue-rotate(209deg) brightness(0%) contrast(100%);
                  fill:black;
                  " src="../../../../assets/img/Loophead_DarkBlue 1 (1).png" />
                </div>
                <div style="color: #444445; font-size: 28px; font-family: Inter; font-weight: 700;margin-top: 20px; word-wrap: break-word;text-align: left;">Log In</div>
                <small class="text-danger error">{{loginerror}}</small>

                <div class=" input-group-outline">
                <label for="username_email" class="form-label">Username</label>
                  <input type="text" formControlName="username_email" (keyup.enter)="login()" class="form-control " aria-describedby="emailHelp"
                  placeholder="Username">
                <span style="display: flex; justify-content: space-between; flex-direction: column;">
                <small class="text-danger error" [innerHTML]="vCheck('username_email', '_INPUT')"></small>
                </span>
            </div>
                <div class="input-group-outline ">
                <label for="password" class="form-label">Password</label>
                <input type="password" formControlName="password" (keyup.enter)="login()"  autocomplete="new-password" class="form-control " aria-describedby="emailHelp"
                    placeholder="Password">
                <span style="display: flex; justify-content: space-between; flex-direction: column;">
                    <small class="text-danger error" [innerHTML]="vCheck('password', '_PASSWORD',{minLength: 6})"></small>
                </span>
                </div>
                <div class="d-flex justify-content-between mt-4">
                  <div>
                    <a style="color: black; font-size: 16px; font-family: Inter; font-weight: 600; word-wrap: break-word; cursor: pointer;" (click)="redirecttoforgot()">Forgot Password?</a>
                  </div>
                  <!-- <div>
                    <a style="color: black; font-size: 12px; font-family: Inter; font-weight: 600; word-wrap: break-word;" (click)="redirecttosignup()">Create an Account</a>
                  </div> -->
                </div>
                <a class="btn btn-neutral login-btn-form" (click)="login()" >
                <p style="font-weight: 700; font-size: medium; color: #FFFFFF; margin: 0; text-transform: capitalize;">Log In</p>
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>