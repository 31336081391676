<div class="desktop-screen">
	<div class="Search-page" >
		<div _ngcontent-htr-c51=""  class="page-wrapper">
			<!-- <div ngcontent-htr-c51="" class="container-fluid "> -->
				<div ngcontent-htr-c51="" class="row srch-rw">
					<div class="col-md-6 my-auto" >
							<div class="searchbox">
								<i class="srch-icon fa fa-search" aria-hidden="true"></i>
									<input type="text" class="form-control" placeholder="Search Packs" (keyup.enter)="getapicallforcouponcode()" [(ngModel)]="search"   style="">
										<button class="btn" (click)="getapicallforcouponcode()">Search</button>
								</div>
					</div>
					<div class="col-md-6 create-btn">
						<button
						
						class="btn " (click)="handleCreate()" *ngIf="check==1">Create Pack</button>
						<!-- (click)="handleCreate()" -->
						</div>
			</div>
				<!-- <div> -->
				<!-- <div> {{totalRecords}} search results for "{{validationforsearch}}"</div> -->
				<div class="table-responsive ">
		
					<table *ngIf="norecordfound">
						<thead>
						<th style="min-width: auto;" >Packs </th>
						<th class="t-align"></th>
						<th class="num"  [ngStyle]="{'text-align':check!=1 ? 'end' : 'none' }"># of loops</th>
						<!-- <th class="t-align">Tags</th> -->
						<th class="dnload-align dnload-head " *ngIf="check == 1"  style="padding-right: 10px;">Actions</th>
					</thead>
					<tbody>
						<tr *ngFor="let items of tableData" ngcontent-yrg-c82="" >
							<td class="loop-artwork">
								<img [src]="setimage(items)"/>
								<span ngcontent-yrg-c82=""
								class="play-btn hover">
							</span>
							</td>
							<td class="loop-details">
								<!-- <div ngcontent-yrg-c82=""> -->
									<div class="lock">
									<h4 ngcontent-yrg-c82="" class="titles" (click)="check == 1||items.pack_visibility!==1?redirecttopackpage(items):''">{{items.pack_name}}</h4>
											
									<img  *ngIf="items.pack_visibility===1" src="../../../../assets/img/lock-01.svg"/>
								</div>
									<!-- <div>
									<a (click)="check == 1||items.pack_visibility!==1?redirecttodownloadspage(items):''" *ngIf="check == 1" style="cursor: pointer;"  > {{ items.number_of_downloads }} downloads</a>
								</div> -->
								<a style="cursor: pointer;" *ngIf="check == 0"> @{{ items.username}}</a>

								<!-- </div> -->
					</td>
							<td class="num"  [ngStyle]="{'text-align':check!=1 ? 'end' : 'none' }" style="padding-right: 5px;">
								{{items.number_of_loops?items.number_of_loops:0}}
							</td>
							

							<td *ngIf="check==1" style="display: flex; justify-content: flex-end;padding-top: 40px ; column-gap: 20px;">
								<div class="action-li"><img src="../../../../assets/img/edit.svg" (click)="edit(items)"/></div>
								<div (click)="openshare(items)" class="action-li">
									<img src="../../../../assets/img/share.svg" /></div>
							</td>
						</tr>
					</tbody>
					</table>
				</div>
				
				
				<button class="btn btn-theme center mb-4" *ngIf="!hidepagination" (click)="paginationgetmore()">Load
					More
				Results</button>
			</div>
			
			<div *ngIf="!norecordfound" class="text-center" style="
			padding: 10px;
			padding-top: 20px;
			
		">
		No packs here yet
	</div>
	</div>
</div>
	

<div class="mobile-screen">
	<div class="Search-page" >
		<div _ngcontent-htr-c51=""  class="page-wrapper">
			<!-- <div ngcontent-htr-c51="" class="container-fluid "> -->
				<div ngcontent-htr-c51="" class="row srch-rw">
							<div class="searchbox">
								<i class="srch-icon fa fa-search" aria-hidden="true" (click)="getapicallforcouponcode()"></i>
									<input type="text" class="form-control" placeholder="Search Packs" (keyup.enter)="getapicallforcouponcode()" [(ngModel)]="search"   style="">
							</div>
				</div>
				<!-- <div> -->
				<!-- <div> {{totalRecords}} search results for "{{validationforsearch}}"</div> -->
				<div class="mobil-tble">
		
					<table *ngIf="norecordfound">
					<tbody>
						<tr *ngFor="let items of tableData" ngcontent-yrg-c82="" >
							<td class="loop-artwork">
								<img [src]="setimage(items)"/>
								<span ngcontent-yrg-c82=""
								class="play-btn hover">
							</span>
							</td>
							<td class="loop-details">
								<!-- <div ngcontent-yrg-c82=""> -->
									<div class="lock">
									<h4 ngcontent-yrg-c82="" class="titles" (click)="check == 1||items.pack_visibility!==1?redirecttopackpage(items):''">{{items.pack_name}}</h4>
											
									<img  *ngIf="items.pack_visibility===1" src="../../../../assets/img/lock-01.svg"/>
								</div>
									<!-- <div>
									<a (click)="check == 1||items.pack_visibility!==1?redirecttodownloadspage(items):''" *ngIf="check == 1" style="cursor: pointer;"  > {{ items.number_of_downloads }} downloads</a>
								</div> -->
								<a style="cursor: pointer;" *ngIf="check == 0"> @{{ items.username}}</a>

								<!-- </div> -->
							</td>
							<td *ngIf="check==1" class="action-li">
								<div class="action-i">
									<div class="action-li"><img src="../../../../assets/img/edit.svg" (click)="edit(items)"/></div>
									<div (click)="openshare(items)" class="action-li">
										<img src="../../../../assets/img/share.svg" />
									</div>
								</div>
							</td>
						</tr>
					</tbody>
					</table>
				</div>
				
				
				<button class="btn btn-theme center mb-4" *ngIf="!hidepagination" (click)="paginationgetmore()">Load
					More
				Results</button>
			</div>
			
			<div *ngIf="!norecordfound" class="text-center" style="
			padding: 10px;
			padding-top: 20px;
			
		">
		No packs here yet
	</div>
	</div>
</div>