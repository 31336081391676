import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteaccountComponent } from '../MODAL/deleteaccount/deleteaccount.component';
import { APIURL, VARS } from 'src/app/allServices/constant.service';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { AuthComponent } from '../MODAL/auth/auth.component';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.css']
})
export class ProfileSettingsComponent implements OnInit {
  userData: any;
  res: any;
  setting:FormGroup
  authdata: any;
  disable:boolean = true
  password: any;
  Passandconfirmvalid: boolean;

  constructor(private router: Router,private matDialog: MatDialog, private toastr: ToastrService, public api: ApiServiceService, public common: commonservice,private fb: FormBuilder) { }
 
 
  ngOnInit(): void {
    this.getUser()
  }
  confirmpasserror : any =''
  passwordConfirm() {
// //console.log('hi');
const password = this.setting.get('password')?.value;
const confirmPassword = this.setting.get('confirmpassword')?.value;
    this.confirmpasserror = document.getElementById('confirmpasserror');
if(password){
if (password !== confirmPassword) {
  this.confirmpasserror = 'Password and Confirm Password do not match';
} else {
this.confirmpasserror = '';
}
}
else{
  this.confirmpasserror = '';
  
  }
  }
  getUser(){
    
    this.userData = localStorage.getItem("client_data");
    this.res = JSON.parse(this.userData)
   
    this.setting = this.fb.group({
      email:this.res.email,
      email_promo:this.res.email_promo_notify,
      download:this.res.download_notify,
      follower:this.res.follower_notify,
      packs:this.res.packs_notify,
      password:[
        this.res.password,
        [
          Validators.minLength(6),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/)
        ]
      ],
      confirmpassword: [
        this.res.password,
        [
          Validators.minLength(6),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/)
        ]
      ],
      usage_rights:this.res.usage_rights
      // bpm: this.route.snapshot.queryParamMap.get('bpm_range')
    } );
    
    

  }
  openAuth(){
    let dialogue = this.matDialog.open(AuthComponent, { panelClass: 'forgot-password' ,data: {data: this.res}});
    dialogue.afterClosed().subscribe(result => {
      this.authdata = result
    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
  openDialog() {
    this.opendialoguefordelete();
  }

  opendialoguefordelete(): void {
    let dialogue = this.matDialog.open(DeleteaccountComponent, { panelClass: 'forgot-password', disableClose: true });
    dialogue.afterClosed().subscribe(result => {
      // //console.log('The dialog was closed');
    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
  submit(){
    // this.common.showLoader()
    let sendingdata = {
      user_id :this.res.user_type_id,
      usage_rights:this.setting.value.usage_rights

      // password : this.setting.value.password
    }
  
    let notify_data = {
      update_email_promo_notify : this.setting.value.email_promo ==true ? 1 : 0,
      update_download_notify: this.setting.value.download ==true ? 1 : 0,
      update_follower_notify: this.setting.value.follower ==true ? 1 : 0,
      update_packs_notify: this.setting.value.packs==true ? 1 : 0,
    }
    // if(this.setting.value.email != null){
      if( this.setting.value.password){
        sendingdata = Object.assign(sendingdata,{password:this.setting.value.password})
        sendingdata = Object.assign(sendingdata,{confirmpassword:this.setting.value.confirmpassword})
      }
      if(this.setting.value.usage_rights){
        sendingdata = Object.assign(sendingdata,{usage_rights:this.setting.value.usage_rights})
        
      }
    if(this.setting.value.email!=null && this.setting.value.email != this.res.email && this.setting.value.email!=this.res.temp_email){
      sendingdata = Object.assign(sendingdata,{email:this.setting.value.email})
    }
  // }
    if(this.setting.value.password != ""){
      sendingdata = Object.assign(sendingdata,{password:this.setting.value.password})
    }
    if(this?.authdata?.phone){
      sendingdata = Object.assign(sendingdata,{auth:2,phone_number:this.authdata.phone_number})
    }
    if(this?.authdata?.email){
      sendingdata = Object.assign(sendingdata,{auth:1})
    }
    // //console.log(notify_data);
    
    if(this.confirmpasserror==''){
    this.api.forclientgettokenpublic(`/user/update_notification_preference`, notify_data).then(async (res: any) => {
      // //console.log(res);
      this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        // this.common.hideLoader()

      })
    })
    this.api.forclientgettokenpublic(`/user/updateprofile`, sendingdata).then(async (res: any) => {
      // //console.log(res);
       
      if(res.response.status){
        this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
          this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
          // //console.log(res.response.status);
          setTimeout(() => {
            this.ngOnInit()
          }, 2000);
          
         
        })
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        }else{
        this.toastr.error(res.response.errors[0], "", { positionClass: 'toast-bottom-right' })
        }

        this.common.hideLoader()
    


    })
    }
  }
}
