
<h3 class="dynamictext">Admin Profile</h3>
<form action="">

    <!-- <div class="form-row">
        <div class="form-row">
            <div class="form-group  col-md-6">
                <label for="Image" value="">Upload your profile pic</label>
                <input type="file" class="form-control" (change)="selectFile($event)" name="fileToUpload"
                    id="fileToUpload" accept=".jpg,.jpeg,.png">
                <small class="text-{{text}} "> {{imageinfo}}.</small>
                <p id="fileerror" class="text-danger"></p>
            </div>
            <div class="form-group col-md-2" id="preview">
                <img class="my-3 shadow-none  bg-dark rounded rounded-circle z-depth-2 " style="object-fit: cover;"
                    [src]="url" id="thumb" />
            </div>
        </div>
        <div class="form-group col-md-6 m-5">

        </div>
    </div> -->

    <div>
        <div class="form-group col-md-6">
            <label for="firstname">First Name</label><span class="ml-1 text-danger">*</span>
            <input type="text" [(ngModel)]="validationfirstname.firstnamecheck" class="form-control" id="firstname"
                placeholder="Enter Your First Name" name="firstname">
            <small class="form-text "
                [ngClass]=" !validationfirstname.isfirstname ? 'text-danger' : 'text-success'">{{firstnameerror}}</small>
        </div>
        <div class="form-group col-md-6">
            <label for="lastname">Last Name</label><span class="ml-1 text-danger">*</span>
            <input type="text" [(ngModel)]="validationsecondname.secondnamecheck" class="form-control" id="lastname"
                placeholder="Enter Your Last name" name="lastname">
            <small class="form-text "
                [ngClass]=" !validationsecondname.issecondname ? 'text-danger' : 'text-success'">{{secondnameerror}}</small>
        </div>
    </div>
    <div >
        <div class="form-group col-md-6">
            <label for="email">Email </label><span class="ml-1 text-danger">*</span>
            <input type="email" [(ngModel)]="validation.emailcheck" class="form-control" id="email" name="email"
                placeholder="Enter Your Email">
            <small class="form-text "
                [ngClass]=" !validation.isvalid ? 'text-danger' : 'text-success'">{{emailerror}}</small>
        </div>
        <div class="form-group col-md-6">
            <label for="phoneno">Phone number </label><span class="ml-1 text-danger">*</span>
            <input type="text" [(ngModel)]="validationforphoneno.phonecheck" class="form-control" name="phoneno"
                id="phoneno" placeholder="Enter Your Phone No">
            <small class="form-text "
                [ngClass]=" !validationforphoneno.isphone ? 'text-danger' : 'text-success'">{{phonenoerr}}</small>
        </div>
    </div>
    <div>
        <div class="form-group col-md-6">
            <label for="createdon">Created On</label>
            <input type="datetime" [(ngModel)]="validationforcreateddate.datecheck" name="createdon" id="createdon" class="form-control" disabled="disabled">
        </div>
        <div class="form-group col-md-6">
            <label for="modifiedon">Modified on</label>
            <input type="datetime"  [(ngModel)]="validationformodifieddate.datecheckd" name="modifiedon" id="modifiedon" class="form-control" disabled="disabled">
        </div>
    </div>

    <div >
        <div class="form-group col-md-6 textmarg">
             <h4 class="passwordtextinfo" >Change Password</h4><i class="iinpassword">(Leave blank if you don't want to reset the password)</i> 
        </div>
        
    </div>



    <div>
        <div class="form-group col-md-6">
            <label for="password">Password</label>
            <input type="password" [(ngModel)]="validationpassword.passwordecheck" autocomplete="new-password" name="password" id="password" placeholder="Enter Your Password" class="form-control">
            <small class="form-text " 
            [ngClass]=" !validationpassword.ispasswordcheck ? 'text-danger' : 'text-success'">{{Passworderror}}</small>
        </div>
        <div class="form-group col-md-6">
            <label for="cpassword">Confirm Password</label>
            <input type="password" [(ngModel)]="validationconfirmpassword.confirmpasswordcheck" name="password" id="cpassword" placeholder="Enter Your Confirm Password" class="form-control">
            <small class="form-text "
            [ngClass]=" !validationconfirmpassword.isconfirmpasswordcheck ? 'text-danger' : 'text-success'">{{cpassworderror}}</small>
        </div>
        <div class="form-group col-md-12 text-right">
            <button type="submit" class="btn btn-theme text-end" (click)="onupdate()">Save</button>
        </div>
    </div>

    
</form>
