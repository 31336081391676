import { E } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
@Component({
  selector: 'app-banned',
  templateUrl: './banned.component.html',
  styleUrls: ['./banned.component.css']
})
export class BannedComponent implements OnInit {

  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  array: any = [];
  arrayofdeletevalue: any;
  disabledeletebutton = true;
  hidepagination = false;
  norecordfound = false;
  anotherarray: any = [];
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;

  isMasterSel: boolean;

  categoryList: any;

  checkedCategoryList: any;
  banned_name ="";
  created_on: any;
  forsort ="";
  local_data: any;
  pagenocount: any;
  totalpagesinaccount: any;
  items: any = [];
  pageOfItems: Array<any>
  firstcall: any = 0;
  temp_pno: any = 0;


  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService, private matDialog: MatDialog,) {
    this.getapicallforcouponcode();
  }

  ngOnInit(): void {
    // //console.log(this)
    // this.isMasterSel = false;
    // this.getCheckedItemList();
  }


  getapicallforcouponcode() {
    this.common.showLoader();
    
    this.router.navigate(['/admin/bannedwords'], { queryParams: { pageno: this.pageno } });

    // //console.log(this.banned_name);
    this.api.postApiHit(`/admin/get_all_banned_words?page_no=${this.pageno}&row_size=${this.rowsize}&sort_on=${this.banned_name}&sort_type=${this.forsort}`, {}, VARS.ADMIN_TYPE).then((res: any) => {



      if (res.response.data.total_records == 0) {
        this.norecordfound = true;
      } else {
        this.norecordfound = false;
      }

      if (res.response.data.total_pages == 1) {
        this.hidepagination = true;
      } else {
        this.hidepagination = false;
      }
      if (res.response.status == 1) {
        if (this.paginationempty == true) {
          this.pagercount = [];
        } else {
          this.pagercount = [];
        }

        this.common.hideLoader();


        this.tableData = res.response.data.pagging_list;
        this.record = this.tableData.length;
        this.pagenolist = res.response.data.total_pages + 1;

        for (let i = 1; i < this.pagenolist; i++) {
          this.pagercount.push(i);

        }
        // //console.log(res.data.total_records)
        this.total = res.response.data.total_records;
        // let ida: any = document.getElementById("liststyle"+1);
        // //console.log(ida);
        // ida.style.backgroundColor = "orange";
        this.anotherarray = [];
        if (this.totalpagesinaccount !== res.response.data.total_pages) {
          this.totalpagesinaccount = res.response.data.total_pages
          this.items = Array(this.totalpagesinaccount).fill(0).map((x, i) => (i + 1))
          }
        this.record = this.pageno - 1 + "1";
        // //console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
        this.pagenocount = 10*(this.pageno - 1) + res.response.data.pagging_list.length

        this.tableData = res.response.data.pagging_list;
        // this.record = this.tableData.length;
        this.pagenolist = res.response.data.total_pages + 1;
        if (this.record == "01") {
          this.record = 1;
        }
        // //console.log(res.response.data);
        for (i = 0; i < this.record; i++) {
          this.anotherarray.push(res.response.data.pagging_list[i].banned_word_id);
        }
        // //console.log(this.anotherarray);

      } else {
        this.common.hideLoader();

        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
  }




 


  onsearch() {

    // //console.log("searching");
    this.searchApi();
  }

  searchClick() {

    this.pageno = 1;
    this.searchApi();
  }

  searchApi() {
    this.common.showLoader();



    this.router.navigate(['/admin/bannedwords'], { queryParams: { search: this.validationforsearch, pageno: this.pageno } });
    let search = this.validationforsearch
    let tokkenget = localStorage.getItem("loggedintoken");
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };


    this.api.postApiHit(`/admin/get_all_banned_words?page_no=${this.pageno}&row_size=${this.rowsize}&search=${search}`, {}, VARS.ADMIN_TYPE).then((res: any) => {



      if (res.response.status == 1) {

        if (res.response.data.total_pages == 1) {
          this.hidepagination = true;
          
        } else {
          this.hidepagination = false;
        }
        // //console.log(res);


        if (res.response.data.total_records == 0) {
          this.common.hideLoader();
          this.pagercount = [];
          this.tableData = res.response.data.pagging_list;
          this.record = this.tableData.length;
          this.noresult = true;
          // this.toastr.success("No record found", res.response.errors, { positionClass: 'toast-bottom-right' });
          this.total = res.response.data.total_records;
          if (this.totalpagesinaccount !== res.response.data.total_pages) {
            this.totalpagesinaccount = res.response.data.total_pages
            this.items = Array(this.totalpagesinaccount).fill(0).map((x, i) => (i + 1))
            }

        } else {
          this.common.hideLoader();
          this.noresult = false;
          this.pagercount = [];
          this.tableData = res.response.data.pagging_list;
          this.record = this.tableData.length;
          this.pagenolist = res.response.data.total_pages + 1;
          this.record = this.tableData.length;
          this.pagenolist = res.response.data.total_pages + 1;
          this.record = this.pageno - 1 + "1";
          if (this.totalpagesinaccount !== res.response.data.total_pages) {
            this.totalpagesinaccount = res.response.data.total_pages
            this.items = Array(this.totalpagesinaccount).fill(0).map((x, i) => (i + 1))
            }
          if (this.record == "01") {
            this.record = 1;
          }
          for (let i = 1; i < this.pagenolist; i++) {
            this.pagercount.push(i);
          }
          this.total = res.response.data.total_records;

        }
      } else {
        this.common.hideLoader();

        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  tablevaluearray = [
    { name: 'Banned Name', tablehead: 'banned_word', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort', isSelected: false },

    { name: 'Created On', tablehead: 'created_on', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort', isSelected: false },

    { name: 'Action', tablehead: 'Action', sort: false, showicon: false, sortdesc: false, class: 'fa fa-sort', isSelected: false }

  ];
  sortOn(value: any) {
    this.getapicallforsort(value.tablehead);
    this.tablevaluearray.map(item => {
      item.sort = false;
      item.sortdesc = false;
      item.class = 'fa fa-sort';
    })
    value.sort = true;
    value.class = "fa fa-sort-asc";
    if ((this.route.snapshot.queryParamMap.get('sort_on') == "ASC") && (value.tablehead == "banned_word")) {
      value.class = 'fa fa-sort-desc';
    }

    if ((this.route.snapshot.queryParamMap.get('sort_on') == "ASC") && (value.tablehead == "created_on")) {
      value.class = 'fa fa-sort-desc';
    }


  }

  getapicallforsort(got: any) {
    let sort_on = got;
    let arrange = "DESC";
    if (this.route.snapshot.queryParamMap.get('sort_on') == "ASC") {
      arrange = "DESC";
      this.sortsign = "sort-desc";
      this.banned_name = sort_on;
      this.forsort = arrange
    } else {
      arrange = "ASC";
      this.sortsign = "sort-asc";
      this.banned_name = sort_on;
      this.forsort = arrange
    }
    // //console.log('banned', this.banned_name, 'asc/dec',this.forsort)
    this.searchwordget = this.route.snapshot.queryParamMap.get('search')
    if (this.searchwordget) {
      this.common.showLoader();

      this.router.navigate(['/admin/bannedwords'], { queryParams: { search: this.validationforsearch, pageno: this.pageno, sort_on: arrange,sort_type : arrange } });
      let tokkenget = localStorage.getItem("loggedintoken");
     
      // //console.log(this.banned_name);
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      this.api.postApiHit(`/admin/get_all_banned_words?&sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&search=${this.searchwordget}`, {}, VARS.ADMIN_TYPE).then((res: any) => {



        if (res.response.status == 1) {
          this.common.hideLoader();
          this.tableData = res.response.data.pagging_list;
        } else {
          this.common.hideLoader();
          this.toastr.error("", res.response.error, { positionClass: 'toast-bottom-right' });

        }
      }, async e => {
        this.common.hideLoader();

        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.common.showLoader();
      this.getpageno = this.route.snapshot.queryParamMap.get('pageno');
      if (this.getpageno) {
        this.pageno = this.getpageno;
      } else {
        this.pageno = 1;
      }
      this.router.navigate(['/admin/bannedwords'], { queryParams: { sort_on: arrange, pageno: this.pageno } });

      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      this.api.postApiHit(`/admin/get_all_banned_words?&sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}`, {}, VARS.ADMIN_TYPE).then((res: any) => {

        if (res.response.status == 1) {
          this.common.hideLoader();

          this.tableData = res.response.data.pagging_list;

        } else {
          this.common.hideLoader();
          this.toastr.error("", res.response.error, { positionClass: 'toast-bottom-right' });

        }
      }, async e => {
        this.common.hideLoader();

        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

      });

    }

  }


  paginationget(pageinfo: any) {
    this.paginationempty = true;
    this.pageno = pageinfo;

    if (this.route.snapshot.queryParamMap.get('search')) {
      this.searchApi()
    } else {
      this.getapicallforcouponcode();
    }


  }

  deletebannedwords(value: any) {
    this.common.showLoader();
    // //console.log(value);
    let sendingdata = {
      "banned_word_ids": value
    }
    this.api.postApiHit(`/admin/delete_banned_words`, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {

      this.array = [];


      if (res.response.status == 1) {

        if (this.array.length == 0) {
          this.disabledeletebutton = true;

        }

        this.emailFormArray = [];

        let a: any = document.getElementById("delete");
        a.checked = false;

        this.getapicallforcouponcode();
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        this.common.hideLoader();

      } else {

        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();

      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
  }

  deletebanned(value: any) {
    this.array.push(value);
    // //console.log(this.array);



  }

  //   if(this.array.length == 0) {
  //   this.disabledeletebutton = true;
  // }

  delete() {
    let deleteArr:any = [];
    this.tableData.map(item => {
      if (item.isSelected) {
        deleteArr.push(item.banned_word_id)
      }
      
    })

    
    // //console.log(this.array)

    this.deletebannedwords(deleteArr);

  }

  yourfunc(e: any, value: any) {
    this.disabledeletebutton = false;
    if (e.target.checked) {
      this.array.push(value);
      // //console.log(this.array);
    } else {
      // //console.log("not xhecked");
      this.array.pop(value);
      // //console.log(this.array);
      if (this.array.length == 0) {
        this.disabledeletebutton = true;
      }
    }
  }

  clickforallcheckbox(items:any) {
    // //console.log(items);
    if (items.isSelected) {
      this.tableData.map(item => {
      item.isSelected = true
    })
    } else {
      this.tableData.map(item => {
        item.isSelected = false
      })

    }
    let check = false;
    this.tableData.map(item => {
      if (item.isSelected) check = true;
        
    })
    if (check) this.disabledeletebutton = false;
    else this.disabledeletebutton = true;
    

    
    // var checkboxes = document.querySelectorAll('.checkbox')  ;
    // checkboxes.forEach(ele  => ele.click());
    // this.disabledeletebutton = false;
    // if (e.target.checked) {
    //   this.disabledeletebutton = false;
    //   //console.log("checked");
    //   this.anotherarray;

    //   for (var i = 0; i < checkboxes.length; i++) { 
    //     // checkboxes[i].checked = true;
    //   }
    // } else {
    //   this.disabledeletebutton = true;
    //   //console.log("notchecked");
    //   for (var i = 0; i < checkboxes.length; i++) {
    //     // checkboxes[i].checked = false;
    //   }
    // }

  }


  singleclick(i:any) {
    // //console.log("single",i);
    let checki = false;
    setTimeout(() => {
      this.tableData.map(it => {
      // //console.log(it,checki)
      if (it.isSelected) {
        
        checki = true;
        // if(check){}
      }
    })
    }, 10);
    
    setTimeout(() => {
      // //console.log(checki);
      if (checki) this.disabledeletebutton = false;
      else  this.disabledeletebutton = true;
    }, 20);
    
    
    let allcheck = false;
    setTimeout(() => {
      this.tableData.map(item => {
      if (!item.isSelected) allcheck = true;
      // //console.log(item,allcheck);
      })
      if (!allcheck) {
      this.tablevaluearray[0].isSelected = true;
    } else {
      this.tablevaluearray[0].isSelected = false;
    }
    }, 50);
    
    
    
  }
  emailFormArray: Array<any> = [];
  encryptUsingAES256(value:any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    this.encrypted =encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);

    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    this.router.navigate([`/admin/addbanned/${this.encrypted}`]);
  }

  // onChange(email: string, isChecked: boolean) {
  //   if (isChecked) {
  //     this.emailFormArray.push(email);
  //     // this.disabledeletebutton = false;
  //   } else {
  //     let index = this.emailFormArray.indexOf(email);
  //     this.emailFormArray.splice(index, 1);
  //   }
  //   //console.log(this.emailFormArray);
  //   if (this.emailFormArray.length == 0) {
  //     this.disabledeletebutton = true;
  //   } else {
  //     this.disabledeletebutton = false;
  //   }
  // }


  // checkUncheckAll() {

  //   for (var i = 0; i <  this.tablevaluearray.length; i++) {

  //     this.tablevaluearray[i].isSelected = this.isMasterSel;

  //   }

  //   this.getCheckedItemList();

  // }



  // isAllSelected() {

  //   this.isMasterSel =  this.tablevaluearray.every(function(item:any) {

  //       return item.isSelected == true;

  //     })

  //   this.getCheckedItemList();

  // }



  // getCheckedItemList(){

  //   this.checkedCategoryList = [];

  //   for (var i = 0; i <  this.tablevaluearray.length; i++) {

  //     if( this.tablevaluearray[i].isSelected)

  //     this.tablevaluearray.push( this.tablevaluearray[i]);

  //   }

  //   this.checkedCategoryList = JSON.stringify( this.tablevaluearray);

  // }

  sure() {
     this.opendialogueforverification()
  }
 


  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "bannedpage" } });
    dialogue.afterClosed().subscribe(result => {

      // return new Promise(resolve => {
      //   if (num) {

      //     resolve((Math.round(num * 100) / 100).toFixed(2))
      //   }
      // })
      // //console.log('The dialog was closed');
      // //console.log(result);
      this.local_data = result.data
      if (result.data == true) {
        this.delete();
      } else {
        
      }
      
    });
  }

  addcolor(item: any) {
    if (item == this.pageno) {
      return 'activated';
    } else {
      return ''
    }
  }

  onChangePage(pageOfItems: Array<any>) {
 
    if (pageOfItems[0] == undefined && this.firstcall == 0) {
      this.firstcall = 1;
      this.temp_pno = Number(this.route.snapshot.queryParamMap.get('pageno'));
     
    }
    else if (this.firstcall == 1 && this.temp_pno > 0 && (pageOfItems[0] != this.temp_pno)) {
      
      // //console.log(2)
      
      this.pageno = this.temp_pno;
      
      this.firstcall = 0;
      this.temp_pno = 0;

      if (this.route.snapshot.queryParamMap.get('search')) {
        this.searchApi()
      } else {
        this.getapicallforcouponcode();
      }
    }
    else if ((this.pageno !== pageOfItems[0]) && !(pageOfItems[0] == undefined)) {

      this.firstcall = 0;
      this.pageno = pageOfItems[0];
      
      if (this.route.snapshot.queryParamMap.get('search')) {
        this.searchApi()
      } else {
        this.getapicallforcouponcode();
      }
    }
    else {
      this.firstcall = 0;
      this.temp_pno = 0;
    }

    
  }






}
