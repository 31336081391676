<app-header></app-header>


<div class="main" style="margin-bottom: 60px; min-height: 80vh;" >
  <div  *ngIf="!empty"> 
    <div class="container">
      <div class="heading-main">Feed</div>
      <div style="color: #797979; font-size: 22px; font-family: Inter; font-weight: 400; word-wrap: break-word; ">Check out packs from users you follow</div>
     <div *ngFor="let items of tableData">
	  <div class="row" style=" padding-top: 60px;">
        <div class="col-sm-3 col-xl-1 col-lg-2 col-3">
          <!-- <img src="{{items.owner_profile_image_path}}" style="width: 100px; border-radius: 999999px;" /> -->
		  <img [src]="setimage1(items)"  class="small-mg"/>
<!-- 
<img
  *ngIf="!items.owner_profile_image_path"
  src="../../../assets/img/images.png"
  style="width: 100px; border-radius: 999999px;"
/> -->

        </div>
        <div class="col-8" style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 6px; display: flex">
          <div (click)="redirecttouser(items.owner_username)" style="color: black;font-size: 20px; font-family: Inter; font-weight: 700; word-wrap: break-word;cursor: pointer;"><a>@{{items.owner_username}}</a></div>
          <div style="color: #444445; font-size: 18px; font-family: Inter; font-weight: 400; word-wrap: break-word">created a new pack</div>
          <div style="color: #797979; font-size: 14px; font-family: Inter; font-weight: 400; word-wrap: break-word">{{timeget(items)}}</div>
        </div>
      </div>
      
        <div style="border-bottom: 1px solid grey;padding-bottom: 60px;">
          <div class="row" style="">
          <div class="col-sm-3 col-xl-1 col-lg-2 col-3"></div>
          <div class="col-8  mt-5" >
            <div class="img-text">
                <img class="feed-page-img" [src]="setimage(items)"  (click)="redirecttoloopdetailpage(items)"/>
                <div style="margin: auto 0px;">
                    <div class="img-head" (click)="redirecttoloopdetailpage(items)">{{items.pack_name}}</div>
                    <div style="color: #797979; font-size: 14px; font-family: Inter; font-weight: 400; word-wrap: break-word">Pack</div>
                </div>
            </div>
          </div>
        </div>
      </div>
	</div>
      <!-- <div style="height: 200px;"></div> -->
    </div>
	<button class=" load-btn btn btn-theme center"  *ngIf="pages>1" (click)="paginationget()">Load More Results</button>
</div>
  <div class="main text-center" *ngIf="empty" style="
  padding: 10px;
  padding-top: 20px;
  
">
<img src="../../../../assets/img/hand and electronic musical instrument.png" class="mb-5"/>
<div class="container msg mb-5 mt-5" style="padding: 40px">
	<h3>No Feed Found</h3>
  </div>
</div>
  </div>

  
<app-footer></app-footer>
