<app-header></app-header>

<div
  class="page-header">
  <div class="page-header-image"></div>
  <div class="container" style="position: relative">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12 mx-auto client-login">
        <div class="card z-index-0">
          <!-- <a>
            <div style=" width: 100%; height: 100%; gap: 5px; display: inline-flex; padding-bottom: 5px;">
              <div style="position: relative">
                <i class="fas fa-arrow-left" style="font-size: 14px; color: black"></i>
              </div>
            </div>
          </a> -->
          <h1 class="color main-head" style="margin-left: 10px;">
            Create a pack
          </h1>
          <h4 class="color1 main-sub-head" style="margin-left: 10px;">
            Add uploaded loops to your pack
          </h4>
          <div class="step-rw"style=" margin: 40px 10px 0px 10px;  border-bottom: 1px solid #444445; padding-bottom: 20px;display: flex; column-gap: 10px;">
            <div class="rw col-lg-2 col-sm-4 col-6" (click)="previous()">
              <div class="icons2"><img class="check" src="../../../assets/img/check-solid.svg"/></div>
              <div class="color" style="font-weight: 500;">Pack Details </div>
            </div>
            <div class="rw">
              <div class="color" >> </div>
            </div>
            <div class="rw col-lg-2 col-sm-4 col-6">
              <div class="icons">2</div>
              <div class="color">Add loops</div>
            </div>
          </div>
          <form >
            <div class="form">
            <div class="col-lg-4 col-12">
              <div class="left-box">
                  <div class="row-1" [ngClass]="user?.number_of_loops>0?'bg':''">
                      <img [src]="setimage1(user)" width="100px" height="100px" class="img1"/>
                      <div class="head-detail" >
                          <h3 class="color hed" [ngClass]="user?.number_of_loops > 0 ?'color1':''">{{user?.pack_name}}</h3>
                          <p class="color" [ngClass]="user?.number_of_loops > 0?'color1':''">New Pack</p>
                          <p class="color" [ngClass]="user?.number_of_loops > 0?'color1':''">{{user?.number_of_loops?user?.number_of_loops:0}} loop(s)</p>
                      </div>
                  </div>
                  <div class="lit">
                      <ul *ngFor="let items of loops_list,let i = index">
                        <li class="row-2">
                          <div class="col-lg-10 col-9 loop-artwork">
                            <img _ngcontent-bsc-c206="" src="../../../../assets/img/Drag-Dots.png" class="dots">
                            <div style="position: relative;">
                                <img [src]="setimage(items)" class="img"/>
                              <span ngcontent-yrg-c82="" class="play-btn hover">
                                <span class="play-btn hover play_button" id="play_button">
                                    <i aria-hidden="true" *ngIf="!items.hidepausebutton" (click)="playAudio(items,i)" class="fa fa-play"></i>
                                    <i (click)="stopAudio(items)" *ngIf="items.hidepausebutton" class="fa fa-pause"
                                        aria-hidden="true"></i>
                                </span>
                              </span>
                            </div>
                             <div class="loop-name">
                              <p class="color-1 small-list-head loops-list">{{items.title}}</p>
                              <p class="color-1 user-p">@{{items.username}}</p>
                            </div>
                          </div>
                         
                          <div class="col-lg-2 cross-btn col-3" (click)="remove(items,i)">
                            <div class="minus-btn-div"><img src="../../../../assets/img/minus-circle.png" class="minus-button"></div>
                          </div>

                        </li>
                          
                      </ul>
                  </div>
              </div>
            </div>
            <div class="col-lg-8 col-12">
              <div class="right-box">
                  <div class="search-box">
                      <input placeholder="Search Loops" style="width: 90%;color: black;" [(ngModel)]="search"  [ngModelOptions]="{standalone: true}" (input)="getallsounds()"/>
                  </div>
                  <div class="list1" *ngIf="total!==0">
                      <ul *ngFor="let items of list,let i = index">
                          <li class="rw-1">
                            <div class="col-md-6 loop-img-name ">
                              <div class="loop-artwork" >
                                <input class="chck-box" type="checkbox" [(ngModel)]="items.checked" (change)="addloop(items)" [ngModelOptions]="{standalone: true}"/>
                                <div class="play-video1">
                                <img [src]="setimage(items)"/><span ngcontent-yrg-c82="" 
                                class="play-btn hover">
                                <span class="play-btn hover play_button" id="play_button">
                                    <i aria-hidden="true" *ngIf="!items.hidepausebutton" (click)="playAudio(items,i)" class="fa fa-play"></i>
                                    <i (click)="stopAudio(items)" *ngIf="items.hidepausebutton" class="fa fa-pause"
                                        aria-hidden="true"></i></span>
                    
                                </span>
                              </div>
                              </div>
                              <div class="lopp-name">
                                  <h3 class="color loopname-heading">{{items.title}}</h3>
                                  <h6 class="color">@{{items.username}}</h6>
                              </div>
                            </div>
                            <div class="col-md-6 tags-rw">
                              <div class="tags-div tags-div_1">
                                  <div class="tags" *ngFor="let i of items.tags_array" (click)="redirectto(i)" >
                                  <span>{{i}}</span>
                                  </div>
                              </div>
                            </div>
                          </li>
                          
                      </ul>
                      <button class="btn  center mb-4" *ngIf="pages>1" (click)="paginationgetmore()">Load
                        More
                        Results</button>
                     
                  </div>   
                  <div class="list1" *ngIf="total===0">
                   <div style="display: flex;justify-content: center;color:black">No Loop found</div> 
                  </div> 
              </div>
              <div class="col-md-12 d-flex" style="justify-content: end; margin-top: 60px; column-gap:50px">
                <button class="previous-btn" (click)="previous()" >Previous</button>
                <button class="finish-btn" (click)="finish()">Finish</button>
                </div>
            </div>
            
          </div>
            <!-- <div class="col-md-12 d-flex" style="justify-content: end; margin-top: 20px;">
            <button class="finish-btn" (click)="finish()">Finish</button>

            </div> -->
          </form>
         
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
