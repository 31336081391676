import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { promise } from 'selenium-webdriver';
import { commonservice } from './commonservice';
// import { CustomvalidationService } from '../+register/customvalidation.service';
import { APIURL, VARS } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  baseurl = APIURL.BASE_URL;
  header: any = {}
  onlyoneerror = false;

  constructor(private formbuilder: FormBuilder, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, public common: commonservice,) {
    let tokkenget = localStorage.getItem("loggedintoken");

    this.header = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
  }

  loginApi(value: any) {



    return this.http.post(`${APIURL.BASE_URL}`, value)

  }

  getApiHit(url: any, type = VARS.ClIENT_TYPE) {
    return new Promise(resolve => {
      if (type === VARS.ClIENT_TYPE) {
        // return new Promise(resolve => {
        resolve(this.getApiForClient(url, type))
        // })
      } else if (type === VARS.ADMIN_TYPE) {
        // return new Promise(resolve => {
        resolve(this.getApiForAdmin(url, type))
        // })

      }
    })

  }


  async getApiForClient(url: any, type = VARS.ClIENT_TYPE) {
    return new Promise(async resolve => {
      let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
      // //console.log(tokkenget)
      let headers: any = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar' };
      // //console.log('0',headers);
      if (!tokkenget) {
        this.common.clearAllLocalAndSession();
        this.router.navigate(['/login']);
      }
      let fullUrl = this.baseurl + url;
      this.http.get(fullUrl, { headers }).subscribe((response: any) => {
        if (response.response.status == 3 && this.onlyoneerror == false) {
          let errorthroughapi = response.response.errors;
          this.onlyoneerror = true;
          let error = [];
          this.common.hideLoader();
          //  //console.log("1")
          for (var i = 0; i < errorthroughapi.length; i++) {
            let jk = error.push(errorthroughapi);
            this.toastr.error(response.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }

          this.common.clearAllLocalAndSession();
          // localStorage.removeItem("clientintoken");
          this.router.navigate(['/login']);
        }
        resolve(response)
      })
    })

  }



  async getApiForAdmin(url: any, type = VARS.ClIENT_TYPE) {
    return new Promise(async resolve => {
      let tokkenget = await this.common.getlocal(VARS.ADMIN_LOCAL_TOKEN_KEY);
      // //console.log(tokkenget)
      let headers: any = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar' };
      // //console.log('0',headers);
      if (!tokkenget) {
        this.common.clearAllLocalAndSession();
        this.router.navigate(['/login']);
      }
      let fullUrl = this.baseurl + url;
      this.http.get(fullUrl, { headers }).subscribe((response: any) => {
        if (response.response.status == 3) {
          let errorthroughapi = response.response.errors;
          let error = [];
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            let jk = error.push(errorthroughapi);
            this.toastr.error(response.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }

          this.common.clearAllLocalAndSession();
          // localStorage.removeItem("clientintoken");
          this.router.navigate(['/login']);
        }
        resolve(response)
      })
    })

  }



  async postApiHit(url: any, sendingData: any = {}, type = VARS.ClIENT_TYPE) {
    // let fullUrl = this.baseurl + url;
    if (type === VARS.ClIENT_TYPE) {
      return new Promise(resolve => {
        resolve(this.forclientgettoken(url, sendingData, type))
      })
    } else if (type === VARS.ADMIN_TYPE) {
      return new Promise(resolve => {
        // //console.log("red");
        resolve(this.forAdmingettoken(url, sendingData, type))
      })


    }

    // //console.log('1');
    // //console.log('fullapi',fullUrl)
    // return new Promise(resolve => {
    //   // //console.log("kuch bhi");
    //   this.http.post(fullUrl, sendingData, this.header).subscribe(response => {
    //     // //console.log(response);
    //     return resolve(response)
    //   })

    // })
  }

  async forclientgettoken(url: any, sending: any = {}, type = VARS.ClIENT_TYPE) {
    return new Promise(async resolve => {
      let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
      // //console.log(tokkenget)
      let headers: any;
      if (!tokkenget) {
        this.common.clearAllLocalAndSession();
        this.router.navigate(['/login']);
      }
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };
      // //console.log('0',headers);
      let fullUrl = this.baseurl + url;
    // //console.log('sendingData0',sending,fullUrl,headers);
    this.http.post(fullUrl, sending, { headers }).subscribe((response: any) => {
        // //console.log("checking");

        if (response.response.status == 3 && this.onlyoneerror == false) {
          // //console.log(2);
          this.onlyoneerror = true;
          let errorthroughapi = response.response.errors;
          let error = [];
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            let jk = error.push(errorthroughapi);
            this.toastr.error(response.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }

          this.common.clearAllLocalAndSession();
          // localStorage.removeItem("clientintoken");
          this.router.navigate(['/emailverify']);
        }

        resolve(response)
      })
    })

  }

  //  Public page

  async forclientgettokenpublic(url: any, sendingData: any = {}) {
    return new Promise(async resolve => {
      let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
      let headers: any;
      if (!tokkenget) {
        let tokkenget = await this.common.getlocal(VARS.ADMIN_LOCAL_TOKEN_KEY);
        let headers: any;
        if (tokkenget) {
          headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };
          let fullUrl = this.baseurl + url;
          this.http.post(fullUrl, sendingData, { headers }).subscribe((response: any) => {
            if (response.response.status == 3 && this.onlyoneerror == false) {
              this.onlyoneerror = true;
              let errorthroughapi = response.response.errors;
              let error = [];
              this.common.hideLoader();
              // //console.log(3);
              for (var i = 0; i < errorthroughapi.length; i++) {
                let jk = error.push(errorthroughapi);
                this.toastr.error(response.response.errors[i], "", { positionClass: 'toast-bottom-right' })
              }
              this.common.clearAllLocalAndSession();
              this.router.navigate(['/login']);
            }
            resolve(response)
          })
        } else {
          let fullUrl = this.baseurl + url;
          this.http.post(fullUrl, sendingData,).subscribe((response: any) => {
            if (response.response.status == 3 && this.onlyoneerror == false) {
              this.onlyoneerror = true;
              let errorthroughapi = response.response.errors;
              let error = [];
              this.common.hideLoader();
              for (var i = 0; i < errorthroughapi.length; i++) {
                let jk = error.push(errorthroughapi);
                this.toastr.error(response.response.errors[i], "", { positionClass: 'toast-bottom-right' })
              }
              this.common.clearAllLocalAndSession();
              this.router.navigate(['/login']);
            }
            resolve(response)
          })
        }

      } else {
        headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };
        let fullUrl = this.baseurl + url;
        this.http.post(fullUrl, sendingData, { headers }).subscribe((response: any) => {
          if (response.response.status == 3 && this.onlyoneerror == false) {
            this.onlyoneerror = true;
            let errorthroughapi = response.response.errors;
            let error = [];
            this.common.hideLoader();
            // //console.log(4);
            for (var i = 0; i < errorthroughapi.length; i++) {
              let jk = error.push(errorthroughapi);
              this.toastr.error(response.response.errors[i], "", { positionClass: 'toast-bottom-right' })
            }
            this.common.clearAllLocalAndSession();
            this.router.navigate(['/login']);
          }
          resolve(response)
        })
      }

    })

  }


  async foradminanduser(url: any, sendingData: any = {}) {
    return new Promise(async resolve => {
      let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
      let headers: any;
      let tokkenget2 = await this.common.getlocal(VARS.ADMIN_LOCAL_TOKEN_KEY);
      // let headers: any;
      if (tokkenget){
        headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };
        let fullUrl = this.baseurl + url;
        this.http.post(fullUrl, sendingData, { headers }).subscribe((response: any) => {
          if (response.response.status == 3 && this.onlyoneerror == false) {
            this.onlyoneerror = true;
            let errorthroughapi = response.response.errors;
            let error = [];
            this.common.hideLoader();
            // //console.log(4);
            for (var i = 0; i < errorthroughapi.length; i++) {
              let jk = error.push(errorthroughapi);
              this.toastr.error(response.response.errors[i], "", { positionClass: 'toast-bottom-right' })
            }
            this.common.clearAllLocalAndSession();
            this.router.navigate(['/login']);
          }
          resolve(response)
        })


      } else if (tokkenget2) {
        headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };
          let fullUrl = this.baseurl + url;
          this.http.post(fullUrl, sendingData, { headers }).subscribe((response: any) => {
            if (response.response.status == 3 && this.onlyoneerror == false) {
              this.onlyoneerror = true;
              let errorthroughapi = response.response.errors;
              let error = [];
              this.common.hideLoader();
              // //console.log(3);
              for (var i = 0; i < errorthroughapi.length; i++) {
                let jk = error.push(errorthroughapi);
                this.toastr.error(response.response.errors[i], "", { positionClass: 'toast-bottom-right' })
              }
              this.common.clearAllLocalAndSession();
              this.router.navigate(['/login']);
            }
            resolve(response)
          })
        
      } else {
        this.common.clearAllLocalAndSession();
        this.router.navigate(['/login']);
      }

    })

  }
  async forAdmingettoken(url: any, sendingData: any = {}, type = VARS.ClIENT_TYPE) {
    return new Promise(async resolve => {
      let tokkenget = await this.common.getlocal(VARS.ADMIN_LOCAL_TOKEN_KEY);
      // //console.log(tokkenget,"Sss");
      let headers: any = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar' };
      // //console.log('0',headers);
      if (!tokkenget) {
        this.common.clearAllLocalAndSession();
        this.router.navigate(['/login']);
      }
      let fullUrl = this.baseurl + url;
      this.http.post(fullUrl, sendingData, { headers }).subscribe((response: any) => {
        if (response.response.status == 3) {
          let errorthroughapi = response.response.errors;
          let error = [];
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            let jk = error.push(errorthroughapi);
            this.toastr.error(response.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }

          this.common.clearAllLocalAndSession();
          // localStorage.removeItem("clientintoken");
          this.router.navigate(['/login']);
        }
        resolve(response)
      })
    })

  }




  resetpasswordapi() {

  }


  getUrlToRedirect(is_on_boarding_complete: any, steps_completed: any) {
    // //console.log(is_on_boarding_complete)
    if (is_on_boarding_complete == 1) {     //this means all steps have been completed
      this.router.navigate(['']);
      // //console.log("step-1");
    } else {
      if (steps_completed == 1) {
        // //console.log("step--2");
        // this.router.navigate(['/plandetails']);
        // $url=baseurl."/user/registration-step-2"  
      } else if (steps_completed == 2) {
        // //console.log("step-3");
        // this.router.navigate(['/emailverify']);
        // $url=baseurl."/user/registration-step-3
      }
    }

    // return $url;
  }


  geturltoredirectexceptplandetail(is_on_boarding_complete: any, steps_completed: any) {
    if (is_on_boarding_complete == 1) {     //this means all steps have been completed
      this.router.navigate(['']);
      // //console.log("step-1");
    } else if (steps_completed == 2) {
      // //console.log("step-3");
      this.router.navigate(['/emailverify']);
      // $url=baseurl."/user/registration-step-3
    }

  }
  geturltoredirectexceptemailverify(is_on_boarding_complete: any, steps_completed: any) {
    if (is_on_boarding_complete == 1) {     //this means all steps have been completed
      this.router.navigate(['']);
      // //console.log("step-1");
    } else if (steps_completed == 1) {
      // //console.log("step-3");
      // this.router.navigate(['/plandetails']);
      // $url=baseurl."/user/registration-step-3
    }

  }
  geturltoredirectexceptemailverifydashboard(is_on_boarding_complete: any, steps_completed: any) {
    // if (is_on_boarding_complete == 1) {     //this means all steps have been completed
    //   this.router.navigate(['/dashboard']);
    //   // //console.log("step-1");
    // } else {
    if (steps_completed == 1) {     //this means all steps have been completed
      // this.router.navigate(['/plandetails']);
      // //console.log("step-1");
    } else if (steps_completed == 2) {
      // //console.log("step-3");
      // this.router.navigate(['/emailverify']);
      // $url=baseurl."/user/registration-step-3
    }
    // }


  }






  apihitforresendverification(data: any) {

    let sendingData = {
      "email": data
    }
    this.common.showLoader();
    this.http.post(APIURL.BASE_URL + `/user/resend_verification_mail`, sendingData).subscribe(async (res: any) => {
      if (res.response.status == 1) {


        this.common.hideLoader();
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      } else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
    });
  }




  roundat2decimal(num: any) {
    return new Promise(resolve => {
      if (num) {

        resolve((Math.round(num * 100) / 100).toFixed(2))
      }
    })


  }


}


