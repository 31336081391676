


import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { ConfirmDeleteComponent } from 'src/app/CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  hidepagination = false;
  norecordfound = false;
  loop_id: any;
  detailofloop: any[] = [];
  encryptedagainremoveslashes: string;
  titlename: any;
  tablefortagsdisplay1: any;
  tablefortagsdisplay2: any;
  tablefortagsdisplay3: any;
  username: any;
  createdon: any;
  bpm: any;
  instrument_name: any;
  parent_instrument_name: any;
  genre_name: any;
  parent_genre_name: any;
  artwork_path: null;
  sub_key: null;
  imagehide: any = false;
  afterload = false;
  key: any;
  imagesrc: any;
  loop_path: any;
  audiosrc: any;
  loop_type: any;
  sellingpreference: any;
  termsofpreference: any;
  time: any;
  looporloopkitid: any;
  showbpm: any = true;
  hidereportbutton: boolean = true;

  arraytableforcomments: any;
  commentData: any;
  nocomments: any = true;
  stream: any;
  src: any;
  commentno: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  artworkpathimage = "assets/img/artwork.jpg";
  hideplaybutton: boolean;
  hidepausebutton: boolean = true;
  number_of_likes: any;
  number_of_reposts: any;
  showupperpage: boolean;
  addred: boolean;
  addgreen: boolean;
  description: any;
  showdescription: any = false;
  loop_price: any;
  hidecommentbox: boolean;
  messageerror = "";
  fILE_PATH = "https://d1ptp6rux3p5im.cloudfront.net"
  local_data: any;
  showloopno: any;
  hidepricedetail: boolean;
  user_idget: any;
  tabname: any = "uploads";
  showcommentpage = true;
  showlikepage: boolean = false;
  showrepostpage: boolean = false;
  showreportlog: boolean = false;
  check: any;
  active = false;
  showdownloadpage: any = false;
  showuploadpage: any = true
  totalpagesinaccount: any;
  items: any = [];
  pageOfItems: Array<any>
  page_referer: any;




  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService, private matDialog: MatDialog,) {

    this.route.queryParams.subscribe(params => {
      this.getpageno = params.page_no;
      if (params.referer) {
        this.page_referer = params.referer;
      }
      else {
        this.page_referer = '/admin/user';
      }
      // //console.log(params.referer)
      // this.encrypted = params.coupon_id;
      
    });

  }

  receiveMessage(ab: any) {
    // //console.log(ab)
  }

  ngOnInit(): void {
    
    // this.encrypted = this.route.snapshot.queryParamMap.get('loop');
    this.encrypted = this.route.snapshot.params['id'];
    this.encryptedagainremoveslashes = decodeURIComponent(this.encrypted);
    this.decryptUsingAES256();
    this.getapicallforcouponcode();
    let left_sidebar:any = document.getElementsByClassName('sidebar-menu');
    if (left_sidebar.length > 0) {
      // //console.log("in")
      left_sidebar = left_sidebar[0];
      left_sidebar.querySelector('[href="/admin/user"]').parentElement.classList.add("active", 'menu-open', 'custom-active');
    }
  }


  decryptUsingAES256() {
    // //console.log(this.encryptedagainremoveslashes);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);


  }

  getapicallforcouponcode() {
    // this.active = false;


    // let Idfromurl = this.route.snapshot.params['id'];

    // //console.log(this.decrypted);
    this.loop_id = this.decrypted;
    this.common.showLoader();
    let sendingdata = {

      "user_type_id": this.loop_id,
      "tab": this.tabname,
      "sort_on": "created_on",
      "sort_type": "DESC",
      "row_size": this.rowsize,
      "page_no": this.pageno

    }


    this.router.navigate([`/admin/userdetails/${this.encrypted}`], { queryParams: {referer: this.page_referer, pageno: this.pageno } });
    let tokkenget = localStorage.getItem("loggedintoken");
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    this.api.postApiHit(`/admin/view_user_detail`, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {



      if (res.response.status == 1) {
        this.common.hideLoader();
        this.check = res
        // //console.log(res.response.data.user_details);
        this.detailofloop = res.response.data.user_details;
        this.active = true;
        if (res.response.data.total_records == 0) {
          this.norecordfound = true;
        } else {
          this.norecordfound = false;
        }

        if (res.response.data.total_pages == 1) {
          this.hidepagination = true;
        } else {
          this.hidepagination = false;
        }
        if (this.paginationempty == true) {
          this.pagercount = [];
        }






        this.tableData = res.response.data.pagging_list;
        this.record = this.tableData.length;
        this.pagenolist = res.response.data.total_pages + 1;

        for (let i = 1; i < this.pagenolist; i++) {
          this.pagercount.push(i);

        }
        // //console.log(res.data.total_records)
        this.total = res.response.data.total_records;
        // let ida: any = document.getElementById("liststyle"+1);
        // //console.log(ida);
        // ida.style.backgroundColor = "orange";

      } else {
        this.common.hideLoader();

        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();

      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
  }





  onsearch() {

    // //console.log("searching");
    this.searchApi(this.validationforsearch);
  }

  searchClick(event: any) {
    this.validationforsearch = event
    // //console.log(event)
    this.active = false;
    this.pageno = 1;
    this.searchApi(this.validationforsearch);
  }

  searchApi(event: any) {
    this.common.showLoader();



    this.loop_id = this.decrypted;
    this.common.showLoader();
    let sendingdata = {
      "page_no": this.pageno,
      "sort_on": "created_on",
      "row_size": this.rowsize,
      "sort_type": "DESC",
      "loop_id": this.loop_id,
      "tab": this.tabname,
      "search": event

    }


    this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`], { queryParams: { search: event, pageno: this.pageno } });
    let tokkenget = localStorage.getItem("loggedintoken");
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    this.api.postApiHit(`/admin/get_upload_detail`, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {


      // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`], { queryParams: { search: this.validationforsearch, pageno: this.pageno } });
      let search = event
      // let tokkenget = localStorage.getItem("loggedintoken");
      // const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };


      // this.api.postApiHit(`/admin/get_upload_detail?page_no=${this.pageno}&row_size=${this.rowsize}&search=${search}&loop_id=${this.loop_id}`, {}, VARS.ADMIN_TYPE).then((res: any) => {

      if (res.response.status == 1) {
        this.common.hideLoader();
        this.check = res
        // //console.log(res.response.data.loop_details);
        this.detailofloop = res.response.data.loop_details;
        this.active = true;
        if (res.response.data.total_pages == 1) {
          this.hidepagination = true;
        } else {
          this.hidepagination = false;
        }


        if (res.response.data.total_records == 0) {
          this.pagercount = [];

          this.tableData = res.response.data.pagging_list;
          this.record = this.tableData.length;
          this.noresult = true;
          // //console.log("aa");
          // this.toastr.success("No record found", res.response.errors, { positionClass: 'toast-bottom-right' });
          this.total = res.response.data.total_records;

        } else {
          this.common.hideLoader();

          this.noresult = false;
          this.pagercount = [];
          this.tableData = res.response.data.pagging_list;
          this.record = this.tableData.length;
          this.pagenolist = res.response.data.total_pages + 1;
          for (let i = 1; i < this.pagenolist; i++) {
            this.pagercount.push(i);
          }
          this.total = res.response.data.total_records;

        }
      } else {
        this.common.hideLoader();

        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  tablevaluearray = [
    { name: 'Reason', tablehead: 'Reason', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },

    { name: 'Reported  By', tablehead: 'Created_By', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Created On', tablehead: 'created_on', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },

    // { name: 'Action', tablehead: 'Action', sort: false, showicon: false, sortdesc: false, class: 'fa fa-sort' }

  ];
  sortOn(value: any) {
    this.active = false;
    this.getapicallforsort(value.tablehead);
    this.tablevaluearray.map(item => {
      item.sort = false;
      item.sortdesc = false;
      item.class = 'fa fa-sort';
    })
    value.sort = true;
    value.class = "fa fa-sort-asc";
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "Reason")) {
      value.class = 'fa fa-sort-desc';
    }
    // if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "loop_type")) {
    //   value.class = 'fa fa-sort-desc';
    // }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "Created_By")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "created_on")) {
      value.class = 'fa fa-sort-desc';
    }


  }

  getapicallforsort(got: any) {
    this.active = true;
    let sort_on = got;
    let arrange = "DESC";
    if (this.route.snapshot.queryParamMap.get('sort') == "ASC") {
      arrange = "DESC";
      this.sortsign = "sort-desc";
    } else {
      arrange = "ASC";
      this.sortsign = "sort-asc";
    }
    this.searchwordget = this.route.snapshot.queryParamMap.get('search')
    if (this.searchwordget) {

      this.loop_id = this.decrypted;
      this.common.showLoader();
      let sendingdata = {
        "page_no": this.pageno,
        "sort_on": "created_on",
        "row_size": this.rowsize,
        "sort_type": arrange,
        "loop_id": this.loop_id,
        "tab": this.tabname,
        "search": this.validationforsearch

      }


      this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`], { queryParams: { search: this.validationforsearch, pageno: this.pageno, sort: arrange } });
      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      this.common.showLoader();
      this.api.postApiHit(`/admin/get_upload_detail`, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {
        //   
        //   this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`], { queryParams: { search: this.validationforsearch, pageno: this.pageno, sort: arrange } });
        //   let tokkenget = localStorage.getItem("loggedintoken");
        //   const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

        // this.http.get(APIURL.BASE_URL + `/admin/get_all_coupon_codes?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&search_input=${this.searchwordget}`, { headers }).subscribe(async (res: any) => {
        // this.api.postApiHit(`/admin/get_upload_detail?&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&search=${this.searchwordget}&loop_id=${this.loop_id}`, {}, VARS.ADMIN_TYPE).then((res: any) => {

        if (res.response.status == 1) {
          this.common.hideLoader();
          this.tableData = res.response.data.pagging_list;
        } else {
          this.toastr.error("", res.response.error, { positionClass: 'toast-bottom-right' });
          this.common.hideLoader();
        }
      }, async e => {
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.getpageno = this.route.snapshot.queryParamMap.get('pageno');
      if (this.getpageno) {
        this.pageno = this.getpageno;
      } else {
        this.pageno = 1;
      }
      this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`], { queryParams: { sort: arrange, pageno: this.pageno } });

      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      let sendingdata = {
        "page_no": this.pageno,
        "sort_on": "created_on",
        "row_size": this.rowsize,
        "sort_type": arrange,
        "loop_id": this.loop_id,
        "tab": this.tabname,
        // "search": event

      }
      // this.http.get(APIURL.BASE_URL + `/admin/get_all_coupon_codes?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}`, { headers }).subscribe(async (res: any) => {
      // this.api.postApiHit(`/admin/get_upload_detail?&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&loop_id=${this.loop_id}`, {}, VARS.ADMIN_TYPE).then((res: any) => {
      //   

      this.common.showLoader();
      this.api.postApiHit(`/admin/get_upload_detail`, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {
        if (res.response.status == 1) {
          this.common.hideLoader();

          this.tableData = res.response.data.pagging_list;

        } else {
          this.toastr.error("", res.response.error, { positionClass: 'toast-bottom-right' });
          this.common.hideLoader();
        }
      }, async e => {
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

      });

    }

  }

  paginationget(pageinfo: any) {
    this.active = false;
    this.paginationempty = true;
    this.pageno = pageinfo;
    // let idname = "liststyle" + this.pageno;
    // let idaa: any = document.getElementById(idname);
    // //console.log(idaa);


    // let x: any = document.querySelectorAll(".bluecolor");
    // for (let i = 0; i < x.length; i++) {
    //   x[i].style.backgroundColor = "";
    // }
    // idaa.style.backgroundColor = "orange";
    
      this.getapicallforcouponcode();
    


  }
  // paginationget(pageOfItems: any) {
  //   //console.log(this.pageno, pageOfItems)
  //   if (pageOfItems !== 1) {
  //     if ((this.pageno !== pageOfItems) && !(pageOfItems == undefined)) {
  //       // //console.log("inside")
  //       // this.check = ""
  //       this.pageno = pageOfItems;


  //       this.active = false;
  //       this.getapicallforcouponcode();

  //     }
  //   }
  //   // 

  // }

  // paginationgeta(pageOfItems: Array<any>) {
  //   //console.log(pageOfItems, this.pageno)

  //   // this.paginationempty = true;
  //   // this.pageno = pageinfo;
  //   // // let idname = "liststyle" + this.pageno;
  //   // // let idaa: any = document.getElementById(idname);
  //   // // //console.log(idaa);


  //   // // let x: any = document.querySelectorAll(".bluecolor");
  //   // // for (let i = 0; i < x.length; i++) {
  //   // //   x[i].style.backgroundColor = "";
  //   // // }
  //   // // idaa.style.backgroundColor = "orange";

  //   // this.getapicallforcouponcode();
  //   if ((this.pageno !== pageOfItems[0]) && !(pageOfItems[0] == undefined)) {
  //     // //console.log("inside")
  //     this.pageno = pageOfItems[0];


  //     this.getapicallforcouponcode();

  //   }



  // }

  // routerLink=""
  toreportedloops() {
    // //console.log("yes");
    this.router.navigate(['/admin/loops/reported-loops']);
  }
  redirecttoinvoices() {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(this.looporloopkitid), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    this.router.navigate(['/admin/loops/allinvoices'], { queryParams: { loop_id: this.encrypted } });
  }


  todeleteowner() {
    let sendingData = {
      "loop_id": this.loop_id,


    }
    // //console.log(sendingData);

    this.common.showLoader();
    this.api.postApiHit(`/admin/delete_loop_user`, sendingData, VARS.ADMIN_TYPE).then((res: any) => {
      if (res.response.status == 1) {

        this.common.hideLoader();
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        this.router.navigate(['/admin/loops/list-uploads']);
      }
      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });

  }
  todeleteloop() {
    let sendingData = {
      "loop_id": this.loop_id,


    }
    // //console.log(sendingData);

    this.common.showLoader();
    this.api.postApiHit(`/admin/delete_upload`, sendingData, VARS.ADMIN_TYPE).then((res: any) => {
      if (res.response.status == 1) {

        this.common.hideLoader();
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        this.router.navigate(['/admin/loops/list-uploads']);
      }
      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });

  }

  toredirect() {
    // this.router.navigate([`/admin/user`]);
    this.router.navigateByUrl(this.page_referer);
  }

  redirectto() {

    this.encryptUsingAES256(this.decrypted);
    // //console.log("yup");

  }
  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    window.open(`${APIURL.BASE_URLforredirect}/user/sounds?user_id=${this.encrypted}`, `_blank`)
    // this.router.navigate(['user/sounds'], { queryParams: { user_id: this.encrypted } });
    // window.open(`http://loophead-web.blinkcoders.com/detail-loop?loop_id=${this.encrypted}`, `_blank`)
    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);
  }

  encryptUsingAES256again() {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(this.decrypted), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);

    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    this.router.navigate([`/admin/user-detail/${this.encrypted}`]);
  }


  suredeleteloop() {
    this.opendialogueforverification();
  }
  suretodeleteowner() {
    this.opendialogueforsecondverification();
  }



  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "deleteloop" } });
    dialogue.afterClosed().subscribe(result => {
      this.local_data = result.data
      if (result.data == true) {
        this.todeleteloop();
      } else {
      }
    });
  }
  opendialogueforsecondverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "deleteloop" } });
    dialogue.afterClosed().subscribe(result => {
      this.local_data = result.data
      if (result.data == true) {
        //  //console.log("owner");
        this.todeleteowner();
      } else {
      }
    });
  }
  // redirecttoprofilepage() {
  //   //console.log(this.user_idget)
  //   let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  //   let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  //   let encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(this.user_idget), _key, {
  //     keySize: 16,
  //     iv: _iv,
  //     mode: CryptoJS.mode.ECB,
  //     padding: CryptoJS.pad.Pkcs7
  //   });
  //   this.encrypted = encrypted.toString();
  //   this.encrypted = encodeURIComponent(this.encrypted);
  //   // //console.log(item.user_id);
  //   // window.open(`http://loophead-web.blinkcoders.com/detail-loop?loop_id=${this.encrypted}`, `_blank`)
  //   window.open(`http://loophead-web.blinkcoders.com/user/sounds?user_id=${this.encrypted}`, `_blank`)
  //   // this.router.navigate(['user/'], { queryParams: { user_id: this.encrypted } });

  // }




  redirecttoprofilepage() {
    // //console.log(this.decrypted)
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(this.decrypted), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(item.user_id);

    window.open(`${APIURL.BASE_URLforredirect}/user/sounds?user_id=${this.encrypted}`, `_blank`)
    // this.router.navigate(['user/sounds'], { queryParams: { user_id: this.encrypted } });
    // window.open(`http://loophead-web.blinkcoders.com/detail-loop?loop_id=${this.encrypted}`, `_blank`)

  }

  detailpageofloop() {

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(this.looporloopkitid), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });

    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);
  }
  downloadpage() {
    this.tabname = "downloads"
    this.pageno = 1
    this.getapicallforcouponcode();
    this.active = false;
    this.showdownloadpage = true
    this.showuploadpage = false

  }

  uploadpage() {
    this.tabname = "uploads"

    this.pageno = 1
    this.getapicallforcouponcode();
    this.active = false;
    this.showuploadpage = true
    this.showdownloadpage = false

  }




  artworkpathimages(data: any) {
    if (data.profile_image_path == null) {
      // //console.log("i am in null");
      return this.profile_image_pathofcomments;
    } else {
      // //console.log("i am not in null");
      return CONSTNAME.fILE_PATH + data.profile_image_path;
    }
  }







}

