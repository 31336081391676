<audio #audioPlayer  [src]="audioSrc"></audio>

<div class="audio-player-controls" *ngIf="isReadyForPlayback">
	<div class="audio-player-controls__play-pause">
		<button *ngIf="!isPlaying" class="play" (click)="play()">
			<i [style.font-size.px]="config.playPauseConfig.iconPixelSize" 
            style="color:rgb(238, 233, 233)" 
         class="{{ config.playPauseConfig.iconClassPrefix }} {{ config.playPauseConfig.playIconClass }}"></i>
		</button>
		<button *ngIf="isPlaying" class="pause" (click)="pause()">
			<i [style.font-size.px]="config.playPauseConfig.iconPixelSize" 
         style="color:rgb(238, 233, 233)" 
         class="{{ config.playPauseConfig.iconClassPrefix }} {{ config.playPauseConfig.pauseIconClass }}"></i>
		</button>
	</div>
	
</div>
<!-- <p>{{audioData?.loop_id}}</p> -->
