import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, INPUT_TYPE_NAME, VARS } from 'src/app/allServices/constant.service';
import { StaticService } from 'src/app/sevicesall/static.service';

@Component({
  selector: 'app-report-track-model',
  templateUrl: './report-track-model.component.html',
  styleUrls: ['./report-track-model.component.css']
})
export class REPORTTRACKMODELComponent implements OnInit {
  
  arraytableforreportname: any;
  reportform = StaticService.reportformforuser();
  loopid: any
  reader = new FormData();
  nameofvalue: any;

  constructor(private router: Router,private matDialog: MatDialog,public common: commonservice,@Inject(MAT_DIALOG_DATA) public data: any,public api: ApiServiceService,private el: ElementRef,private toastr: ToastrService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loopid=this.data.pageValue
    this.getallinfoofloops();
  }


  cancel(){
    this.matDialog.closeAll()
  }


  getallinfoofloops() {
    let sendingData = {}
    this.common.showLoader();
    this.api.postApiHit(`/user/get_all_report_points`, sendingData,  VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        // //console.log(res);
        this.arraytableforreportname = res.response.data;
        this.common.hideLoader();
        //  //console.log("rec");
      } else if (res.response.status == 0) {
        // //console.log(res.response.data.total_records);
        this.common.hideLoader();
      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
    });
  }

  vCheck(fieldName: any, type: INPUT_TYPE_NAME, options: any = {}) {
    options.isRequired = true;
    const cFrm = this.reportform.controls;
    if (cFrm) {
      return StaticService.formError(cFrm, fieldName, type, options).msg;
    }
  }



  onSubmit() {
    // //console.log(this.loopid);
    // //console.log(this.reportform.value)
    const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    StaticService.markFormGroupTouched(this.reportform, invalidElements);

    this.arraytableforreportname.map((data: any) => {
      if (data.report_point_id == this.reportform.value.checkboxreport) {
        this.nameofvalue = data.report_point
        // //console.log(this.nameofvalue);
      }
    })


    let sendingData = {
      "loop_id" : this.loopid,
      "report_point_id": this.reportform.value.checkboxreport,
      "report_point": this.nameofvalue 
      
    }
    // //console.log(sendingData);
    if (this.reportform.valid){
      this.common.showLoader();
      this.api.postApiHit(`/user/report_upload`,sendingData).then((res: any) => {
        if (res.response.status == 1) {
          this.reportform.reset();
          this.common.hideLoader();
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          this.cancel();
        } else if (res.response.status == 2) {
          this.common.hideLoader();
          this.cancel();
          this.router.navigate([CONSTNAME.REDIRECT_CLIENT_LoopstatusUSers]);
        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {
        this.cancel();
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    }
    
  }

}
