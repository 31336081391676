<app-header></app-header>

<form class="main" [formGroup]="setting">
  <div class="container">
    <div class="setting-heading">
      <div class="setting-text">Settings
      </div>
      <div class="sub-text">Manage
        the settings on your account</div>
    </div>
    
    <div class="row" style="margin-top: 60px">
      <div class="col-lg-3 col-md-5 pl">
        <div class="label">Email</div>
      </div>
      <div class="col-md-7 mrt" style="position: relative">
        <div class="input-frm">
          <div formControlName="email"
            style="font-size: 16px; font-family: Inter; font-weight: 300; word-wrap: break-word; border-style: none; outline: none; width: 100%"  >
            {{res.email||res.temp_email}} 
            </div>
          </div>
          <!-- <small *ngIf="res.is_email_changed">{{res.temp_email}} is pending for verification</small> -->
        </div>
    </div>
    <div class="row" style=" margin-top: 50px">
      <div class="col-lg-3 col-md-5 pl">
        <div class="label">Password</div>
      </div>
      <div class="col-md-7 mrt" style="position: relative">
        <div class="input-frm">
          <input type="password" formControlName="password" (input)="passwordConfirm()"
            style="font-size: 16px; font-family: Inter; font-weight: 300; word-wrap: break-word; border-style: none; outline: none; width: 100%;color:black">
           
        </div>
        <span>
          <small class="form-text text-danger error"
            *ngIf=" setting.get('password')?.touched && setting.get('password')?.hasError('minlength')">Minimum
            6 characters required</small>
          <small class="form-text text-danger error"
            *ngIf="  setting.get('password')?.hasError('pattern')">Password should include at least
            one uppercase letter and one special character</small>
        </span>
      </div>
    </div>
    <div class="row" style=" margin-top: 50px">
      <div class="col-lg-3 col-md-5 pl label" style="padding-left: 13px;">Confirm Password
      </div>
      <div class="col-md-7 mrt" style="position: relative">
        <div class="input-frm">
          <input type="password" formControlName="confirmpassword" (input)="passwordConfirm()" id="confirmpasserror"
            style="font-size: 16px; font-family: Inter; font-weight: 300; word-wrap: break-word; border-style: none; outline: none; width: 100%;color:black">
        </div>
        <span>
        <small class="form-text text-danger error"
        *ngIf=" setting.get('confirmpassword')?.touched && setting.get('confirmpassword')?.hasError('minlength')">Minimum
        6 characters required</small>
       <small class="form-text text-danger"
        *ngIf="confirmpasserror!=''">Passsword and Confirm
        Password didn't match.</small> 
      <small class="form-text error text-danger"
        *ngIf="setting.get('confirmpassword')?.hasError('pattern')">Password should include at
        least one uppercase letter and one special character</small>
    </span>
      </div>
    </div>
    <!-- <div class="row" style=" margin-top: 50px">
      <div class="col-lg-3 col-md-5 pl" style="line-height: 26px;">
        <div class="label">Two-Factor<br>
        Authentication</div>
      </div>
      <a class="col-md-7 mrt"  (click)="openAuth()">
        <button class="set-btn"> <p class="text-center"
          style="font-weight: 700; background-color: black; border-radius: 10px; border: none;
          padding: 8px 40px; width: 100%; max-width: 175px;  font-size: medium; color: #FFFFFF; margin: 0; text-transform: capitalize;">Set Up 2FA
        </p></button>
      </a>
    </div> -->
    <div class="row" style=" margin-top: 50px;">
      <div class="col-lg-3 col-md-5 pl">
        <div  class="label" style="padding: 0px;">
          Email Notifications</div>
        <!-- <div
          style="width: 100%; color: #797979; font-size: 16px; font-family: Inter; font-weight: 400; line-height: 25.60px; word-wrap: break-word;
          margin-top: 10px;">
          Choose what notifications you see</div> -->
      </div>
      <div class="col-md-5 mrt" style="position: relative">
        <!-- <div style="display: flex; align-items: center; gap:8px; margin: 10px 0px 25px; ">
          <input type="checkbox" formControlName="email_promo" class="checkbox-input">
          <a style="color:black; font-size: 16px;">Email Promo</a>
        </div> -->
        <div style="display: flex; align-items: center; gap:8px; margin: 10px 0px 25px;">
          <input type="checkbox" formControlName="download" class="checkbox-input">
          <a style="color:black; font-size: 16px;">New Downloads</a>
        </div>
        <div style="display: flex; align-items: center; gap:8px;margin:10px 0px 25px;">
          <input type="checkbox" formControlName="follower" class="checkbox-input">
          <a style="color:black; font-size: 16px;">New Followers</a>
        </div>
        <div style="display: flex; align-items: center; gap:8px;margin:10px 0px 25px;">
          <input type="checkbox" formControlName="packs" class="checkbox-input">
          <a style="color:black; font-size: 16px;">New Packs</a>
        </div>
      </div>
    </div>
    <!-- <div class="row" style="margin-left: 6%; margin-right: 6%; margin-top: 50px">
      <div class="col-md-4">
        <div style="color: #444445; font-size: 20px; font-family: Inter; font-weight: 600; word-wrap: break-word">Email
          Preferences</div>
        <div
          style="width: 100%; color: #797979; font-size: 16px; font-family: Inter; font-weight: 400; line-height: 25.60px; word-wrap: break-word">
          Choose what emails hit your inbox</div>
      </div> -->
      <!-- <div class="col-md-5" style="position: relative;">
        <div style="display: flex; align-items: center; gap:8px">
          <input type="checkbox" value="" id="" formControlName="email_promo">
          <a style="color: #7b809a; font-size: 18px;">Email Promotions (i.e New Updates, Opportunities, Etc.)</a>
        </div>
        <div style="display: flex; align-items: center; gap:8px; margin-top: 8px;">
          <input type="checkbox" value="" id="">
          <a style="color: #7b809a; font-size: 18px;">New Follower</a>
        </div>
        <div style="display: flex; align-items: center; gap:8px; margin-top: 8px">
          <input type="checkbox" value="" id="">
          <a style="color: #7b809a; font-size: 18px;">New Download</a>
        </div>
        <div style="display: flex; align-items: center; gap:8px; margin-top: 8px">
          <input type="checkbox" value="" id="">
          <a style="color: #7b809a; font-size: 18px;">New Pack</a>
        </div>
      </div> -->
    <!-- </div> -->
    <div class="row" style=" margin-top: 50px">
      <div class="col-lg-3 col-md-5 pl settings-usage">
        <div  class="label" style="padding: 0px;">Usage
          Rights</div>
        <div style="margin-top: 10px;">
          Create a default usage rights for your loops</div>
      </div>
      <div class="col-md-7 mrt" style="position: relative">
        <div
          style="padding-left: 12px; padding-right: 12px; padding-top: 8px; padding-bottom: 8px; background: white; border-radius: 10px; overflow: hidden; border: 1.5px black solid;">
          <textarea formControlName ="usage_rights"
            style="font-size: 16px; font-family: Inter; font-weight: 300; word-wrap: break-word; border-style: none; outline: none; width: 100%; height: 100px"></textarea>
        </div>
      </div>
    </div>
    <div class="row" style="  margin-top: 50px">
      <div class="col-lg-3 col-md-53  col-6">
        <div class="label">Delete
        Account</div>
      </div>
      <div  (click)="openDialog()" class="col-md-4 col-6 delete-text">
        <img src="assets/img/icons/trash-01.svg">
        <div  class="delete-cntnt">Delete
          Account</div>
      </div>
    </div>
    <div style="height: 160px;cursor: pointer;" (click)="submit()">
      <div class="btns">
        <div class="save-btn">Save Changes</div>
      </div>
    </div>
  </div>
</form>

<app-footer></app-footer>
