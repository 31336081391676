





import { environment } from 'src/app/sevicesall/alldata';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
// import { ConfirmDeleteComponent } from 'src/app/CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.
import { NgWaveformModule } from 'ng-waveform';
import { ITimeUpdateEvent, NgWaveformComponent, IRegionPositions } from 'ng-waveform';
import { MatDialog } from '@angular/material/dialog';
// import { ConfirmDeleteComponent } from '../CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
// import { WaveformComponent } from '../commonComponent/waveform/waveform.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
 

  

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService, private matDialog: MatDialog) { }

  ngOnInit(): void {
    

  }




}





