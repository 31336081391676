<app-header></app-header>

<div
  class="page-header">
  <div class="page-header-image"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12 mx-auto client-login">
        <div
          class="card z-index-0">
          <a (click)="redirecttoProfile()">
            <div
              style="
                /* width: 100%; */
                height: 100%;
                gap: 5px;
                display: inline-flex;
                padding-bottom: 5px;
                padding-left: 15px;
              " class="back-profile">
              <div style="position: relative">
                <i
                  class="fas fa-arrow-left"
                  style="font-size: 14px; color: black"></i>
              </div>
              <div
                style="
                  color: black;
                  font-size: 16px;
                  font-family: Inter;
                  font-weight: 600;
                  word-wrap: break-word;
                  margin: 6px 0px 0px;
                  
                ">
                Back to Profile
              </div>
            </div>
          </a>
          <h1 class="color head" style="padding-left: 15px;">
            Create a Pack
          </h1>
          <h4 class="color1 sub-head" style="padding-left: 15px;" >
            Add uploaded loops to your pack
          </h4>
          
          <div class="row step-rw">
            <div class="rw col-lg-2 col-sm-4 col-6">
              <div class="icons">1</div>
              <div class="color">Pack Details</div>
            </div>
            <div class="rw">
              <div class="color arrow">> </div>
            </div>
            <div class="rw col-lg-2 col-sm-4 col-6">
              <div class="icons2">2</div>
              <div class="color" (click)="next()" style="font-weight: 500;">Add Loops</div>
            </div>
          </div>
          <form >
            <div class="row m-t">
              <div class="col-md-4 col">
                <label class="color"style="font-size: 20px; font-weight: 600">Pack Name</label>
              </div>
              <div class="col-md-8 col-12">
              <input style="font-size: 16px;  width: 100%;  border-radius: 5px; padding: 10px 15px;  border: 1px solid #797979;  color:black;" type="text"
                [(ngModel)]="pack_name"
                [ngModelOptions]="{standalone: true}" placeholder="Give your new pack a name" />
            <small *ngIf="nameerror" style="color: red;">{{nameerror}}</small>
          </div>
          </div>

            <div class="row m-t" style="margin-bottom: 0px;">
              <div class="col-md-4 col-sm-7 col-10 ">
                <label class="color" style="font-size: 20px; font-weight: 600">Upload Artwork </label>
                <label class="color" style="font-size: 16px; font-weight: 500;color:#797979;max-width: 250px;">Artwork will appear with your pack in searches (optional)</label>
              </div>
                <div class="browse-div col-md-8 col-12">
                  <div style="border: 1px dotted black; padding: 10px;min-height:185px; background-color: rgba(44, 47, 116, 0.05);border-radius: 5px;position: relative;">
                    <label class="after-upload"
                      *ngIf="disable_input_as_itsloading1"
                      >{{ ImagefileName }}
                      <i (click)="deleteImageFile()" class="fa fa-trash" style="  font-size: 14px;color: #c70000; z-index: 9999; position: relative; "></i>
                      
                    </label>
                      <label class="after-upload-2"
                        *ngIf="disable_input_as_itsloading1">
                        <i
                          class="fa fa-check"
                          style="font-size: 14px; color: green; z-index: 9999; position: relative"></i>
                        File  Uploaded Successfully
                        <div style="max-height: 90px;;">
                          <img style="max-height: 90px; "*ngIf="selectedImage" width="100px" [src]="selectedImage" alt="Selected Image">
                        </div>
                      </label>
                      <img  src="../../../assets/img/file-download-03.svg" class="select-Label dnload-png"  *ngIf="!disable_input_as_itsloading1"/>
                  
                  <label *ngIf="!disable_input_as_itsloading1" class="select-Label" 
                  [matTooltip]="disable_input_as_itsloading1 ? 'You cannot perform this action while uploading is in progress' :
                    ''"  for="uploadlooptit">Drag & Drop a File or
                    <div class="form-group" [ngClass]="dragClass">
                      <input
                        type="file"
                        id="fileinput"
                        [attr.disabled]="disable_input_as_itsloading1 ? true : null"
                        accept=".jpeg, .jpg, .png"
                        class="form-control"
                        (change)="selectFileImage($event)"
                        placeholder="Title"
                        style="position:absolute;" />
                      <span  class="link"> Browse</span>
                    </div>
                    .jpeg & .png file only</label>
                  <ngx-file-drop  *ngIf="showsecondpage" dropZoneClassName="drop-zone" class="dropBox" (onFileDrop)="onFileDropArtwork($event)"></ngx-file-drop>
                  </div>
            <small *ngIf="picerror" style="color: red;">{{picerror}}</small>
            <div>
              <div _ngcontent-xyg-c237="" style="display: flex; margin-top: 20px;column-gap: 20px; ">
                <label _ngcontent-xyg-c237="" for="checkbox" class="switch">
                  <input _ngcontent-xyg-c237="" type="checkbox" id="checkbox" [(ngModel)]="visiblity"
                  [ngModelOptions]="{ standalone: true }" />
              <div _ngcontent-xyg-c237="" class="slider round">

              </div>
          </label>
          <p _ngcontent-xyg-c237="" class="toggleText">Make this pack private
                  <button
                  style="
                  box-shadow: none;
                  min-width: auto;
                "
                  mat-raised-button
                  matTooltip="Making a pack private means that it is only sharable by link, any private loops added to this pack will be visible when pack link is shared
                  "
                  aria-label="Button that displays a tooltip when focused or hovered over"
                >
                  <img src="../../../assets/img/Icon.svg"/>
                  </button>
                </p>
              </div>
                  </div>
                </div> 
            </div>
            <div class="nextDiv">
              <button mat-raised-button class="next"  (click)="next()"  color="primary"> Next </button>
            </div>
          </form>
          
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
