export const environment = {
    adminLogin: false,
    clientregister: 'default',
    menubar: {
        showMenu: true,
        regType: false,
        menuItems: [
            // { type: false, name: 'Promo', val: 'promo', hide: false, isRegType: true, link: "/promo-page" },
            { type: false, name: 'Sign Up', val: 'rg', hide: false, isRegType: true, link: "/register" },
            // { type: false, name: 'Login', val: 'ad', hide: false, isRegType: true, link: "/login" },
            { type: false, name: 'Top Charts', icon: 'margin:100px', val: 'topcharts', hide: false, isRegType: false, link: "/charts/producers" },
            { type: false, name: 'My Feed', val: 'myfeed', hide: false, isRegType: false, link: "/my-feed" },
            { type: false, name: 'Upload ', val: 'upload', hide: false, isRegType: false, link: "/upload" },


        ],
        seconMenu: [
            { type: true, icon: 'fa fa-search', name: 'search', val: 'top', hide: false, secondSec: true, isRegType: false },
            { type: true, icon: 'fa fa-bell', name: 'noti', val: 'topchart', hide: false, secondSec: true, isRegType: false },
            { type: false, name: 'user', val: 'user', hide: false, secondSec: true, isRegType: false, },
            // { type: false,name: 'Logout', val: 'logout',hide:false,secondSec:true ,isRegType:false},
        ]
    },
    client_login_data: {

    },

    account_menu: [
        { val: 'subscriptiondetail', active: false },
        { val: 'accountdetail', active: false },
        { val: 'uploadloopstatus', active: false },
        { val: 'paymentaccounts', active: false },
        { val: 'followingpage', active: false },
        { val: 'notification', active: false },
        { val: 'Likedlooploopkit', active: false },
        { val: 'downloadhistory', active: false },
        { val: 'archivework', active: false }
    ]
    ,
    followerspage: [
        { val: 'followers', active: false },
        { val: 'following', active: false },

    ]
    ,
    Likedlooploopkit: [
        { val: 'loopsloopkits', active: false },


    ]
    ,
    SOundprofile: [
        { val: 'sound', active: false },


    ],

    // sidebarLeftMenu: [
    //     {label: 'Coupon Codes', route: 'admin/list-coupon-codes', iconClasses: 'fa fa-clipboard'
    //     },
    //     { label: 'Loops / Loop Kits', route: 'admin/loops/list-uploads', iconClasses: 'fa fa-arrows-v' },
    //     { label: 'Banned words', route: 'admin/bannedwords', iconClasses: 'fa fa-cog' },
        
    // ],
    sidebarLeftMenu: [
        {label: 'MAIN NAVIGATION',route: 'admin', separator: true},
        { label: 'Dashboard', route: 'admin/dashboard', iconClasses: 'fa fa-cog' },
        { label: 'Admin Profile', route: 'admin', iconClasses: 'fa fa-unlock' },
        {
          label: 'Subscription Plans', route: 'admin/subscriptiontiers', iconClasses: 'fa fa-credit-card'
        },
        {
          label: 'Coupon Codes', route: 'admin/list-coupon-codes', iconClasses: 'fa fa-clipboard'
        },
      
        { label: 'Loops / Loop Kits', route: 'admin/loops/list-uploads', iconClasses: 'fa fa-arrows-v' },
        {label: 'Users', route: 'admin/user', iconClasses: 'fa fa-users'},
        { label: 'Banned words', route: 'admin/bannedwords', iconClasses: 'fa fa-cog' },
        { label: 'Global Settings', route: 'admin/globalsetting', iconClasses: 'fa fa-cog' }
        
    ],

};