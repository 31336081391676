import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL } from 'src/app/allServices/constant.service';
// import { CustomvalidationService } from '../+register/customvalidation.service';
// import { ApiServiceService } from '../allServices/api-service.service';
// import { commonservice } from '../allServices/commonservice';
// import { APIURL } from '../allServices/constant.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetformforloophead: FormGroup;
  tokenget: any;
  Passandconfirmvalid = false;

  constructor(private formbuilder: FormBuilder, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, public api: ApiServiceService, public common: commonservice) {


    const password = '^(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$';
    this.resetformforloophead = formbuilder.group({


      passwordLoginpage: ["", [Validators.minLength(6), Validators.required, Validators.pattern(password)]],
      passwordconfirmLoginpage: ["", [Validators.minLength(6), Validators.required, Validators.pattern(password)]]

    });

  }

  ngOnInit(): void {
    this.getapiforreset();
  }

  getapiforreset() {
    this.tokenget = this.route.snapshot.queryParamMap.get('token');
    if (this.tokenget == null) {
      // //console.log("null")
      this.router.navigate(['/login']);
    } else {
      let sendingData = {
        pwd_verification_token: this.tokenget
      }


      this.http.post(APIURL.BASE_URL+APIURL.VALIDATE_PASSWORD_TOKEN, sendingData).subscribe(async (res: any) => {
        if (res.response.status == 1) {

          this.common.hideLoader();
          // //console.log(res);


          // this.toastr.success("Succesfully logged in", "Success", { positionClass: 'toast-bottom-right' });


        } else {
          // //console.log(res);
          this.common.hideLoader();
          let errorthroughapi = res.response.errors;
          let error = [];
          // //console.log(res);





          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
          this.router.navigate(['/login']);


        }
      }, async e => {
        this.common.hideLoader();

        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

      });
    }
    // //console.log(this.tokenget);

  }






  Submit() {
    let againid: any = document.getElementById("confirmpasserror");
    let passwordverification: any = this.resetformforloophead.value.passwordLoginpage === this.resetformforloophead.value.passwordconfirmLoginpage;
    // //console.log(passwordverification);
    if (passwordverification) {
      againid.innerHTML = "";
      this.Passandconfirmvalid = true;
    } else {
      againid.innerHTML = "Passsword and Confirm Password didnt match";
      this.Passandconfirmvalid = false;
    }



    let sendingData = {
      password: this.resetformforloophead.value.passwordLoginpage,
      confirm_new_password: this.resetformforloophead.value.passwordconfirmLoginpage,
      pwd_verification_token: this.tokenget
    }

    if (this.resetformforloophead.valid == true && this.Passandconfirmvalid == true) {

      this.common.showLoader();
      this.http.post(APIURL.BASE_URL+APIURL.RESET_USER_PASSWORD, sendingData).subscribe(async (res: any) => {
        if (res.response.status == 1) {
          // //console.log(res);
          this.common.hideLoader();
          this.toastr.success(res.response.msg, "Success", { positionClass: 'toast-bottom-right' });
          this.router.navigate(['/login']);
        }
        else {
          this.common.hideLoader();
          let errorthroughapi = res.response.errors;
          let error = [];
          for (var i = 0; i < errorthroughapi.length; i++) {

            this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
          }

        }
      }, async e => {
        this.common.hideLoader();
        this.toastr.error("Error in Api", "Error", { positionClass: 'toast-bottom-right' });

      });
    }




  }
}









