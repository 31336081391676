<h3 class="dynamictext">Subscription Plans</h3>

<div>
  <div>
    <ul class="name">

      <li>
        <form class="displayinline">
          <input type="search" [(ngModel)]="validationforsearch" class="form-control rounded form-control-sm" placeholder="Search"
            aria-label="Search" name="searchsubscription" id="searchsubscription" aria-describedby="search-addon" />
          <button class="btn btn-theme" style="margin: 0px;" type="submit" (click)="onsearch()"><i class="fa fa-search"></i></button>
        </form>
      </li>
      <!-- <li><button [disabled]=true routerLink="/subscriptionplan/new" class="btn btn-theme"> <i class="fa fa-plus"
            aria-hidden="true"></i> Add
          Subsription Plan</button></li> -->
    </ul>
  </div>
</div>
<div class="table-responsive">
  <table class="table table-striped  table-bordered " *ngIf = "!noresult">
    <thead>
      <!-- <tr>
        <th class="text-center">Plan Name <i (click)="sortOn('Plan_name')" class="hover fa fa-{{sortsign}}"
            aria-hidden="true"></i></th>
        <th class="text-center">Plan Price($) <i (click)="sortOn('Plan_price')" class=" hover fa fa-{{sortsign}}"
            aria-hidden="true"></i></th>
        <th class="text-center">Status <i (click)="sortOn('is_enabled')" class="hover fa fa-{{sortsign}}"
            aria-hidden="true"></i></th>
        <th class="text-center">Created Date <i (click)="sortOn('created_on')" class="hover fa fa-{{sortsign}}"
            aria-hidden="true"></i></th>
        <th class="text-center">Action</th>
      </tr> -->
      <tr>
        <th class="text-center marginfa" *ngFor = "let items of tablevaluearray">{{items.name}} <i (click)="sortOn(items)"  *ngIf = "items.showicon" id="{{items.tablehead}}"  class =  {{items.class}} style="cursor: pointer;"
            aria-hidden="true"></i></th>
            <!-- class="hover marginfa {{items.tablehead}}  fa fa-{{items.sort? 'sort-asc':'sort'}} fa fa-{{items.sortdesc?'sort-desc':'sort'}}" -->
        <!-- <th class="text-center">Loops to upload <button (click)="sortOn('loopstoupload')"><i class="fa fa-sort" aria-hidden="true"></i></button></th>
                <th class="text-center">Can sell loops <button (click)="sortOn('cansellloops')"><i class="fa fa-sort" aria-hidden="true"></i></button></th>
                <th class="text-center">Loopkits to upload <button (click)="sortOn('toupload')"><i class="fa fa-sort" aria-hidden="true"></i></button></th>
                <th class="text-center">Can sell loopkits <button (click)="sortOn('cansellloopkit')"><i class="fa fa-sort" aria-hidden="true"></i></button></th> -->
      </tr>
    </thead>
    <tbody>


      <tr *ngFor="let item of tableData">
        <td class="text-center">{{item.plan_name}}</td>
        <td class="text-center">$ {{item.plan_price}}</td>
        <!-- <td class="text-center"  >{{item?.loops_to_upload}}</td>
        <td class="text-center" >{{item.can_sell_loops?'Yes':'No'}}</td>
        <td class="text-center">{{item.loopkits_to_upload}}</td>
        <td class="text-center">{{item.can_sell_loopkits?'Yes':'No'}}</td> -->
        <td class="text-center" [ngClass]="item.is_enabled?'text-success':'text-danger'">
          {{item.is_enabled?'Enabled':'Disabled'}}</td>
        <td class="text-center">{{item.created_on| date: 'dd-MM-yyyy h:mm a'}} </td>
        <td><a class="text-center hover" (click)="encryptUsingAES256(item.plan_id)" ><i
              class="fa fa-pencil-square-o text-center" aria-hidden="true"></i></a></td>
      </tr>
<!-- [routerLink]="['/admin/subscriptionplan/'+item.plan_id]" -->

    </tbody>
    
  </table>
<div *ngIf = "noresult" class="noresult">No result to display</div>

</div>
<div class="text-center">
  <ul class="paginationlist">
    <!-- <li *ngIf = "!noresult" class="text-info">Showing {{record}} records of {{total}}</li> -->
    <li class="text-info" *ngIf="!noresult">Showing {{record}} to {{pagenocount}} records of {{total}}</li>
    <!-- <li class="text-info"> {{total}}</li> -->
    <ul class="nextulpagination">

      <!-- <li class="paginationliyellowcolor bluecolor" *ngFor="let item of pagercount" id="liststyle{{item}}"
        (click)="paginationget(item)">
        {{item}}

      </li> -->
    </ul>

  </ul>


</div>