import { Component, ElementRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, INPUT_TYPE_NAME, VARS } from 'src/app/allServices/constant.service';
import { StaticService } from 'src/app/sevicesall/static.service';

import { COMMA, E, ENTER } from '@angular/cdk/keycodes';
import { ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as CryptoJS from 'crypto-js';
import { Formp3filevalidationService } from 'src/app/sevicesall/formp3filevalidation.service';
import { MatDialog } from '@angular/material/dialog';
// import { ConfirmLeaveComponent } from '../../MODAL/confirm-leave/confirm-leave.component';
import { NavigationEnd } from '@angular/router';
import { HostListener } from "@angular/core";
import { CanDeactivate } from '@angular/router';
import { ConfirmLeaveComponent } from '../../MODAL/confirm-leave/confirm-leave.component';
import { ForprogressService } from 'src/app/forprogress.service';
import { Title } from "@angular/platform-browser"
// import { ConfirmLeaveComponent } from '../CLIENT_PAGES/MODAL/confirm-leave/confirm-leave.component';
// import { ForprogressService } from '../forprogress.service';
declare interface Window {
  AudioContext: AudioContext;
}
var thiscomp: any;
declare var p5: { SoundFile: new (arg0: string, arg1: (s: any) => void, arg2: (err: any) => void) => any; };
// import { getAudioDurationInSeconds } from 'get-audio-duration';
@Component({
  selector: 'app-uploadloopkit',
  templateUrl: './uploadloopkit.component.html',
  styleUrls: ['./uploadloopkit.component.css']
})
export class UploadloopkitComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;
  progress: any = 0;
  whenpreferencenotselected: any = true;
  showwhenpreferenceselected: any = false;
  imagevalidation = true;
  wavfilevalidation = false;
  optionvalueArray2box: any = [];
  uploadloopkit = StaticService.uploadloopkituser();
  url = "assets/img/artwork.jpg";
  fileerrorformandatory = false;
  tableDataofgenre: any;
  tableDataoftags: any;
  tableDataofinstrumentcategory: any;
  instrumentcategory: any;
  tableDataofinstruments: any;
  genrecategory: any;
  tableDataofsubgenre: any;
  reader = new FormData();
  tagvalid = false;
  nameoffile: any;
  uploadcompletestatus: any;
  hidedetailcontent: any = false;
  showform: any = true;
  premiumselectedpriceshow: any = false
  disablesaleplane: any = false;
  priceerror: any = true;
  priceerrorshow: any;
  arrayofgenre: any = [];
  searchsymbol: any = "";
  private changeCallback: Function;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: any[] = [];
  allFruits: any = [];
  tagErr = '';
  showTagErr = false;
  tagArr: any = [];
  selected1: any = [];
  pricetag: any;
  arrforsubgenre: any = [];
  banned_word: any = [];
  errorshowfortitle: any;
  validtitle: any = false;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  durationText: any;
  currentRoute: string;
  isdirty: any = true;
  showartworkcancelbutton: boolean;
  canDeactivate: any = false;
  showerror: string;
  showerrorinlooptitle: boolean;
  mp3duration: any;
  getloopidfrommedia = "";
  imagename: any;
  value = 0;
  showloaderloading = false;
  showloaderloading2 = false;
  errorshowloopkitno: string;
  errorforloopkitno = false;
  yesartwork = false;
  showartworkcancel = false;

  yesfirstloop = false;
  shownowfirstloader = false;
  yessecondloop = false;
  shownowsecondloader = false;
  valuefromurl: any
  encryptedagainremoveslashes: any;

  endstring: string;
  Loopvalue: string;
  show1bar = false;
  show2bar = false;
  editloopapi: boolean = false;
  disable_input_as_itsloading = false;
  valueofprogressbarofrequiredloop: number;
  valueofprogressbaroftaggedversionofloop: number;
  show_message_while_uploading = 'You cannot perform any action while uploading';
  showleftform: boolean = false;

  @HostListener('window:beforeunload', ['$event'])

  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }

  }

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;


  dragClass = "";
  arr: any = [];

  public files: NgxFileDropEntry[] = [];
  errorshow: string;
  cancelsign: any;
  nameoffileformp3: any;

  trim() {
    let valueoftitle = this.uploadloopkit.value.uploadlooptitle
    let ab = valueoftitle.trim();
    // //console.log(ab.length);
    if (ab.length < 3 && ab.length !== 0) {
      this.showerror = "Min 3 characters allowed";
      this.showerrorinlooptitle = false;
    } else {
      this.showerror = "";
      this.showerrorinlooptitle = true;
    }
  }



  public dropped(files: NgxFileDropEntry[]) {
    let id: any = document.getElementById("fileerrorformandatory");
    this.files = files;

    files[0].fileEntry.isFile;
    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      if (file == undefined) {
        // //console.log("in");
        // this.url = "assets/img/artwork.jpg";
        this.fileerrorformandatory = false;
        this.progress = false;
        id.innerHTML = "";
      } else {
        let extensiontype = file.name.split('.').pop();;
        // //console.log(extensiontype);

        if (extensiontype == 'rar' || extensiontype == 'zip') {
          if (file.type == '' || file.type == 'application/x-zip-compressed' || file.type == 'application/zip') {
            if (file.size == 0 || file.size > 1000000000) {
              this.fileerrorformandatory = false;
              id.innerHTML = "File size cannot be 0 mb or above 1 Gb";
            } else {
              if (file) {
                this.progress = 0;
                this.changeProgress();
                this.nameoffile = file.name;
                setTimeout(() => {
                  this.uploadcompletestatus = "Upload Complete";

                }, 1000);
                this.zipfile = file;
                this.zipfilename = file;


                this.fileerrorformandatory = true;
                id.innerHTML = "";

                this.reader.append('main_loopkit', this.zipfile, this.zipfilename);

                if (this.getloopidfrommedia !== "") {
                  this.reader.append("loop_id", this.getloopidfrommedia);
                }

                this.opendialogueforverification_insame("Loop")
              }
            }
          } else {
            this.fileerrorformandatory = false;
            id.innerHTML = "Only .zip or .rar media is supported ";
          }
        } else {
          this.fileerrorformandatory = false;
          id.innerHTML = "Only .zip or .rar media is supported ";
        }
      }
    });


  }

  cancelzipfile() {
    let id: any = document.getElementById("fileerrorformandatory");
    this.fileerrorformandatory = false;
    this.progress = false;
    id.innerHTML = "";
    this.nameoffile = "";
    // //console.log("Cancel");

  }
  cancelmp3file() {
    let id: any = document.getElementById("fileerror");


    this.progress1 = false;
    this.nameofTagfile = "";
    this.wavfilevalidation = false;
  }

  public fileOver(event: any) {
    this.dragClass = 'backgroundDrop';
  }
  public fileLeave(event: any) {
    this.dragClass = '';
  }




  constructor(private formbuilder: FormBuilder, public common: commonservice, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, public api: ApiServiceService, private el: ElementRef, public matDialog: MatDialog, public forprogress: ForprogressService, private titleService: Title) {
    // this.callnow();
    this.titleService.setTitle("Upload");

  }


  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      // //console.log(value);
      this.fruits.push(value);
    }

    // Clear the input value
    // event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
    this.checktag(value);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();

    // return this.allFruits.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }



  ngOnInit(): void {

    this.valuefromurl = this.route.snapshot.queryParamMap.get('loop_id');


    if (this.valuefromurl) {
      this.showleftform = true
      this.valueofprogressbarofrequiredloop = 100;
      this.valueofprogressbaroftaggedversionofloop = 100;
      this.editloopapi = true
      this.common.showLoader();
      this.encryptedagainremoveslashes = decodeURIComponent(this.valuefromurl);
      this.decryptUsingAES256();
      // //console.log("api hit");
      this.Loopvalue = "Edit Upload Loop Kit"
      this.endstring = "Save Upload Loop Kit"

    } else {
      this.editloopapi = false
      this.Loopvalue = "Upload Loop Kit"
      this.endstring = "Upload Loop Kit"
      // //console.log(" noapi hit");
    }


    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.getUserDetail();
      }
      let resa = JSON.parse(res)
      if (resa) {
        this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed);
        this.loopheadData = resa;
        this.loopdetails();
      }
    })
    this.getapicallforalldata();

    // if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
    //   //console.log("load");
    //   var r = confirm("Are you sure you want to leave? Your upload will be deleted");
    //   if (r == true) {

    //   } else {

    //   }
    // } else {
    //   //console.log("no load");
    // }
    // window.onbeforeunload = function(){
    //   return "Are you sure?";
    // }

    window.addEventListener("keyup", disableF5);

    window.addEventListener("keydown", disableF5);



    function disableF5(e: any) {

      if ((e.which || e.keyCode) == 116) e.preventDefault();

    };


  }

  decryptUsingAES256() {
    // //console.log(this.encryptedagainremoveslashes);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    // //console.log(this.decrypted);
    this.decrypted = this.decrypted.replace(/[""]/gi, '');
    // //console.log(this.tokenFromUI);
    // //console.log(this.decrypted)
    this.apihetforgetallldata(this.decrypted);
    // this.getallinfoofloops(this.decrypted);
  }

  apihetforgetallldata(value: any) {
    this.getloopidfrommedia = value




    let sendingdata = {

      "loop_id": value,

    }
    // this.http.post(APIURL.BASE_URL + ``, sendingdata, { headers }).subscribe(async (res: any) => {
    this.api.postApiHit('/user/get_upload_detail', sendingdata, VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        // //console.log(res.response.data.loop_details[0])
        this.callforsetdata(res.response.data.loop_details[0]);
        this.common.hideLoader();
        // this.getallinfoofloops();
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      }

      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }


    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });

  }

  callforsetdata(res: any) {

    this.imagevalidation = true
    this.wavfilevalidation = true
    this.fileerrorformandatory = true
    this.tagvalid = true
    this.wavfilevalidation = true
    this.priceerror = true
    this.validtitle = true
    this.showerrorinlooptitle = true
    this.errorforloopkitno = false
    this.priceerror = true

    // this.instrumentssubgenre(res.parent_instrument_id);
    this.genrecategoryapi(res.parent_genre_id);
    if (res.loop_price) {
      this.premiumselectedpriceshow = true
      this.showwhenpreferenceselected = true
      this.pricetag = res.loop_price
    }

    if (res.selling_preference == 1) {
      this.hidesellingprefernce('free')
    } else {
      this.hidesellingprefernce('premium')
    }

    if (res.artwork_path) {
      this.imagevalidation = true;
      // id.innerHTML = "";
      // this.showartworkcancelbutton = true;
      this.showartworkcancel = true;
      this.showartworkcancelbutton = true
      this.url = CONSTNAME.fILE_PATH + res.artwork_path;
    }


    let a = res.tags
    let strtag: any = a.split('\n ');
    let strtag1: any = strtag[0];
    let strtag2: any = strtag[1];
    let strtag3: any = strtag[2];
    this.tag1 = strtag[0]
    this.tag2 = strtag[1]
    this.tag3 = strtag[2]
    this.fruits.push(strtag1)
    this.fruits.push(strtag2)
    this.fruits.push(strtag3)


    this.shownowfirstloader = true
    this.progress = 0
    this.progress = true;
    // this.show1bar = true
    this.changeProgress();


    this.shownowsecondloader = true
    this.progress1 = 0;
    this.progress1 = true;
    this.changeProgress1();
    this.show2bar = true
    // this.show1bar = false

    // if (res.main_key == 1) {
    //   this.yesinsert2 = true
    // } else{
    //   this.yesinsert2 = true
    // }
    // //console.log(strtag1,strtag2,strtag3)
    // //console.log(this.fruits)

    //   let a = items.tags
    //   let strtag: any = a.split('\n ');

    //   let strtag2: any = strtag[1];
    //   return "#" + strtag2
    // }
    // tagsget3(items: any) {
    //   let a = items.tags
    //   let strtag: any = a.split('\n ');
    //   //  //console.log(strtag[2])
    //   let strtag3: any = strtag[2];
    //   // //console.log(strtag3);
    //   return "#" + strtag3
    // }
    // //console.log(res.main_key)

    this.uploadloopkit.patchValue({
      uploadlooptitle: res.title,
      // uploadloopbpm: res.bpm,
      // instrumentcategory: res.parent_instrument_id,
      genre: res.parent_genre_id,
      sellingpreference: res.selling_preference,
      selling_preference_type: res.terms,
      descriptionbox: res.description,
      uploadloopno: res.loops_count
      // key: Number(res.main_key),
      // keynotes:res.sub_key









    });

    if (res.instrument_id) {
      this.uploadloopkit.patchValue({
        instruments: res.instrument_id,
      })
    }
    if (res.genre_id) {
      this.uploadloopkit.patchValue({
        subgenre: res.genre_id,
      })
    }
  }

  detailpageofloop() {
    // //console.log(value);
    // //console.log(`/detail-loop?loop_id=${value}`);
    this.encryptUsingAES256forpage(this.decrypted);

  }

  encryptUsingAES256forpage(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });
    window.open(`${APIURL.BASE_URLforredirect}/detail-loop?loop_id=${this.encrypted}`, `_blank`)

    // this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });

    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);
  }



  vCheck(fieldName: any, type: INPUT_TYPE_NAME, options: any = {}) {
    options.isRequired = true;
    const cFrm = this.uploadloopkit.controls;
    if (this.uploadloopkit.pristine == false) {
      this.canDeactivate = true;
    }
    if (cFrm) {
      return StaticService.formError(cFrm, fieldName, type, options).msg;
    }
  }


  cancelartwork() {
    let id: any = document.getElementById("imageerror");
    this.url = "assets/img/artwork.jpg";
    this.imagevalidation = true;
    id.innerHTML = "";
    this.showartworkcancelbutton = false;


    this.callfordeleteartwork();
  }


  callfordeleteartwork() {
    this.common.showLoader();
    let sending = {

      "loop_id": this.getloopidfrommedia,
      "delete_artwork": 1

    }
    this.api.postApiHit(`/user/remove_optional_draft`, sending, VARS.ClIENT_TYPE).then((res: any) => {
      this.reader = new FormData();
      if (res.response.status == 1) {
        // this.getloopidfrommedia = res.response.data.loop_id;
        // this.reader = new FormData();
        this.common.hideLoader();
        this.showartworkcancel = false;
        this.yesartwork = false;

      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.reader = new FormData();
        this.common.hideLoader();

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Failed to get details of instrument", "", { positionClass: 'toast-bottom-right' });

    });

  }









  //  image file loopkit_artwork
  loopartwork: any

  selectFileimage(event: any) {
    thiscomp = this;
    let id: any = document.getElementById("imageerror");
    if (event.target.files[0] == undefined) {
      this.url = "assets/img/artwork.jpg";
      this.imagevalidation = true;
      id.innerHTML = "";
      // this.showartworkcancelbutton = false;
      this.canDeactivate = true;
    } else {
      let extensiontype = event.target.files[0].name.split('.').pop();;
      // //console.log(extensiontype);

      if (extensiontype == 'png' || extensiontype == 'jpeg' || extensiontype == 'jpg') {
        if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpg') {
          this.canDeactivate = true;
          if (event.target.files[0].size > 10000000) {
            this.imagevalidation = false;
            this.canDeactivate = true;

            id.innerHTML = "Image size can be max 10 mb";
          } else {

            for (const file of event.target.files) {
              const readers = new FileReader();
              readers.readAsDataURL(file);
              readers.onload = () => {
                const img = new Image();
                img.src = readers.result as string;
                img.onload = () => {
                  const height = img.naturalHeight;
                  const width = img.naturalWidth;
                  // //console.log('Width and Height', width, height);
                  if (height < 500 || width < 500) {
                    id.innerHTML = "Image height  should be in between 500*2000";
                    this.imagevalidation = false;
                    // thiscomp.runnow();
                  } else if (height > 2000 || width > 2000) {
                    id.innerHTML = "Image width  should be in between 500*2000";
                    this.imagevalidation = false;
                  } else {
                    // //console.log("in")
                    this.imagevalidation = true;
                    id.innerHTML = "";
                    // this.showartworkcancelbutton = true;
                    this.showartworkcancel = true;
                    // this.imageloopartwork = event.target.files[0]
                    this.loopartwork = event.target.files[0]


                    var read = new FileReader()
                    read.readAsDataURL(event.target.files[0])
                    read.onload = (event: any) => {
                      this.url = event.target.result;
                    }

                    this.imagename = event.target.files[0].name;
                    // //console.log(this.imagename);
                    this.reader.append("loopkit_artwork", this.loopartwork, this.imagename);
                    if (this.getloopidfrommedia !== "") {
                      this.reader.append("loop_id", this.getloopidfrommedia);
                    }
                    this.canDeactivate = true;

                    this.opendialogueforverification_insame("image");
                  }
                };
              };
            }
            // //console.log("in")
            const file = event.target.files[0];
            const img = new Image();
            // img.onload = function () {

            //   id.innerHTML = "";

            //   thiscomp.imagevalidation = true;

            //   thiscomp.imageloopartwork = event.target.files[0]
            //   if (thiscomp.imagevalidation == true) {
            //     // //console.log(thiscomp.imagevalidation)
            //     thiscomp.imagevalidation = true;
            //     id.innerHTML = "";
            //     // this.showartworkcancelbutton = true;
            //     thiscomp.showartworkcancel = true;


            //     var read = new FileReader()
            //     read.readAsDataURL(event.target.files[0])
            //     read.onload = (event: any) => {
            //       thiscomp.url = event.target.result;
            //     }

            //     thiscomp.imagename = event.target.files[0].name;
            //     // //console.log(this.imagename);
            //     thiscomp.reader.append("loop_artwork", thiscomp.imageloopartwork, thiscomp.imagename);
            //     if (thiscomp.getloopidfrommedia !== "") {
            //       thiscomp.reader.append("loop_id", thiscomp.getloopidfrommedia);
            //     }
            //     thiscomp.canDeactivate = true;

            //     thiscomp.opendialogueforverificatio();

            //   }
            // }

            // };
            // img.src = URL.createObjectURL(file);




          }






        }


        if (event.target.files) {

          id.innerHTML = "";

          // thiscomp.imagevalidation = true;
          // thiscomp.loopartwork = event.target.files[0]
          // thiscomp.showartworkcancel = true;
          // thiscomp.imagevalidation = true;
          // id.innerHTML = "";
          // var read = new FileReader()
          // read.readAsDataURL(event.target.files[0])
          // read.onload = (event: any) => {
          //   thiscomp.url = event.target.result;
          // }

          // thiscomp.imagename = event.target.files[0].name;

          // thiscomp.reader.append("loopkit_artwork", thiscomp.loopartwork, thiscomp.imagename);
          // if (thiscomp.getloopidfrommedia !== "") {
          //   thiscomp.reader.append("loop_id", thiscomp.getloopidfrommedia);
          // }
          // thiscomp.canDeactivate = true;
          // thiscomp.opendialogueforverificatio()







        } else {
          this.imagevalidation = false;
          id.innerHTML = "Image file should be in jpeg,png,jpg";


        }
      } else {
        this.imagevalidation = false;
        id.innerHTML = "Image file should be in jpeg,png,jpg";


      }

    }
  }


  // mp3 file demo_loopkit
  mp3file: any;
  mp3filename: any;

  async selectwavFilemp3(event: any) {
    let id: any = document.getElementById("fileerror");
    this.canDeactivate = true;

    if (event.target.files[0] == undefined) {
      this.progress1 = false;
      this.nameofTagfile = "";
      this.wavfilevalidation = false;

      id.innerHTML = "";
      this.showloaderloading2 = false
    } else {
      let extensiontype = event.target.files[0].name.split('.').pop();;
      // //console.log(extensiontype);

      if (extensiontype == 'mpeg' || extensiontype == 'mp3') {


        if (event.target.files[0].type == "audio/mpeg") {
          if (event.target.files[0].size > 75000000) {
            this.wavfilevalidation = false;
            id.innerHTML = "File size can not be more than  75mb";
            this.showloaderloading2 = false
          } else {
            if (event.target.files) {
              let sound: any;

              const path = URL.createObjectURL(event.target.files[0]);
              sound = await new Promise((resolve, reject) => {
                new p5.SoundFile(path, (s: any) => resolve(s), (err: any) => {
                  this.wavfilevalidation = false;
                  id.innerHTML = "Only .mp3 media is supported ";
                });
              });
              // //console.log('Sample Rate: ' + sound.sampleRate() + '\nChannels: ' + sound.channels() + '\nDuration: ' + sound.duration());

              // //console.log(audioCtx.sampleRate);
              if (sound.sampleRate() < 44100) {
                this.wavfilevalidation = false;
                this.nameofTagfile = "";
                id.innerHTML = "Min Precision can be 44100 Khz";
                this.showloaderloading2 = false
              } else if (sound.sampleRate() > 48000) {
                // //console.log("gaya");
                this.wavfilevalidation = false;
                this.nameofTagfile = "";
                id.innerHTML = "Max duration can be 48000 Khz";
                this.showloaderloading2 = false
              } else if (sound.channels() !== 2) {
                this.wavfilevalidation = false;
                this.nameofTagfile = "";
                id.innerHTML = "Minimum channels for the mp3 file must be 2 ";
                this.showloaderloading2 = false
              }
              else if (sound.channels() < 1) {
                this.wavfilevalidation = false;
                id.innerHTML = "Min duration can be 1 second";
                this.nameofTagfile = "";
                this.showloaderloading2 = false
              } else if (sound.duration() > 300) {
                this.wavfilevalidation = false;
                id.innerHTML = "Max duration should be 300 second";
                this.showloaderloading2 = false
                this.nameofTagfile = "";
              } else {
                this.progress1 = 0;
                this.changeProgress1();
                this.nameofTagfile = event.target.files[0].name;
                this.mp3file = event.target.files[0];
                this.mp3filename = event.target.files[0].name
                this.nameoffileformp3 = event.target.files[0].name;
                this.wavfilevalidation = true;
                id.innerHTML = "";
                this.mp3duration = sound.duration();

                this.reader.append("demo_loopkit", this.mp3file, this.mp3filename);
                this.reader.append("tagged_loop_duration", this.mp3duration);

                if (this.getloopidfrommedia !== "") {
                  this.reader.append("loop_id", this.getloopidfrommedia);
                }
                this.canDeactivate = true;
                this.showloaderloading2 = true


                this.opendialogueforverification_insame("tagged");;

                // this.api.postApiHit(`/user/upload_loopkit_draft`, this.reader, VARS.ClIENT_TYPE).then((res: any) => {
                //   this.reader = new FormData();
                //   if (res.response.status == 1) {
                //     this.getloopidfrommedia = res.response.data.loop_id;
                //     this.reader = new FormData();

                //   } else {
                //     let errorthroughapi = res.response.errors;
                //     let error = [];
                //     this.reader = new FormData();

                //     for (var i = 0; i < errorthroughapi.length; i++) {
                //       let jk = error.push(errorthroughapi);
                //       this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
                //     }
                //   }
                // }, async e => {
                //   this.toastr.error("Failed to get details of instrument", "", { positionClass: 'toast-bottom-right' });

                // });
              }

            }
          }
        } else {
          this.wavfilevalidation = false;
          this.nameofTagfile = "";
          id.innerHTML = "Only .mp3 media is supported ";
        }
      } else {
        this.wavfilevalidation = false;
        this.nameofTagfile = "";
        id.innerHTML = "Only .mp3 media is supported ";
      }
    }
  }

  zipfile: any;
  zipfilename: any;
  //  zip/rar file mandatory main_loopkit
  selectFilemandatoryzip(event: any) {
    // //console.log(event.target.files[0].type);
    let id: any = document.getElementById("fileerrorformandatory");
    // //console.log("gaya")
    if (event.target.files[0] == undefined) {
      // this.url = "assets/img/images.png";
      // //console.log("cancel");
      this.fileerrorformandatory = false;
      this.progress = false;
      id.innerHTML = "";
      this.canDeactivate = true;
      this.showloaderloading = false;
    } else {
      let extensiontype = event.target.files[0].name.split('.').pop();;
      // //console.log(extensiontype);

      if (extensiontype == 'rar' || extensiontype == 'zip') {


        if (event.target.files[0].type == '' || event.target.files[0].type == 'application/x-zip-compressed' || event.target.files[0].type == 'application/zip') {
          this.canDeactivate = true;
          if (event.target.files[0].size == 0 || event.target.files[0].size > 1000000000) {
            this.fileerrorformandatory = false;
            id.innerHTML = "File size cannot be 0 mb or above 1 Gb";
            this.showloaderloading = false;
          } else {
            if (event.target.files) {
              // //console.log("i am in");
              this.progress = 0;
              this.changeProgress();
              this.nameoffile = event.target.files[0].name;
              setTimeout(() => {
                this.uploadcompletestatus = "Upload Complete";
              }, 1000);
              this.zipfile = event.target.files[0];
              this.zipfilename = event.target.files[0].name;
              // //console.log(this.zipfilename);


              this.fileerrorformandatory = true;
              id.innerHTML = "";

              this.reader.append('main_loopkit', this.zipfile, this.zipfilename);

              if (this.getloopidfrommedia !== "") {
                this.reader.append("loop_id", this.getloopidfrommedia);
              }

              // this.forprogress.upload(this.zipfile,this.zipfilename).pipe().subscribe((event:any) => {
              //   //console.log(event);
              //   if (event['loaded'] && event['total']) {
              //   this.value =  Math.round((event['loaded'] / event['total'] * 100))
              //   }

              // })
              this.canDeactivate = true;
              this.showloaderloading = true;
              // this.opendialogueforverificatio();
              this.opendialogueforverification_insame("Loop");










              // this.api.postApiHit(`/user/upload_loopkit_draft`, this.reader, VARS.ClIENT_TYPE).then((res: any) => {
              //   this.reader = new FormData();
              //   if (res.response.status == 1) {
              //     this.getloopidfrommedia = res.response.data.loop_id;
              //     this.reader = new FormData();

              //   } else {
              //     let errorthroughapi = res.response.errors;
              //     let error = [];
              //     this.reader = new FormData();

              //     for (var i = 0; i < errorthroughapi.length; i++) {
              //       let jk = error.push(errorthroughapi);
              //       this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
              //     }
              //   }
              // }, async e => {
              //   this.toastr.error("Failed to get details of instrument", "", { positionClass: 'toast-bottom-right' });

              // });

            }
          }
        } else {
          this.fileerrorformandatory = false;
          id.innerHTML = "Only .zip or .rar media is supported ";
        }
      } else {
        this.fileerrorformandatory = false;
        id.innerHTML = "Only .zip or .rar media is supported ";
      }
    }
  }



  changeProgress() {
    setTimeout(() => {
      if (this.progress < 100) {
        this.uploadcompletestatus = "Uploading"
        this.progress = this.progress + 10;
        this.changeProgress()
      } else if (this.progress == 100) {
        this.uploadcompletestatus = "Upload Complete";
        this.dragClass = '';
      }
    }, 100);
  }







  optionvalueArray: any;
  hidesellingprefernce(value: any) {
    // //console.log(value);
    this.whenpreferencenotselected = false;
    this.showwhenpreferenceselected = true;
    let idaa1: any = document.getElementById("firstdiv");
    let idaa2: any = document.getElementById("seconddiv");
    if (value == "free") {
      this.premiumselectedpriceshow = false;
      idaa1.style.backgroundColor = "orange";
      if (idaa2) idaa2.style.backgroundColor = "";

      this.uploadloopkit.patchValue({
        sellingpreference: 1,
        selling_preference_type: 1
      })
      this.optionvalueArray = [
        { name: 'Free', value: 1, selected: true },
        { name: 'For Sale', value: 2, selected: false }
      ];

      this.optionvalueArray2box = [
        { name: 'Royality Free', value: 1 },
        { name: '50/50 Master + Publishing split', value: 2 }
      ];
    } else {
      this.premiumselectedpriceshow = true;
      idaa2.style.backgroundColor = "orange";
      idaa1.style.backgroundColor = "";
      this.uploadloopkit.patchValue({
        sellingpreference: 2,
        selling_preference_type: 1
      })
      this.optionvalueArray = [
        { name: 'For Sale', value: 2, selected: true },
        { name: 'Free', value: 1, selected: false }
      ];

      this.optionvalueArray2box = [
        { name: 'Royality Free', value: 1 },
        { name: '50/50 Master + Publishing split', value: 2 },
        { name: 'Exclusive', value: 3 }
      ];
    }
  }





  listchanged(value: any) {
    if (this.uploadloopkit.value.sellingpreference == 1) {
      this.uploadloopkit.patchValue({
        selling_preference_type: 1
      })
      this.optionvalueArray2box = [
        { name: 'Royality Free', value: 1 },
        { name: '50/50 Master + Publishing split', value: 2 }
      ];
    } else {
      this.uploadloopkit.patchValue({
        selling_preference_type: 1
      })
      this.optionvalueArray2box = [
        { name: 'Royality Free', value: 1 },
        { name: '50/50 Master + Publishing split', value: 2 },
        { name: 'Exclusive', value: 3 }
      ];
    }
  }

  changevalue = false;
  changevalueofsubgenre = false;


  list: any;
  getapicallforalldata() {
    const sendingdata = "";
    this.api.postApiHit(`/user/get_genre_instru_tags`, sendingdata, VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        this.tableDataofinstrumentcategory = res.response.data.instrument_categories;
        this.tableDataofgenre = res.response.data.genres;
        this.allFruits = res.response.data.tags;
        this.arrayofgenre = this.arrayofgenre.push(res.response.data.genres[0].genre_name);
        for (i = 0; i < res.response.data.genres.length; i++) {
          this.arr.push(res.response.data.genres[i].genre_name);
        }
        for (i = 0; i < res.response.data.banned_words.length; i++) {
          this.banned_word.push(res.response.data.banned_words[i].banned_word);
        }

      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.toastr.error("Failed to get details of instrument", "", { positionClass: 'toast-bottom-right' });
    });
  }


  testhtml() {
    if (this.changevalue == true) {
      return "Loading...Please wait"
    } else {
      return "Select";
    }

  }

  testhtmlforsubgenre() {
    if (this.changevalueofsubgenre == true) {
      return "Loading...Please wait"
    } else {
      return "Select";
    }
  }


  valuepassgenre() {
    this.genrecategory = this.uploadloopkit.value.genre;

    // //console.log(this.genrecategory);
    this.genrecategoryapi(this.genrecategory);
    if (this.uploadloopkit.value.genre === "") {
      this.changevalueofsubgenre = false;
    } else {
      this.changevalueofsubgenre = true;
    }
  }
  valuesubgenre() {
    // this.checktag();
  }

  genrecategoryapi(value: any) {
    let sendingData = {
      "genre_id": value
    }
    this.api.postApiHit('/user/get_sub_genres', sendingData, VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        this.tableDataofsubgenre = res.response.data;
        this.changevalueofsubgenre = false;
        for (i = 0; i < res.response.data.length; i++) {
          this.arrforsubgenre.push(res.response.data[i].genre_name);
        }
        // this.checktag();
      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.changevalueofsubgenre = false;
        for (var i = 0; i < errorthroughapi.length; i++) {
          // //console.log("hhh");
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.changevalueofsubgenre = false;
      this.toastr.error("Failed to get subgenres ", "", { positionClass: 'toast-bottom-right' });
    });
  }


  price: any;
  onSubmit() {
    // //console.log(this.uploadloopkit)
    this.canDeactivate = false;
    if (this.uploadloopkit.value.sellingpreference == 1) {
      this.priceerror = true;
    } else if (this.uploadloopkit.value.sellingpreference == 2) {
      if (this.pricetag <= 0) {
        this.priceerror = false;
        this.priceerrorshow = "Price should be above zero"
      } else if (isNaN(this.pricetag)) {
        this.priceerror = false;
        this.priceerrorshow = "Price should be in number only"
      } else {
        this.priceerrorshow = ""
        this.priceerror = true;
        this.price = this.pricetag
      }
    }

    for (const key of Object.keys(this.uploadloopkit.controls)) {
      if (this.uploadloopkit.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }



    if (this.fruits.length == 0) {
      this.errorshow = "This field is required";
    } else {
      // this.errorshow = "";
    }
    const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    StaticService.markFormGroupTouched(this.uploadloopkit, invalidElements);
    if (this.uploadloopkit.valid && this.imagevalidation == true && this.wavfilevalidation == true && this.fileerrorformandatory == true && this.tagvalid == true && this.wavfilevalidation == true && this.priceerror == true && this.validtitle == true && this.showerrorinlooptitle == true && this.errorforloopkitno == false && this.priceerror == true) {
      this.apihitfullsuccess();
      this.canDeactivate = false;
    }
    if (this.fileerrorformandatory == false) {
      let id: any = document.getElementById("fileerrorformandatory");
      id.innerHTML = "This Field is required";
    } else {
      let id: any = document.getElementById("fileerrorformandatory");
      id.innerHTML = "";
    }
    if (this.wavfilevalidation == false) {
      let id: any = document.getElementById("fileerror");
      id.innerHTML = " This Field is required  ";
    } else {
      let id: any = document.getElementById("fileerror");
      id.innerHTML = "";
    }
  }


  apihitfullsuccess() {
    this.canDeactivate = false;
    // //console.log("checking");
    this.common.showLoader();
    let checkbox: any;
    if (this.uploadloopkit.value.tickbox == true) {
      checkbox = 1
    } else {
      checkbox = 0
    }

    let sendingdata: any = {
      "loop_price": this.price,



      "title": this.uploadloopkit.value.uploadlooptitle,
      "loops_count": this.uploadloopkit.value.uploadloopno,
      "genre": this.uploadloopkit.value.genre,
      "sub_genre": this.uploadloopkit.value.subgenre,
      "selling_preference": this.uploadloopkit.value.sellingpreference,
      "terms": this.uploadloopkit.value.selling_preference_type,
      "description": this.uploadloopkit.value.descriptionbox,
      "checkbox": checkbox,
      "tags": [this.tag1, this.tag2, this.tag3],
      // "loop_id": this.getloopidfrommedia/
    }


    if (this.editloopapi) {
      sendingdata["loop_id"] = Number(this.decrypted)
    } else {
      sendingdata["loop_id"] = this.getloopidfrommedia
    }


    this.tableDataofgenre.map((data: any) => {
      if (data.genre_id == this.uploadloopkit.value.genre) {
        sendingdata["visible_genre_name"] = data.genre_name
      }
    })

    this.tableDataofsubgenre.map((data: any) => {
      if (data.genre_id == this.uploadloopkit.value.subgenre) {
        sendingdata["visible_sub_genre_name"] = data.genre_name
      }
    })



    // if (!this.reader) {

    // }
    // //console.log(this.reader);
    // //console.log(this.uploadloopkit.value.genre);



    this.api.postApiHit('/user/upload_loopkit_test', sendingdata, VARS.ClIENT_TYPE).then((res: any) => {


      if (res.response.status == 1) {
        this.canDeactivate = false;
        this.reader = new FormData();
        this.common.hideLoader();
        let keys = (res.response.data.loop_id);
        // //console.log(keys);
        this.getUserDetail();

        if (this.editloopapi) {
          this.toastr.success("Loop Kit Edited Successfully", "", { positionClass: 'toast-bottom-right' })
        } else {
          this.toastr.success("Success! Your Loop Kit is live", "", { positionClass: 'toast-bottom-right' })
        }
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        this.encryptUsingAES256(keys);
        // this.router.navigate(['detail-loop'], { queryParams: { loop_id: keys } });
      } else if (res.response.status == 2) {
        alert("You exceeded your upload limit, upgrade to Premium for unlimited uploads!");
        this.common.hideLoader();
      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.reader = new FormData();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Failed ", "", { positionClass: 'toast-bottom-right' });
    });
  }


  toggleSelection(item: any) {
    this.changeCallback()
  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    // //console.log(this.encrypted);
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });

    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);
  }



  getUserDetail() {
    this.showloader = true;
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          let resa = JSON.parse(res);
          this.loopheadData = resa;
          this.showloader = false;
          this.loopdetails();
        })
      }
    })
  }

  loopheadData: any;
  showloader = false;


  public dropped1(files: NgxFileDropEntry[]) {
    let id: any = document.getElementById("fileerror");
    this.files = files;
    // //console.log('tdsf', files)
    files[0].fileEntry.isFile;
    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    fileEntry.file(async (file: File) => {
      // //console.log('one', files[0].relativePath, file);

      let extensiontype = file.name.split('.').pop();;
      // //console.log(extensiontype,file.name);

      if (extensiontype == 'mpeg' || extensiontype == 'mp3') {
        if (file['type'] == "audio/mpeg") {
          if (file.size > 10000000) {
            this.wavfilevalidation = false;
            id.innerHTML = "File size can be max 10 mb";
          } else {

            let sound: any;

            const path = URL.createObjectURL(file);
            sound = await new Promise((resolve, reject) => {
              new p5.SoundFile(path, (s: any) => resolve(s), (err: any) => reject(err));
            });
            // //console.log('Sample Rate: ' + sound.sampleRate() + '\nChannels: ' + sound.channels() + '\nDuration: ' + sound.duration());

            // //console.log(audioCtx.sampleRate);
            if (sound.sampleRate() < 44100) {
              this.wavfilevalidation = false;
              this.nameofTagfile = "";
              id.innerHTML = "Min Precision can be 44100 Khz";
            } else if (sound.sampleRate() > 48000) {
              // //console.log("gaya");
              this.wavfilevalidation = false;
              this.nameofTagfile = "";
              id.innerHTML = "Max duration can be 48000 Khz";
            } else if (sound.channels() !== 2) {
              this.wavfilevalidation = false;
              this.nameofTagfile = "";
              id.innerHTML = "Minimum channels for the mp3 file must be 2 ";
            }
            else if (sound.channels() < 1) {
              this.wavfilevalidation = false;
              id.innerHTML = "Min duration can be 1 second";
              this.nameofTagfile = "";
            } else if (sound.duration() > 300) {
              this.wavfilevalidation = false;
              id.innerHTML = "Max duration should be 300 second";
              this.nameofTagfile = "";
            } else {
              this.progress1 = 0;
              this.changeProgress1()
              this.nameofTagfile = file.name;
              // this.reader.append('tagged_loop', file, file.name);
              this.wavfilevalidation = true;
              id.innerHTML = "";
              this.mp3filename = file.name;
              this.mp3file = file;
              this.mp3duration = sound.duration();
              this.reader.append("demo_loopkit", this.mp3file, this.mp3filename);
              this.reader.append("tagged_loop_duration", this.mp3duration);

              if (this.getloopidfrommedia !== "") {
                this.reader.append("loop_id", this.getloopidfrommedia);
              }
              this.opendialogueforverification_insame("demo")
            }

          }
        } else {
          this.wavfilevalidation = false;
          this.nameofTagfile = "";
          id.innerHTML = "file should be in mp3 format";
        }
      } else {
        this.wavfilevalidation = false;
        this.nameofTagfile = "";
        id.innerHTML = "file should be in mp3 format";
      }
    });
  }

  nameofTagfile = '';
  newbackgroundClass = '';
  public fileOver1(event: any) {
    this.newbackgroundClass = 'backgroundDrop'
  }

  public fileLeave1(event: any) {
    this.newbackgroundClass = ''
  }

  progress1: any = 0;
  changeProgress1() {
    this.newbackgroundClass = '';
    setTimeout(() => {
      if (this.progress1 < 100) {
        this.progress1 = this.progress1 + 10;
        this.changeProgress1()
      } else if (this.progress1 == 100) {
        // this.progress1 = 0;
        this.newbackgroundClass = '';
      }
    }, 100);
  }

  checkdata = '';
  testww() {
    // //console.log('testClick')
  }
  loopdetails() {
    this.checkdata = this.loopheadData.subscription_type;
    // //console.log('thisdata', this.checkdata)
    if (this.loopheadData.subscription_type == "Free") {
      this.disablesaleplane = true;

    }
    if (this.loopheadData.loopkits_to_upload_allowed == 0) {
      this.showloader = false;
    } else {
      if ((this.loopheadData.loopkits_to_upload_allowed - this.loopheadData.already_uploaded_loopkits) > 0) {
        this.hidedetailcontent = true;
      } else {
        this.showform = false;
      }
    }
  }




  keyupfunc() {
    var text_max = 1000;
    let get: any = document.getElementById("descriptiontext");
    // //console.log(get.value.length);
    let text_remainning = text_max - get.value.length
    // //console.log(text_remainning);
    let getid: any = document.getElementById("textarea_feedback");
    if (get.value.length > 0) {
      getid.innerHTML = (text_remainning + " characters remaining");
    } else {
      getid.innerHTML = "";
    }
  }

  tag1: any;
  tag2: any;
  tag3: any;


  valuefortitle() {
    let tolower = function (x: any) {
      return x.toLowerCase();
    };
    let valuefortitle = this.uploadloopkit.value.uploadlooptitle;
    var res = valuefortitle.split(" ");
    res = res.map(tolower);
    // //console.log(this.banned_word);
    this.banned_word = this.banned_word.map(tolower)
    let allFounded = this.banned_word.some((ai: any) => res.includes(ai));
    if (allFounded == true) {
      this.errorshowfortitle = "Use of profanity is restricted"
      // //console.log("error")
      this.validtitle = false;
    } else {
      // //console.log("no error");
      this.errorshowfortitle = "";
      this.validtitle = true;
    }

  }

  valueforloopkit() {
    let valuefortitle: any = this.uploadloopkit.value.uploadloopno;

    if (valuefortitle > 1000) {
      this.errorforloopkitno = true;
      this.errorshowloopkitno = 'Loop kit cannot have more than 1000 loops'

    } else if (valuefortitle < 0) {
      this.errorforloopkitno = true;
      this.errorshowloopkitno = "Loopkit cannot be less than 1"
    } else if (valuefortitle % 1 != 0) {
      this.errorforloopkitno = true;
      this.errorshowloopkitno = "Loopkit should be in number only"
    } else if (isNaN(valuefortitle)) {
      this.errorforloopkitno = true;
      this.errorshowloopkitno = "Loopkit should be in number only"
    } else {
      this.errorforloopkitno = false;
      this.errorshowloopkitno = "";
    }
  }

  checktag(value: any = null) {
    var nameofuser = value;

    // var nameofuser = this.searchsymbol;
    // var afterdeletingextraspace = nameofuser.replace(/\s\s+/g, ' ');

    // var trim = afterdeletingextraspace.trim()
    // var valuesforsending = trim.split(" ");
    // var values = trim.split(" ");

    var values = this.fruits;
    let tolower = function (x: any) {
      return x.toLowerCase();
    };
    // //console.log(values);
    values = values.map(tolower);
    this.arr = this.arr.map(tolower);
    this.arrforsubgenre = this.arrforsubgenre.map(tolower);
    let allFounded = this.arr.some((ai: any) => values.includes(ai));
    let allFoundedfor = this.arrforsubgenre.some((ai: any) => values.includes(ai));
    if (values.length > 3) {
      // //console.log("error");
      this.errorshow = "Only 3 tags can be entered";
    } else if (values.length < 3) {
      this.errorshow = "Minimum 3 tags are required";
    } else if (values[0] === values[1] || values[1] === values[2] || values[2] === values[3]) {
      this.errorshow = "Tags cannot be same";
    } else if (values[0].length > 25 || values[1].length > 25 || values[2].length > 25) {
      this.errorshow = "Only 25 characters max per tag";
    } else if (allFounded == true) {
      this.errorshow = "Genre  metadata should not be included in tags, try mood words or Soundalikes!";
    } else if (this.uploadloopkit.value.subgenre != "" && allFoundedfor == true) {

      this.errorshow = "Genre and Sub Genre metadata should not be included in tags, try mood words or Soundalikes!";


    } else {
      this.tagvalid = true;
      this.tag1 = values[0]
      this.tag2 = values[1]
      this.tag3 = values[2]
      this.errorshow = "";
    }


  }


  // ngOnDestroy() {
  //   //console.log("api will hit");

  // }


  opendialogueforverification() {
    let dialogue = this.matDialog.open(ConfirmLeaveComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: this.router.url } });
    dialogue.afterClosed().subscribe(result => {
      // //console.log('The dialog was closed');
      // this.animal = result;
    });
  }







  trypremium() {
    this.router.navigate([CONSTNAME.REDIRECT_CLIENT_AGAINPAYMENT1]);
  }


  opendialogueforverificatio(): void {
    let dialogue = this.matDialog.open(ConfirmLeaveComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: this.reader, pagename: "loopkit" } });
    dialogue.afterClosed().subscribe(result => {



      // if (result.data == this.id) {
      //   // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      //   this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
      //   // this.router.navigate(['/users/uploadloopstatus']);
      // } else {

      // }
      // //console.log(result.data);
      this.reader = new FormData();
      this.getloopidfrommedia = result.data
      // this.showloaderloading = true;
      // this.showloaderloading2 = true;
      this.yesartwork = true;
      this.yesfirstloop = true;
      this.yessecondloop = true;
      if (this.showartworkcancel == true && this.yesartwork == true) {
        this.showartworkcancelbutton = true
      }

      if (this.showloaderloading == true && this.yesfirstloop == true) {
        this.shownowfirstloader = true
      }
      if (this.showloaderloading2 == true && this.yessecondloop == true) {
        this.shownowsecondloader = true
      }


    });
  }





  opendialogueforverification_insame(Loop_type: string) {
    this.disable_input_as_itsloading = true;
    let tokkenget: any = localStorage.getItem("clientintoken");
    let headers: any = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    this.http.post(`${APIURL.BASE_URL}/user/upload_loopkit_draft`, this.reader, { headers, reportProgress: true, observe: "events" }).pipe().subscribe((event: any) => {

      if (event['loaded'] && event['total']) {
        if (Loop_type == "Loop") {
          this.showleftform = true
          // //console.log("yes from loop")
          this.valueofprogressbarofrequiredloop = Math.round((event['loaded'] / event['total'] * 100))
        } else if (Loop_type == "tagged") {
          this.valueofprogressbaroftaggedversionofloop = Math.round((event['loaded'] / event['total'] * 100))
        } else {

        }
      }
      if (event.type == 4) {
        this.disable_input_as_itsloading = false;
        // //console.log("yeeeeeeeeeeeee")
        this.reader = new FormData();
        this.getloopidfrommedia = event.body.response.data.loop_id
        this.yesartwork = true;
        this.yesfirstloop = true;
        this.yessecondloop = true;
        if (this.showartworkcancel == true && this.yesartwork == true) {
          this.showartworkcancelbutton = true
        }
        if (this.showloaderloading == true && this.yesfirstloop == true) {
          this.shownowfirstloader = true
        }
        if (this.showloaderloading2 == true && this.yessecondloop == true) {
          this.shownowsecondloader = true
        }
      }
    })

  }









}

