export enum APIURL {

  // BASE_URL = 'https://loopheadmariadb.herokuapp.com',
  // BASE_URL = 'https://loophead-staging.herokuapp.com',
  // BASE_URL = 'http://loophead-api.blinkcoders.com',
  BASE_URL = 'https://api.loophead.com',
  // BASE_URL = 'https://loophead-api.orientaloutsourcing.in',
  // BASE_URL = 'https://loophead-staging-api.orientaloutsourcing.in',

  BASE_URLforredirect = 'https://loophead.com',
  // BASE_URLforredirect = 'https://loophead.orientaloutsourcing.in',
  // BASE_URL = 'http://localhost:8000',
  httpaddresforscript = 'Ae_lrXL2bbMjVNVUT8d-RoveqoIBmiXAa6keAr-AqVGR6SukvpU3tkV2Ck7oAGxzEwaMWVV6NR87TIrs',
  // httpaddresforscript = 'AfMvK078dSW9PoxwyEON1NNWLrYobiS2KmchtPFXLYdCoSSNtC3m2S9FnSebT1kWby9zyxTpsBAdKod2',
  // httpaddresforscript = 'ARFlZTh4r5OOPxAJKsmiHRq2jHitzO4rrkY_BE6dzPHvcS-JebPCZZbl8bnbl7KT5nnVf_GqBIWHXL-R',
  // Merchantid = "X7UEU4UBWGXZY",


  ADMIN_LOGIN               = '/admin/admin_login',
  LOGIN_CLIENT              = '/user/user_login',
  FORGET_PASSWORD_MAIL      = '/user/send_forgot_password_mail',
  RESET_USER_PASSWORD       = '/user/reset_user_password',
  VALIDATE_PASSWORD_TOKEN   = '/user/validate_pwd_token',
  GET_COUNTRIES             = '/user/get_all_countries',
  GET_STATE                 = '/user/get_states_for_country_id',
  REGISTER_USER             = '/user/register_user',
  Get_lOGGED_IN_USER_DETAILS = '/user/get_logged_in_user_details',
  ADMIN_PROFILE = '/admin/save_admin_profile',
  GET_ADMINPROFILE_DETAILS = '/admin/get_admin_profile_detail',
  GET_GLOBAL_SETTING = '/admin/get_global_settings',
  ADMIN_ADD_COUPONDETAILS = '/admin/save_coupon_code',
  ADMIN_ENABLE_DISABLE_COUPON = '/admin/enable_disable_coupon',
  ADMIN_GET_ALL_LISTING_DETAILS = `admin/get_all_coupon_codes?page_no=1&row_size=2&sort_on='created_on'&sort_type='DESC'`,
  ADMIN_GET_ALL_Addbannedwords = `/admin/save_banned_word`,
  ADMIN_GET_ALL_dashboard = `/admin/get_dashboard_details`,
  Contact_page_GE = `/user/contact_us`,
  admin_registerpage = `/admin/save_user`,
  admin_savedetailpage = `/admin/get_user_details`,
  admin_invoice = `/admin/get_invoice`,
  admin_foralluserlisting = `/admin/get_all_users`,


  
  POST_GLOBAL_SETTING    = '/admin/save_global_settings',
  user_get_logged_in_user_details = '/user/get_logged_in_user_details',
}
 
export enum VARS {
  APP_TIMEZONE = 'Asia/Kolkata',
  TZ_TIMEZONE_FORMAT = 'ha z',
  DEFAULT_DATE_FORMAT = 'MM/dd/yyyy',
  APP_TIMEZONE_LOCALES = 'en-US',
  MSG_BACK_BUTTON = 'Tap again to exit',
  EMAIL_PATTERN = '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  OFFSET = 0,
  PAGE = 1,
  LIMIT_LIST = 15,
  SORT_BY = 'DESC',
  SORT_ON = 'created_on',
  ADMIN_LOCAL_TOKEN_KEY = 'loggedintoken',  //ADMIN_TOKEN
  // USER_CLIENT_TOKEN_KEY = 'loopheadtokken',

  CLIENT_LOCAL_TOKEN_KEY = 'clientintoken', //USER_TOKEN
  CLIENT_LOCAL_TOKEN_temp = 'clientintokentemp', //USER_TOKEN
  AUTH_WITH = 'authenticate',
  USAGE_BUTTON = 'usage_button',
  USAGE = 'usage',
  LOOPHEAD_CLIENT_USERNAME = 'loopheadusername',
  ClIENT_TYPE = 'clients',
  ADMIN_TYPE = 'admin',
  Login_Client_data = 'client_data'
}

export declare type INPUT_TYPE_NAME = keyof typeof INPUT_TYPE_NAME_MAP;
declare const INPUT_TYPE_NAME_MAP: {
  _EMAIL: any,
  _PASSWORD: any,
  _INPUT: any,
  _SELECT: any,
  _EQUAL: any,
  _MINLENGTH_MAXLENGTH_SAME: any,
  _PATTERN_NUM_MIN_MAX: any,
  _MAX_CHAR: any,
  _MIN_CHAR: any,
  PASSWORD: any,
  MINLENGTH_MAXLENGTH_SAME: any,
  WEBLINK: any,
  AGRREE_POLICY:any,
  OTHER: any;
  _COUPON: any;
  _COUPONPATTERN: any;
  _STARTDATECOUPON: any;
  _ENDDATECOUPON: any;
  _uploadtitle: any;
  _uploadloopbpm: any;
  _descriptionbox: any;
  _loppkittitle:any
  _loppkittitleforloop: any
  _uploadloopkitloopno:any
};

export enum VALIDATION_MSG {
  ERR_REQUIRED_FIELD = 'This field is required',
  ERR_REQUIRED_SELECT = '*You must select a value.',
  ERR_EMAIL_PATTERN = 'Please enter valid email',
  ERR_WEB_PATTERN = "*URL format is not correct",
  ERR_FIELD_NOT_MATCH = '*Fields do not match',
  ERR_NUMERIC_ONLY = '*Only Numeric Values Allowed',
  ERR_GREATER_OR_EQUAL = '*Value should be greater than or Equal to ',
  ERR_LESS_OR_EQUAL = '*Value should be Less than or Equal to ',
  ERR_PASS_MIN_LENGTH = 'Password must be minimum 6 characters long!',
  ERR_GREATER_THAN_ZERO = '*Value should be greater than or Equal to 1',
  CUSTOMER_ERR_LENGTH_NOT_MATCH = '*Length do not match.',
  ERR_PERMISSION_ALLOW = 'Please allow camera permissions.',
  ERR_INVALID_USER = 'Logged out due to invalid Login.',
  ERR_NOT_AUTHORISED_USER = 'You are not authorised person. Please contact admin.',
  ERR_FILE_TYPE = 'File type not allowed!',
  ERR_FILE_SIZE = 'File size not allowed!',
  ERR_FILE_SIZE_AND_TYPE = 'One or more file(s) SIZE or TYPE not allowed!',
  ERR_LOGOUT = 'Logged out.',

  ERR_LENGTH_NOT_MATCH = '*Enter 10 digits only.',
  ERR_TICKET_PROGRESS1 = 'Progress should be greater or equal to initial progress.',
  ERR_TICKET_PROGRESS2 = 'Please mark progress to 100%.',

  ERR_GREATER_THAN_ZERO_SHORT = '*Value should be >= 1',
  ERR_ZIPCODE_LENGTH = '*Enter 5 digits only.',
  ERR_DIGIT_ONLY = '*Enter number digits only.',
  ERR_FILE_SIZE_AND_TYPE_ONE = 'File size or type not allowed!',
  ERR_ITEM_LENGTH = 'Characters Should be between 1 and 999',
  ERR_CATEGORY_LENGTH = '*Max 100 Characters allowed',
  ERR_REQUIRED_SELECT_FILE = '*You must select at least one file.',
  ERR_MULTIPLE_SELECT = '*You must select a single value.',
  ERR_ATLEAST_ONE_SELECT = '*You must select at least one value.',
  ERR_EMAIL_REQUIRED = 'Email is required',
  ERR_PASSWORD_REQUIRED = 'Password is required',
  ERR_PASS_PATTERN = 'Password should include at least one uppercase letter and one special character',
  ERR_COUPON_CODE_NAME = 'Coupon Code is required',
  ERR_COUPON_START_DATE = 'Coupon Start date is required',
  ERR_COUPON_END_DATE = 'Coupon End date is required',
  ERR_COUPON_DISCOUNT_PERCENTAGE = 'Coupon Discount Percentage is required',
  ERR_COUPON_DISCOUNT_PERCENTAGE_PATTERN = 'Discount percentage must be in range of 0 to 100',

  ERR_UPLOAD_TITLE = 'Title is required',
  ERR_UPLOAD_BPM = 'BPM is required',
  ERR_UPLOAD_BPM_PATTERN = 'BPM must be a valid integer in between 1 to 200',
  ERR_DESCRIPTION_UPLOADLOOPKIT = 'This field is required',
  ERR_DESCRIPTION_PATTERN = 'Max 1000 character allowed',


  ERR_DESCRIPTION_Min_LoopKit = 'Min 9 character allowed',
  ERR_DESCRIPTION_Min_Loop = 'Min 3 character allowed',
  ERR_DESCRIPTION_Max_LoopKit = 'Max 50 character allowed',
  ERR_DESCRIPTION_Pattern_LoopKit = 'No Special character is allowed',

  ERR_LOOPKIT_NO_LOOP = "This field is required",
  ERR_LOOPKIT_NO_LOOP_pattern = "Must be a valid integer"


}

export enum APP_ROUTES {
  ADMIN_HOME = 'admin',

  PLANDETAIL_PAGE = 'plandetails',
}



export enum CONSTNAME{
  artworkpathimage = "assets/img/artwork.jpg",
  fILE_PATH = "https://d1ptp6rux3p5im.cloudfront.net",
  REDIRECT_CLIENT_AGAINPAYMENT2 = "profile/subscriptiondetail/premiumplan2",
  REDIRECT_CLIENT_AGAINPAYMENT1 = "profile/subscriptiondetail/premiumplan1",
  REDIRECT_CLIENT_users = "profile",
  // REDIRECT_CLIENT_ACCOUNT_MYUPLOADS = "user/my-account/my-uploads",
  // REDIRECT_CLIENT_ACCOUNT_PROFILECHANGE = "user/my-account/profile",
  REDIRECT_CLIENT_ACCOUNT_MAINSUBSCRIPTIONSTATUS = "subscription-status",
  REDIRECT_CLIENT_ACCOUNT_uploadloop = "upload-loop",
  REDIRECT_CLIENT_ACCOUNT_uploadloopkit = "uploadloopkit",
  REDIRECT_CLIENT_ACCOUNT_detailloop = "detail-loop",
  REDIRECT_CLIENT_DASHBOARD = "dashboard",
  REDIRECT_CLIENT_emailverify = "emailverify",
  REDIRECT_CLIENT_loginCLIENT = "login",
  REDIRECT_CLIENT_registerCLIENT = "register",
  REDIRECT_CLIENT_resetpassword = "reset-password",
  REDIRECT_CLIENT_verifyemail = "verify-email",
  REDIRECT_CLIENT_paymentreceipt = "paymentreceipt",
  REDIRECT_CLIENT_termsandcondition = "termsandcondition",
  REDIRECT_CLIENT_contactus= "contactus",
  REDIRECT_CLIENT_payment= "payment",
  REDIRECT_CLIENT_premimum= "premimum",
  REDIRECT_CLIENT_plandetail= "plandetail",
  REDIRECT_CLIENT_register= "register",
  REDIRECT_CLIENT_LoopstatusUSers= "profile/uploadloopstatus",
  REDIRECT_CLIENT_subscriptiondetail= "profile/subscriptiondetail",
}

// export enum FORMYACCOUNTPAGE{
//   SHOW_SUBSCRIPTION_PAGE = "true",
//   SHOW_MYACCOUNT_DETAIL_PAGE = "true",
//   SHOW_MYUPLOAD_PAGE = "true",
// }

export enum fileinfo{
  filesize =10000000 ,
}