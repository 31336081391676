<div class="modal-header">
    <button type="button" (click)="leave()" class="close"><span aria-hidden="true">Choose what you want to do with header image X</span></button>
    <h4 class="mt-0 mb-0"></h4>
  </div>
  <mat-divider></mat-divider>
  <div class="modal-body text-center">
  <p class="h4 mt-3 mb-3" (click)="leave()"></p>
   <button type="file" class="btn btn-secondary mb-3" (click)="update()" >Update </button>
   <button class="btn btn-theme mb-3 ml-1" (click)="remove()"  >Remove </button>
  
  </div>

