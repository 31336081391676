import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// import { CustomvalidationService } from '../+register/customvalidation.service';
// import { ApiServiceService } from '../allServices/api-service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';

import { MatDialog } from '@angular/material/dialog';
// import { ForgotpasswordmodelComponent } from '../forgotpasswordmodel/forgotpasswordmodel.component';
// import { StaticService } from '../sevicesall/static.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, APP_ROUTES, INPUT_TYPE_NAME, VARS } from 'src/app/allServices/constant.service';
import { StaticService } from 'src/app/sevicesall/static.service';
import { ApiServiceService } from 'src/app/allServices/api-service.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  // men = environment.menubar;
  apihit = false;
  constructor(private matDialog: MatDialog,
    private el: ElementRef,
    private formbuilder: FormBuilder, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute,
    public common: commonservice,
    public api: ApiServiceService) { }

  ngOnInit(): void {

  }

  loginFormforcontact = StaticService.contactForm();

  vCheck(fieldName: any, type: INPUT_TYPE_NAME, options: any = {}) {
    options.isRequired = true;
    const cFrm = this.loginFormforcontact.controls;
    // //console.log(this.loginForm.value);
    if (cFrm) {
      return StaticService.formError(cFrm, fieldName, type, options).msg;
    }
  }


  async send() {
    // //console.log('Click', this.loginForm.value)
    const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    StaticService.markFormGroupTouched(this.loginFormforcontact, invalidElements);
    if (this.loginFormforcontact.valid && !this.apihit) {
      this.apihit = true;
      this.common.showLoader();
      let sendingdata = {
        "name": this.loginFormforcontact.value.contact_name,
        "email": this.loginFormforcontact.value.contact_email,
        "message": this.loginFormforcontact.value.contact_message
      }
      // //console.log('check',APIURL.ADMIN_LOGIN);
      // this.api.postApiHit(APIURL.LOGIN_CLIENT, sendingdata).then((res: any) => {
        this.http.post(APIURL.BASE_URL+`/user/contact_us`, sendingdata).subscribe((res: any) => {

        // this.apiHit = false;
        
        
          if (res.response.status == 1) {
            this.apihit = false;
            this.loginFormforcontact.reset();
          // //console.log(res);
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });

          this.common.hideLoader();


        } else {
          this.apihit = false;
          this.common.hideLoader();
          let errorthroughapi = res.response.errors;
          let error = [];
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            let jk = error.push(errorthroughapi);
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }


      }, async e => {
        this.common.hideLoader();
        this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
      });
    }
  }

}
