import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { promise } from 'selenium-webdriver';
import * as CryptoJS from 'crypto-js';
// import { CustomvalidationService } from '../+register/customvalidation.service';
import { APIURL, VARS } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class commonservice {
  baseurl = APIURL.BASE_URL;
  header: any = {}
  event$: BehaviorSubject<any> = new BehaviorSubject(null);

  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  constructor(private formbuilder: FormBuilder,
    private http: HttpClient,
    private cookieService: CookieService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public router: Router, private route: ActivatedRoute) {

  }
  showLoader() {
    this.spinner.show();
  }
  hideLoader() {
    this.spinner.hide();
  }
  showToast(msg: any = "Success", type = 1, position = 'toast-bottom-right') {
    if (type == 1) this.toastr.success(msg, "", { positionClass: position });
    if (type == 0) this.toastr.error(msg ? msg : '', "", { positionClass: position });
  }
  loginfor() {

  }

  navigateToUrl(route = '') {
    this.router.navigate([route]);
  }
  setlocal(key: string, data: any) {
    return new Promise(resolve => {
      resolve(localStorage.setItem(key, data))
    })

  }
  getlocal(key: string) {
    return new Promise(resolve => {
      let data = localStorage.getItem(key);
      resolve(data)
    })

  }

  setDataInCookie(key: any = VARS.Login_Client_data, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getDataInCookie(key: any = VARS.Login_Client_data) {
    return new Promise(resolve => {
      resolve(localStorage.getItem(key))
    })

  }

  clearAllLocalAndSession() {
    sessionStorage.clear();
    // localStorage.clear()
    localStorage.removeItem('clientintoken');
    localStorage.removeItem('usage');
    localStorage.removeItem('usage_button');
    localStorage.removeItem('authenticate');
    localStorage.removeItem('client_data');
    localStorage.removeItem('global_setting');
  }
  clearAllLocalAndSessionAdmin() {
    sessionStorage.clear();
    // localStorage.clear()
    localStorage.removeItem('loggedintoken');
    localStorage.removeItem('adminprofiledetail');
  }

  eventPublish(eventName: any, data = null) {
    this.event$.next({ eventName, data });
  }

  eventResMatch(eventName: any, res: any, isData = true) {
    return isData ? (res && res.eventName && res.data && (res.eventName === eventName)) : (res && res.eventName && (res.eventName === eventName));
  }

  // Encryptedfunction(key: any ) {
  //   return new Promise(resolve => {
  //     resolve(
  //    )
  //   })
  Encryptedfunction(key: any) {
    return new Promise(resolve => {
      if (key) {
        let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(key), _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
        this.encrypted = encrypted.toString();
        this.encrypted = encodeURIComponent(this.encrypted);
        resolve(this.encrypted)
      }
    })
  }



  

















}
