<app-header>
</app-header>
<div class="desktop-screen">
  <div class="page-header-image" >

    <!-- <div class="detail-view">
      check again
    </div> -->
    
              <!-- <div class="detail-view" *ngIf="showupperpage && !showstatus4 "> -->
      <div class="container">
          <div class="loop--details">
            <!-- <fa name="cog" animation="spin"></fa> -->
            <div class="col-xl-3 col-sm-3 loop-img">
              <div class="loop-artwork" >
                <img alt=""  [src]="setimage1(arraytableforallvalues)">
              </div>
            </div>
              <!-- <div *ngIf="completerender" class="loop-artwork"><img alt="" [src]="artworkpathimage"></div> -->
              
            
            <div class="col-xl col info-text">
              <div class="col-lg-7 col-md-7 col loop-info-header">
                <div style="display: flex;">
                <h4 class="title">{{arraytableforallvalues?.pack_name}}</h4>
          <img class="lock" *ngIf="arraytableforallvalues?.pack_visibility===1" src="../../../../assets/img/lock-01.svg"/>
        </div>
           <div class="pack-data">Pack</div>
                <h4 class="user">
                  <div class="link" (click)="redirecttouser(arraytableforallvalues.username)"><img [src]="setimage(arraytableforallvalues)"/> @{{arraytableforallvalues?.username}}</div>
                  </h4>
              </div>
              <div class="col-md-5 col inner-box">
                <div class="btns-rw">
                  <div class="share-btn"  (click)="openshare(arraytableforallvalues)" *ngIf="sameshowdownload" > <img src="../../../../assets/img/share 1.svg" class = "share-file"/>Share</div>
                  <div class="download-btn" (click)="openEdit(arraytableforallvalues) " *ngIf="sameshowdownload" >Edit Pack</div>
                  <!-- <div class="download-btn" (click)="downloadzip()" *ngIf="!sameshowdownload" >Download</div> -->
                </div>
              </div>
            </div>
                </div>
                </div>
    </div>
    <!-- </div> -->
    <div class="container">
      <div class="row details-space">
        <div class="col-md-8">
      <div class="mb-2">
        <div>
        <div class ="pack_details">Details
        </div> 
      </div> 
      </div>
    
        <div class="text">
        <div class="col-md-4 text-line">
          # of Loops
        </div>
        <div class="col-md-4 text-line">
          {{arraytableforallvalues?.number_of_loops?arraytableforallvalues?.number_of_loops:0}}
        </div>
      </div>
      <div class="text">
        <div class="col-md-4 text-line">
          Date Added
        </div>
        <div class="col-md-4 text-line">
          <span class="posted ml-1" [innerHtml]="timeget(arraytableforallvalues)"></span>
        </div>
      </div>
      </div>
      <div _ngcontent-fbn-c153="" class="col-md-4 col-12 usage-div">
        <div _ngcontent-uhp-c159="" class="col text-line">
          <h4 _ngcontent-fbn-c153="" class="usage_rights">Usage Rights</h4>
        </div>
        <div _ngcontent-uhp-c159="" class="col usage-text text-line">
          <div [innerHTML]=arraytableforallvalues?.usage_rights class="usage-right"></div>
          <div _ngcontent-uhp-c159="" _ngcontent-fbn-c153="">
          </div>
        </div>
      </div>
      <!-- <div _ngcontent-fbn-c153="" class="col-md-4">
        <h4 class="usage_rights" _ngcontent-fbn-c153="">Usage Rights</h4>
        <div>{{arraytableforallvalues.usage}}</div>
        <div _ngcontent-fbn-c153="">
        </div>
      </div> -->
    </div>
    
    </div>
    
    
    
    <div class="container mt-5">
    
    <div class="pack_details-table">
      <h5>{{arraytableforallvalues?.number_of_loops?arraytableforallvalues?.number_of_loops:0}} {{arraytableforallvalues?.number_of_loops===1?"Loop":"Loops"}}</h5>
      <div class="mt-5">
        <div class="table-responsive mt-5 mb-5"  *ngIf="tableData?.length!==0">		
          <table class="table" style="width: 100%;"  >
            <thead>
            <th class="pl">Loop </th>
            <th></th>
            <th class="mobi-hide-2"></th>
            <th class="t-align mobi-hide-2">BPM</th>
            <th class="t-align mobi-hide-2">Key</th>
            <th class="mobi-hide-2">Tags</th>
            <th class="mobi-shw-2">User</th>
            <th class="dnload-align">Action</th>
          </thead>
          <tbody>
            <tr *ngFor="let items of tableData,let i = index" ngcontent-yrg-c82=""  id="player-row-{{i}}">
              <td class="loop-artwork pl">
                <img [src]="setimage(items)"/>
                <span class="play-btn hover play_button" id="play_button"  *ngIf="!items.hidepausebutton"  (click)="playAudio(items,i,$event)"  >
    
                  <i aria-hidden="true" class="fa fa-play"></i></span>
              <span class="play-btn hover play_button" id="play_button"  *ngIf="items.hidepausebutton" (click)="stopAudio(items,i)" >
                  <i class="fa fa-pause"
                      aria-hidden="true"></i></span>
                <!-- <span ngcontent-yrg-c82=""
                class="play-btn hover">
                <span class="play-btn hover play_button" id="play_button">
                  <i aria-hidden="true" *ngIf="!items.hidepausebutton" (click)="playAudio(items,i,$event)" class="fa fa-play"></i>
                  <i (click)="stopAudio(items,i)" *ngIf="items.hidepausebutton" class="fa fa-pause"
                    aria-hidden="true"></i></span>
          
              </span> -->
              </td>
              <td class="loop-details">
                <!-- <div ngcontent-yrg-c82=""> -->
                  <h4 ngcontent-yrg-c82=" " (click)="redirecttolooppage(items.loop_id)" style="cursor: pointer;" [ngClass]="fontSize(items)"  >{{items.title}}</h4>
                      
                      <!-- <a  >
                      {{items.is_already_downloaded}} downloads</a> -->
                <!-- </div> -->
              </td>
          <td class="col-md-4 wave mobi-hide-2">
            <div _ngcontent-yrg-c82="" class="">
              <div _ngcontent-yrg-c82="" class="media-equlizer">
                <app-waveform #waveformRef [arr]="srcforsound(items)" [loop_id]="items.loop_id" [items]="i"[passedValue]="items" 
                [slide]="sliderValue"  (sliderValueChange)="onSliderValueChanged($event)"  (musicEnded)="next(i)"></app-waveform>          </div>
            </div>
          </td>
              <td class="t-align bpk mobi-hide-2">
                {{items.bpm}}
              </td>
              <td class="t-align bpk mobi-hide-2">
                {{items.sub_key}}   {{items.main_key===0?"Major":items.main_key===1?"Minor":""}}
              <td class="mobi-hide-2" >
                <div class="tags" *ngFor="let i of items.tags" (click)="redirectto(i)" >
                  {{i}}
          
                </div>
              </td>
              <td class="mobi-shw-2">
               {{items.username}}
              </td>
    
              <!-- <td  style="display: flex;">
                <div><img src="../../../../assets/img/edit.svg"/></div>
                <div (click)="openshare(items)"><img src="../../../../assets/img/share.svg"/></div>
              </td> -->
                <td class="bpk action"  *ngIf="!sameshowdownload">
                  <div class="action-div">
                        <div  class="stem">{{items.stems?"Contains Stems":""}}</div>
                        <div class="download dnload-align" (click)="downloadFile(items)" ><img src="../../../../assets/img/download.svg"/></div>
                      </div>
                </td>
                <td class="dnload-align" *ngIf="sameshowdownload" >
                  <div class="action-i">
                    <div class="action-li">
                      <img src="../../../../assets/img/edit.svg" (click)="openEditloop(items.loop_id) "/>
                    </div>
                    <div (click)="openshare1(items)" class="action-li">
                      <img src="../../../../assets/img/share.svg" />
                    </div>
                  </div>
                </td>        
            </tr>
          </tbody>
          </table>
        </div>   
        <div *ngIf="tableData?.length===0">
          No Loop Added
        </div>
      </div>
    </div>
    </div>
</div>


<div class="mobile-screen">
  <div class="page-header-image">
    <h4>Pack Details</h4> 
  </div>

  <div class="container">
        <div class="mobi-loop-artwork" >
          <div class="loop-img">
            <img alt=""  [src]="setimage1(arraytableforallvalues)">
          </div>
        </div>
  </div>     

  <div class="container">
    <div class="loop--details pack--details">
      <div class=" col">
        <div class="mobil-loop-info-header">
          <div>
           <h4 class="pck-nme">{{arraytableforallvalues?.pack_name}}</h4>
            <img class="lock" *ngIf="arraytableforallvalues?.pack_visibility===1" src="../../../../assets/img/lock-01.svg"/>
          </div>
          <h4 style="color: rgb(0, 0, 0);" class="user">
            <div class="user-nme" (click)="redirecttouser(arraytableforallvalues.username)">
               @{{arraytableforallvalues?.username}}
            </div>
          </h4>
          <div class="mobil-btns-rw">
            <button class=""  (click)="openshare(arraytableforallvalues)" *ngIf="sameshowdownload">
              <img src="../../../../assets/img/share-icon (1).png">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
 
  <div class="container ">
    
    <div class="pack_details-table">
      <h5>{{arraytableforallvalues?.number_of_loops?arraytableforallvalues?.number_of_loops:0}} {{arraytableforallvalues?.number_of_loops===1?"Loop":"Loops"}}</h5>
        <div class="mobil-tble mb-5"  *ngIf="tableData?.length!==0">		
          <table class="table" style="width: 100%;"  >
          <tbody>
            <tr *ngFor="let items of tableData,let i = index" ngcontent-yrg-c82=""  id="player-row-{{i}}">
              <td class="loop-artwork pl">
                <img [src]="setimage(items)"/>
                <span class="play-btn hover play_button" id="play_button"  *ngIf="!items.hidepausebutton"  (click)="playAudio(items,i,$event)"  >
    
                  <i aria-hidden="true" class="fa fa-play"></i>
                </span>
                <span class="play-btn hover play_button" id="play_button"  *ngIf="items.hidepausebutton" (click)="stopAudio(items,i)" >
                  <i class="fa fa-pause"
                      aria-hidden="true"></i>
                </span>
              </td>
              <td class="loop-details">
                <h4 ngcontent-yrg-c82=" " (click)="redirecttolooppage(items.loop_id)" style="cursor: pointer;" [ngClass]="fontSize(items)"  >{{items.title}}</h4>
                <div class="bpm-det">
                  <span>Bpm - {{items.bpm?items.bpm:"NA"}}</span>
                  <span> Key - {{items.sub_key?items.sub_key:items.main_key?"":"NA"}} {{items.main_key===0?"Major":items.main_key===1?"Minor":""}}</span>
                </div>
              </td>
    
              <td class="dnload-align" *ngIf="sameshowdownload" >
                  <div class="action-i">
                    <div (click)="openshare1(items)" class="action-li">
                      <img src="../../../../assets/img/share.svg" />
                    </div>
                  </div>
                </td>        
            </tr>
          </tbody>
          </table>
        </div>   
        <div *ngIf="tableData?.length===0">
          No Loop Added
        </div>
    </div>
    </div>
</div>
<td class="music-player" *ngIf="showAudioPlayer">
  <div class="aWrap" data-src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3">
    <div class="col-sm-4 player-img">
		<div class="img-info-div">
      <img class="img" [src]="setimage(tableData[player])" />
      <div class="name">
        <h5>{{ tableData[player].title }}</h5>
        <span class="username-box">@{{ tableData[player].username }}</span>
      </div>
		</div>
    </div>

    <div class="col-md-5 col-sm-6 media-player">
      <div class="btns d-flex media-player-btns">
        <div (click)="backward()"><img src="../../../assets/img/fast-backward.svg" /></div>
      
      <div class="buttons" *ngIf="!tableData[player].hidepausebutton" (click)="playAudio(tableData[player], player,$event)">
        <button class="aPlay">
          <span class="aPlayIco"><i class="fa fa-play" style="color: white"></i></span>
        </button>
        <!-- <button class="aPlay" *ngIf="!play" ><span class="aPlayIco"><i class="fa fa-pause" style="color: white;"></i></span></button> -->
        <!-- </span><i class="fa-solid fa-forward"></i></span> -->
      </div>

      <div class="buttons" *ngIf="tableData[player].hidepausebutton" (click)="stopAudio(tableData[player],player)">
        <!-- <button class="aPlay" ><span class="aPlayIco"><i class="fa fa-play" style="color: white;"></i></span></button> -->
        <button class="aPlay">
          <span class="aPlayIco">
            <i class="fa fa-pause" style="color: white"></i>
          </span>
        </button>
        <!-- </span><i class="fa-solid fa-forward"></i></span> -->
      </div>
	
      <div>
        <div (click)="forward()"><img src="../../../assets/img/fast-forward.svg" /></div>
      </div>
	</div>
    </div>
    <!-- <div class="range" style="width:100%;"> -->
    <!-- <span class="under-ranger"></span>  -->
    <input #slider class="range" style="width: 100%;" id="pi_input" type="range" [min]="0" [max]="timeupdate()" [step]="steps" [(ngModel)]="sliderValue" (input)="onInput($event)"/>
    <!-- </div> -->
	<div class="col-3 volume-btns">
		<div class="volume-div d-flex">
    <div *ngIf="!mute" class="volume-container" (click)="Mute()">
      <span class="aVolIco"><i class="fa fa-volume-up icon"></i></span>
    </div>
    <div *ngIf="mute" class="volume-container" (click)="Unmute()">
      <span class="aVolIco" >
        <i class="fa fa-volume-off" style="color: black"></i>
    </span>     </div>
    <div class="range-volume">
      <!-- <span class="under-ranger" style="height: 5px;"></span> -->
      <input
        class="aVolume"
        id="volume"
        type="range"
        min="0"
        max="1"
        step="0.01"
        [(ngModel)]="volumeValue"
        (input)="onVolumeInput($event)"
        style="height: 5px" />
      <!-- </div> -->
      <!-- </div> -->
    </div>
		</div>
	</div>
  </div>
</td>
<app-footer></app-footer>