<!-- <p>music-player works!</p> -->
<div class="music-player">
  
 
  <div class="aWrap" data-src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3">
    <div style="width:fit-content;display: flex;column-gap: 30px;">
      <img class="img" [src]="setimage(items[index])"/>
      <div class="name">
        <h5>{{items[index].title}} </h5>
        <span>@{{items[index].username}}</span>
      </div>
    </div>
	<div>
		<div (click)="previous()">Next</div>
	</div>
    <div class="buttons" *ngIf="!play"  (click)="PlayAudio()">
	    <button class="aPlay" ><span class="aPlayIco"><i class="fa fa-play" style="color: white;"></i></span></button>
	    <!-- <button class="aPlay" *ngIf="!play" ><span class="aPlayIco"><i class="fa fa-pause" style="color: white;"></i></span></button> -->
      <!-- </span><i class="fa-solid fa-forward"></i></span> -->
    </div>
	
    <div class="buttons" *ngIf="play" (click)="stopAudio()" >
	    <!-- <button class="aPlay" ><span class="aPlayIco"><i class="fa fa-play" style="color: white;"></i></span></button> -->
	    <button class="aPlay"><span class="aPlayIco">
			<i class="fa fa-pause" style="color: white;"></i>
		</span></button>
      <!-- </span><i class="fa-solid fa-forward"></i></span> -->
    </div>
	<div>
		<div (click)="next()">Next</div>
	</div>
	  <!-- <div class="range" style="width:100%;"> -->
		<!-- <span class="under-ranger"></span>  -->
		<input class="range" style="width: 100%;" id="pi_input" type="range" [min]="0" [max]="items[index].duration || items[index].loop_duration || items[index].tagged_loop_duration" [step]="0.01" [(ngModel)]="sliderValue" (input)="onInput($event)" />
		<!-- </div> -->
	  <div *ngIf="!mute" class="volume-container" (click) ="Mute()">
		<span class="aVolIco"><i class="fa fa-volume-up icon"></i></span>
		</div>
		<div *ngIf="mute" class="volume-container" (click) ="Unmute()">
			<span class="aVolIco">unmute</span>
			</div>
		<div  class="range-volume" style="width:270px;height: 5px;">
		  <!-- <span class="under-ranger" style="height: 5px;"></span> -->
		  <input class="aVolume" id="volume" type="range" min="0" max="1" step="0.01" [(ngModel)]="volumeValue" (input)="onVolumeInput($event)" style="height: 5px;">
		<!-- </div> -->
	  <!-- </div> -->
	</div>
</div>