import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from '../../allServices/api-service.service';
import { commonservice } from '../../allServices/commonservice';
import { APIURL, VARS } from '../../allServices/constant.service';
import { Meta, Title } from '@angular/platform-browser';
import { async } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.css']
})
export class ExploreComponent implements OnInit {
  stoploader3: boolean;
  arraytablefortags: any = [];
  stoploader5:boolean;
  toastr: any;
  maximumnooflisting: any;
  stoploader2: boolean;
  stoploader1: boolean;
  stoploader4: any;
  searchQuery: string = '';
  check: string;
  active = false;
  isMobile: boolean;

  constructor(private meta: Meta,public common: commonservice,public api: ApiServiceService,public router: Router,private titleService: Title, private route: ActivatedRoute,private breakpointObserver: BreakpointObserver) { 
  }

  ngOnInit(): void {
    // this.meta.updateTag({property: 'og:title', content:'Explore'});
    // this.meta.updateTag({property: 'og:image', content:APIURL.BASE_URLforredirect+"/assets/img/logo.png"});
    // this.meta.updateTag({property: 'og:url', content: APIURL.BASE_URLforredirect+'/explore'})
    this.breakpointObserver.observe(['(max-width: 576px)']).subscribe((state: BreakpointState) => {
      this.isMobile = state.matches;
    });
    const element = document.querySelectorAll(
      "#page")[0];

    if (element && "click" in element) {
      (element as HTMLElement).click();
    }
    let sendingdata = {
     
    }
    // const routeData = this.route.snapshot.data;
    // const title = routeData && routeData.title ? routeData.title : 'Default Title';

    // Set the title
    // this.titleService.setTitle(title);
  
    // this.api.forclientgettokenpublic(`/user/get_global_settings`, sendingdata).then((res: any) => {
    // //console.log('hii');
    // if (res.response.status == 1) {
    //     this.stoploader5 = true;
    //   // //console.log(res.response.data[4].meta_value);
    //   this.maximumnooflisting = res.response.data[4].meta_value;
  this.getapifortag();
  // this.searchapi(search:any);
     
  //   }
  //   else {
  //     this.common.hideLoader();
  //     let errorthroughapi = res.response.errors;
  //     let error = [];
  //     this.common.hideLoader();
  //     for (var i = 0; i < errorthroughapi.length; i++) {
  //       let jk = error.push(errorthroughapi);
  //       this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
  //     }
  //   }
  // }, async (e: any) => {
  //   this.common.hideLoader();
    
  //   this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
  // });
  }

     async getapifortag(){
      
      // this.common.showLoader();
      let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
      // //console.log(tokkenget)
      let headers: any;
      if (tokkenget) {
        headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };
  
      }
  
      let sendingdata = {
        "page_no": 1,
        "sort_on": "created_on",
        "row_size": "50",
        "sort_type": "DESC"
      }
  
      // this.http.post(APIURL.BASE_URL + `/user/get_recommended_tags`, sendingdata, { headers }).subscribe(async (res: any) => {
        this.api.forclientgettokenpublic(`/user/get_recommended_tags`, sendingdata).then((res: any) => {
      
        if (res.response.status == 1) {
          this.common.hideLoader();
          this.stoploader3 = true;
          // //console.log(res);
  
          this.callforloader();
  
  
          // //console.log(res);
  
  
  
  
          // var size = 5;
          // var items = res.response.data.pagging_list.slice(0, size).map((i: any) => {
          //   return 
          // })
          
          let sortarray = res.response.data.pagging_list;
  
          sortarray = sortarray.sort(() => Math.random() - 0.5)
          // //console.log(sortarray);
  
          this.arraytablefortags = sortarray;
          // this.firstrun();
  
          // this.totalpages = res.response.data.total_pages;
          // //console.log(this.totalpages);
  
          // //console.log(this.arraytableforcomments)
  
  
          var i;
          for (let k: any = 0; k < res.response.data.pagging_list.length; k++) {
            let text: any = res.response.data.pagging_list[k].total_tag_count + "<br>";
            // //console.log(text);
          }
  
  
  
  
        }
        else {
          this.common.hideLoader();
          let errorthroughapi = res.response.errors;
          let error = [];
          this.common.hideLoader();
          for (var i: any = 0; i < errorthroughapi.length; i++) {
            let jk = error.push(errorthroughapi);
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async (_e: any) => {
        this.common.hideLoader();
        this.common.hideLoader();
        this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
      });
    }
  
    performSearch(): void {
      // if (this.searchQuery) {
      //   // Prepare your search data
      //   const searchData = {
      //     key_word: this.searchQuery,
      //     loop_search: 1,
      //     page_no: 1,
      //     row_size: 10,
      //     sort_on: "relevance",
      //     sort_type: "DESC"
      //     // Add other parameters as needed
      //   };
  
      //   // Call your search API using the ApiService
      //   this.api.forclientgettokenpublic(`/user/advance_search`,searchData).then((res: any) => {
      //     // Handle the search results here
      //     if (res.response.status == 1) {
      //       this.common.hideLoader();
      //       // //console.log(res)
      //       this.check = ""
      //       // this.tableData = res.response.data.pagging_list;
      //       this.check = res
      //       this.active = true;
  // this.common.showLoader()
            
      this.router.navigate(['advancesearch/'], { queryParams: { page_no:1,sort_on:'relevance',row_size:10,sort_type:'DESC',key_word:this.searchQuery,loop_search:1 } });
  
  
  
        //   } else {
        //     this.common.hideLoader();
  
  
        //     let errorthroughapi = res.response.errors;
        //     let error = [];
        //     for (var i = 0; i < errorthroughapi.length; i++) {
  
        //       this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        //     }
  
        //   }
        // }, async e => {
        //   this.common.hideLoader();
  
        //   this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
  
        // });
        
      // }
  }
    callforloader() {
      if (this.stoploader2 == true && this.stoploader1 && this.stoploader3 && this.stoploader4 && this.stoploader5) {
        this.common.hideLoader();
        // //console.log("problem");
      } else {
  
      }
    }
  forfont(item: any) {
    if (item.total_tag_count <= 40 && item.total_tag_count >= 30) {
      return 'eighty'
    } else if (item.total_tag_count <= 30 && item.total_tag_count >= 20) {
      return 'sixty'
    } else if (item.total_tag_count <= 20 && item.total_tag_count >= 10) {
      return 'forty'
    } else {
      return 'twenty'
    }
  }
  redirectto(item: any) {
    // //console.log(item)
    this.router.navigate(['advancesearch/'], { queryParams: { page_no:1,sort_on:'relevance',row_size:10,sort_type:'DESC',key_word:item,loop_search:1 } });
    // //console.log(item)
    
  }

}

