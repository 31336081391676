import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { ConfirmDeleteComponent } from 'src/app/CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-loops-detail-uploads',
  templateUrl: './loops-detail-uploads.component.html',
  styleUrls: ['./loops-detail-uploads.component.css']
})
export class LoopsDetailUploadsComponent implements OnInit {
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  hidepagination = false;
  norecordfound = false;
  loop_id: any;
  detailofloop: any[] = [];
  encryptedagainremoveslashes: string;
  titlename: any;
  tablefortagsdisplay1: any;
  tablefortagsdisplay2: any;
  tablefortagsdisplay3: any;
  username: any;
  createdon: any;
  bpm: any;
  instrument_name: any;
  parent_instrument_name: any;
  genre_name: any;
  parent_genre_name: any;
  artwork_path: null;
  sub_key: null;
  imagehide: any = false;
  afterload = false;
  key: any;
  imagesrc: any;
  loop_path: any;
  audiosrc: any;
  loop_type: any;
  sellingpreference: any;
  termsofpreference: any;
  time: any;
  looporloopkitid: any;
  showbpm: any = true;
  hidereportbutton: boolean = true;

  arraytableforcomments: any;
  commentData: any;
  nocomments: any = true;
  stream: any;
  src: any;
  commentno: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  artworkpathimage = "assets/img/artwork.jpg";
  hideplaybutton: boolean;
  hidepausebutton: boolean = true;
  number_of_likes: any;
  number_of_reposts: any;
  showupperpage: boolean;
  addred: boolean;
  addgreen: boolean;
  description: any;
  showdescription: any = false;
  loop_price: any;
  hidecommentbox: boolean;
  messageerror = "";
  fILE_PATH = "https://d1ptp6rux3p5im.cloudfront.net"
  local_data: any;
  showloopno: any;
  hidepricedetail: boolean;
  user_idget: any;
  tabname: any = "comments";
  showcommentpage = true;
  showlikepage: boolean = false;
  showrepostpage: boolean = false;
  showreportlog: boolean = false;
  check: any;
  active = false;
  page_referer: any;




  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService, private matDialog: MatDialog,) {

    this.route.queryParams.subscribe(params => {
      this.getpageno = params.page_no;
      if (params.referer) {
        this.page_referer = params.referer;
      }
      else {
        this.page_referer = '/admin/loops/list-uploads';
      }
      // //console.log(params.referer)
      // this.encrypted = params.coupon_id;
      
    });

  }

  cancelandredirect() {
    // //console.log(this.page_referer);
    this.router.navigateByUrl(this.page_referer);
  }

  receiveMessage(ab: any) {
    // //console.log(ab)
  }

  ngOnInit(): void {
    let left_sidebar:any = document.getElementsByClassName('sidebar-menu');
    if (left_sidebar.length > 0) {
      
      left_sidebar = left_sidebar[0];
      left_sidebar.querySelector('[href="/admin/loops/list-uploads"]').parentElement.classList.add("active", 'menu-open', 'custom-active');
    } 
    // this.encrypted = this.route.snapshot.queryParamMap.get('loop');
    this.encrypted = this.route.snapshot.params['id'];
    this.encryptedagainremoveslashes = decodeURIComponent(this.encrypted);
    this.decryptUsingAES256();
    this.getapicallforcouponcode();
  }


  decryptUsingAES256() {
    // //console.log(this.encryptedagainremoveslashes);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);


  }

  getapicallforcouponcode() {


    // let Idfromurl = this.route.snapshot.params['id'];

    // //console.log(this.decrypted);
    this.loop_id = this.decrypted;
    this.common.showLoader();
    let sendingdata = {
      "page_no": this.pageno,
      "sort_on": "created_on",
      "row_size": this.rowsize,
      "sort_type": "DESC",
      "loop_id": this.loop_id,
      "tab": this.tabname

    }


    this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`], { queryParams: { referer: this.page_referer, pageno: this.pageno } });
    let tokkenget = localStorage.getItem("loggedintoken");
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    this.api.postApiHit(`/admin/get_upload_detail`, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {



      if (res.response.status == 1) {
        this.common.hideLoader();
        this.check = res
        // //console.log(res.response.data.loop_details);
        this.detailofloop = res.response.data.loop_details;
        this.active = true;
        if (res.response.data.total_records == 0) {
          this.norecordfound = true;
        } else {
          this.norecordfound = false;
        }

        if (res.response.data.total_pages == 1) {
          this.hidepagination = true;
        } else {
          this.hidepagination = false;
        }
        if (this.paginationempty == true) {
          this.pagercount = [];
        }
        this.commentno = res.response.data.loop_details[0].number_of_comments;


        if (res.response.data.loop_details[0].artwork_path !== null) {
          this.artworkpathimage = CONSTNAME.fILE_PATH + res.response.data.loop_details[0].artwork_path;
          // //console.log("dfdf");
        } else if (res.response.data.loop_details[0].profile_image_path !== null) {
          this.artworkpathimage = CONSTNAME.fILE_PATH + res.response.data.loop_details[0].profile_image_path;
        }
        // this.imageofartwork = 
        // //console.log(res);
        if (res.response.data.loop_details[0].loop_type == 1) {
          if (res.response.data.loop_details[0].tagged_loop_path !== null) {
            this.src = CONSTNAME.fILE_PATH + res.response.data.loop_details[0].tagged_loop_path;
            // //console.log("checking");
          } else {
            this.src = CONSTNAME.fILE_PATH + res.response.data.loop_details[0].loop_path;
          }

          // //console.log(this.src);
        } else {
          this.src = CONSTNAME.fILE_PATH + res.response.data.loop_details[0].tagged_loop_path;
          // //console.log(this.src);
        }

        if (res.response.data.loop_details[0].loop_type == 2) {
          this.description = res.response.data.loop_details[0].description;
          this.showdescription = true;
          this.showloopno = res.response.data.loop_details[0].loops_count;
        } else {
          this.showdescription = false;
        }


        if (res.response.data.loop_details[0].is_liked == 1) {
          // //console.log(res.response.data.loop_details[0].is_liked);
          this.addred = true;
          //   let a: any = document.getElementById("red");
          // a.classList.add("red");
        } else {
          this.addred = false;
          // //console.log(res.response.data.loop_details[0].is_liked);
        }
        if (res.response.data.loop_details[0].is_reposted == 1) {
          this.addgreen = true;
          // let a: any = document.getElementById("green");
          // a.classList.add("green");
        } else {
          this.addgreen = false;
          // //console.log(res.response.data.loop_details[0].is_reposted);
        }




        // //console.log(res.response.data.loop_details[0].tagged_loop_path);
        let a = res.response.data.loop_details[0].tags
        let strtag: any = a.split('\n ');
        let strtag1: any = strtag[0];
        let strtag2: any = strtag[1];
        let strtag3: any = strtag[2];
        this.artwork_path = res.response.data.loop_details[0].artwork_path;
        if (this.artwork_path == null) {
          this.imagehide = true;
        } else {
          this.imagesrc = this.artwork_path;
          // //console.log(this.imagesrc);
          this.imagehide = false;
        }
        this.key = res.response.data.loop_details[0].main_key;
        if (this.key == 1) {
          this.key = "Major";
        } else {
          this.key = "Minor";
        }
        this.loop_path = res.response.data.loop_details[0].loop_path;

        if (this.loop_path) {
          this.audiosrc = CONSTNAME.fILE_PATH + this.loop_path;
          // //console.log(this.audiosrc);
        }

        if (res.response.data.loop_details[0].bpm == null || res.response.data.loop_details[0].bpm == "") {
          this.showbpm = false;
        }
        if (res.response.data.loop_details[0].loop_id) {
          this.looporloopkitid = res.response.data.loop_details[0].loop_id
          // //console.log(this.looporloopkitid);
        }
        if (res.response.data.loop_details[0].show_report_button == 0) {
          this.hidereportbutton = false;
          // //console.log(res.response.data.loop_details[0].show_report_button);

        } else {
          this.hidereportbutton = true;
        }

        this.number_of_likes = res.response.data.loop_details[0].number_of_likes;
        this.number_of_reposts = res.response.data.loop_details[0].number_of_reposts;


        // //console.log(res);
        this.sub_key = res.response.data.loop_details[0].sub_key;
        this.titlename = res.response.data.loop_details[0].title;
        if (res.response.data.loop_details[0].instrument_name !== null) {
          this.instrument_name = "- " + res.response.data.loop_details[0].instrument_name;
        } else {
          this.instrument_name = "";
        }


        this.parent_instrument_name = res.response.data.loop_details[0].parent_instrument_name;
        this.parent_genre_name = res.response.data.loop_details[0].parent_genre_name;
        if (res.response.data.loop_details[0].genre_name !== null) {
          this.genre_name = "- " + res.response.data.loop_details[0].genre_name;
        } else {
          this.genre_name = "";
        }

        this.bpm = res.response.data.loop_details[0].bpm;
        this.username = res.response.data.loop_details[0].username;
        if (res.response.data.loop_details[0].loop_price == 0) {
          this.hidepricedetail = true;
        } else {
          this.loop_price = (Math.round(res.response.data.loop_details[0].loop_price * 100) / 100).toFixed(2);
          this.hidepricedetail = false;
        }

        this.user_idget = res.response.data.loop_details[0].user_id

        this.tablefortagsdisplay1 = strtag1;
        this.tablefortagsdisplay2 = strtag2;
        this.tablefortagsdisplay3 = strtag3;
        this.createdon = moment(res.response.data.loop_details[0].created_on).format(' MMMM DD,YYYY ');
        this.loop_type = res.response.data.loop_details[0].loop_type;
        this.sellingpreference = res.response.data.loop_details[0].selling_preference;
        this.termsofpreference = res.response.data.loop_details[0].terms;
        var date_future: any = new Date();
        var date_now: any = new Date(res.response.data.loop_details[0].created_on);
        //  //console.log( moment(res.response.data.loop_details[0].created_on).format(' MMMM DD,YYYY h:m'))
        var time = date_future - date_now;
        // //console.log(time);
        var seconds = moment.duration(time).seconds();
        var minutes = moment.duration(time).minutes();
        var hours = moment.duration(time).hours();
        var days = moment.duration(time).days();
        // //console.log(hours, days, minutes);

        if (res.response.data.loop_details[0].number_of_streams == null) {
          this.stream = 0;
        } else {
          this.stream = res.response.data.loop_details[0].number_of_streams;
          this.nFormatter(this.stream);
        }


        if (tokkenget) {
          this.hidecommentbox = false;
        } else {
          this.hidecommentbox = true;
        }
        // if (seconds < 60 && minutes === 0) {
        //   this.time = seconds + " sec"
        // }
        // else if (minutes < 60 && hours === 0) {
        //   this.time = minutes + " min"
        // } else if (hours <= 24 && days === 0) {
        //   this.time = hours + " hrs"
        // } else if (days >= 1) {
        //   this.time = days + " days"
        // }

        if (seconds < 60 && minutes === 0) {
          this.time = seconds + " sec"
        }
        else if (minutes < 60 && hours === 0) {
          this.time = minutes + " min"
        } else if (hours == 1 && days === 0) {
          this.time = hours + " hr"
        } else if (hours <= 24 && days === 0) {
          this.time = hours + " hrs"
        }
        else if (days = 1) {
          this.time = days + " day"

        } else if (days > 1) {
          this.time = days + " days"
        }
        if (this.sellingpreference == 1) {
          if (this.termsofpreference == 1) {
            this.termsofpreference = "Royalty Free";
          } else if (this.termsofpreference == 2) {
            this.termsofpreference = "50/50 Master + Publishing Split";
          }
        } else if (this.sellingpreference == 2) {
          if (this.termsofpreference == 1) {
            this.termsofpreference = "Royalty Free";
          } else if (this.termsofpreference == 2) {
            this.termsofpreference = "50/50 Master + Publishing Split";
          } else {
            this.termsofpreference = "Exclusive";
          }
        }
        // //console.log(this.loop_type);
        if (this.loop_type == 1) {
          this.loop_type = "Loop";
        } else {
          this.loop_type = "Loop Kit";
        }

        this.tableData = res.response.data.pagging_list;
        this.record = this.tableData.length;
        this.pagenolist = res.response.data.total_pages + 1;

        for (let i = 1; i < this.pagenolist; i++) {
          this.pagercount.push(i);

        }
        // //console.log(res.data.total_records)
        this.total = res.response.data.total_records;
        // let ida: any = document.getElementById("liststyle"+1);
        // //console.log(ida);
        // ida.style.backgroundColor = "orange";

      } else {
        this.common.hideLoader();

        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();

      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
  }





  onsearch() {

    // //console.log("searching");
    this.searchApi(this.validationforsearch);
  }

  searchClick(event: any) {
    this.validationforsearch = event
    // //console.log(event)
    this.active = false;
    this.pageno = 1;
    this.searchApi(this.validationforsearch);
  }

  searchApi(event: any) {
    this.common.showLoader();



    this.loop_id = this.decrypted;
    this.common.showLoader();
    let sendingdata = {
      "page_no": this.pageno,
      "sort_on": "created_on",
      "row_size": this.rowsize,
      "sort_type": "DESC",
      "loop_id": this.loop_id,
      "tab": this.tabname,
      "search": event

    }


    this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`], { queryParams: { search: event, pageno: this.pageno } });
    let tokkenget = localStorage.getItem("loggedintoken");
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    this.api.postApiHit(`/admin/get_upload_detail`, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {


      // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`], { queryParams: { search: this.validationforsearch, pageno: this.pageno } });
      let search = event
      // let tokkenget = localStorage.getItem("loggedintoken");
      // const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };


      // this.api.postApiHit(`/admin/get_upload_detail?page_no=${this.pageno}&row_size=${this.rowsize}&search=${search}&loop_id=${this.loop_id}`, {}, VARS.ADMIN_TYPE).then((res: any) => {

      if (res.response.status == 1) {
        this.common.hideLoader();
        this.check = res
        // //console.log(res.response.data.loop_details);
        this.detailofloop = res.response.data.loop_details;
        this.active = true;
        if (res.response.data.total_pages == 1) {
          this.hidepagination = true;
        } else {
          this.hidepagination = false;
        }


        if (res.response.data.total_records == 0) {
          this.pagercount = [];

          this.tableData = res.response.data.pagging_list;
          this.record = this.tableData.length;
          this.noresult = true;
          // //console.log("aa");
          // this.toastr.success("No record found", res.response.errors, { positionClass: 'toast-bottom-right' });
          this.total = res.response.data.total_records;

        } else {
          this.common.hideLoader();

          this.noresult = false;
          this.pagercount = [];
          this.tableData = res.response.data.pagging_list;
          this.record = this.tableData.length;
          this.pagenolist = res.response.data.total_pages + 1;
          for (let i = 1; i < this.pagenolist; i++) {
            this.pagercount.push(i);
          }
          this.total = res.response.data.total_records;

        }
      } else {
        this.common.hideLoader();

        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  tablevaluearray = [
    { name: 'Reason', tablehead: 'Reason', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },

    { name: 'Reported  By', tablehead: 'Created_By', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Created On', tablehead: 'created_on', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },

    // { name: 'Action', tablehead: 'Action', sort: false, showicon: false, sortdesc: false, class: 'fa fa-sort' }

  ];
  sortOn(value: any) {
    this.active = false;
    this.getapicallforsort(value.tablehead);
    this.tablevaluearray.map(item => {
      item.sort = false;
      item.sortdesc = false;
      item.class = 'fa fa-sort';
    })
    value.sort = true;
    value.class = "fa fa-sort-asc";
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "Reason")) {
      value.class = 'fa fa-sort-desc';
    }
    // if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "loop_type")) {
    //   value.class = 'fa fa-sort-desc';
    // }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "Created_By")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "created_on")) {
      value.class = 'fa fa-sort-desc';
    }


  }

  getapicallforsort(got: any) {
    this.active = true;
    let sort_on = got;
    let arrange = "DESC";
    if (this.route.snapshot.queryParamMap.get('sort') == "ASC") {
      arrange = "DESC";
      this.sortsign = "sort-desc";
    } else {
      arrange = "ASC";
      this.sortsign = "sort-asc";
    }
    this.searchwordget = this.route.snapshot.queryParamMap.get('search')
    if (this.searchwordget) {

      this.loop_id = this.decrypted;
      this.common.showLoader();
      let sendingdata = {
        "page_no": this.pageno,
        "sort_on": "created_on",
        "row_size": this.rowsize,
        "sort_type": arrange,
        "loop_id": this.loop_id,
        "tab": this.tabname,
        "search": this.validationforsearch

      }


      this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`], { queryParams: { search: this.validationforsearch, pageno: this.pageno, sort: arrange } });
      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      this.common.showLoader();
      this.api.postApiHit(`/admin/get_upload_detail`, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {
        //   
        //   this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`], { queryParams: { search: this.validationforsearch, pageno: this.pageno, sort: arrange } });
        //   let tokkenget = localStorage.getItem("loggedintoken");
        //   const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

        // this.http.get(APIURL.BASE_URL + `/admin/get_all_coupon_codes?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&search_input=${this.searchwordget}`, { headers }).subscribe(async (res: any) => {
        // this.api.postApiHit(`/admin/get_upload_detail?&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&search=${this.searchwordget}&loop_id=${this.loop_id}`, {}, VARS.ADMIN_TYPE).then((res: any) => {

        if (res.response.status == 1) {
          this.common.hideLoader();
          this.tableData = res.response.data.pagging_list;
        } else {
          this.toastr.error("", res.response.error, { positionClass: 'toast-bottom-right' });
          this.common.hideLoader();
        }
      }, async e => {
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.getpageno = this.route.snapshot.queryParamMap.get('pageno');
      if (this.getpageno) {
        this.pageno = this.getpageno;
      } else {
        this.pageno = 1;
      }
      this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`], { queryParams: { sort: arrange, pageno: this.pageno } });

      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      let sendingdata = {
        "page_no": this.pageno,
        "sort_on": "created_on",
        "row_size": this.rowsize,
        "sort_type": arrange,
        "loop_id": this.loop_id,
        "tab": this.tabname,
        // "search": event

      }
      // this.http.get(APIURL.BASE_URL + `/admin/get_all_coupon_codes?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}`, { headers }).subscribe(async (res: any) => {
      // this.api.postApiHit(`/admin/get_upload_detail?&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&loop_id=${this.loop_id}`, {}, VARS.ADMIN_TYPE).then((res: any) => {
      //   

      this.common.showLoader();
      this.api.postApiHit(`/admin/get_upload_detail`, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {
        if (res.response.status == 1) {
          this.common.hideLoader();

          this.tableData = res.response.data.pagging_list;

        } else {
          this.toastr.error("", res.response.error, { positionClass: 'toast-bottom-right' });
          this.common.hideLoader();
        }
      }, async e => {
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

      });

    }

  }


  paginationget(pageinfo: any) {
    this.active = false;
    this.paginationempty = true;
    this.pageno = pageinfo;
    // let idname = "liststyle" + this.pageno;
    // let idaa: any = document.getElementById(idname);
    // //console.log(idaa);


    // let x: any = document.querySelectorAll(".bluecolor");
    // for (let i = 0; i < x.length; i++) {
    //   x[i].style.backgroundColor = "";
    // }
    // idaa.style.backgroundColor = "orange";
    if (this.route.snapshot.queryParamMap.get('search')) {
      this.searchApi(this.validationforsearch)
    } else {
      this.getapicallforcouponcode();
    }


  }

  // routerLink=""
  toreportedloops() {
    // //console.log("yes");
    this.router.navigate(['/admin/loops/reported-loops']);
  }
  redirecttoinvoices() {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(this.looporloopkitid), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    this.router.navigate(['/admin/loops/allinvoices'], { queryParams: { loop_id: this.encrypted } });
  }


  todeleteowner() {
    let sendingData = {
      "loop_id": this.loop_id,


    }
    // //console.log(sendingData);

    this.common.showLoader();
    this.api.postApiHit(`/admin/delete_loop_user`, sendingData, VARS.ADMIN_TYPE).then((res: any) => {
      if (res.response.status == 1) {

        this.common.hideLoader();
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        this.router.navigate(['/admin/loops/list-uploads']);
      }
      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });

  }
  todeleteloop() {
    let sendingData = {
      "loop_id": this.loop_id,


    }
    // //console.log(sendingData);

    this.common.showLoader();
    this.api.postApiHit(`/admin/delete_upload`, sendingData, VARS.ADMIN_TYPE).then((res: any) => {
      if (res.response.status == 1) {

        this.common.hideLoader();
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        this.router.navigate(['/admin/loops/list-uploads']);
      }
      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });

  }

  toredirect() {
    this.router.navigate([`admin/loops/list-uploads`]);
  }

  redirectto(value: any) {

    this.encryptUsingAES256(value);
    // //console.log("yup");

  }
  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    
    window.open(`${APIURL.BASE_URLforredirect}/detail-loop?loop_id=${this.encrypted}`, `_blank`)
    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);
  }


  suredeleteloop() {
    this.opendialogueforverification();
  }
  suretodeleteowner() {
    this.opendialogueforsecondverification();
  }



  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "deleteloop" } });
    dialogue.afterClosed().subscribe(result => {
      this.local_data = result.data
      if (result.data == true) {
        this.todeleteloop();
      } else {
      }
    });
  }
  opendialogueforsecondverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "deleteloop" } });
    dialogue.afterClosed().subscribe(result => {
      this.local_data = result.data
      if (result.data == true) {
        //  //console.log("owner");
        this.todeleteowner();
      } else {
      }
    });
  }

  nFormatter(num: any) {
    if (num >= 1000000000) {
      this.stream = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';

    }
    else if (num >= 1000000) {
      this.stream = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';

    }
    else if (num >= 1000) {
      this.stream = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';

    } else {
      this.stream = num;
    }


  }
  addcolor(item: any) {
    if (item == this.pageno) {
      return 'activated';
    } else {
      return ''
    }
  }

  redirecttoprofilepage() {
    // //console.log(this.user_idget)
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(this.user_idget), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(item.user_id);
    // window.open(`http://loophead-web.blinkcoders.com/detail-loop?loop_id=${this.encrypted}`, `_blank`)
    window.open(`${APIURL.BASE_URLforredirect}/user/sounds?user_id=${this.encrypted}`, `_blank`)
    // this.router.navigate(['user/'], { queryParams: { user_id: this.encrypted } });

  }

  detailpageofloop() {

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(this.looporloopkitid), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });

    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);
  }
  commentpageon() {
    this.tabname = "comments"
    this.getapicallforcouponcode();
    this.active = false;
    this.showcommentpage = true
    this.showlikepage = false
    this.showrepostpage = false
    this.showreportlog = false
  }

  likepageon() {
    this.tabname = "likes"
    this.getapicallforcouponcode();
    this.active = false;
    this.showlikepage = true
    this.showrepostpage = false
    this.showreportlog = false
    this.showcommentpage = false
  }

  repostpageon() {
    this.tabname = "reposts"
    this.active = false;
    this.getapicallforcouponcode();
    this.showrepostpage = true
    this.showreportlog = false
    this.showcommentpage = false
    this.showlikepage = false
  }

  reportlogpageon() {
    this.tabname = "report_log"
    this.active = false;
    this.getapicallforcouponcode();
    this.showreportlog = true;
    this.showcommentpage = false
    this.showlikepage = false
    this.showrepostpage = false
  }





}
