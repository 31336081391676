

<div class="length">
<div class="invoice-wrap container" *ngFor="let item of arrayfortable" id="contentToConvert">
  <table style="width: 100%;" class="invoice-header">
    <tbody>
      <tr>
        <td> <img src="https://loophead.com/assets/img/logo.png" style="width: 70px;"></td>
        <td align="right">
          <h2>Invoice Receipt</h2>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="table-n2">
    <tbody>
      <tr>
        <th>Date</th>
        <!-- <th>Billed To</th> -->
      </tr>
      <tr style="vertical-align: top;">
        <td>{{item.payment_created_on | date: 'MMM dd , yyyy
          '}}</td>
        <!-- <td *ngIf="item.shipping_address_line_1">{{item.shipping_address_line_1}}<br>{{item.shipping_admin_area_1}} , {{item.shipping_admin_area_2}}<br>
          {{item.shipping_country_code}}<br>
          {{item.shipping_postal_code}}</td> -->
      </tr>
    </tbody>
  </table>
  <table class="table-n3">
    <tbody>
      <tr>
        <td align="left">
          <span class="loop_artwork">
            <img [src]="setimage(item)">
          </span>
          <span class="loop_info">
            <span (click)="redirecttolooppage(item.loop_id)" class="hover">"{{item.title}}" by </span> <a class="link hover"
              (click)="redirecttoprofilepage(item.user_id)">@{{item.username}}</a><br>

            <span class="badge loop-badge" *ngIf="item.loop_type == 1">Loop</span><span class="badge kit-badge"
              *ngIf="item.loop_type == 2">Loop Kit</span>
          </span>

        </td>
        <td class="text-right">
          <h4 >${{item.loop_price}}</h4>
          <p class="dn-type">Download Type: <span>{{item.terms_name}}</span></p>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- <table class="table-n3" style="width: 100%;">
     <tbody>
       <tr><td>
         <span class="loop_artwork"><img src="https://d1ptp6rux3p5im.cloudfront.net/loop_artworks/1624446852039-311618c85f313390262c.jpg"></span> Music by <a class="link">@R.K.S</a><br>
   <span class="badge loop-badge">Loop</span>
         </td>
   <td class="text-right"><h4>$10.00</h4>
         <p class="dn-type">Download Type: <span>ROYALTY FREE</span></p></td></tr></tbody>
   </table> -->
  <table class="total">
    <tbody>
      <tr>
        <td>Total</td>
        <td>${{item.loop_price}}</td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="showerror" >
  <h2 class="cen"style="
color: #721c24;
background-color: #f8d7da;
border-color: #f5c6cb;
padding: 10px;">The invoice doesn't exist.</h2>
</div>

</div>
