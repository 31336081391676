
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-deletedloop-loopkits',
  templateUrl: './deletedloop-loopkits.component.html',
  styleUrls: ['./deletedloop-loopkits.component.css']
})
export class DeletedloopLoopkitsComponent implements OnInit {
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  hidepagination = false;
  norecordfound = false;
  totalpagesinaccount: any;
  items: any = [];
  pageOfItems: Array<any>
  pagenocount: any;
  sorttype = "";
  sorton = "";
  firstcall: any = 0;
  temp_pno: any = 0;
  urlofrouter: string;


  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService) { }

  ngOnInit(): void {

    
    

    this.pageno = this.route.snapshot.queryParamMap.get('pageno')
    if (this.pageno) {
      this.pageno = Number(this.pageno)
     
    }
    this.getapicallforcouponcode();

    let left_sidebar:any = document.getElementsByClassName('sidebar-menu');
    if (left_sidebar.length > 0) {
      
      left_sidebar = left_sidebar[0];
      left_sidebar.querySelector('[href="/admin/loops/list-uploads"]').parentElement.classList.add("active", 'menu-open');
    }

    
  }


  getapicallforcouponcode() {
    this.common.showLoader();
    if (this.pageno == undefined) {
      // //console.log("undefined")
      // this.yesundefined = true
      this.pageno = 1
    }
    let value = this.route.snapshot.queryParamMap.get('report');
    // //console.log(value);

    this.router.navigate(['/admin/loops/deleted-loops-loopkits'], { queryParams: { pageno: this.pageno } });
    let tokkenget = localStorage.getItem("loggedintoken");
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    this.api.postApiHit(`/admin/get_all_uploads?page_no=${this.pageno}&row_size=${this.rowsize}&sort_on=${this.sorton}&sort_type=${this.sorttype}&deleted_loops=1`, {}, VARS.ADMIN_TYPE).then((res: any) => {

      // //console.log(res.response.data);

      if (res.response.status == 1) {
        // //console.log(res);
        this.common.hideLoader();

        if (res.response.data.total_records == 0) {
          this.norecordfound = true;
        } else {
          this.norecordfound = false;
        }

        if (res.response.data.total_pages == 1) {
          this.hidepagination = true;

        } else {
          this.hidepagination = false;
        }
        if (this.paginationempty == true) {
          this.pagercount = [];
        }

        this.tableData = res.response.data.pagging_list;
        this.record = this.tableData.length;
        this.pagenolist = res.response.data.total_pages + 1;


        this.tableData = res.response.data.pagging_list;
        this.record = this.tableData.length;
        this.pagenolist = res.response.data.total_pages + 1;
        this.record = this.pageno - 1 + "1";
        // //console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
        this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length

        this.tableData = res.response.data.pagging_list;
        // this.record = this.tableData.length;
        this.pagenolist = res.response.data.total_pages + 1;
        if (this.record == "01") {
          this.record = 1;
        }

        // for (let i = 1; i < this.pagenolist; i++) {
        //   this.pagercount.push(i);

        // }
        if (this.totalpagesinaccount !== res.response.data.total_pages) {
          this.totalpagesinaccount = res.response.data.total_pages
          this.items = Array(this.totalpagesinaccount).fill(0).map((x, i) => (i + 1))
        }

        for (let i = 1; i < this.pagenolist; i++) {
          this.pagercount.push(i);

        }
        // //console.log(res.data.total_records)
        this.total = res.response.data.total_records;
        // let ida: any = document.getElementById("liststyle"+1);
        // //console.log(ida);
        // ida.style.backgroundColor = "orange";

      } else {
        this.common.hideLoader();

        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();

      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
  }





  onsearch() {

    // //console.log("searching");
    this.searchApi();
  }
  searchClick() {

    this.pageno = 1;
    this.searchApi();
  }

  searchApi() {
    this.common.showLoader();
    if (this.pageno == undefined) {
      // //console.log("undefined")
      // this.yesundefined = true
      this.pageno = 1
    }


    this.router.navigate(['/admin/loops/deleted-loops-loopkits'], { queryParams: { search: this.validationforsearch, pageno: this.pageno } });
    let search = this.validationforsearch
    let tokkenget = localStorage.getItem("loggedintoken");
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };


    this.api.postApiHit(`/admin/get_all_uploads?page_no=${this.pageno}&row_size=${this.rowsize}&search=${search}&deleted_loops=1`, {}, VARS.ADMIN_TYPE).then((res: any) => {

      if (res.response.status == 1) {
        this.common.hideLoader();

        if (res.response.data.total_pages == 1) {
          this.hidepagination = true;

        } else {
          this.hidepagination = false;
        }



        if (res.response.data.total_records == 0) {
          this.pagercount = [];



          this.tableData = res.response.data.pagging_list;
          this.record = this.tableData.length;
          this.noresult = true;
          // //console.log("aa");
          // this.toastr.success("No record found", res.response.errors, { positionClass: 'toast-bottom-right' });
          this.total = res.response.data.total_records;

        } else {

          this.common.hideLoader();

          this.noresult = false;
          this.pagercount = [];
          this.tableData = res.response.data.pagging_list;
          this.record = this.tableData.length;
          this.pagenolist = res.response.data.total_pages + 1;
          for (let i = 1; i < this.pagenolist; i++) {
            this.pagercount.push(i);
          }
          this.total = res.response.data.total_records;

          this.record = this.tableData.length;
          this.pagenolist = res.response.data.total_pages + 1;
          this.record = this.pageno - 1 + "1";
          if (this.record == "01") {
            this.record = 1;
          }
          // //console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
          this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length
          for (let i = 1; i < this.pagenolist; i++) {
            this.pagercount.push(i);
          }
          this.total = res.response.data.total_records;


        }
        if (this.totalpagesinaccount !== res.response.data.total_pages) {
          this.totalpagesinaccount = res.response.data.total_pages
          this.items = Array(this.totalpagesinaccount).fill(0).map((x, i) => (i + 1))
        }
      } else {
        this.common.hideLoader();

        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  tablevaluearray = [
    { name: 'Title', tablehead: 'title', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Loop type', tablehead: 'loop_type', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Created By', tablehead: 'Created_By', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Created On', tablehead: 'created_on', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },

    { name: 'Action', tablehead: 'Action', sort: false, showicon: false, sortdesc: false, class: 'fa fa-sort' }

  ];
  sortOn(value: any) {
    this.getapicallforsort(value.tablehead);
    this.tablevaluearray.map(item => {
      item.sort = false;
      item.sortdesc = false;
      item.class = 'fa fa-sort';
    })
    value.sort = true;
    value.class = "fa fa-sort-asc";
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "title")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "loop_type")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "Created_By")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "created_on")) {
      value.class = 'fa fa-sort-desc';
    }


  }

  getapicallforsort(got: any) {
    let sort_on = got;
    let arrange = "DESC";
    if (this.route.snapshot.queryParamMap.get('sort') == "ASC") {
      arrange = "DESC";
      this.sortsign = "sort-desc";
      this.sorton = sort_on;
      this.sorttype = arrange;
    } else {
      arrange = "ASC";
      this.sortsign = "sort-asc";
      this.sorton = sort_on;
      this.sorttype = arrange;
    }
    this.searchwordget = this.route.snapshot.queryParamMap.get('search')
    if (this.searchwordget) {
      this.common.showLoader();
      this.router.navigate(['/admin/loops/deleted-loops-loopkits'], { queryParams: { search: this.validationforsearch, pageno: this.pageno, sort: arrange } });
      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

      // this.http.get(APIURL.BASE_URL + `/admin/get_all_coupon_codes?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&search_input=${this.searchwordget}`, { headers }).subscribe(async (res: any) => {
      this.api.postApiHit(`/admin/get_all_uploads?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&search=${this.searchwordget}&deleted_loops=1`, {}, VARS.ADMIN_TYPE).then((res: any) => {

        if (res.response.status == 1) {
          this.common.hideLoader();
          this.tableData = res.response.data.pagging_list;
        } else {
          this.toastr.error("Error", res.response.error, { positionClass: 'toast-bottom-right' });
          this.common.hideLoader();
        }
      }, async e => {
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.getpageno = this.route.snapshot.queryParamMap.get('pageno');
      if (this.getpageno) {
        this.pageno = this.getpageno;
      } else {
        this.pageno = 1;
      }
      this.router.navigate(['/admin/loops/deleted-loops-loopkits'], { queryParams: { sort: arrange, pageno: this.pageno } });

      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      // this.http.get(APIURL.BASE_URL + `/admin/get_all_coupon_codes?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}`, { headers }).subscribe(async (res: any) => {
      this.api.postApiHit(`/admin/get_all_uploads?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&deleted_loops=1`, {}, VARS.ADMIN_TYPE).then((res: any) => {
        if (res.response.status == 1) {
          this.common.hideLoader();

          this.tableData = res.response.data.pagging_list;

        } else {
          this.toastr.error("", res.response.error, { positionClass: 'toast-bottom-right' });
          this.common.hideLoader();
        }
      }, async e => {
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

      });

    }

  }

  toreportedloops() {
    // //console.log("yes");
    this.router.navigate(['/admin/loops/reported-loops']);
  }


  paginationget(pageinfo: any) {
    this.paginationempty = true;
    this.pageno = pageinfo;
    // let idname = "liststyle" + this.pageno;
    // let idaa: any = document.getElementById(idname);
    // //console.log(idaa);


    // let x: any = document.querySelectorAll(".bluecolor");
    // for (let i = 0; i < x.length; i++) {
    //   x[i].style.backgroundColor = "";
    // }
    // idaa.style.backgroundColor = "orange";
    if (this.route.snapshot.queryParamMap.get('search')) {
      this.searchApi()
    } else {
      this.getapicallforcouponcode();
    }


  }

  // routerLink=""
  touploadloops() {
    // //console.log("yes");
    this.router.navigate(['/admin/loops/list-uploads', { queryParams: { report: "yes" } }]);
  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);

    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`], { queryParams: { "referer": encodeURI(this.router.url) } });
  }

  addcolor(item: any) {
    if (item == this.pageno) {
      return 'activated';
    } else {
      return ''
    }
  }
  // onChangePage(pageOfItems: Array<any>) {

  //   if ((this.pageno !== pageOfItems[0]) && !(pageOfItems[0] == undefined)) {
  //     // //console.log("inside")
  //     this.pageno = pageOfItems[0];
  //     if (this.route.snapshot.queryParamMap.get('search')) {
  //       this.searchApi()
  //     } else {
  //       this.getapicallforcouponcode();
  //     }
  //   }




  // }

  onChangePage(pageOfItems: Array<any>) {

    if (pageOfItems[0] == undefined && this.firstcall == 0) {
      this.firstcall = 1;
      this.temp_pno = Number(this.route.snapshot.queryParamMap.get('pageno'));

    }
    else if (this.firstcall == 1 && this.temp_pno > 0 && (pageOfItems[0] != this.temp_pno)) {

      // //console.log(2)

      this.pageno = this.temp_pno;

      this.firstcall = 0;
      this.temp_pno = 0;

      if (this.route.snapshot.queryParamMap.get('search')) {
        this.searchApi()
      } else {
        this.getapicallforcouponcode();
      }
    }
    else if ((this.pageno !== pageOfItems[0]) && !(pageOfItems[0] == undefined)) {

      this.firstcall = 0;
      this.pageno = pageOfItems[0];

      if (this.route.snapshot.queryParamMap.get('search')) {
        this.searchApi()
      } else {
        this.getapicallforcouponcode();
      }
    }
    else {
      this.firstcall = 0;
      this.temp_pno = 0;
    }


  }

}

