import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UploadloopComponent } from '../CLIENT_PAGES/uploadloop/uploadloop.component';
// import { UploadloopkitComponent } from '../CLIENT_PAGES/user/uploadloopkit/uploadloopkit.component';

@Injectable({
  providedIn: 'root'
})
export class CandeactivateGuard implements CanDeactivate<UploadloopComponent> {
  // canDeactivate(component: UploadloopComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot | undefined): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
  //   throw new Error('Method not implemented.');
  // }
  canDeactivate(component: UploadloopComponent): boolean {
    if (component.canDeactivate) {
      if (confirm("You have unsaved changes! If you leave, your changes will be lost.")) {
        return true;
      } else {
        return false;
      }

    }
   
    return true;
  }

}
