<div _ngcontent-htr-c51="" style="padding-left: 100px;padding-right: 100px;padding-top: 50px;" class="home-wrapper
                                  ">
    <div ngcontent-htr-c51="" class="container-fluid">
        <div ngcontent-htr-c51="" class="text-center">
            <h1 _ngcontent-htr-c51="">Discover your next sound</h1>

        </div>
        <div class="home-search ">
            <mat-form-field>
                <input matInput [matAutocomplete]="auto" type="text" [(ngModel)]="validationforsearch"
                    (keyup)="searchhistory1()" (mouseenter)="searchhistory1()" (keyup.enter)="searchClick()" class="form-control"
                    placeholder="try “piano loop” or “guitar loop"
                    ><button
                   ><i
                        class="fa fa-search" (click)=" searchClick()"></i></button>
                <!-- <small *ngIf="errorforsearch" class="text-danger">{{searchvalue}}</small> -->
                <p class="text-right mt-2"><a class="link" (click)="redireacttoadvancesearch()">Advance Search</a></p>
                <div >
                     <mat-autocomplete #auto="matAutocomplete" class="p-2">
                        <span *ngIf="!hiderecentsearches" class="ml-2">Recent searches </span>   <span class="hoverl-2 link float-right" (click)="clearhistory()">Clear History</span>               
                    <mat-option *ngFor="let option of arrayforsearch" [value]="option.key_word" (click)="searchClick()">
                        {{option.key_word}}
                    </mat-option>
                </mat-autocomplete>
                </div>
                
               
            </mat-form-field>
        </div>
        <div class="loops-carousel-wrap mt-3">
            <h4>Recommended Loops <i (click)="showaccordingto()" class="fa fa-refresh ml-2 hover"></i></h4>
            <!-- <div *ngIf="hidepaginationforloop" class="loop-arrows">
                <span (click)="!lefthide?firstrunprev():null " class="hover {{lefthide?'disable':''}}"><i
                        class="fa fa-arrow-circle-o-left mr-1" aria-hidden="true"></i></span><span
                    class="hover {{righthide?'disable':''}}" (click)="!righthide?firstrunnext():null"><i
                        class="fa fa-arrow-circle-o-right" aria-hidden="true"></i></span>
            </div> -->
            <div *ngIf="hidepaginationforloop" class="loop-arrows">
                <span (click)="!lefthide?firstruna():null" class="hover {{lefthide?'disable':''}}"><i
                        class="fa fa-angle-left mr-1" aria-hidden="true"></i></span><span
                    class="hover {{!lefthide?'disable':''}}" (click)="lefthide?firstruna():null"><i
                        class="fa fa-angle-right" aria-hidden="true"></i></span>
            </div>

            <swiper [navigation]="true" (reachEnd)="call1()" class="mySwiper" [slidesPerView]="slideper"
                [spaceBetween]="20">

                <ng-template swiperSlide *ngFor="let item of array">
                    <div class="loops-carousel">
                        <div class="carousel-item">
                            <div class="loop-artwork">
                                <img [src]="setImage(item)" class="coverImage">
                                <!-- <app-audio-player #audioPlayer [audioSrc]="srcfileforimage(item)"></app-audio-player> -->
                                <span class="play-btn hover play_button" id="play_button">
                                    <i aria-hidden="true" *ngIf="!item.hidepausebutton" (click)="playAudio(item)"
                                        class="fa fa-play"></i>
                                    <i (click)="stopAudio(item)" *ngIf="item.hidepausebutton" class="fa fa-pause"
                                        aria-hidden="true"></i></span>
                            </div>
                            <h5 class="loop-name hover" (click)="redirecttolooppage(item.loop_id)"><a
                                    matTooltip="Go to Detail Page" matTooltipHideDelay="100"
                                    mattooltip="Comments">{{item.title}}</a></h5>
                            <p> <a class="link" (click)="redirecttoprofilepage(item)" matTooltip="Go to Profile Page"
                                    matTooltipHideDelay="100" mattooltip="Comments">@{{item.username}}</a></p>
                            <ul class="loop-stats">
                                <li id="red" class="mat-tooltip-trigger hover "
                                    (click)="heartcolorred(item.loop_id,'loop',item)"
                                    [ngClass]="addredcolor(item)? 'red' : ''" mat-raised-button #red="matTooltip"
                                    matTooltip="Likes" matTooltipHideDelay="100"><i class="fa fa-heart"></i></li>

                                <li class="mat-tooltip-trigger hover" (click)="repost(item.loop_id,item)"
                                    [ngClass]="callforcolour(item)? 'green' : ''" mat-raised-button #red="matTooltip"
                                    matTooltip="Reposts" matTooltipHideDelay="100"><i class="fa fa-retweet"></i></li>
                                <li class="mat-tooltip-trigger hover" (click)="redirecttolooppagecomment(item.loop_id)"
                                    mat-raised-button #red="matTooltip" matTooltip="Comments" matTooltipHideDelay="100">
                                    <i class="fa fa-comment"></i></li>
                                <!-- <li class="mat-tooltip-trigger hover" *ngIf="item.paypal_merchant_account_linked == 1"
                                    [innerHtml]="showpricetag(item)"><i class="fa fa-download"></i>
                                </li> -->
                                <li mat-raised-button #red="matTooltip" matTooltip="Download" matTooltipHideDelay="100"
                                    mattooltip="Download" class="mat-tooltip-trigger hover"
                                    *ngIf="item.paypal_merchant_account_linked == 1" (click)="openmodal(item)"><i class="fa fa-download"></i>
                                </li>
                                <li mat-raised-button #red="matTooltip" matTooltip="Price" matTooltipHideDelay="100"
                                    *ngIf="item.loop_price!==0" mat-raised-button #red="matTooltip" matTooltip="Price"
                                    matTooltipHideDelay="100" class="">
                                    <i class="fa fa-dollar" [innerHtml]="priceget(item)"></i>
                                </li>
                                <!-- <li mat-raised-button #red="matTooltip" matTooltip="Price" matTooltipHideDelay="100"
                                    class="hover">
                                    <i class="fa fa-dollar">{{item.loop_price}}</i>
                                </li> -->
                            </ul>
                            <!-- <div class="displayhide">
                                <ng-waveform #waveform class="waveform" [src]="srcfileforimage(item)">
        
                                </ng-waveform>
                            </div> -->

                        </div>






                    </div>
                </ng-template>

            </swiper>
        </div>
        <div class="loops-carousel-wrap mt-5 my-5">
            <h4>Recommended Loop Kits<i (click)="showaccordingtoloopkit()" class="fa fa-refresh ml-2 hover"></i></h4>
            <!-- <div class="loop-arrows">
                <div *ngIf="hidepagination">
                    <span (click)="!lefthideforloopkit?firstrunforloopkitprev():null "
                        class="hover {{lefthideforloopkit?'disable':''}}"><i class="fa fa-arrow-circle-o-left mr-1"
                            aria-hidden="true"></i></span><span class="hover {{righthideforloopkit?'disable':''}}"
                        (click)="!righthideforloopkit?firstrunforloopkitnext():null"><i
                            class="fa fa-arrow-circle-o-right" aria-hidden="true"></i></span>
                </div>

            </div> -->
            <div class="loop-arrows">
                <div *ngIf="hidepagination">
                    <span (click)="!lefthideforloopkit?firstrunforloopkit():null "
                        class="hover {{lefthideforloopkit?'disable':''}}"><i class="fa fa-angle-left mr-1"
                            aria-hidden="true"></i></span><span class="hover {{!lefthideforloopkit?'disable':''}}"
                        (click)="lefthideforloopkit?firstrunforloopkit():null"><i
                            class="fa fa-angle-right" aria-hidden="true"></i></span>
                </div>

            </div>


            <swiper [navigation]="true" (reachEnd)="call2()" class="mySwiper" [slidesPerView]="slideper"
                [spaceBetween]="20">

                <ng-template swiperSlide *ngFor="let item of arrayforllopkit">
                    <div class="loops-carousel">
                        <div class="carousel-item">
                            <div class="loop-artwork">
                                <img [src]="setImage(item)" class="coverImage">
                                <!-- <app-audio-player #audioPlayer [audioData]="srcforloopid(item)" [audioSrc]="srcfileforimage(item)">
                                </app-audio-player> -->
                                <span class="play-btn hover play_button" id="play_button">
                                    <i aria-hidden="true" *ngIf="!item.hidepausebutton" (click)="playAudio(item)"
                                        class="fa fa-play"></i>
                                    <i (click)="stopAudio(item)" *ngIf="item.hidepausebutton" class="fa fa-pause"
                                        aria-hidden="true"></i></span>
                                <!-- <span class="play-btn hover"><i aria-hidden="true" class="fa fa-play"></i></span> -->
                            </div>
                            <h5 class="loop-name hover" (click)="redirecttolooppage(item.loop_id)"> <a
                                    matTooltip="Go to Detail Page" matTooltipHideDelay="100"
                                    mattooltip="Comments">{{item.title}}</a></h5>
                            <p class="link" (click)="redirecttoprofilepage(item)"><a class="link"
                                    matTooltip="Go to Profile Page" matTooltipHideDelay="100"
                                    mattooltip="Comments">@{{item.username}}</a></p>
                            <ul class="loop-stats">
                                <li id="red" [ngClass]="addredcolor(item)? 'red' : ''"
                                    class="mat-tooltip-trigger hover "
                                    (click)="heartcolorred(item.loop_id,'loopkit',item) " mat-raised-button
                                    #red="matTooltip" matTooltip="Likes" matTooltipHideDelay="100">
                                    <i class="fa fa-heart"></i>
                                </li>

                                <li class="mat-tooltip-trigger hover" (click)="repost(item.loop_id,item)"
                                    [ngClass]=" callforcolour(item)? 'green' : ''" mat-raised-button #red="matTooltip"
                                    matTooltip="Reposts" matTooltipHideDelay="100"><i class="fa fa-retweet"></i></li>
                                <li class="mat-tooltip-trigger hover" (click)="redirecttolooppagecomment(item.loop_id)"
                                    mat-raised-button #red="matTooltip" matTooltip="Comments" matTooltipHideDelay="100">
                                    <i class="fa fa-comment"></i></li>
                                <!-- <li class="mat-tooltip-trigger hover" [innerHtml]="showpricetag(item)"><i
                                        class="fa fa-download"></i>
                                </li> -->
                                <li mat-raised-button #red="matTooltip" matTooltip="Download" matTooltipHideDelay="100"
                                    mattooltip="Download" class="mat-tooltip-trigger hover"
                                    *ngIf="item.paypal_merchant_account_linked == 1" (click)="openmodal(item)"><i class="fa fa-download"></i>
                                </li>
                                <li mat-raised-button #red="matTooltip" matTooltip="Price" matTooltipHideDelay="100"
                                    *ngIf="item.loop_price!==0" mat-raised-button #red="matTooltip" matTooltip="Price"
                                    matTooltipHideDelay="100" class="">
                                    <i class="fa fa-dollar" [innerHtml]="priceget(item)"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>

            </swiper>
        </div>
        
        <div class="top-artists" style="padding: 40px 0;background: #F7F7F7;margin-top: 40px;position: relative;">
            <div class="loops-carousel-wrap">
                <h4>Check Them Out!<i (click)=" showaccordingtouserrecommend()" class="fa fa-refresh ml-2 hover"></i></h4>
                <!-- <div *ngIf="hidepaginationforuser" class="loop-arrows">
                    <span (click)="!lefthideforuser?firstrunforuserprev():null "
                        class="hover {{lefthideforuser?'disable':''}}"><i class="fa fa-arrow-circle-o-left mr-1"
                            aria-hidden="true"></i></span><span class="hover {{righthideforuser?'disable':''}}"
                        (click)="!righthideforuser?firstrunforusernext():null"><i class="fa fa-arrow-circle-o-right"
                            aria-hidden="true"></i></span>
                </div> -->
                <div *ngIf="hidepaginationforuser" class="loop-arrows">
                    <span (click)="!lefthideforuser?firstrunforuser():null "
                        class="hover {{lefthideforuser?'disable':''}}"><i class="fa fa-angle-left mr-1"
                            aria-hidden="true"></i></span><span class="hover {{!lefthideforuser?'disable':''}}"
                        (click)="lefthideforuser?firstrunforuser():null"><i class="fa fa-angle-right"
                            aria-hidden="true"></i></span>
                </div>
    
            </div>
            <swiper [navigation]="true" (reachEnd)="call3()" class="mySwiper" [slidesPerView]="slideperforcheck"
                [spaceBetween]="20">

                <ng-template swiperSlide *ngFor=" let item of arrayforuser">
                    <div class="top-artist" >
                        <img [src]="setImageforuser(item)" class="coverImage">
                        <a class="link" (click)="redirecttoprofilepage(item)" matTooltip="Go to Profile Page"
                            matTooltipHideDelay="100" mattooltip="Comments">@{{item.username}}</a>
                        <p class="text-muted mb-0" *ngIf="item.number_of_loops == 0">{{item.number_of_loops}} Loops</p>
                        <p class="text-muted mb-0" *ngIf="item.number_of_loops == 1">{{item.number_of_loops}} Loops</p>
                        <p class="text-muted mb-0" *ngIf="item.number_of_loops > 1">{{item.number_of_loops}} Loops</p>
                        <p class="text-muted mt-0" *ngIf="item.number_of_loopkits == 0">{{item.number_of_loopkits}} Loop
                            Kit
                        </p>
                        <p class="text-muted mt-0" *ngIf="item.number_of_loopkits == 1">{{item.number_of_loopkits}} Loop
                            Kit</p>
                        <p class="text-muted mt-0" *ngIf="item.number_of_loopkits > 1">{{item.number_of_loopkits}} Loop
                            Kit</p>
                    </div>
                </ng-template>

            </swiper>




        </div>
        <div class="tags-section">
            <h4>Browse Tags</h4>
            <div>
                <span *ngFor="let item of arraytablefortags;last as last" [ngClass]="forfont(item)"
                    (click)="redirectto(item.tag_name)"> #{{item.tag_name}}<span *ngIf="!last">,</span></span>
            </div>


        </div>

    </div>
</div>