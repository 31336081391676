import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { APIURL, VARS } from 'src/app/allServices/constant.service';
import { environment } from 'src/app/sevicesall/alldata';
// import { environment } from '../sevicesall/alldata';


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  client_login_data = environment.client_login_data;

  constructor(
    public api: ApiServiceService
  ) { }

  ngOnInit(): void {
    // //console.log("redkghgkk");
    // this.api.postApiHit(APIURL.Get_lOGGED_IN_USER_DETAILS, {}, VARS.ClIENT_TYPE).then((res: any) => {
    //   //console.log(res.response);
    //   this.client_login_data = res.response.data;
    //   //console.log(this.client_login_data);
    // })
  }

}
