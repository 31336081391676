import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, VARS } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-verify-login-token',
  templateUrl: './verify-login-token.component.html',
  styleUrls: ['./verify-login-token.component.css']
})
export class VerifyLoginTokenComponent implements OnInit {
  apiResponse: boolean;
  tokenget: string | null;
  firsttime: boolean;
  displayname: string;
  apivalid: boolean;
  statusmessage: any;

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute,public common: commonservice,public api: ApiServiceService) { }

  ngOnInit(): void {
    this.getapiforemailverification()
  }
  getapiforemailverification() {
    this.common.showLoader();
    this.apiResponse = false;
    this.tokenget = this.route.snapshot.queryParamMap.get('token');
    // //console.log(this.tokenget);


    this.http.get(APIURL.BASE_URL+`/user/verify_login_token?token=${this.tokenget}`).subscribe(async (res: any) => {
      this.apiResponse = true;
      if (res.response.status == 1) {
        this.common.hideLoader();
        this.firsttime = false;
        this.displayname = "none";
        // //console.log(res);
        this.apivalid = true;
        this.statusmessage = res.response.msg;
        this.common.getDataInCookie(VARS.CLIENT_LOCAL_TOKEN_temp).then((res:any) => {
          // //console.log('Raw String:', res);
          try {
              // let resa = res.json();
              // //console.log(resa);
        localStorage.setItem(VARS.CLIENT_LOCAL_TOKEN_KEY, res);
        this.getUserDetail()
        // this.common.setDataInCookie(VARS.CLIENT_LOCAL_TOKEN_KEY, resa);
          } catch (error) {
              console.error('JSON Parsing Error:', error);
          }
      });
        
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        
     
      } else {
        this.common.hideLoader();
        // //console.log(res);
        this.displayname = "none";
        let errorthroughapi = res.response.errors;
        let error = [];
        this.apivalid = false;
        this.statusmessage = res.response.errors;
        for (var i = 0; i < errorthroughapi.length; i++) {
          // this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.displayname = "none";
      this.common.hideLoader();
    
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
    
  }
  getUserDetail(){
    // //console.log('hii');
  
    
    this.api.postApiHit(APIURL.user_get_logged_in_user_details,{},VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if(res.response.status){
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res:any)=>{
          let resa = JSON.parse(res)
          this.router.navigate(['/explore'])
          this.api.geturltoredirectexceptemailverify(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }
}
