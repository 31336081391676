import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CONSTNAME } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-reportedlog',
  templateUrl: './reportedlog.component.html',
  styleUrls: ['./reportedlog.component.css']
})
export class ReportedlogComponent implements OnInit {
  @Input() items: any;
  @Output() messageEvent = new EventEmitter<string>();
  @Output() sendingsearchEvent = new EventEmitter<string>();
  @Output() sortValueEvent = new EventEmitter<string>();
  total: any;
  record: any;
  hidepagination = false;
  norecordfound: any;
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  pagenocount: any;
  nocomments: boolean;
  pagenolist: any;
  tableData: any[] = [];
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.run(this.items);
    // //console.log(this.items)
    this.pageno = this.route.snapshot.queryParamMap.get('pageno')
  }
  
  paginationget(item:any) {
    // //console.log("red")
    this.messageEvent.emit(item)
  }

  sortOn(item:any) {
    // //console.log(item)
    this.sortValueEvent.emit(item)
  }
  run(res:any) {
    if (res.response.data.total_records == 0) {
      this.norecordfound = true;
    } else {
      this.norecordfound = false;
      // this.callforallinfo(res);
    }

    if (res.response.data.total_pages == 1) {
      this.hidepagination = true;
      // //console.log("hide");
    } else {
      this.hidepagination = false;
      // //console.log("not hide");
    }
    if (this.paginationempty == true) {
      this.pagercount = [];
    }
    this.total = res.response.data.total_records;
    
    this.tableData = res.response.data.pagging_list;
    // //console.log(this.tableData)

    this.record = this.tableData.length;
    if (this.record == 0) {
      this.nocomments = false;
    } else {
      this.nocomments = true;
    }
    this.pagenolist = res.response.data.total_pages + 1;

    this.record = this.pageno - 1 + "1";
    // //console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
    this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length

    this.tableData = res.response.data.pagging_list;
    // this.record = this.tableData.length;
    this.pagenolist = res.response.data.total_pages + 1;
    if (this.record == "01") {
      this.record = 1;
    }
    for (let i = 1; i < this.pagenolist; i++) {
      this.pagercount.push(i);
    }
    this.total = res.response.data.total_records;
  }

  setimage(data: any) {
    if (data.profile_image_path == null) {
      // //console.log("i am in null");
      return this.profile_image_pathofcomments;
    } else {
      // //console.log("i am not in null");
      return CONSTNAME.fILE_PATH + data.profile_image_path;
    }
  }

  tablevaluearray = [
    { name: 'Reason', tablehead: 'Reason', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },

    { name: 'Reported  By', tablehead: 'Created_By', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Created On', tablehead: 'created_on', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },

    // { name: 'Action', tablehead: 'Action', sort: false, showicon: false, sortdesc: false, class: 'fa fa-sort' }

  ];
  searchClick() {
    // //console.log(this.validationforsearch)
    this.sendingsearchEvent.emit(this.validationforsearch)
  }

}
