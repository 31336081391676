<app-header></app-header>
<div class="page-header" style="background:white;background-size: cover;">
  <div class="title-header">
  <!-- <div class="container">
    <h3>Contact</h3>
  </div> -->
</div>

<div ngcontent-pmk-c122="" class="contact-wrapper mt-2"><div ngcontent-pmk-c122="" class="container">
  <h3 class="top-padding"></h3>
  <div class="row padding-side">
    <div class="text-start col-lg-5 col-md-8 col-12 mx-auto client-login card">
    <h4 class="reach-out">Reach out to us! </h4>
      <p class="text-muted text-box">We will answer any questions, comments, or concerns you have</p>
      <form (ngSubmit)="send()" [formGroup]="loginFormforcontact">
        <div class="form-group text-left">
          <label>Name</label><span class="ml-1 text-danger">*</span>
          <input type="text" formControlName="contact_name" class="form-control" placeholder="Name">
          <small class="text-danger" [innerHTML]="vCheck('contact_name', '_INPUT')"></small>
        </div>
        <div class="form-group text-left">
          <label>Email</label><span class="ml-1 text-danger">*</span>
          <input type="email" class="form-control" formControlName="contact_email" placeholder="Email">
          <span style="display: flex; justify-content: space-between; flex-direction: column;">
            <small class="text-danger" [innerHTML]="vCheck('contact_email', '_EMAIL')"></small>
        </span>
        </div>
        <div class="form-group text-left">
          <label>Message</label><span class="ml-1 text-danger">*</span>
          <textarea class="form-control message" formControlName="contact_message" placeholder="Message"></textarea>
          <span style="display: flex; justify-content: space-between; flex-direction: column;">
            <small class="text-danger" [innerHTML]="vCheck('contact_message', '_INPUT')"></small>
        </span>
        </div>
  <div class="form-group text-left"><button class="btn login-btn-form" (click)="send()">Send message</button></div>
      </form>
      
    </div>
    </div>
  </div></div>
</div>