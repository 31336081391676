<app-header></app-header>
<div style="margin-top: 100px;">

	<div class="container tandc">
		<h1>Privacy Policy for Loophead
		</h1>
		<p>
		Last Updated: 01/21/2024

		</p>
	<h1>Introduction</h1>

<p>Welcome to Loophead! This Privacy Policy explains how we, Loophead, collect, use, disclose, and safeguard your information when you visit our website [Your Website URL] including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”).<p>

<h3>1. Collection of Your Information</h3>

<p>We may collect information about you in a variety of ways. The information we may collect on the Site includes:</p>

<p class="semi-li"><strong>1.1 Personal Data </strong></p><p class="semi-li">
    Personally identifiable information, such as your name, email address, and telephone number, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as uploading music content.
    </p>
<p class="semi-li"><strong>1.2 Derivative Data</strong> </p><p class="semi-li">
    Information our servers automatically collect when you access the Site, such as your IP address, browser type, operating system, access times, and the pages you have viewed directly before and after accessing the Site.
    </p>
    <p class="semi-li"><strong>1.3 Financial Data</strong> </p><p class="semi-li">
        Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, or request information about our services from the Site. [If applicable]
        </p>
<h3>2. Use of Your Information</h3>

<p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:</p>
<ul type="none">
    <li>- Create and manage your account.</li>
    <li>- Email you regarding your account or orders.</li>
    <li>- Fulfill and manage purchases, orders, payments, and other transactions related to the Site.</li>
    <li>- Increase the efficiency and operation of the Site.</li>
    <li>- Monitor and analyze usage and trends to improve your experience with the Site.</li>
    <li>- Notify you of updates to the Site.</li>
    <li>- Offer new products, services, and/or recommendations to you.</li>
    <li>- Perform other business activities as needed.</li>
    <li>- Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
    <li>- Request feedback and contact you about your use of the Site.</li>
    <li>- Resolve disputes and troubleshoot problems.</li>
    <li>- Respond to product and customer service requests.</li>
    <li>- Send you a newsletter. [If applicable]</li>
  </ul>
  

<h3>3. Disclosure of Your Information</h3>

<p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:<p>
    <ul type="none">
        <li>- By Law or to Protect Rights</li>
        <li>- Third-Party Service Providers</li>
        <li>- Marketing Communications</li>
        <li>- Interactions with Other Users</li>
        <li>- Online Postings</li>
        <li>- Third-Party Advertisers [If applicable]</li>
        <li>- Affiliates [If applicable]</li>
        <li>- Business Partners [If applicable]</li>
        <li>- Offer Wall [If applicable]</li>
        <li>- Social Media Contacts</li>
        <li>- Sale or Bankruptcy</li>
      </ul>
      

<h3>4. Tracking Technologies</h3>

<p class="semi-li"><strong>4.1 Cookies and Web Beacons </strong></p>
<p class="semi-li">
    We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site to help customize the Site and improve your experience.</p>

    <p class="semi-li"><strong>4.2 Internet-Based Advertising</strong></p>
    <p class="semi-li">
        Additionally, we may use third-party software to serve ads on the Site, implement email marketing campaigns, and manage other interactive marketing initiatives. [If applicable]</p>
        
<p class="semi-li"><strong>4.3 Website Analytics </strong></p>
<p class="semi-li"> 
    We may also partner with selected third-party vendors[, such as [List of analytics providers]], to allow tracking technologies and remarketing services on the Site through the use of first-party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Site, determine the popularity of certain content and better understand online activity.</p>
<h3>Third-Party Websites</h3>

<p>The Site may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Site, any information you provide to these third parties is not covered by this Privacy Policy.</p>

<h3>6. Security of Your Information</h3>

<p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other types of misuse.</p>

<h3>7. Policy for Children</h3>

<p>We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.</p>

<h3>8. Controls for Do-Not-Track Features</h3>
<p>Most web browsers and some mobile operating systems include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.
</p>
<h3>9. Options Regarding Your Information</h3>
<p>You may at any time review or change the information in your account or terminate your account by:
</p>
<ul type="none" >
    <li>- Logging into your account settings and updating your account</li>
    <li>- Contacting us using the contact information provided</li>
    <li>- Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use, and/or comply with legal requirements.
    </li>
  </ul>
  <h3>10. Contact Us</h3>
<p>For any queries or concerns about these Terms, please contact us at theloophead@gmail.com</p>
</div>
</div>

<app-footer></app-footer>