<app-header></app-header>
<div class="container">
<div class="box1">
<div>
    <h4 class="ntification-head">Notifications</h4>
</div>
<div>
    <p class="p-head">View and manage your Loophead notifications</p>
    <div *ngIf="total_records!=0" style="margin-top: 50px;" class="notification-themselves">
    <div class="ntification-line"  *ngFor="let item of arrayfortable">
        <div class="my-auto" (click)="remove(item)" style="min-width:20px; margin-right: 14px; font-size: 18px;color: #444445;cursor: pointer;">
            <img src="../../../../assets/img/Cross img.svg"/>
        </div>
        <div  *ngIf="item.notification_type===1" style="min-width: 24px;
        text-align: center;"><img src="../../../../assets/img/heart-regular.svg"/></div>
        <div *ngIf="item.notification_type===2" style="min-width: 24px;
        text-align: center;"><img src="../../../../assets/img/comment.svg"/></div>
        <div *ngIf="item.notification_type===3" style="min-width: 24px;
        text-align: center;"><img src="../../../../assets/img/repost.svg"/></div>
        <div *ngIf="item.notification_type===4" style="min-width: 24px;
        text-align: center;"><img src="../../../../assets/img/user-plus-01 (1).svg"/></div>
        <div *ngIf="item.notification_type===5" style="min-width: 24px;
        text-align: center;"><img src="../../../../assets/img/user-plus-01 (1).svg"/></div>
        <div *ngIf="item.notification_type===6" style="min-width: 24px;
        text-align: center;"><img src="../../../../assets/img/sales.svg"/></div>
                      <div *ngIf="item.notification_type===7" style="min-width: 24px;
                      text-align: center;"><img src="../../../../assets/img/download-01 (2).svg"/></div>
                      <div *ngIf="item.notification_type===8" style="min-width: 24px;
                      text-align: center;"><img src="../../../../assets/img/list (2).svg"/></div>
                      <img [src] = "setimage(item)" min-width="50px" style="border-radius: 50%;max-width: 50px;min-height: 50px;    min-width: 50px;
                      max-height: 50px;object-fit: cover;"/>
            <div class="d-flex my-auto ntify-name">
                <span class="name-tag"  style="cursor: pointer;"  (click)="redirecttouser(item.notification_text_1)">{{item.notification_text_1}}</span>
                <span >{{item.notification_text_2}}</span>
                <span style="cursor: pointer;" class="name-tag" *ngIf="item.notification_type===7"  (click)="redirecttoLoop(item.loop_id)" >{{item.notification_text_3}}</span>
                <span style="cursor: pointer;" class="name-tag" *ngIf="item.notification_type===8" (click)="redirecttopack(item)" >{{item.notification_text_3}}</span>
            </div>
    </div>
</div><div *ngIf="total_records==0" style="width: 100%;display: flex;justify-content: center;margin-top: 10%;">
        <h2>No Notifications</h2>
    </div>
</div>
</div>
</div>
<app-footer></app-footer>
