// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-admininvoice',
//   templateUrl: './admininvoice.component.html',
//   styleUrls: ['./admininvoice.component.css']
// })
// export class AdmininvoiceComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BannedComponent } from 'src/app/ADMIN_PAGE/banned/banned.component';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-admininvoice',
  templateUrl: './admininvoice.component.html',
  styleUrls: ['./admininvoice.component.css']
})
export class AdmininvoiceComponent implements OnInit {
  loopid: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  arrayfortable: any = []
  loopidpayment: any;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;

  constructor(public matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public api: ApiServiceService, private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, public common: commonservice, private dialogRef: MatDialogRef<BannedComponent>) { }

  ngOnInit(): void {
    this.loopid = this.data.pagename
    this.loopidpayment = this.data.paymentid

    this.callforinvoice(this.loopid,this.loopidpayment);
  }
  leave() {
    this.matDialog.closeAll();
    // //console.log("cancel");
    // this.dialogRef.close({ data: false });
  }

  callforinvoice(item: any,items:any) {
    this.common.showLoader();
    let sendingData = {
      "loop_id":Number(item),
      "payment_id":items
    }
    this.api.postApiHit(APIURL.admin_invoice, sendingData, VARS.ADMIN_TYPE).then((res: any) => {
    // this.api.postApiHit('/user/get_invoice',sendingData , VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        // //console.log(res.response.data);
       
        this.arrayfortable = res.response.data
        // this.getallinfo();
        // this.getUserDetail();
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        this.common.hideLoader();


      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
        this.common.hideLoader();
        this.matDialog.closeAll();

      }
    }, async e => {
      
      this.toastr.error("Failed to get state details ", "", { positionClass: 'toast-bottom-right' });
      this.common.hideLoader();
      this.matDialog.closeAll();
    });
  }

  setimage(data:any) {
    if (data.artwork_path == null) {
      // //console.log("i am in null");
    return this.profile_image_pathofcomments;
    } else {
      // //console.log("i am not in null");
      return CONSTNAME.fILE_PATH + data.artwork_path;
    }
  }

  redirecttoprofilepage(item:any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(item.user_id);

    this.router.navigate(['user/sounds'], { queryParams: { user_id: this.encrypted } });
    this.matDialog.closeAll()
  }
  redirecttolooppage(item:any) {
    // //console.log(value)


    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
    this.matDialog.closeAll()
    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);

  }

  title = 'html-to-pdf-angular-application';
  public convetToPDF() {
    var data: any = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
    });
  }

  
  }



