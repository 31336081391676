<!-- <img [src]="srcforimage(item)"> -->
<!-- <h4 class="mb-4">Download History</h4> -->
<div class="uploads-wrapper" *ngFor="let item of tableData">
    <div class="uploaded-item  download-item"> <span class="user-img
        "></span><span class=" loop-badge"
            *ngIf="item.loop_type == 1">Loop</span><span class=" kit-badge" *ngIf="item.loop_type == 2">Loop
            Kit</span><span class="item-name hover"><a class="link"
                (click)="detailpageofloop(item.loop_id)">{{item.title}}</a>, </span> <span class="item-nam hover"><a
                class="lin" (click)="redirecttoprofilepage(item)">{{item.username}}</a>,
        </span><span class="item-terms"> {{item.terms_name}}, </span><span
            class="upload-date">{{item.download_date_time| date: 'dd-MM-yyyy
            ' }} </span><span (click)="redirecttoinvoice(item.loop_id,item.payment_id)" class="download-invoice hover"
            *ngIf="item.is_paid_loop == 1"><i class="fa fa-file"></i></span>
        
    </div>


</div>


<!-- 
<h4 class="mb-4">Download Archives  <a href="" class="link-right link"><i class="fa fa-arrow-left"></i> Back to Uploads</a></h4>
<div class="archive-loop-info"><span class="loop-badge">Loop </span> <span class="sub-type">One Man Tribe, 50+50 Split</span> <small>2k downloads</small></div>
<div class="uploads-wrapper">
    <div class="uploaded-item archive-item"><span class="user-img
        "><img src="https://d1ptp6rux3p5im.cloudfront.net/profile_photos/1624622395806-46166b36421ad8152365.jpg" ></span><span class="user-name">@datacode</span><span class="upload-date">18/06/2021 3:53 PM </span><span class="download-invoice"><i class="fa fa-file"></i></span>
    </div>
</div> -->

<div *ngIf="noresult" class="noresul text-center">No records to display </div>

<div *ngIf="norecordfound" class="text-center">No records to display </div>
<div class="text-center" *ngIf="!noresult">
    <ul class="paginationlist">
        <!-- <li class="text-info" *ngIf="!norecordfound">Showing {{record}} records of {{total}}</li> -->
        <li class="text-info" *ngIf="!norecordfound">Showing {{record}} to {{pagenocount}} records of {{total}}</li>
        <!-- <li class="text-info"> {{total}}</li> -->
        <ul class="nextulpagination" *ngIf="!hidepagination">

            <li class="paginationliyellowcolor bluecolor hover" [ngClass]="{'activated':item == pageno}"
                *ngFor="let item of pagercount" id="liststyle{{item}}" (click)="paginationget(item)">
                {{item}}

            </li>
        </ul>

    </ul>


</div>