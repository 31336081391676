<app-header></app-header>

<div class="main">
  <div class="desktop-screen"  *ngIf="!isMobile">
    <div class="container">
      <div class="row mt-5">
        <div class="col-lg-2 col-md-3 col-6 d-flex align-items-center">
          <div style="max-width: 180px">
            <img class="prfile-img" [src]="setimage(tableforinfo)" />
          </div>
        </div>
        <div class="col-lg-10 col-md-9 main-details">
          <div class="user-details">
            <div class="col-lg-8 mt-3">
              <div>
                <div style="display: grid">
                  <div class="prfil-user">
                    @{{ tableforinfo.username }}
                  </div>
                  <div class="follower-list-icons">
                    <div class="icons-social">
                      <!-- <a
                      (click)="redirecttotwitter(tableforinfo)"
                      *ngIf="tableforinfo.twitter_link"
                      style="margin-right: 8px; cursor: pointer"
                      ><i
                        class="fab fa-twitter"
                        style="color: #1da1f2; font-size: 28px"
                      ></i
                    ></a> -->
                      <button class="icon-btn"
                      style="box-shadow: none; padding: 0px; width: fit-content; min-width: 38px"

                        mat-raised-button
                        (click)="redirecttotwitter(tableforinfo)"
                        matTooltip="{{ tableforinfo.twitter_link }}"
                        *ngIf="tableforinfo.twitter_link"
                        aria-label="Button that displays a tooltip when focused or hovered over">
                        <i class="fab fa-twitter" style="color: #1da1f2; font-size: 28px"></i>
                      </button>

                      <!-- <a (click)="redirecttoinstagram(tableforinfo)" *ngIf="tableforinfo.instagram_link"  style="margin-right: 8px;cursor: pointer;"><i class="fab fa-instagram" style="color:#444445; font-size: 28px;"></i></a> -->
                      <button class="icon-btn"
                      style="box-shadow: none; padding: 0px; width: fit-content; min-width: auto"
                        mat-raised-button
                        matTooltip="{{ tableforinfo.instagram_link }}"
                        (click)="redirecttoinstagram(tableforinfo)"
                        *ngIf="tableforinfo.instagram_link"
                        aria-label="Button that displays a tooltip when focused or hovered over">
                        <i class="fab fa-instagram" style="color: #444445; font-size: 28px"></i>
                      </button>
                      <!-- <a (click)="redirecttobeatstar(tableforinfo)" *ngIf="tableforinfo.beatstars_link"  style="margin-right: 8px;cursor: pointer;"><img src="assets/img/icons/beatstars.png" style="height: 55px; padding-bottom: 16px;"></a> -->
                      <button class="icon-btn"
                        style="box-shadow: none; padding: 0px; width: fit-content; min-width: auto"
                        mat-raised-button
                        matTooltip="{{ tableforinfo.beatstars_link }}"
                        (click)="redirecttobeatstar(tableforinfo)"
                        *ngIf="tableforinfo.beatstars_link"
                        aria-label="Button that displays a tooltip when focused or hovered over">
                        <img src="assets/img/icons/beatstars.png" style="    height: 40px; margin-top: 3px;" />
                      </button>
                      <!-- <a (click)="redirecttoyoutube(tableforinfo)" *ngIf="tableforinfo.youtube_link" ><i class="fab fa-youtube" style="cursor: pointer;color: #FF0302; font-size: 28px;"></i></a> -->
                      <button class="icon-btn"
                        style="box-shadow: none; padding: 0px; width: fit-content; min-width: auto"
                        mat-raised-button
                        matTooltip="{{ tableforinfo.youtube_link }}"
                        (click)="redirecttoyoutube(tableforinfo)"
                        *ngIf="tableforinfo.youtube_link"
                        aria-label="Button that displays a tooltip when focused or hovered over">
                        <i class="fab fa-youtube" style="cursor: pointer; color: #ff0302; font-size: 28px"></i>
                      </button>
                    </div>
                    
                    <div class="follow-no">
                      <div
                    (click)="openfollower()"
                    style="align-self: stretch; justify-content: center; align-items: baseline; gap: 8px; display: flex; cursor: pointer">
                    <div
                      style="
                        color: black;
                        font-size: 24px;
                        font-family: Inter;
                        font-weight: 700;
                        line-height: 30px;
                        word-wrap: break-word;
                      ">
                      {{ tableforinfo.number_of_followers }}
                    </div>
                    <div
                      style="
                        color: #797979;
                        font-size: 16px;
                        font-family: Inter;
                        font-weight: 400;
                        line-height: 30px;
                        word-wrap: break-word;
                      ">
                      followers
                    </div>
                  </div>
                      <div
                      (click)="openfollowing()"
                      style="
                        align-self: stretch;
                        justify-content: flex-start;
                        align-items: baseline;
                        gap: 8px;
                        display: flex;
                        cursor: pointer;
                      ">
                      <div
                        style="
                          color: black;
                          font-size: 24px;
                          font-family: Inter;
                          font-weight: 700;
                          line-height: 30px;
                          word-wrap: break-word;
                        ">
                        {{ tableforinfo.number_of_following }}
                      </div>
                      <div
                        style="
                          color: #797979;
                          font-size: 16px;
                          font-family: Inter;
                          font-weight: 400;
                          line-height: 30px;
                          word-wrap: break-word;
                        ">
                        following
                      </div>
                    </div>
                    
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4" style="align-items: flex-end; display: flex; padding: 0px">
              <div class="following-row col">
                <div class="side">
                  <a class="col edit-btn" (click)="redirecttoeditProfile()" *ngIf="tableforinfo.show_edit_profile_button == 1">
                    <div
                      style="text-align: center; color: white; font-size: 16px; font-family: Inter; font-weight: 700; word-wrap: break-word">
                      Edit Profile
                    </div>
                  </a>
                  <a
                    class="col"
                    (click)="Followapi()"
                    *ngIf="tableforinfo.show_edit_profile_button == 0 && tableforinfo.is_follower == 0"
                    style="
                      padding-left: 60px;
                      padding-right: 60px;
                      padding-top: 16px;
                      padding-bottom: 16px;
                      background: black;
                      border-radius: 10px;
                      justify-content: center;
                      align-items: flex-start;
                      gap: 10px;
                      display: flex;
                      cursor: pointer;
                    ">
                    <div
                      style="text-align: center; color: white; font-size: 16px; font-family: Inter; font-weight: 700; word-wrap: break-word">
                      Follow
                    </div>
                  </a>
                  <div class="dropdown btns-unfollow" *ngIf="tableforinfo.is_follower == 1 && tableforinfo.show_edit_profile_button == 0">
                    <button
                      class="dropbtn btn btn-sm unfollw-btn"
                      style="
                        text-align: center;
                        color: white;
                        font-size: 16px;
                        font-family: Inter;
                        font-weight: 700;
                        cursor: pointer;
                        word-wrap: break-word;
                        padding: 13px 48px;
                        border-radius: 12px;
                      ">
                      Following
                    </button>
                    <div class="dropdown-content" style="cursor: pointer">
                      <a (click)="unfollow(tableforinfo)">Unfollow</a>
                    </div>
                  </div>

                  <div class="dropdown btn" *ngIf="tableforinfo.is_follower == 0 && tableforinfo.has_user_requested_before == 1">
                    <button class="dropbtn btn-sm btn-theme">Requested</button>
                    <div class="dropdown-content">
                      <a (click)="withdraw()">Withdraw Follow Request</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              color: #797979;
              font-size: 16px;
              font-family: Inter;
              font-weight: 400;
              line-height: 30px;
              word-wrap: break-word;
              margin: 16px;
            ">
            {{ tableforinfo.bio_guidelines }}
          </div>
        </div>
      </div>
      <!-- <button (click)="redirecttourl()">Click</button> -->

      <div style="width: 100%; position: relative">
        <div
          *ngIf="
            tableforinfo.profile_type !== 0 ||
            tableforinfo.show_edit_profile_button == 1 ||
            admintokenpresent ||
            tableforinfo.is_follower == 1
          "
          ngcontent-wil-c120=""
          class="tabs"
          style="margin-top: 40px; padding-inline-start: 10px; display: flex; column-gap: 26px; margin-bottom: 20px">
          <div
            ngcontent-wil-c120=""
            style="
              font-weight: 600;
              cursor: pointer;
              font-size: 16px;
              color: #888888;
            "
            (click)="soundfun()"
            [ngClass]="showsoundfound ? 'active border1' : ''">
            Loops
          </div>
          <div
            (click)="looplikeon()"
            [ngClass]="!showsoundfound ? 'active border1' : ''"
            style="
              cursor: pointer;
              font-size: 16px;
              color: #888888;
              font-weight: 600;
            "
          >
            Packs
          </div>
        </div>
        <div
          *ngIf="
            tableforinfo.profile_type == 0 &&
            tableforinfo.show_edit_profile_button !== 1 &&
            tableforinfo.is_follower == 0 &&
            !admintokenpresent
          "
          ngcontent-wil-c120=""
          class="tabs">
          <button ngcontent-wil-c120="" [ngClass]="showsoundfound ? 'active ' : ''">Loops</button>
        </div>
        <app-loopkitsuserlikes *ngIf="!showsoundfound" [check]="tableforinfo.show_edit_profile_button" [profileid]="decrypted"></app-loopkitsuserlikes>
        <app-sounds *ngIf="showsoundfound" [check]="tableforinfo.show_edit_profile_button" [profileid]="decrypted"></app-sounds>
      </div>
    </div>
  </div>


  <div class="mobile-screen"  *ngIf="isMobile">
    <div class="container">
      <div class="row mt-4">
        <div class="col-4 following-div count-div"  (click)="openfollowing()">
          <div class="following-no count-no">
            {{ tableforinfo.number_of_following }}
            <span>Following</span>
          </div>
        </div>
        <div class="col-4 img-div">
          <div>
            <img class="prfile-img" [src]="setimage(tableforinfo)" />
          </div>
        </div>
        <div class="col-4 followers-div count-div" (click)="openfollower()">
          <div class="followers-no count-no">
            {{ tableforinfo.number_of_followers }}
            <span>Followers</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="prfil-user">
          @{{ tableforinfo.username }}
        </div>
      </div>
        <div class="icons-social">
          <!-- <a
          (click)="redirecttotwitter(tableforinfo)"
          *ngIf="tableforinfo.twitter_link"
          style="margin-right: 8px; cursor: pointer"
          ><i
            class="fab fa-twitter"
            style="color: #1da1f2; font-size: 28px"
          ></i
        ></a> -->
          <button class="icon-btn"
            mat-raised-button
            (click)="redirecttotwitter(tableforinfo)"
            matTooltip="{{ tableforinfo.twitter_link }}"
            *ngIf="tableforinfo.twitter_link"
            aria-label="Button that displays a tooltip when focused or hovered over">
            <i class="fab fa-twitter"></i>
          </button>

          <!-- <a (click)="redirecttoinstagram(tableforinfo)" *ngIf="tableforinfo.instagram_link"  style="margin-right: 8px;cursor: pointer;"><i class="fab fa-instagram" style="color:#444445; font-size: 28px;"></i></a> -->
          <button class="icon-btn"
            mat-raised-button
            matTooltip="{{ tableforinfo.instagram_link }}"
            (click)="redirecttoinstagram(tableforinfo)"
            *ngIf="tableforinfo.instagram_link"
            aria-label="Button that displays a tooltip when focused or hovered over">
            <i class="fab fa-instagram"></i>
          </button>
          <!-- <a (click)="redirecttobeatstar(tableforinfo)" *ngIf="tableforinfo.beatstars_link"  style="margin-right: 8px;cursor: pointer;"><img src="assets/img/icons/beatstars.png" style="height: 55px; padding-bottom: 16px;"></a> -->
          <button class="icon-btn"
            mat-raised-button
            matTooltip="{{ tableforinfo.beatstars_link }}"
            (click)="redirecttobeatstar(tableforinfo)"
            *ngIf="tableforinfo.beatstars_link"
            aria-label="Button that displays a tooltip when focused or hovered over">
            <img src="assets/img/icons/beatstars.png" style="width: 20px;"/>
          </button>
          <!-- <a (click)="redirecttoyoutube(tableforinfo)" *ngIf="tableforinfo.youtube_link" ><i class="fab fa-youtube" style="cursor: pointer;color: #FF0302; font-size: 28px;"></i></a> -->
          <button class="icon-btn"
            mat-raised-button
            matTooltip="{{ tableforinfo.youtube_link }}"
            (click)="redirecttoyoutube(tableforinfo)"
            *ngIf="tableforinfo.youtube_link"
            aria-label="Button that displays a tooltip when focused or hovered over">
            <i class="fab fa-youtube"></i>
          </button>
        </div>
        <div class="bio-data">
          {{ tableforinfo.bio_guidelines }}
        </div>
        <div class="side all-btns">
          <a class="col edit-btn" (click)="redirecttoeditProfile()" *ngIf="tableforinfo.show_edit_profile_button == 1">
            <button>
              Edit Profile
            </button>
          </a>
          <a class="col"(click)="Followapi()" *ngIf="tableforinfo.show_edit_profile_button == 0 && tableforinfo.is_follower == 0">
            <button style="text-align: center; color: white; font-size: 16px; font-family: Inter; font-weight: 700; word-wrap: break-word">
              Follow
            </button>
          </a>
          <div class="dropdown btns-unfollow" *ngIf="tableforinfo.is_follower == 1 && tableforinfo.show_edit_profile_button == 0">
            <button class="dropbtn btn btn-sm unfollw-btn">
              Following
            </button>
            <div class="dropdown-content" style="cursor: pointer">
              <a (click)="unfollow(tableforinfo)">Unfollow</a>
            </div>
          </div>

          <div class="dropdown btn" *ngIf="tableforinfo.is_follower == 0 && tableforinfo.has_user_requested_before == 1">
            <button class="dropbtn btn-sm btn-theme">Requested</button>
            <div class="dropdown-content">
              <a (click)="withdraw()">Withdraw Follow Request</a>
            </div>
          </div>
        </div>
      </div>
      <!-- <button (click)="redirecttourl()">Click</button> -->

      <div class="container">
        <div *ngIf="tableforinfo.profile_type !== 0 || tableforinfo.show_edit_profile_button == 1 || admintokenpresent || tableforinfo.is_follower == 1 "ngcontent-wil-c120="" class="tabs">
          <div ngcontent-wil-c120="" class="tab tab1"  (click)="soundfun()"  [ngClass]="showsoundfound ? 'active border1' : ''">
            Loops
          </div>
          <div
            (click)="looplikeon()"
            [ngClass]="!showsoundfound ? 'active border1' : ''" class="tab tab2">
            Packs
          </div>
        </div>
        <div
          *ngIf="
            tableforinfo.profile_type == 0 &&
            tableforinfo.show_edit_profile_button !== 1 &&
            tableforinfo.is_follower == 0 &&
            !admintokenpresent
          "
          ngcontent-wil-c120=""
          class="tabs">
          <button ngcontent-wil-c120="" [ngClass]="showsoundfound ? 'active ' : ''">Loops</button>
        </div>
        <app-loopkitsuserlikes *ngIf="!showsoundfound" [check]="tableforinfo.show_edit_profile_button" [profileid]="decrypted"></app-loopkitsuserlikes>
        <app-sounds *ngIf="showsoundfound" [check]="tableforinfo.show_edit_profile_button" [profileid]="decrypted"></app-sounds>
      </div>
    </div>
  </div>

<app-footer></app-footer>

<!--  -->
<!-- <div class="container" style="padding: 40px">
      <div class="row" style="gap: 10px">
        <div class="col-md-4" style="padding-left: 12px; padding-right: 12px; padding-top: 12px; padding-bottom: 12px; background: white; border-radius: 10px; overflow: hidden; border: 0.50px #888888 solid; justify-content: flex-start; gap: 8px;">
          <label class="fas fa-search" style="color: #888888; padding-right: 8px;"></label>
          <input type="search" placeholder="Search by title or tag" style="font-size: 16px; font-family: Inter; font-weight: 300; word-wrap: break-word; border-style: none; outline: none; width: 85%">
        </div>
        
        <a class="col-md-2" href="#" style="border-radius: 10px; padding: 12px 20px; background-color: black; margin-left: auto;">
          <p class="text-center" style="font-weight: 700; font-size: medium; color: #FFFFFF; margin: 0; text-transform: capitalize;">Create Pack</p>
        </a>

        <div style="width:100%; margin-top: 40px;">
          <table class="table table-borderless">
          <thead>
            <tr>
              <th scope="col" width="55%">Loop</th>
              <th scope="col" width="10%">BPM</th>
              <th scope="col" width="10%">Key</th>
              <th scope="col" width="15%">Tags</th>
              <th scope="col" width="10%">Actions</th>
            </tr>
          </thead>
          <tbody>
            
          </tbody>
          </table>
        </div>
      </div>
    </div> -->

<!-- </div>
</div> -->
