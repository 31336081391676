<!-- <h1>SHUBHAm</h1> -->
<div infiniteScroll [infiniteScrollDistance]="0.1" (scrolled)="onScroll()">
    <div *ngFor="let items of arrayfortable">
        <p> <img class="userImage" [src]="imagesrc(items)" alt="">{{items.notification_text}} </p>
        <!-- <p *ngIf="items.has_owner_requested_before == 1">Requested</p>
    <p *ngIf="items.has_owner_requested_before == 1">Requested</p> -->
        <!-- <p *ngIf="items.is_owner_already_follower !== 1 && items.has_owner_requested_before == 1">Requested</p>
    <p *ngIf="items.is_owner_already_follower !== 1 && items.has_owner_requested_before == 1">Follow</p> -->
        <p *ngIf="items.is_follow_request == 1"> <span (click)="functionforaccept(items)"> Accept</span>
            <span (click)="functionfordecline(items)">Decline</span>
        <p *ngIf="items.is_follow_request == 0 && items.is_owner_already_follower == 1 "
            (click)="functionforaccept(items)"><span [innerHtml]="timeget(items)">dd</span>
        </p>
        <p *ngIf="items.is_follow_request == 0 && items.is_owner_already_follower == 0 && items.has_owner_requested_before == 1 "
            (click)="functionforaccept(items)">Requested
        </p>
        <p *ngIf="items.is_follow_request == 0 && items.is_owner_already_follower == 0 && items.has_owner_requested_before == 0 && items.started_following_request === 1"
            (click)="Followapi(items)">Follow
            <span [innerHtml]="timeget(items)"></span>
        </p>
        <!-- <p *ngIf="items.is_follow_request == 0 && items.is_owner_already_follower == 0 && items.has_owner_requested_before == 0 "
        (click)="functionforaccept(items)">Follow
    </p> -->
        <span *ngIf="items.started_following_request !== 1"><img class="userImage" [src]="srcartwork(items)"
                alt=""></span>

    </div>
</div>

<ul 
  infiniteScroll
  [infiniteScrollDistance]="0.1"
  (scrolled)="onScroll()">

  <li *ngFor="let post of posts">
    {{post}}
  </li>
  
</ul>