import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-download-filter',
  templateUrl: './download-filter.component.html',
  styleUrls: ['./download-filter.component.css']
})
export class DownloadFilterComponent implements OnInit {
  username_checked: any;
  username:any
  title_checked:any
  title:any
  days:any
  month:any
  year:any
  data: any;
  numbers: string | null;
  type: any;
  pack:any;
  loop:any
  constructor(public matDialog: MatDialog,private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.title = this.route.snapshot.queryParamMap.get('title');
    this.numbers = this.route.snapshot.queryParamMap.get('days');
    this.username = this.route.snapshot.queryParamMap.get('username');
    this.type = this.route.snapshot.queryParamMap.get('type');
    if(this.title){
      this.title_checked=true
    }
    if(this.username){
      this.username_checked=true
    }
    // //console.log(this.loop,this.pack);
    
    if(this.type){
      if(this.type=="loop"){
        this.loop = true
      }else if(this.type=="pack"){
        this.pack=true
      }
    }
    if(this.numbers){
    if(this.numbers=="7"){
      this.days=this.numbers
    }else if(this.numbers=="30"){
      this.month=this.numbers
    }else{
      this.year=this.numbers
    }
  }
  }
  cancel(){
    this.matDialog.closeAll()
  }
  apply(){
    // //console.log(this.type);
    // //console.log(this.loop);
    // //console.log(this.pack);
    
    this.data = {

    }
    if(this.username_checked){
      Object.assign(this.data,{username:this.username})
    }
    if(this.title_checked){
      Object.assign(this.data,{title:this.title})

    }
    if(this.days){
      Object.assign(this.data,{days :7})
    }
    if(this.month){
      Object.assign(this.data,{days :30})
    }
    if(this.year){
      Object.assign(this.data,{days :365})
    }
    if(this.pack){
      Object.assign(this.data,{type :2})
      
    }
    if(this.loop){
      Object.assign(this.data,{type :1})
      
    }
    this.router.navigate(['downloadhistory'],{queryParams:this.data})
    this.matDialog.closeAll()
  }
}
