

<div class="row" >
  <div class="col-md-4 col-sm-6 col-xs-12">
    <div class="user-card" *ngFor="let items of tableData">
      <img [src]="setimage(items)">
      <h5><a (click)="redirecttoprofilepage(items)" class="link">@{{items.username}}</a></h5>
      <p class="text-muted">{{items.number_of_followers}} Followers, {{items.number_of_following}} Following</p>
      <p class="mb-0">{{items.country}}</p>
    </div>
  </div>

</div>


<div class="text-center" *ngIf="nocomments">
  <ul class="paginationlist">
      <li class="text-info" *ngIf="!norecordfound">Showing {{record}} to {{pagenocount}} records of {{total}}</li>
      <!-- <li class="text-info" *ngIf="!noresult">Showing {{record}} records of {{total}}</li> -->
      <!-- <li class="text-info"> {{total}}</li> -->
      <ul class="nextulpagination" *ngIf="!hidepagination">

          <li class="paginationliyellowcolor bluecolor hover" [ngClass]="{'activated':item === pageno}"
              *ngFor="let item of pagercount" id="liststyle{{item}}" >
              {{item}}
<!-- (click)="paginationget(item)" -->
          </li>
      </ul>

  </ul>


</div>

<div *ngIf="!nocomments" class="text-center" style="
  
  padding: 10px;
">No records to display</div>