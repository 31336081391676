import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { APIURL } from 'src/app/allServices/constant.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { Adminvariables } from '../../globaladminvariable';

@Component({
  selector: 'app-sidebar-left-inner',
  templateUrl: './sidebar-left-inner.component.html'
})
export class SidebarLeftInnerComponent {

  ownername = ""
  ownerName: any;
  constructor(public router: Router, private http: HttpClient,public common: commonservice,private route: ActivatedRoute) {
    this.ownerName = Adminvariables.ownername;
    if (window.location.href.indexOf('admin') > -1) {
      this.common.event$.subscribe(ev => {
        if (this.common.eventResMatch('_ADMINUPDATE_profile', ev, false)) {
          if (localStorage.getItem("adminprofiledetail")) {
            this.call();
          } else {
            this.getapicallforupdate();
          }
        }
      });
    }

  }

  ngOnInit(): void {
    // ;
  //  if(this.route.snapshot.paramMap.get('subscriptiontiers')) {
  //  //console.log("yes")
  //  } else {
  //    //console.log(this.router.url)
  //   }
    // this.getapicallforupdate();
    // this.getapicallforupdate();
    if (window.location.href.indexOf('admin') > -1) {
      if (localStorage.getItem("adminprofiledetail")) {
        this.call();
      } else {
        this.getapicallforupdate();
      }
    }
    // this.call();
  }

  call() {
    let local:any = localStorage.getItem("adminprofiledetail");
    let data = JSON.parse(local);
    // //console.log(data.first_name +" "+ data.last_name);
    // this.getapicallforupdate();
    this.ownerName = data.first_name + " " + data.last_name;
    // //console.log(this.ownerName,"owner name");
  }

  getapicallforupdate() {
    let tokkenget = localStorage.getItem("loggedintoken");

    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    this.http.get(APIURL.BASE_URL+`/admin/get_admin_profile_detail`, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
      let  first_name = res.response.data.first_name;
       let second_name =  res.response.data.last_name;
        this.ownername = first_name +" "+ second_name;
          
  

      } else {

  

      }
    }, async e => {
      // //console.log('Error', e);
    });
  }


}
