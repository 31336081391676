

import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, INPUT_TYPE_NAME, VARS } from 'src/app/allServices/constant.service';
import { StaticService } from 'src/app/sevicesall/static.service';
import * as CryptoJS from 'crypto-js';
import { BrowserModule } from '@angular/platform-browser'
import { CommonModule } from '@angular/common';
imports: [CommonModule];
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  detail: any = [];
  loops_loopkits: any = [];
  users: any = [];
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string
  arraytableforcommentsforloopkit: any = [];
  arraytableforrecommendeduser: any = [];
  arraytableforrecommendedloopkitsagain: any = [];
  profile_image_pathofcomments = "assets/img/artwork.jpg";


  constructor(private formbuilder: FormBuilder, public common: commonservice, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService) {
    this.getallinfoofdashboard();
  }

  ngOnInit(): void {
  }

  getallinfoofdashboard() {
    this.common.showLoader();



    let sendingdata = {



    }









    this.api.postApiHit(APIURL.ADMIN_GET_ALL_dashboard, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {



      if (res.response.status) {

        this.againgetinfo(res);

      } else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });


  }

  setimage(data: any) {
    // if (data.artwork_path == null) {

    //   return this.profile_image_pathofcomments;
    // } else {

    //   return CONSTNAME.fILE_PATH + data.artwork_path;
    // }
    if (data.profile_image_path == null) {


      return this.profile_image_pathofcomments;



    } else {
      return  data.profile_image_path;

    }

  }

  setimageforupload(data: any) {
    if (data.artwork_path == null) {
      if (data.profile_image_path == null) {
        return this.profile_image_pathofcomments;
       
      } else {
        return data.profile_image_path;
      }

    } else {

      return CONSTNAME.fILE_PATH + data.artwork_path;
    }
  }

  sendtouserdetail(item:any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);

    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    this.router.navigate([`/admin/userdetails/${this.encrypted}`] , { queryParams: { "referer": encodeURI(this.router.url) }});
  }


  againgetinfo(res: any) {
    // //console.log(res.response.data);

    this.arraytableforcommentsforloopkit = res.response.data.details
    this.arraytableforrecommendedloopkitsagain = res.response.data.loops_loopkits
    this.arraytableforrecommendeduser = res.response.data.users

    // //console.log(this.detail)
    // //console.log(this.loops_loopkits)
    // //console.log(this.users)
    this.common.hideLoader();

  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);

    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`] , { queryParams: { "referer": encodeURI(this.router.url) }});
  }

  redirecttolooppage() {
    // //console.log("me")
    this.router.navigate([`/admin/loops/list-uploads`]);

  }

  redirecttouserpage() {
    this.router.navigate([`/admin/user`]);
  }

  redirectto(value: any) {

    this.encryptUsingAES2(value);
    // //console.log("yup");

  }

  encryptUsing(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);

    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    this.router.navigate([`/admin/user-detail/${this.encrypted}`]);
  }
  encryptUsingAES2(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    // window.open(`http://loophead-web.blinkcoders.com/detail-loop?loop_id=${this.encrypted}`, `_blank`)
    window.open(`${APIURL.BASE_URLforredirect}/user/sounds?user_id=${this.encrypted}`, `_blank`)
    // this.router.navigate(['user/sounds/'], { queryParams: { user_id: this.encrypted } });
    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);
  }
  redirecttodetail(value: any) {
    this.encryptUsingAES233(value);
  }
  encryptUsingAES233(value:any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    window.open(`${APIURL.BASE_URLforredirect}/detail-loop?loop_id=${this.encrypted}`, `_blank`)
    // window.open(`http://loophead-web.blinkcoders.com/user?user_id=${this.encrypted}`, `_blank`)
    // //console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);
  }


}






