import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { APIURL } from 'src/app/allServices/constant.service';
// import { APIURL } from '../allServices/constant.service';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-subscriptionplan',
  templateUrl: './subscriptionplan.component.html',
  styleUrls: ['./subscriptionplan.component.css'],
  providers: [DatePipe]
})
export class SubscriptionplanComponent implements OnInit {
  isplannamevalidate = false;
  isplanpricevalidate = false;
  isrecurrenceintervalvalidate = false;
  isuploadloopsvalid = false;
  isuploadloopkit = false;
  isstatusvalid = false;
  isuploadloopkitvalid = false;
  iscansellloop = false;
  iscansellloopkit = false;
  isRecurrenceintervalunit = false;
  recuurenceintervaluniterror = "";
  plannameerror = "";
  planpriceerror = "";
  recuurenceintervalerror = "";
  recurrenceintervaluniterror = "";
  uploadloopserror = "";
  cansellloopserror = "";
  uploadloopkitserror = "";
  cansellloopkitserror = "";
  statuserror = "";
  displayname = "none";
  disabledvalue = false;
  sendingData: any = {};
  no = false;
  forzero = false;
  sandboxplan = "";
  liveplanid = "";
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;



  validationforplanname = {
    plannamecheck: '',
    isplanvalid: false
  }


  validationforplanprice: any = {
    planpricecheck: '',
    isplanpricevalid: false
  }

  validationforRecurrenceinterval: any = {
    recurrenceintervalcheck: '',
    recurrenceintervalvalid: false
  }

  validationforRecurrenceintervalunit: any = {
    recurrenceintervalunitcheck: '',
    recurrenceintervalunitvalid: false
  }

  validationforUploadloops = {
    Uploadloopscheck: '',
    Uploadloopsvalid: false
  }

  validationforcansellloops = {
    cansellloopscheck: '',
    cansellloopsvalid: false
  }

  validationforUploadloopkits = {
    Uploadloopkitscheck: '',
    Uploadloopkitsvalid: false
  }

  validationforCansellloopkits = {
    Cansellloopkitscheck: '',
    Cansellloopkitsvalid: false
  }

  validationforstatus: any = {
    statuscheck: "1",
    statusvalid: false
  }

  validationforcreateddate = {
    datecheck: ''
  }
  validationformodifieddate = {
    datecheckd: ''
  }


  submitbutton = {
    submitresponse: "Save changes ",

  }
  createdate: any = "";
  modifidate: any = "";
  name = "";
  detail = "";
  encryptedagainremoveslashes: string;






  constructor(private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, private datePipe: DatePipe) {
    this.encrypted = this.route.snapshot.params['id'];
    this.encryptedagainremoveslashes = decodeURIComponent(this.encrypted);
    this.decryptUsingAES256();
    
    if (this.route.snapshot.params['id'] !== "new") {
      this.name = "Edit";
      this.detail ="Any changes in the subscription plan will not effect the current users subscriptions. The changes will be valid only for new users."
      this.getapicallforsubscriptionsetting();

    } else {
      // //console.log("nahi hoga");
      this.name = "Add";
    }

  }




  ngOnInit() {

  }

  decryptUsingAES256() {
    // //console.log(this.encryptedagainremoveslashes);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

  }


  getapicallforsubscriptionsetting() {
    // //console.log(this.decrypted);
    let Idfromurl = this.route.snapshot.params['id'];
    let tokkenget = localStorage.getItem("loggedintoken");
    let data = {

      "plan_id": this.decrypted

    }

    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    this.http.get(APIURL.BASE_URL+`/admin/get_subscription_plan_detail?plan_id=${this.decrypted}`, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        // //console.log(res.response.data[0]);

        // this.validateemail.emailcheck = res.response.data[1].meta_value,
        //   this.validatetopchart.topchartcheck = res.response.data[2].meta_value,
        //   this.validationinstagram.instagramcheck = res.response.data[3].meta_value,
        //   this.validationtwitter.twittercheck = res.response.data[4].meta_value,
        //   this.validationdiscord.discordcheck = res.response.data[5].meta_value,
        //   this.validaterecommendloop.loopcheck = res.response.data[6].meta_value
        // //console.log(res);
        this.validationforplanname.plannamecheck = res.response.data[0].plan_name,
          this.validationforplanprice.planpricecheck = res.response.data[0].plan_price,
          this.validationforRecurrenceinterval.recurrenceintervalcheck = res.response.data[0].recurrence_interval,
          this.validationforRecurrenceintervalunit.recurrenceintervalunitcheck = res.response.data[0].recurrence_interval_unit,
          this.validationforUploadloops.Uploadloopscheck = res.response.data[0].loops_to_upload,
          // this.validationforcansellloops.cansellloopscheck = res.response.data[0].can_sell_loops,
          this.validationforUploadloopkits.Uploadloopkitscheck = res.response.data[0].loopkits_to_upload,
          // this.validationforCansellloopkits.Cansellloopkitscheck = res.response.data[0].can_sell_loopkits,
          this.validationforstatus.statuscheck = res.response.data[0].is_enabled;
        const date = new Date(res.response.data[0].created_on);

        let createddate = moment(res.response.data[0].created_on).format('DD-MM-YYYY h:mm A');
        this.createdate = this.datePipe.transform(date, 'YY-MM-yyyy hh:mm a');
        this.validationforcreateddate.datecheck = createddate ;


        this.sandboxplan = res.response.data[0].sandbox_plan_id;
        if (res.response.data[0].live_plan_id == null) {
          this.liveplanid = "";
        }
       
        if (res.response.data[0].modified_on == null) {
          this.validationformodifieddate.datecheckd = "";
        } else {
          const moddate = new Date(res.response.data[0].modified_on);
          let modifieddate = moment(res.response.data[0].modified_on).format('DD-MM-YYYY h:mm A');
          // this.modifidate = this.datePipe.transform(moddate, 'YY-MM-yyyy hh:mm a');
          this.validationformodifieddate.datecheckd =  modifieddate;
        }


        // //console.log(res.response.data[0].is_enabled);
        if (res.response.data[0].is_enabled == 0) {
          this.validationforstatus.statuscheck = '0';
        } else {
          this.validationforstatus.statuscheck = '1';
        }

        if (res.response.data[0].can_sell_loopkits == 0) {
          this.validationforCansellloopkits.Cansellloopkitscheck = '0';
        } else {
          this.validationforCansellloopkits.Cansellloopkitscheck = '1';
        }
        if (res.response.data[0].can_sell_loops == 0) {
          this.validationforcansellloops.cansellloopscheck = '0';
        } else {
          this.validationforcansellloops.cansellloopscheck = '1';
        }

        if (res.response.data[0].plan_price === 0) {
          this.forzero = true;
          // alert("zero aya");
        }






        // if (res.response.data[0].can_sell_loopkits == 0) {
        //   this.validationforstatus.statuscheck = 0;
        // } else {
        //   this.validationforstatus.statuscheck = 1;
        // }
      } else {
        this.toastr.error(res.response.errors, "Error", { positionClass: 'toast-bottom-right' });
        // //console.log(res);
        // let errorthroughapi = res.response.errors;
        // let error = [];
        // for (var i = 0; i < errorthroughapi.length; i++) {
        //   let jk = error.push(errorthroughapi);
        //   this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        // }

      }
    }, async e => {
      // //console.log('Error', e);
      this.toastr.error("Error in Api", "Error", { positionClass: 'toast-bottom-right' });

    });
  }





























  subscriptionapi() {

    let tokkenget = localStorage.getItem("loggedintoken");
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    this.sendingData = {
      "plan_name": this.validationforplanname.plannamecheck,
      "plan_price": this.validationforplanprice.planpricecheck,
      "recurrence_interval": this.validationforRecurrenceinterval.recurrenceintervalcheck,
      "recurrence_interval_unit": this.validationforRecurrenceintervalunit.recurrenceintervalunitcheck,
      "loops_to_upload": this.validationforUploadloops.Uploadloopscheck,
      "can_sell_loops": this.validationforcansellloops.cansellloopscheck,
      "loopkits_to_upload": this.validationforUploadloopkits.Uploadloopkitscheck,
      "can_sell_loopkits": this.validationforCansellloopkits.Cansellloopkitscheck,
      "is_enabled": this.validationforstatus.statuscheck,
      "sandbox_plan_id": this.sandboxplan,
      "live_plan_id":this.liveplanid


    }
    if (this.route.snapshot.params['id'] !== "new") {
      this.sendingData["plan_id"] = this.decrypted;

    } else {

    }
    // //console.log(this.sendingData);

    this.http.post(APIURL.BASE_URL+'/admin/save_subscription_plan', this.sendingData, { headers }).subscribe(async (res: any) => {
      this.disabledvalue = false;
      this.submitbutton.submitresponse = "Save changes";
      this.displayname = "none";
      if (res.response.status == 1) {

        let successmess = res.response.msg;

        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        this.router.navigate(['/admin/subscriptiontiers']);

      } else {

        let errorthroughapi = res.response.errors;
        let error = [];
        this.displayname = "none";
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.submitbutton.submitresponse = "Save changes";
      this.displayname = "none";

      this.toastr.error("Error please try again later", "", { positionClass: 'toast-bottom-right' });
    });



  }






  onsavesubscription() {


    if ((this.validationforRecurrenceintervalunit.recurrenceintervalunitcheck.length !== 0) && (this.validationforRecurrenceintervalunit.recurrenceintervalunitcheck !== 0)) {

      this.isRecurrenceintervalunit = true;
      this.recuurenceintervaluniterror = "";
    } else {
      this.isRecurrenceintervalunit = false;
      this.recuurenceintervaluniterror = "Recurrence interval unit is required"
    }




    if (this.validationforplanname.plannamecheck.length !== 0) {
      this.validationforplanname.isplanvalid = true;
      this.plannameerror = "";
      this.isplannamevalidate = true;

    } else {
      this.validationforplanname.isplanvalid = false;
      this.plannameerror = "Plan Name is required";
      this.isplannamevalidate = false;
    }

    let Planprice = this.validationforplanprice.planpricecheck;
    let recuranceinterval = this.validationforRecurrenceinterval.recurrenceintervalcheck;

    if (recuranceinterval != null && recuranceinterval.length !== 0) {

      if (isNaN(parseInt(recuranceinterval)) || (parseInt(recuranceinterval) < 0)) {
        this.validationforRecurrenceinterval.recurrenceintervalvalid = false;
        this.recuurenceintervalerror = "Only positive no  is valid";
        this.isrecurrenceintervalvalidate = false;
      } else {
        if (parseInt(recuranceinterval) == 0) {
          if ((parseInt(Planprice) == 0)) {
            this.validationforRecurrenceinterval.recurrenceintervalvalid = true;
            this.recuurenceintervalerror = "";
            this.isrecurrenceintervalvalidate = true;
          } else {
            this.validationforRecurrenceinterval.recurrenceintervalvalid = false;
            this.recuurenceintervalerror = "Cannot set zero until the plan price is zero";

            this.isrecurrenceintervalvalidate = false;
          }


        } else {
          this.validationforRecurrenceinterval.recurrenceintervalvalid = true;
          this.recuurenceintervalerror = "";
          this.isrecurrenceintervalvalidate = true;
        }

      }
    } else {
      this.validationforRecurrenceinterval.recurrenceintervalvalid = false;
      this.recuurenceintervalerror = "Recurrence Interval is required ";
      this.isrecurrenceintervalvalidate = false;
    }


    Planprice = this.validationforplanprice.planpricecheck;
    // //console.log(loop);
    if (Planprice != null && Planprice.length !== 0) {

      if (isNaN(parseInt(Planprice)) || (parseInt(Planprice) < 0)) {
        this.validationforplanprice.isplanpricevalid = false;
        this.planpriceerror = "Only positive no and zero is valid";
        this.isplanpricevalidate = false;
      } else {
        if (parseInt(Planprice) === 0) {
          this.validationforplanprice.isplanpricevalid = true;
          // this. planpriceerror = "Cannot set zero or below zero";
          this.planpriceerror = "";
          this.isplanpricevalidate = true;

          this.validationforRecurrenceinterval.recurrenceintervalvalid = true;
          this.recuurenceintervalerror = "";
          this.isrecurrenceintervalvalidate = true;
          this.validationforRecurrenceinterval.recurrenceintervalcheck = 0;
          this.validationforRecurrenceintervalunit.recurrenceintervalunitcheck = 0;


          this.isRecurrenceintervalunit = true;
          this.recuurenceintervaluniterror = "";


          // if ((isNaN(parseInt(recuranceinterval)) && recuranceinterval.length !== 0 ) || (parseInt(recuranceinterval) < 0)) {
          //   this.validationforRecurrenceinterval.recurrenceintervalvalid = false;
          //   this.recuurenceintervalerror = "Only positive no  is valid";
          //   this.isrecurrenceintervalvalidate = false;
          // } else {

          //   this.validationforRecurrenceinterval.recurrenceintervalvalid = true;
          //   this.recuurenceintervalerror = "";
          //   this.isrecurrenceintervalvalidate = true;
          // }

        } else {
          this.validationforplanprice.isplanpricevalid = true;
          this.planpriceerror = "";
          this.isplanpricevalidate = true;
        }

      }
    } else {
      this.validationforplanprice.isplanpricevalid = false;
      this.planpriceerror = "Plan Price is required";
      this.isplanpricevalidate = false;
    }





    let uploadloop = this.validationforUploadloops.Uploadloopscheck;
    // //console.log(loop);
    if (uploadloop != null && uploadloop.length !== 0) {

      if (isNaN(parseInt(uploadloop)) || (parseInt(uploadloop) < 0)) {
        this.validationforUploadloops.Uploadloopsvalid = false;
        this.uploadloopserror = "Only positive no  is valid";
        this.isuploadloopsvalid = false;
      } else {
        if (parseInt(Planprice) === 0) {
          this.validationforUploadloops.Uploadloopsvalid = true;
          // this. planpriceerror = "Cannot set zero or below zero";
          this.uploadloopserror = "";
          // this.isplanpricevalidate = true;
          this.isuploadloopsvalid = true;
        } else {
          this.validationforUploadloops.Uploadloopsvalid = true;
          this.uploadloopserror = "";
          this.isuploadloopsvalid = true;
        }

      }
    } else {
      this.validationforUploadloops.Uploadloopsvalid = false;
      this.uploadloopserror = "Upload Loops is required ";
      this.isuploadloopsvalid = false;
    }


    let uploadloopkit = this.validationforUploadloopkits.Uploadloopkitscheck;
    // //console.log(loop);
    if (uploadloopkit != null && uploadloopkit.length !== 0) {

      if (isNaN(parseInt(uploadloopkit)) || (parseInt(uploadloopkit) < 0)) {
        this.validationforUploadloopkits.Uploadloopkitsvalid = false;
        this.uploadloopkitserror = "Only positive no  is valid";
        this.isuploadloopkit = false;
      } else {
        if (parseInt(Planprice) === 0) {
          this.validationforUploadloopkits.Uploadloopkitsvalid = true;
          // this. planpriceerror = "Cannot set zero or below zero";
          this.uploadloopkitserror = "";
          // this.isplanpricevalidate = true;
          this.isuploadloopkit = true;
        } else {
          this.validationforUploadloopkits.Uploadloopkitsvalid = true;
          this.uploadloopkitserror = "";
          this.isuploadloopkit = true;
        }

      }
    } else {
      this.validationforUploadloopkits.Uploadloopkitsvalid = false;
      this.uploadloopkitserror = "Upload Loopkits is required ";
      this.isuploadloopkit = false;
    }

    if (this.validationforstatus.statuscheck.length !== 0) {
      // alert();
      this.statuserror = "";
      this.isstatusvalid = true;
      // alert(this.validationforstatus.statuscheck);
    } else {
      this.statuserror = "Status is required";
      this.isstatusvalid = false;
    }

    if (this.validationforCansellloopkits.Cansellloopkitscheck.length !== 0) {
      this.cansellloopkitserror = "";
      this.iscansellloopkit = true;
      // alert(this.validationforCansellloopkits.Cansellloopkitscheck);
    } else {
      this.cansellloopkitserror = "Can Sell Loopkits is required";
      this.iscansellloopkit = false;
    }


    if (this.validationforcansellloops.cansellloopscheck.length !== 0) {
      this.cansellloopserror = "";
      this.iscansellloop = true;
      // alert(this.validationforcansellloops.cansellloopscheck);
    } else {
      this.cansellloopserror = " Can Sell Loops is required";
      this.iscansellloop = false;
    }




    if ((this.isplannamevalidate == true) && (this.isplanpricevalidate == true) && (this.isrecurrenceintervalvalidate) && (this.isuploadloopsvalid) && (this.isuploadloopkit == true) && (this.isstatusvalid == true) && (this.iscansellloopkit == true) && (this.iscansellloop == true) && (this.isRecurrenceintervalunit == true)) {

      this.submitbutton.submitresponse = "Loading...";
      this.disabledvalue = true;
      this.displayname = "";
      this.subscriptionapi();
      // API 

    } else {
      // this.toastr.error("Some error please come later", "Error", { positionClass: 'toast-bottom-right' });
    }
  }

  


}














