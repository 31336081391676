<div class="">
    <div (click)="cancel()"class="modal-head">
        <div class=" col-md-6 heding">
            <img src="../../../../assets/img/x-close.svg" />
            
            <div class="text-start" style="width: 100%; height: 100%; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div class="text">
                    <p>Following</p>
                    <span>People that you follow</span>
                </div>
                <img style="width: 35px" src="../../../../assets/img/logo.png" />
                <img style="width: 35px" src="../../../../assets/img/logo-secondary.png" class="img" />
              </div>
        </div>
        <div class="col-md-6 srch">
            <input type="text" [(ngModel)]="search" value="search" (input)="performsearch()"/>
            <!-- <button (click)="performsearch()">Search</button> -->
        </div>
    </div>
    
    
    <div class="list mt-5" *ngIf="total!=0" >
        <ul type="none" *ngFor="let item of data" > 
            <li style="cursor: pointer;" (click)="redirecttouser(item.username)">
                <div>
                    <img class="img" [src]="setimage(item)" width="50px" height="50px"/>
                </div>
                <div class="user">
                    <p  class="username">@{{item.username}}</p>
                </div>
            </li>
        </ul>
    </div>
    
<div *ngIf="total==0">
    <h5 class="empty">No Followers</h5> 
 </div>
    </div>