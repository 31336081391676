import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-emailsent',
  templateUrl: './emailsent.component.html',
  styleUrls: ['./emailsent.component.css']
})
export class EmailsentComponent implements OnInit {

  constructor(
    public router: Router, 
    public matDialog : MatDialog
  ) { }

  ngOnInit(): void {
    // //console.log("the");
    
  }

  redirecttologin(){
    this.matDialog.closeAll()
    this.router.navigate(['/login']);

  }
}
