import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiServiceService } from './allServices/api-service.service';
import { APIURL, VARS } from './allServices/constant.service';

@Injectable({
  providedIn: 'root'
})
export class ForprogressService {

  constructor(private http: HttpClient,public api: ApiServiceService) { }

  upload(file: File,name:any) {
    const multipart = new FormData();
    multipart.append('main_loopkit', file,name);
    let tokkenget = localStorage.getItem("clientintoken");

    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    return this.http.post(`${APIURL.BASE_URL}/user/upload_loopkit_draft`, multipart, {headers ,reportProgress: true, observe:"events"})
  }
}
