import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL } from 'src/app/allServices/constant.service';
import { HeaderComponent } from '../../CLIENT_PAGE_HEADER_FOOTER/header/header.component';

@Component({
  selector: 'app-confirm-leave',
  templateUrl: './confirm-leave.component.html',
  styleUrls: ['./confirm-leave.component.css']
})
export class ConfirmLeaveComponent implements OnInit {
  expirydateget: any;
  value: any
  id: any;
  pagevalue: any;
  pagename: string;
  constructor(public matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public api: ApiServiceService, private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, public common: commonservice, private dialogRef: MatDialogRef<HeaderComponent>) { }

  ngOnInit(): void {
    this.expirydateget = this.data.pageValue;
    // //console.log(this.data.pagename);
    // //console.log(this.data.pageValue);
    if (this.data.pagename == "loop") {
      // //console.log("loop");
      this.runloop();
    } else {
      this.runloopkit();
      // //console.log("loopkit");
    }
    
  }


  runloop() {
    let tokkenget: any = localStorage.getItem("clientintoken");
    let headers: any = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    this.http.post(`${APIURL.BASE_URL}/user/upload_loop_draft`, this.expirydateget, { headers, reportProgress: true, observe: "events" }).pipe().subscribe((event: any) => {
      // //console.log(event.type);
      if (event['loaded'] && event['total']) {
        this.value = Math.round((event['loaded'] / event['total'] * 100))
      }
      if (event.type == 4) {
        // //console.log(event.body.response.data.loop_id)
        this.id = event.body.response.data.loop_id
        this.dialogRef.close({ data: this.id });
      }
    })
  }

  runloopkit() {
    let tokkenget: any = localStorage.getItem("clientintoken");
    let headers: any = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    this.http.post(`${APIURL.BASE_URL}/user/upload_loopkit_draft`, this.expirydateget, { headers, reportProgress: true, observe: "events" }).pipe().subscribe((event: any) => {
      // //console.log(event.type);
      if (event['loaded'] && event['total']) {
        this.value = Math.round((event['loaded'] / event['total'] * 100))
      }
      if (event.type == 4) {
        // //console.log(event.body.response.data.loop_id)
        this.id = event.body.response.data.loop_id
        this.dialogRef.close({ data: this.id });
      }
    })
  }

  cancel() {
    // this.subscription.unsubscribe();
  }




}
