<!-- <p>filter works!</p> -->
<div class="wrapper filter">
   
    <form [formGroup]="advancesearch">
        <div (click)="cancel()" style="background-color: transparent; color: gray; display: flex;column-gap: 17px;">
            <img src="../../../../assets/img/x-close.svg"/>
            <h6 class="filter-modal-heading">Filter</h6>
        </div>
        
            <div class="row">
                <div class=" col-xs-12">
                  <div class="keygroup-wrap">
                    <p>Key</p>
                    <mat-dialog-content>
                        <div class="keygroup col-sm-2 col-3" *ngFor="let item of data.arrayforradio">
                          <button class="key" [ngStyle]="{'background-color': item === selectedKey ? 'gray' : 'initial'}" mat-button (click)="onButtonClick(item,$event)">{{ item }}</button>
                        </div>
                        <mat-dialog-content>
                          <div class="keygroup col-sm-2 col-3" *ngFor="let item of data.arrayformainkey">
                            <button class="key" [ngStyle]="{'background-color': item.key === selectedKey1 ? 'gray' : 'initial'}" mat-button (click)="onButtonClick1(item.key)"> {{item.name}} </button>
                          </div>
                        </mat-dialog-content>
                      </mat-dialog-content>
                    
                  </div>
                </div>
            </div>
            <div class="input-wrap bpm-filter1">
                <h6>BPM</h6>
                <div style="    margin-left: 5px;">
                    <div class="row">
                        <div class="col-1">                    
                            <input  type="radio" value="0"  formControlName ="bpm" name="bpm"/>
                        </div>

                        <div class="col-9" >
                            <p class="filter-p">Exact</p>
                            <input class="filter-input"  type="text" placeholder="BPM" formControlName="exact_bpm" />
                        </div>
                    </div>
                    <div class="row range-space">
                        <div class="col-1">                    
                            <input  type="radio" value="1" formControlName="bpm"  name="bpm"/>
                        </div>
                        <div class="col-9">
                            <p class="filter-p">Range</p>
                            <div style="display: flex;"> <input type="text" class="filter-input" placeholder="BPM" formControlName="min_bpm"/>
                                <p class="to">to</p>
                                <input type="text" class="filter-input" placeholder="BPM"   formControlName="max_bpm" /></div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="form-group">
                <div class="tabs tabs-alt ng-star-inserted">
                    <input type="checkbox"
                    (click)="Rangeactive()">BPM Range</input><input type="checkbox" 
                    (click)="exactbpmctive()">Exact BPM </input>
      
                </div>
                <div *ngIf="hiderangeactive">
                  <ng5-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ng5-slider>
                </div>
      
                <div *ngIf="hideexactbpm">
                  <label for="Genre">BPM</label>
                  <input type="number" placeholder="Bpm" formControlName="exact_bpm" (keyup)="valueforBPM()">
                  <small class="text-danger">{{errorshowbpm}}</small>
                </div>
      
              </div> -->
      <button class="btn" (click)="apply()">Apply</button>
    </form>
</div>
<div *ngIf="active">
<app-maintagsearchforadvance [items]="check"></app-maintagsearchforadvance>
</div>